import {tinyAlert,} from "./_config.services";

class FormUtilService {
  // constructor() { }

  async currencyThresholds(data: any) {
    tinyAlert();
   let work_flow_data, is_department;
   
    if (
        data.currency?.label === "USD" ||
        data.currency?.label === "NGN"
    ) {
        work_flow_data = data.form_workflow?.workflowThresholds.find(
            (x:any) => x.currency === data.currency?.label
        );
    } else {
        work_flow_data = data.form_workflow?.workflowThresholds.find(
            (x:any) => x.currency === "USD"
        );
    }

    // check if selected department is part of work flow;
    if (!!data.department?.value) {
        is_department = !!data.form_workflow?.workflowDepartments.find(
            (x:any) => x.departmentId === data.department?.value
        );
    }

    // workflow-frontend-validation
    if (work_flow_data) {
        if (
            Number(data.ContractValue) + Number(data.AmendmentValue) >=
            (is_department
                ? work_flow_data.departmentContractValueThreshold
                : work_flow_data.contractValueThreshold)
        ) {
            tinyAlert(
                "error",
                `thresholds amount should be less than ${!!data.currency?.value ? data.currency?.label : "USD"
                } ${is_department
                    ? work_flow_data.departmentContractValueThreshold.toLocaleString()
                    : work_flow_data.contractValueThreshold.toLocaleString()
                }`
            );
        }
    }
  }
  

};

const form_util = new FormUtilService();
export default form_util;