
import { LuInfo } from "react-icons/lu";
export default function FormDescription(props:any) {
    const formDescription = props.data;
    return (
        <div className="bg-blue-50  dark:bg-gray-700 ml-4 mr-4 px-4 py-4 rounded-bl-[15px] rounded-br-[15px] flex shadow font-12 relative top-neg-25 ease-out text-[#1350b9] dark:text-blue-500">

            <LuInfo className='text-[#1350b9] text-4xl dark:text-blue-500'  />

            {
                !!formDescription ?
                    <div className='block px-3 text-justify form-description leading-5 text-sm font-semibold' dangerouslySetInnerHTML={{ __html: !!formDescription ? formDescription.description : ''  }} />
                :
                    <ul className="space-y-2 w-3/4 px-4">
                        <li className="flex items-center space-x-4 animate-pulse">
                            <div className="bg-gray-200 h-4 w-4 rounded-full"></div>
                            <div className="flex-1 py-1 w-3/4">
                                <div className="h-3 bg-gray-200 w-full rounded"></div>
                            </div>
                        </li>

                        <li className="flex items-center space-x-4 animate-pulse">
                            <div className="bg-gray-300 h-4 w-4 rounded-full"></div>
                            <div className="flex-1 py-1">
                                <div className="h-3 bg-gray-200 w-1/2 rounded"></div>
                            </div>
                        </li>

                        <li className="flex items-center space-x-4 animate-pulse">
                            <div className="bg-gray-200 h-4 w-4 rounded-full"></div>
                            <div className="flex-1 py-1">
                                <div className="h-3 bg-gray-200 w-2/3 rounded"></div>
                            </div>
                        </li>
                    </ul>
            }
        </div>
    )
}