// seen - review useLoginTepngUser custom-hook;


import useLogoutTepngUser from '../../hooks/UseLogoutTepngUser';

const LogoutTepngUser = () => {
  useLogoutTepngUser();

  return (<></>);
};

export default LogoutTepngUser;
