import { CheckValidationKeys, formatYYYYMMDD } from "../../utils";
import { makePOST, makePUT, makeREQUEST, tinyAlert, printError, } from "../_config.services";


// declare required fields here;
const requiredInputs = ['ContractTitle','ContractorsName','ContractValue','AgoraReference','ReferenceCost','NewCost'];
const requiredSelect = ['currency','department','ddq_status','assets','nuprc_status','procurement_method', 'human_right'];


class CPNCRoutingSlipFormService {
  // constructor() { }

  // create a new from as draft or published
  // replace ANY with right types
  async setForm(data: any): Promise<any> {
    try{
      
      const user = data.user;
      const input = data.formInputs;
      const form_approval = data.form_approval;

      // [validation 1] check for required input-fields here;
      const emptyRequiredInputs = Object.keys(data.formInputs).filter(key => data.formInputs[key] === "");
      const emptyRequiredSelects = Object.keys(data.formSelects).filter(key => {
        return !(data.formSelects[key]?.value === undefined ? data.formSelects[key] :  data.formSelects[key]?.value);
      });

      let missingItems = '';
      missingItems += CheckValidationKeys(requiredInputs, emptyRequiredInputs);
      missingItems += CheckValidationKeys(requiredSelect, emptyRequiredSelects);

      if(missingItems){
        tinyAlert('error', 'Complete all required field: ' + missingItems);
        return;
      }

      // [validation 2] validate alternative email && user emails
      if(user.email === input.AlternateEmailAddress){
        tinyAlert('error', 'You cannot use yourself as the alternative email address.');
        return;
      }

      // check if approval sequence is not all empty
      const form_approval_emails = form_approval.every((obj:any) => obj.email.trim() !== '');
      if (!form_approval_emails) {
          tinyAlert('error','Some or all emails in approval sequence are empty.');
          return;
      }

      const invalid_emails = form_approval.filter((obj: any) => (obj.email.trim() === user.email) || (obj.email.trim() === input.AlternateEmailAddress));
      console.log(invalid_emails);

      if (invalid_emails.length > 0) {
        tinyAlert('error','Some or all emails in approval sequence cannot have your email address or alternative email address.');
        return;
      }

      // [validation 3] check if approval sequence does not have same email address;
      const emails = data.form_approval.map((obj:any) => obj.email);
      const uniqueEmails = new Set(emails);
      if (emails.length !== uniqueEmails.size) {
        tinyAlert('error','Approval sequence with the same approval emails are not allowed!.');
        return;
      }

      if(input.ContractNumber && input.ContractNumber.length < 10){
        tinyAlert('error','Contract Number must be equal to 10 numbers');
        return;
      }

      if(input.PRNumber && input.PRNumber.length < 8){
        tinyAlert('error','PR Number must be equal to 8 numbers');
        return;
      }
      
      const select = data.formSelects;
      let others_description = "Others";

      const form: any = new FormData();
      form.append("ContractTitle", input.ContractTitle);
      form.append("ContractorsName", input.ContractorsName);
      form.append("ContractValue", input.ContractValue);
      form.append("AmendmentValue", Number(input.AmendmentValue));
      form.append("Currency", select.currency.value);
      form.append("AgoraReference", input.AgoraReference);
      form.append("DepartmentId", select.department.value);
      form.append("DDQStatus", select.ddq_status.value);
      form.append("Asset", select.assets.value);
      form.append("StartDate", formatYYYYMMDD(select.start_date));
      form.append("EndDate", formatYYYYMMDD(select.end_date));
      form.append("NewDate", formatYYYYMMDD(select.new_end_date));
      form.append("NUPRCStatus", select.nuprc_status.value);
      form.append("ProcurementMethod", select.procurement_method.value);
      form.append("MarketIntelligence", select.market_intelligence.value);
      form.append("humanrights", select.human_right.value);
      form.append("ContractNumber", input.ContractNumber);
      form.append("PRNumber", input.PRNumber);
      form.append("NameOfBuyer", input.NameOfBuyer);
      form.append("AmendmentNo", input.AmendmentNo);
      form.append("AlternateEmailAddress", input.AlternateEmailAddress);
      form.append("SavingsAmount", Number(input.NewCost) - Number(input.ReferenceCost));
      form.append("ReferenceCost", input.ReferenceCost);
      form.append("NewCost", input.NewCost);
      form.append("ContractJustification", input.ContractJustification);
      form.append("SavingsJustification", input.SavingsJustification);
      form.append("IsDraft", data.IsDraft);
      form.append("FormApprovers", JSON.stringify(data.form_approval));

      for (let i = 0; i < data.uploadedFiles.length; i++) {
          if (data.uploadedFilesAttachType[i] === "Fully signed CPSC Presentation")
          form.append("FullySignedCpscPresentation", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "CPSC resolution and follow on actions (if any)")
          form.append("CpscResolutionAndFollowOnActions", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Close out of CPSC follow on actions")
          form.append("CloseOutOfCpscFollowOnActions", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Signed RTA by Duet")
          form.append("SignedRtaByDuet", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Draft Contract/PO to be reviewed by Signatories")
          form.append("DraftContractPoToBeReviewedBySignatories", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Draft Memo to be reviewed and commented along with the draft contract")
          form.append("DraftMemoToBeReviewedAndCommentedAlongWithTheDraftContract", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "CPSC Approval or Md's Email")
          form.append('CPSCApprovalOrMDsEmail', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === 'Draft Contract or Amendment')
          form.append('DraftContractOrAmendment', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Signed RTA")
          form.append('SignedRTA', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Technical Evaluation Result")
          form.append('TechnicalEvaluationResult', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Bidder's List")
          form.append('BiddersList', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Vendor's Quotation")
          form.append('VendorsQuotation', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Any Document Related to the Transaction")
          form.append('AnyDocumentRelatedToTheTransaction', data.uploadedFiles[i]);

          else {
            form.append('Others', data.uploadedFiles[i])
            others_description = data.uploadedFilesAttachType[i];
          }
      }

      form.append('OthersDescription', others_description);

      return await makePOST('CPNCRoutingSlipForm/Initiator/Submit', form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

      } catch (error:any) {
        const allKeysRequired = !!error.response.data.errors ? Object.keys(error.response.data.errors).join(', ') : error.response.data.Message;
        tinyAlert("error", 'Complete all required field: '+ JSON.stringify(allKeysRequired) );
        
      }
  }

  async updateForm(data: any): Promise<any> {
    try{

      const user = data.user;
      const input = data.formInputs;
      const form_approval = data.form_approval;

      // [validation 1] check for required input-fields here;
      const emptyRequiredInputs = Object.keys(data.formInputs).filter(key => data.formInputs[key] === "");
      const emptyRequiredSelects = Object.keys(data.formSelects).filter(key => {
        return !(data.formSelects[key]?.value === undefined ? data.formSelects[key] :  data.formSelects[key]?.value);
      });

      let missingItems = '';
      missingItems += CheckValidationKeys(requiredInputs, emptyRequiredInputs);
      missingItems += CheckValidationKeys(requiredSelect, emptyRequiredSelects);

      if(missingItems){
        tinyAlert('error', 'Complete all required field: ' + missingItems);
        return;
      }


      // [validation 2] validate alternative email && user emails
      if(user.email === input.AlternateEmailAddress){
        tinyAlert('error', 'You cannot use yourself as the alternative email address.');
        return;
      }

      // check if approval sequence is not all empty
      const form_approval_emails = form_approval.every((obj:any) => obj.email.trim() !== '');
      if (!form_approval_emails) {
          tinyAlert('error','Some or all emails in approval sequence are empty.');
          return;
      }

      const invalid_emails = form_approval.filter((obj: any) => (obj.email.trim() === user.email) || (obj.email.trim() === input.AlternateEmailAddress));
      console.log(invalid_emails);

      if (invalid_emails.length > 0) {
        tinyAlert('error','Some or all emails in approval sequence cannot have your email address or alternative email address.');
        return;
      }

      // [validation 3] check if approval sequence does not have same email address;
      const emails = data.form_approval.map((obj:any) => obj.email);
      const uniqueEmails = new Set(emails);
      if (emails.length !== uniqueEmails.size) {
        tinyAlert('error','Approval sequence with the same approval emails are not allowed!.');
        return;
      }

      if(input.ContractNumber && input.ContractNumber.length < 10){
        tinyAlert('error','Contract Number must be equal to 10 numbers');
        return;
      }

      if(input.PRNumber && input.PRNumber.length < 8){
        tinyAlert('error','PR Number must be equal to 8 numbers');
        return;
      }
      
      const select = data.formSelects;
      let others_description = "Others";

      const form: any = new FormData();
      form.append("ContractTitle", input.ContractTitle);
      form.append("ContractorsName", input.ContractorsName);
      form.append("ContractValue", input.ContractValue);
      form.append("AmendmentValue", Number(input.AmendmentValue));
      form.append("Currency", select.currency.value);
      form.append("AgoraReference", input.AgoraReference);
      form.append("DepartmentId", select.department.value);
      form.append("DDQStatus", select.ddq_status.value);
      form.append("Asset", select.assets.value);
      form.append("StartDate", formatYYYYMMDD(select.start_date));
      form.append("EndDate", formatYYYYMMDD(select.end_date));
      form.append("NewDate", formatYYYYMMDD(select.new_end_date));
      form.append("NUPRCStatus", select.nuprc_status.value);
      form.append("ProcurementMethod", select.procurement_method.value);
      form.append("MarketIntelligence", select.market_intelligence.value);
      form.append("humanrights", select.human_right.value);
      form.append("ContractNumber", input.ContractNumber);
      form.append("PRNumber", input.PRNumber);
      form.append("NameOfBuyer", input.NameOfBuyer);
      form.append("AmendmentNo", input.AmendmentNo);
      form.append("AlternateEmailAddress", input.AlternateEmailAddress);
      form.append("SavingsAmount", Number(input.NewCost) - Number(input.ReferenceCost));
      form.append("ReferenceCost", input.ReferenceCost);
      form.append("NewCost", input.NewCost);
      form.append("ContractJustification", input.ContractJustification);
      form.append("SavingsJustification", input.SavingsJustification);
      form.append("IsDraft", data.IsDraft);
      form.append("FormApprovers", JSON.stringify(data.form_approval));

      if (!!data.removedDocumentIds.length) {
          for (let i = 0; i < data.removedDocumentIds.length; i++) {
              form.append("RemovedDocumentIds", data.removedDocumentIds[i]);
          }
      }

      // processing-attachment-area
      for (let i = 0; i < data.uploadedFiles.length; i++) {
        if (data.uploadedFilesAttachType[i] === "Fully signed CPSC Presentation")
        form.append("FullySignedCpscPresentation", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "CPSC resolution and follow on actions (if any)")
        form.append("CpscResolutionAndFollowOnActions", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Close out of CPSC follow on actions")
        form.append("CloseOutOfCpscFollowOnActions", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Signed RTA by Duet")
        form.append("SignedRtaByDuet", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Draft Contract/PO to be reviewed by Signatories")
        form.append("DraftContractPoToBeReviewedBySignatories", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Draft Memo to be reviewed and commented along with the draft contract")
        form.append("DraftMemoToBeReviewedAndCommentedAlongWithTheDraftContract", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "CPSC Approval or Md's Email")
          form.append('CPSCApprovalOrMDsEmail', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === 'Draft Contract or Amendment')
          form.append('DraftContractOrAmendment', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Signed RTA")
          form.append('SignedRTA', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Technical Evaluation Result")
          form.append('TechnicalEvaluationResult', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Bidder's List")
          form.append('BiddersList', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Vendor's Quotation")
          form.append('VendorsQuotation', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Any Document Related to the Transaction")
          form.append('AnyDocumentRelatedToTheTransaction', data.uploadedFiles[i]);

          else {
            form.append('Others', data.uploadedFiles[i])
            others_description = data.uploadedFilesAttachType[i];
          }
      }

      form.append("OthersDescription", others_description);

      return await makePUT(`CPNCRoutingSlipForm/Initiator/Update/${data.formIDQuery}`, form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } 
    catch (error:any) {
      const allKeysRequired = !!error.response.data.errors ? Object.keys(error.response.data.errors).join(', ') : error.response.data.Message;
      tinyAlert("error", 'Complete all required field: '+ JSON.stringify(allKeysRequired) );
    }
  }

  async submitDraft(id: any): Promise<any> {
    try{
      const data = {
        "cpncRoutingSlipFormId": id
      }
      return await makeREQUEST("PUT", 'CPNCRoutingSlipForm/Draft', data)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } catch (error) {
      tinyAlert("error", 'there is an error');
      console.error('Error:', error);
    }
  }

  async reviewForm(data: any): Promise<any> {
    try{
      const user = data.user;
      const input = data.formInputs;
      const form_approval = data.form_approval;

      // [validation 1] check for required input-fields here;
      const emptyRequiredInputs = Object.keys(data.formInputs).filter(key => data.formInputs[key] === "");
      const emptyRequiredSelects = Object.keys(data.formSelects).filter(key => {
        return !(data.formSelects[key]?.value === undefined ? data.formSelects[key] :  data.formSelects[key]?.value);
      });

      let missingItems = '';
      missingItems += CheckValidationKeys(requiredInputs, emptyRequiredInputs);
      missingItems += CheckValidationKeys(requiredSelect, emptyRequiredSelects);

      if(missingItems){
        tinyAlert('error', 'Complete all required field: ' + missingItems);
        return;
      }

      // [validation 2] validate alternative email && user emails
      if(user.email === input.AlternateEmailAddress){
        tinyAlert('error', 'You cannot use yourself as the alternative email address.');
        return;
      }

      // check if approval sequence is not all empty
      const form_approval_emails = form_approval.every((obj:any) => obj.email.trim() !== '');
      if (!form_approval_emails) {
          tinyAlert('error','Some or all emails in approval sequence are empty.');
          return;
      }

      const invalid_emails = form_approval.filter((obj: any) => (obj.email.trim() === user.email) || (obj.email.trim() === input.AlternateEmailAddress));
      console.log(invalid_emails);

      if (invalid_emails.length > 0) {
        tinyAlert('error','Some or all emails in approval sequence cannot have your email address or alternative email address.');
        return;
      }

      // [validation 3] check if approval sequence does not have same email address;
      const emails = data.form_approval.map((obj:any) => obj.email);
      const uniqueEmails = new Set(emails);
      if (emails.length !== uniqueEmails.size) {
        tinyAlert('error','Approval sequence with the same approval emails are not allowed!.');
        return;
      }

      if(input.ContractNumber && input.ContractNumber.length < 10){
        tinyAlert('error','Contract Number must be equal to 10 numbers');
        return;
      }

      if(input.PRNumber && input.PRNumber.length < 8){
        tinyAlert('error','PR Number must be equal to 8 numbers');
        return;
      }
      
      const select = data.formSelects;
      let others_description = "Others";

      const form: any = new FormData();
      form.append("PreviousCPNCRoutingSlipFormId", data.parentFormId);
      form.append("ContractTitle", input.ContractTitle);
      form.append("ContractorsName", input.ContractorsName);
      form.append("ContractValue", input.ContractValue);
      form.append("AmendmentValue", Number(input.AmendmentValue));
      form.append("Currency", select.currency.value);
      form.append("AgoraReference", input.AgoraReference);
      form.append("DepartmentId", select.department.value);
      form.append("DDQStatus", select.ddq_status.value);
      form.append("Asset", select.assets.value);
      form.append("StartDate", formatYYYYMMDD(select.start_date));
      form.append("EndDate", formatYYYYMMDD(select.end_date));
      form.append("NewDate", formatYYYYMMDD(select.new_end_date));
      form.append("NUPRCStatus", select.nuprc_status.value);
      form.append("ProcurementMethod", select.procurement_method.value);
      form.append("MarketIntelligence", select.market_intelligence.value);
      form.append("humanrights", select.human_right.value);
      form.append("ContractNumber", input.ContractNumber);
      form.append("PRNumber", input.PRNumber);
      form.append("NameOfBuyer", input.NameOfBuyer);
      form.append("AmendmentNo", input.AmendmentNo);
      form.append("AlternateEmailAddress", input.AlternateEmailAddress);
      form.append("SavingsAmount", Number(input.NewCost) - Number(input.ReferenceCost));
      form.append("ReferenceCost", input.ReferenceCost);
      form.append("NewCost", input.NewCost);
      form.append("ContractJustification", input.ContractJustification);
      form.append("SavingsJustification", input.SavingsJustification);
      form.append("IsDraft", false);
      form.append("FormApprovers", JSON.stringify(data.form_approval));

      
      if (!!data.ReviewDocumentIds.length) {
          for (let i = 0; i < data.ReviewDocumentIds.length; i++) {
              form.append("ReviewDocumentIds", data.ReviewDocumentIds[i].id);
          }
      }

      if (!!data.removedDocumentIds.length) {
          for (let i = 0; i < data.removedDocumentIds.length; i++) {
              form.append("RemovedDocumentIds", data.removedDocumentIds[i]);
          }
      }

      for (let i = 0; i < data.uploadedFiles.length; i++) {
        if (data.uploadedFilesAttachType[i] === "Fully signed CPSC Presentation")
        form.append("FullySignedCpscPresentation", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "CPSC resolution and follow on actions (if any)")
        form.append("CpscResolutionAndFollowOnActions", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Close out of CPSC follow on actions")
        form.append("CloseOutOfCpscFollowOnActions", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Signed RTA by Duet")
        form.append("SignedRtaByDuet", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Draft Contract/PO to be reviewed by Signatories")
        form.append("DraftContractPoToBeReviewedBySignatories", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Draft Memo to be reviewed and commented along with the draft contract")
        form.append("DraftMemoToBeReviewedAndCommentedAlongWithTheDraftContract", data.uploadedFiles[i]);
        
        else if (data.uploadedFilesAttachType[i] === "CPSC Approval or Md's Email")
          form.append('CPSCApprovalOrMDsEmail', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === 'Draft Contract or Amendment')
          form.append('DraftContractOrAmendment', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Signed RTA")
          form.append('SignedRTA', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Technical Evaluation Result")
          form.append('TechnicalEvaluationResult', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Bidder's List")
          form.append('BiddersList', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Vendor's Quotation")
          form.append('VendorsQuotation', data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Any Document Related to the Transaction")
          form.append('AnyDocumentRelatedToTheTransaction', data.uploadedFiles[i]);

          else {
            form.append('Others', data.uploadedFiles[i])
            others_description = data.uploadedFilesAttachType[i];
          }
      }

      form.append('OthersDescription', others_description);

      return await makePOST('CPNCRoutingSlipForm/Review', form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } catch (error) {
      tinyAlert("error", 'An error occurred!');
      console.error('Error:', error);
      
    }
  }

  async fetchFormById(id: string): Promise<any> {
    try{
      return await makeREQUEST("GET", "CPNCRoutingSlipForm/" + id)
      .then((res) => {
        return res.data.data;

      })

    } catch (error) {
      tinyAlert("error", 'there is an error');
      console.error('Error:', error);
    }
  }

  async fetchApprovalSequence(data: any): Promise<any> {
    try{
      return await makeREQUEST("GET", `CPNCRoutingSlipForm/GetApprovalSequence?ContractValue=${data.ContractValue}&Currency=${data.Currency}&DepartmentId=${data.DepartmentId}`)
      .then((res) => {
        tinyAlert("success", "approval sequence updated!");
        return res.data.data;
      })
    } catch (error) {
      tinyAlert("error", printError(error));
      console.error('Error:', error);
    }
  }

  async deleteForm(id: string): Promise<any> {
    try{
      return await makeREQUEST("DELETE", "CPNCRoutingSlipForm/" + id)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;

      })

    } catch (error) {
      tinyAlert("error", 'there is an error');
      console.error('Error:', error);
    }
  }


};

const cpncrs = new CPNCRoutingSlipFormService();
export default cpncrs;