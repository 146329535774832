import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  axiosError,
  closeAlert,
  formatDateTime,
  formTypesData,
  preloader,
  _$http,
} from "../../utils";
import api from "../../services/form.services";
import BarChartComponent from "./BarChart";
import { Eye } from "../../components/Icons";
import {
  LuAlarmCheck,
  LuBadgeAlert,
  LuCheckCheck,
  LuListRestart,
  LuXOctagon,
} from "react-icons/lu";
import { FormDetails, OptionProps } from "../../interfaces/forms.interface";
import { formTypesID } from "../../utils/data";
import { FaTimes } from "react-icons/fa";

type Status = "PENDING" | "CLARIFY" | "RESTART" | "APPROVED" | "DECLINED";
interface DataItem {
  departmentName: string | null;
  formType: string | null;
  year: number;
  value: number;
  status: Status;
  statusCount: number;
}
interface DepartmentData {
  total: number;
  statuses: { [key in Status]?: number | any };
}
interface FormTypeData {
  total: number;
  statuses: { [key in Status]?: number | any };
}

// set the data types;
interface KPIStatusCounts {
  PENDING: number;
  CLARIFY: number;
  RESTART: number;
  APPROVED: number;
  DECLINED: number;
}
const KPIStatusDefault: KPIStatusCounts = {
  PENDING: 0,
  CLARIFY: 0,
  RESTART: 0,
  APPROVED: 0,
  DECLINED: 0,
};

// utility function to process the data
const processData = (data: DataItem[]): { [key: string]: DepartmentData } => {
  const departments: { [key: string]: DepartmentData } = {};

  data.forEach((item) => {
    const { departmentName, status, statusCount } = item;

    // Check if departmentName is not null or undefined
    if (departmentName) {
      if (!departments[departmentName]) {
        departments[departmentName] = { total: 0, statuses: {} };
      }

      if (!departments[departmentName].statuses[status]) {
        departments[departmentName].statuses[status] = 0;
      }

      departments[departmentName].statuses[status] += statusCount;
      departments[departmentName].total += statusCount;
    } else {
      // Handle the case where departmentName is null or undefined if needed
      console.warn(
        `Invalid departmentName found in data item: ${JSON.stringify(item)}`
      );
    }
  });

  return departments;
};
const processDataForm = (data: DataItem[]): { [key: string]: FormTypeData } => {
  const forms: { [key: string]: FormTypeData } = {};

  data.forEach((item) => {
    const { formType, status, statusCount } = item;

    // Check if departmentName is not null or undefined
    if (formType) {
      if (!forms[formType]) {
        forms[formType] = { total: 0, statuses: {} };
      }

      if (!forms[formType].statuses[status]) {
        forms[formType].statuses[status] = 0;
      }

      forms[formType].statuses[status] += statusCount;
      forms[formType].total += statusCount;
    } else {
      // Handle the case where departmentName is null or undefined if needed
      console.warn(
        `Invalid departmentName found in data item: ${JSON.stringify(item)}`
      );
    }
  });

  return forms;
};

const getKPIByStatus = (data: DataItem[]): KPIStatusCounts => {
  const kpiStatusCounts: KPIStatusCounts = {
    PENDING: 0,
    CLARIFY: 0,
    RESTART: 0,
    APPROVED: 0,
    DECLINED: 0,
  };

  data.forEach((item) => {
    if (item.status in kpiStatusCounts) {
      kpiStatusCounts[item.status as keyof KPIStatusCounts] += item.statusCount;
    }
  });

  return kpiStatusCounts;
};

function ApprovalDashboard() {
  const location = useLocation();
  const formType = location.pathname.split("/")[4];

  const [ByStartDate, setByStartDate] = useState("");
  const [byEndDate, setByEndDate] = useState("");
  const [DepartmentID, setDepartmentID] = useState<any[]>([]);
  const [department, setDepartment] = useState<OptionProps[]>([]);
  const [isOpenDepartment, setIsOpenDepartment] = useState(false);
  const [FormID, setFormID] = useState<any[]>([]);
  const [isOpenFormTypesID, setIsOpenFormTypesID] = useState(false);

  // const [formSplicer, setFormSplicer] = useState(1);
  // const [Page] = useState(1);
  // const [PageSize] = useState(15);
  // const [OrderBy] = useState(2);
  // const [SearchString] = useState("");
  // const [ApprovalStatus] = useState("");

  const formList = formTypesData;
  const [formRecord, setFormRecord] = useState<any>([]);

  const [departmentStats, setDepartmentStats] = useState<any>([]);
  const [formStats, setFormStats] = useState<any>([]);
  const [processedKPI, setProcessedKPI] = useState(KPIStatusDefault);

  useEffect(() => {
    api.fetchDepartment().then((res) => setDepartment(res));
  }, []);

  useEffect(() => {
    ApproversStatusCountByFormType(
      DepartmentID,
      FormID,
      ByStartDate,
      byEndDate
    );
    ApproversStatusCountByDepartment(
      DepartmentID,
      FormID,
      ByStartDate,
      byEndDate
    );
  }, [DepartmentID, FormID, ByStartDate, byEndDate]);

  useEffect(() => {
    fetchFormHistory();
  }, []);

  const ApproversStatusCountByDepartment = (
    DepartmentID: any,
    FormID: any,
    ByStartDate: any,
    byEndDate: any
  ) => {
    preloader();

    let data: any = {};
    // data.statuses = [status];

    if (!!DepartmentID.length) data.departments = DepartmentID;
    if (!!FormID.length) data.formTypes = FormID;

    if (!!byEndDate && !!ByStartDate) {
      data.StartDate = ByStartDate;
      data.EndDate = byEndDate;
    }

    _$http("POST", `Reports/Approver/ApproversStatusCountByDepartment`, data)
      .then((res) => {
        closeAlert();
        const record: any = res.data.data.approversStatusCountResponses;
        const processedData = processData(record);
        const processedKPI = getKPIByStatus(record);
        setDepartmentStats(processedData);
        setProcessedKPI(processedKPI);
      })
      .catch((error) => {
        axiosError(error);
      });
  };

  const ApproversStatusCountByFormType = (
    DepartmentID: any,
    FormID: any,
    ByStartDate: any,
    byEndDate: any
  ) => {
    preloader();

    let data: any = {};
    // data.statuses = [status];

    if (!!DepartmentID.length) data.departments = DepartmentID;
    if (!!FormID.length) data.formTypes = FormID;

    if (!!byEndDate && !!ByStartDate) {
      data.StartDate = ByStartDate;
      data.EndDate = byEndDate;
    }

    _$http("POST", `Reports/Approver/ApproversStatusCountByFormType`, data)
      .then((res) => {
        const record: any = res.data.data.approversStatusCountResponses;
        const processedData = processDataForm(record);
        setFormStats(processedData);
      })
      .catch((error) => {
        axiosError(error);
      });
  };

  const fetchFormHistory = (
  ) => {
    api.fetchPendingAll().then((res) => {
      console.log(res)
      setFormRecord(res);
    });
  };

  const [isSubNav, setIsSubNav] = useState(false);

  const [subNavLabel, setSubNavLabel] = useState("");
  const [crudAction, setCrudAction] = useState("");
  const [formDescription, setFormDescription] = useState<FormDetails[]>();

  const openSubNav = (label: string, type: string) => {
    setIsSubNav(true);
    setSubNavLabel(label);
    setCrudAction(type);
  };

  const closeSubNav = () => {
    setIsSubNav(false);
  };

  useEffect(() => {
    api.fetchAvailableFormsCount().then((res) => setFormDescription(res));
  }, []);

  return (
    <section className="flex w-full mt-2">
      {/* graph && list */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-3 mb-5 w-full">
        <div className="col-span-full">
          {/* filter by date; department and form area */}
          <div className="flex gap-3 justify-end">
            {/* start date */}
            <div className="filter-br">
              <span className="text-xs">Start Month/Year</span>
              <div className="flex gap-1">
                <input
                  className="text-xs h-[28px] border border-gray-400 px-3 py-1"
                  type="date"
                  id="startmonth"
                  name="startmonth"
                  onChange={(e: any) =>
                    setByStartDate((e.target as HTMLInputElement).value)
                  }
                />
              </div>
            </div>

            {/* end date */}
            <div className="filter-br">
              <span className="text-xs">End Month/Year</span>
              <div className="flex gap-1">
                <input
                  className="text-xs h-[28px] border border-gray-400 px-3 py-1"
                  type="date"
                  id="endmonth"
                  name="endmonth"
                  onChange={(e: any) =>
                    setByEndDate((e.target as HTMLInputElement).value)
                  }
                />
              </div>
            </div>

            {/* Department/Entity */}
            <div className="relative filter-br">
              <span className="text-xs">Department/Entity</span>

              <select
                multiple
                className="hidden"
                id="departmentSelect"
                onChange={(e) =>
                  setDepartmentID(
                    Array.from(
                      e.target.selectedOptions,
                      (option) => option.value
                    )
                  )
                }
              >
                <option value="">Department</option>
                {department?.map((item: any, i: any) => (
                  <option value={item.value} key={i}>
                    {item.label}
                  </option>
                ))}
              </select>

              <div className="flex flex-col space-y-1 text-xs">
                <div
                  className="relative bg-white"
                  onClick={() => setIsOpenDepartment(!isOpenDepartment)}
                >
                  <div className="flex items-center justify-between w-[150px] h-[28px] border border-gray-400 px-3 py-1 cursor-pointer">
                    <span className="block truncate">Department</span>
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className={
                        isOpenDepartment
                          ? "h-5 w-5 text-gray-500 transform rotate-180"
                          : "h-5 w-5 text-gray-500"
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </div>

                  <div
                    className="absolute z-10 w-[200px] bg-white border border-gray-300 mt-1 h-[300px] overflow-y-scroll"
                    style={{ display: isOpenDepartment ? "block" : "none" }}
                  >
                    <div className="max-h-38 overflow-y-auto">
                      {department?.map((item: any, i: any) => (
                        <label
                          className="block px-4 py-2 flex gap-2 cursor-pointer hover:bg-gray-100"
                          key={i}
                        >
                          <input
                            type="checkbox"
                            className="form-checkbox"
                            value={item.value}
                            checked={DepartmentID.includes(item.value)}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              if (isChecked) {
                                setDepartmentID((prev) => [
                                  ...prev,
                                  item.value,
                                ]);
                              } else {
                                setDepartmentID((prev) =>
                                  prev.filter((id) => id !== item.value)
                                );
                              }
                            }}
                          />
                          <span>{item.label}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Form Type */}
            <div className="relative filter-br">
              <span className="text-xs">Form Type</span>

              <select
                multiple
                className="hidden"
                id="formtypeSelect"
                onChange={(e) =>
                  setFormID(
                    Array.from(
                      e.target.selectedOptions,
                      (option) => option.value
                    )
                  )
                }
              >
                <option value="">Form Type</option>
                {formTypesID?.map((item: any, i: any) => (
                  <option value={item.type} key={i}>
                    {item.name}
                  </option>
                ))}
              </select>

              <div className="flex flex-col space-y-1 text-xs">
                <div
                  className="relative bg-white"
                  onClick={() => setIsOpenFormTypesID(!isOpenFormTypesID)}
                >
                  <div className="flex items-center justify-between w-[150px] h-[28px] border border-gray-400 px-3 py-1 cursor-pointer">
                    <span className="block truncate">Form Type</span>
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className={
                        isOpenFormTypesID
                          ? "h-5 w-5 text-gray-500 transform rotate-180"
                          : "h-5 w-5 text-gray-500"
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </div>

                  <div
                    className="absolute z-10 w-[200px] bg-white border border-gray-300 mt-1 h-[300px] overflow-y-scroll"
                    style={{ display: isOpenFormTypesID ? "block" : "none" }}
                  >
                    <div className="max-h-38 overflow-y-auto">
                      {formTypesID?.map((item: any, i: any) => (
                        <label
                          className="block px-4 py-2 flex gap-2 cursor-pointer hover:bg-gray-100"
                          key={i}
                        >
                          <input
                            type="checkbox"
                            className="form-checkbox"
                            value={item.type}
                            checked={FormID.includes(item.type)}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              if (isChecked) {
                                setFormID((prev) => [...prev, item.type]);
                              } else {
                                setFormID((prev) =>
                                  prev.filter((id) => id !== item.type)
                                );
                              }
                            }}
                          />
                          <span>{item.name}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-full">
          <div className="grid grid-cols-1 grid-cols-2 lg:grid-cols-5 gap-3">
            <div
              className="cursor-pointer rounded py-3 px-4 flex justify-between bg-white shadow border-b-4 border-[#216ba5] rounded-b-[5px]"
              onClick={() => openSubNav("Pending Approval", "pending")}
            >
              <div>
                <h5 className="font-semibold text-gray-700 text-sm sm:text-md">
                  Pending
                </h5>
                <h1 className="sm:text-2xl text-lg font-extrabold ">
                  {processedKPI?.PENDING}
                </h1>
              </div>

              <LuAlarmCheck className="text-6xl text-[#216ba5] bg-gray-100 p-4 rounded-[50px]" />
            </div>

            <div className="rounded py-3 px-4 flex justify-between bg-white shadow border-b-4 border-[#ca8a04] rounded-b-[5px]">
              <div>
                <h5 className="font-semibold text-gray-700 text-sm sm:text-md">
                  Clarify
                </h5>
                <h1 className="sm:text-2xl text-lg font-extrabold ">
                  {processedKPI?.CLARIFY}
                </h1>
              </div>
              <LuBadgeAlert className="text-6xl text-[#ca8a04] bg-gray-100 p-4 rounded-[50px]" />
            </div>

            <div className="rounded py-3 px-4 flex justify-between bg-white shadow border-b-4 border-[#2563eb] rounded-b-[5px]">
              <div>
                <h5 className="font-semibold text-gray-700 text-sm sm:text-md">
                  Restart
                </h5>
                <h1 className="sm:text-2xl text-lg font-extrabold ">
                  {processedKPI?.RESTART}
                </h1>
              </div>
              <LuListRestart className="text-6xl text-[#2563eb] bg-gray-100 p-4 rounded-[50px]" />
            </div>

            <div className="rounded py-3 px-4 flex justify-between bg-white shadow border-b-4 border-[#008000] rounded-b-[5px]">
              <div>
                <h5 className="font-semibold text-gray-700 text-sm sm:text-md">
                  Approved
                </h5>
                <h1 className="sm:text-2xl text-lg font-extrabold ">
                  {processedKPI?.APPROVED}
                </h1>
              </div>

              <LuCheckCheck className="text-6xl text-[#008000] bg-gray-100 p-4 rounded-[50px]" />
            </div>

            <div className="rounded py-3 px-4 flex justify-between bg-white shadow border-b-4 border-[#FF0000] rounded-b-[5px]">
              <div>
                <h5 className="font-semibold text-gray-700 text-sm sm:text-md">
                  Declined
                </h5>
                <h1 className="sm:text-2xl text-lg font-extrabold ">
                  {processedKPI?.DECLINED}
                </h1>
              </div>
              <LuXOctagon className="text-6xl text-[#FF0000] bg-gray-100 p-4 rounded-[50px]" />
            </div>
          </div>
        </div>

        {/* activities table */}
        <div className="col-span-3 bg-white border w-full shadow rounded">
          <div className="w-full barchart-title text-xs flex justify-between">
            <p className="mt-2">Recent Pending Form</p>
            {/* <select
              className="border px-1 py- hover:shadow-sm text-[11px] w-[180px]"
              onChange={(e: any) => setFormSplicer(e.target.value)}
              value={formSplicer}
            >
              {formList.map((item: any) => {
                return (
                  <option key={item?.type} value={item?.type}>
                    {item?.name}
                  </option>
                );
              })}
            </select> */}
          </div>
          
          <div className="h-[700px] overflow-scroll">
            <table className="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className=" text-[10px] text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-1 py-3 px-4">
                    Form ID
                  </th>
                  <th scope="col" className="px-1 py-3 px-4">
                    Form Type
                  </th>
                  <th scope="col" className="px-1 py-3">
                    Contract Title
                  </th>
                  <th scope="col" className="px-1 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date Initiated
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>
                {formRecord?.map((item: any, index: number) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-t hover:bg-gray-50 text-[11px] dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
                    >
                      <td className="px-1 py-2 px-4">{item.generatedFormId}</td>
                      <td className="px-1 py-2">{item.formType}</td>
                      <td className="px-1 py-2">{item.contractTitle}</td>
                      <td className="px-1 py-2">{item.status}</td>
                      <td className="px-6 py-2">
                        {formatDateTime(item.createdDate)}
                      </td>

                      <td className="px-2 py-3 flex">
                        <Link
                          to={`/approval/forms/details/${
                            formList.find(
                              (x: any) => x.code === item.formType
                            )?.link
                          }/${item.formId}/${item.formApproverId}`}
                          className="text-blue-600 flex gap-2"
                        >
                          <Eye /> <span>View</span>
                        </Link>
                      </td>

                      {/* ${formTypeParam} */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* barchart area */}
        <div className="col-span-2 gap-3 grid">
          <div className="col-span-full border bg-white shadow rounded">
            <p className="w-full barchart-title text-xs">
              Status counts by form type
            </p>
            <div className="p-4">
              <BarChartComponent data={formStats} />
            </div>
          </div>

          <div className="col-span-full border bg-white shadow rounded">
            <p className="w-full barchart-title text-xs">
              Status counts by departments
            </p>
            <div className="p-4">
              <BarChartComponent data={departmentStats} />
            </div>
          </div>
        </div>
      </div>

      {
        /* sub nav section */
        isSubNav && (
          <nav className="fixed nav-forms overflow-y-auto shadow-sm dark:bg-[#414b5a]">
            <div className="modal-container">
              <div
                className="flex justify-between shadow-sm dark:bg-gray-700 bg-white dark:text-gray-300 dark:border-[#474747] border-b border-[#d6d6d6]"
                style={{ position: "fixed", width: "250px", padding: "2px" }}
              >
                <div className="modal-content py-5 text-left px-5 w-full">
                  <div className="flex justify-between">
                    <h2 className="text-sm font-bold mt-9 block uppercase">
                      {subNavLabel}
                    </h2>
                    <button
                      className="text-red-500 text-md font-bold mt-6 block text-end bg-gray-100 p-2 border hover:shadow"
                      onClick={closeSubNav}
                    >
                      <FaTimes />
                    </button>
                  </div>
                </div>
              </div>

              <div className="modal-content mb-4 text-left pb-4 capitalize">
                <ul
                  style={{ paddingTop: "95px" }}
                  className="shadow-sm dark:bg-gray-700 bg-white dark:text-gray-300 dark:border-[#474747]"
                >
                  <li className="mb-1">
                    <Link
                      onClick={closeSubNav}
                      to={`/approval/forms/${crudAction}/admin-amendment-form`}
                      className={
                        ([
                          "admin-amendment-form"
                            .toLocaleLowerCase()
                            .replace(/ /g, "-")
                            .replace(/\//g, "-"),
                        ].includes(formType)
                          ? "active"
                          : "") +
                        " block py-4 px-5 text-left hover:text-[#fff] hover:bg-[#e0424d] dark:hover:bg-[#162c51] dark:text-gray-300 flex justify-between text-sm"
                      }
                    >
                      <span>admin amendment form</span>
                      <div>
                        {crudAction === "pending" && (
                          <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                            {
                              formDescription?.find(
                                (item: FormDetails) =>
                                  item.name
                                    .toLocaleLowerCase()
                                    .replace(/ /g, "-")
                                    .replace(/\//g, "-") ===
                                  "admin-amendment-form"
                              )?.pendingCount
                            }

                            {/* {JSON.stringify(formDescription)} */}
                          </span>
                        )}
                      </div>
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link
                      onClick={closeSubNav}
                      to={`/approval/forms/${crudAction}/cpnc-routing-slip`}
                      className={
                        ([
                          "cpnc-routing-slip"
                            .toLocaleLowerCase()
                            .replace(/ /g, "-")
                            .replace(/\//g, "-"),
                        ].includes(formType)
                          ? "active"
                          : "") +
                        " block py-4 px-5 text-left hover:text-[#fff] hover:bg-[#e0424d] dark:hover:bg-[#162c51] dark:text-gray-300 flex justify-between text-sm"
                      }
                    >
                      <span>CPNC routing slip</span>
                      <div>
                        {crudAction === "pending" && (
                          <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                            {
                              formDescription?.find(
                                (item: FormDetails) =>
                                  item.name
                                    .toLocaleLowerCase()
                                    .replace(/ /g, "-")
                                    .replace(/\//g, "-") === "cpnc-routing-slip"
                              )?.pendingCount
                            }
                          </span>
                        )}
                      </div>
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link
                      onClick={closeSubNav}
                      to={`/approval/forms/${crudAction}/routing-slip-for-tas-contracts`}
                      className={
                        ([
                          "routing-slip-for-tas-contracts"
                            .toLocaleLowerCase()
                            .replace(/ /g, "-")
                            .replace(/\//g, "-"),
                        ].includes(formType)
                          ? "active"
                          : "") +
                        " block py-4 px-5 text-left hover:text-[#fff] hover:bg-[#e0424d] dark:text-gray-300 dark:hover:bg-[#162c51] flex justify-between text-sm"
                      }
                    >
                      <span>routing slip for TAS contracts</span>
                      <div>
                        {crudAction === "pending" && (
                          <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                            {
                              formDescription?.find(
                                (item: FormDetails) =>
                                  item.name
                                    .toLocaleLowerCase()
                                    .replace(/ /g, "-")
                                    .replace(/\//g, "-") ===
                                  "routing-slip-for-tas-contracts"
                              )?.pendingCount
                            }
                          </span>
                        )}
                      </div>
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link
                      onClick={closeSubNav}
                      to={`/approval/forms/${crudAction}/contract-approval-form-admin-amendment-for-decentralized-purchasing`}
                      className={
                        ([
                          "contract-approval-form-admin-amendment-for-decentralized-purchasing"
                            .toLocaleLowerCase()
                            .replace(/ /g, "-")
                            .replace(/\//g, "-"),
                        ].includes(formType)
                          ? "active"
                          : "") +
                        " block py-4 px-5 text-left hover:text-[#fff] dark:text-gray-300 dark:hover:bg-[#162c51] hover:bg-[#e0424d] flex justify-between text-sm"
                      }
                    >
                      <span>
                        contract approval form admin amendment for decentralized
                        purchasing
                      </span>
                      <div>
                        {crudAction === "pending" && (
                          <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                            {
                              formDescription?.find(
                                (item: FormDetails) =>
                                  item.name
                                    .toLocaleLowerCase()
                                    .replace(/ /g, "-")
                                    .replace(/\//g, "-") ===
                                  "contract-approval-form-admin-amendment-for-decentralized-purchasing"
                              )?.pendingCount
                            }
                          </span>
                        )}
                      </div>
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link
                      onClick={closeSubNav}
                      to={`/approval/forms/${crudAction}/contract-approval-form`}
                      className={
                        ([
                          "contract-approval-form"
                            .toLocaleLowerCase()
                            .replace(/ /g, "-")
                            .replace(/\//g, "-"),
                        ].includes(formType)
                          ? "active"
                          : "") +
                        " block py-4 px-5 text-left hover:text-[#fff] hover:bg-[#e0424d] dark:hover:bg-[#162c51] dark:text-gray-300 flex justify-between text-sm"
                      }
                    >
                      <span>contract approval form</span>
                      <div>
                        {crudAction === "pending" && (
                          <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                            {
                              formDescription?.find(
                                (item: FormDetails) =>
                                  item.name
                                    .toLocaleLowerCase()
                                    .replace(/ /g, "-")
                                    .replace(/\//g, "-") ===
                                  "contract-approval-form"
                              )?.pendingCount
                            }
                          </span>
                        )}
                      </div>
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link
                      onClick={closeSubNav}
                      to={`/approval/forms/${crudAction}/routing-slip-for-material-purchase`}
                      className={
                        ([
                          "routing-slip-for-material-purchase"
                            .toLocaleLowerCase()
                            .replace(/ /g, "-")
                            .replace(/\//g, "-"),
                        ].includes(formType)
                          ? "active"
                          : "") +
                        " block py-4 px-5 text-left hover:text-[#fff] hover:bg-[#e0424d] dark:text-gray-300 dark:hover:bg-[#162c51] flex justify-between text-sm"
                      }
                    >
                      <span>routing slip for material purchase</span>
                      <div>
                        {crudAction === "pending" && (
                          <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                            {
                              formDescription?.find(
                                (item: FormDetails) =>
                                  item.name
                                    .toLocaleLowerCase()
                                    .replace(/ /g, "-")
                                    .replace(/\//g, "-") ===
                                  "routing-slip-for-material-purchase"
                              )?.pendingCount
                            }
                          </span>
                        )}
                      </div>
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link
                      onClick={closeSubNav}
                      to={`/approval/forms/${crudAction}/routing-slip-for-deviation-from-standard-template`}
                      className={
                        ([
                          "routing-slip-for-deviation-from-standard-template"
                            .toLocaleLowerCase()
                            .replace(/ /g, "-")
                            .replace(/\//g, "-"),
                        ].includes(formType)
                          ? "active"
                          : "") +
                        " block py-4 px-5 text-left hover:text-[#fff] dark:text-gray-300 dark:hover:bg-[#162c51] hover:bg-[#e0424d] flex justify-between text-sm"
                      }
                    >
                      <span>
                        routing slip for deviation from standard template
                      </span>
                      <div>
                        {crudAction === "pending" && (
                          <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                            {
                              formDescription?.find(
                                (item: FormDetails) =>
                                  item.name
                                    .toLocaleLowerCase()
                                    .replace(/ /g, "-")
                                    .replace(/\//g, "-") ===
                                  "routing-slip-for-deviation-from-standard-template"
                              )?.pendingCount
                            }
                          </span>
                        )}
                      </div>
                    </Link>
                  </li>
                  <li className="mb-1 hidden">
                    <Link
                      onClick={closeSubNav}
                      to={`/approval/forms/${crudAction}/tucn-savings-declaration-sheet`}
                      className={
                        ([
                          "tucn-savings-declaration-sheet"
                            .toLocaleLowerCase()
                            .replace(/ /g, "-")
                            .replace(/\//g, "-"),
                        ].includes(formType)
                          ? "active"
                          : "") +
                        " block py-4 px-5 text-left hover:text-[#fff] dark:text-gray-300 hover:bg-[#e0424d] dark:hover:bg-[#162c51] flex justify-between text-sm"
                      }
                    >
                      <span>TUCN savings declaration sheet</span>
                      <div>
                        {crudAction === "pending" && (
                          <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                            {
                              formDescription?.find(
                                (item: FormDetails) =>
                                  item.name
                                    .toLocaleLowerCase()
                                    .replace(/ /g, "-")
                                    .replace(/\//g, "-") ===
                                  "tucn-savings-declaration-sheet"
                              )?.pendingCount
                            }
                          </span>
                        )}
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        )
      }
    </section>
  );
}

export default ApprovalDashboard;
