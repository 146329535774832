import { Routes, Route } from "react-router-dom";
import { ReactProps } from "../../interfaces";
import useAdminProtectedRoute from "../../hooks/UseAdminProtectedRoute";
import Reporting from "../../views/utilities/Reporting";
import Departments from "../../views/utilities/Departments";
import Users from "../../views/utilities/Users";
import Roles from "../../views/utilities/Roles";
import Workflows from "../../views/utilities/Workflows";
import WorkflowThresholds from "../../views/utilities/WorkflowThresholds";
import ApprovalSequenceRules from "../../views/utilities/ApprovalSequenceRules";
import ApprovalSequences from "../../views/utilities/ApprovalSequences";
import Notifications from "../../views/utilities/Notifications";
import Help from "../../views/utilities/Help";
import Manual from "../../views/utilities/Manual";
import ManageFormContent from "../../views/forms/ManageFormContent";
import RaisedForm from "../../views/forms/RaisedForms";
import NoRouteMatch from "../../views/authentication/NoRouteMatch";
import AAFDetails from "../../views/forms/admin-amendment-form/AAF.details";
import CAFADetails from "../../views/forms/contract-approval-form-admin-amendment-for-decentralized-purchasing/CAFA.details";
import CAFDetails from "../../views/forms/contract-approval-form/CAF.details";
import CPNCRSDetails from "../../views/forms/cpnc-routing-slip/CPNCRS.details";
import RSMPDetails from "../../views/forms/routing-slip-for-material-purchase/RSMP.details";
import TUCNDetails from "../../views/forms/tucn-savings-declaration-sheet/TUCN.details";
import DFSTDetails from "../../views/forms/routing-slip-for-deviation-from-standard-template/RST.details";
import TASDetails from "../../views/forms/routing-slip-for-tas-contracts/TAS.details";
import Console from "../../views/forms/Console";

function ProtectedAdminRoute({ children, title = "Digital CPNC" }: ReactProps) {
  const getProtectedRoute = useAdminProtectedRoute();
  return getProtectedRoute(children, title);
}

function AdminRoutes() {
  return (
    <Routes>
      <Route path="*" element={<NoRouteMatch />} />
      <Route
        path="ms-reporting-and-analytics"
        element={
          <ProtectedAdminRoute title="Report & Analytics">
            <Reporting />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="departments"
        element={
          <ProtectedAdminRoute title="Departments">
            <Departments />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="users"
        element={
          <ProtectedAdminRoute title="User Management">
            <Users />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="roles-and-permission"
        element={
          <ProtectedAdminRoute title="Roles And Permission">
            <Roles />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="approval-workflows"
        element={
          <ProtectedAdminRoute title="Approval Workflows">
            <Workflows />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="workflow-thresholds"
        element={
          <ProtectedAdminRoute title="Workflow Thresholds">
            <WorkflowThresholds />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="approval-sequence-rules"
        element={
          <ProtectedAdminRoute title="Approval Sequence Rules">
            <ApprovalSequenceRules />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="approval-sequences"
        element={
          <ProtectedAdminRoute title="Approval Sequences">
            <ApprovalSequences />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="notifications"
        element={
          <ProtectedAdminRoute title="Notifications">
            <Notifications />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="help"
        element={
          <ProtectedAdminRoute title="FAQ & Help">
            <Help />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="user-manual"
        element={
          <ProtectedAdminRoute title="User's Manual">
            <Manual />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="forms/manageformcontent/:formType"
        element={
          <ProtectedAdminRoute title="Manage Form Content">
            <ManageFormContent />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="forms/raisedforms/:formType"
        element={
          <ProtectedAdminRoute title="Raised Form">
            <RaisedForm />
          </ProtectedAdminRoute>
        }
      />

      {/* Approve form links */}
      <Route
        path="forms/details/admin-amendment-form/:id"
        element={
          <ProtectedAdminRoute title="Approve Form">
            <AAFDetails />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="forms/details/contract-approval-form-admin-amendment-for-decentralized-purchasing/:id"
        element={
          <ProtectedAdminRoute title="Approve Form">
            <CAFADetails />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="forms/details/contract-approval-form/:id"
        element={
          <ProtectedAdminRoute title="Approve Form">
            <CAFDetails />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="forms/details/cpnc-routing-slip/:id"
        element={
          <ProtectedAdminRoute title="View Form">
            <CPNCRSDetails />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="forms/details/routing-slip-for-material-purchase/:id"
        element={
          <ProtectedAdminRoute title="View Form">
            <RSMPDetails />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="forms/details/tucn-savings-declaration-sheet/:id"
        element={
          <ProtectedAdminRoute title="View Form">
            <TUCNDetails />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="forms/details/routing-slip-for-deviation-from-standard-template/:id"
        element={
          <ProtectedAdminRoute title="View Form">
            <DFSTDetails />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="forms/details/routing-slip-for-tas-contracts/:id"
        element={
          <ProtectedAdminRoute title="View Form">
            <TASDetails />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="console-config"
        element={
          <ProtectedAdminRoute title="Console">
            <Console />
          </ProtectedAdminRoute>
        }
      />
    </Routes>
    
  );
}

export default AdminRoutes;
