import { FaDownload, FaPaperclip, FaEye } from "react-icons/fa6";
import api from '../services/form.services';
import { useState } from 'react';
import { XMark } from "./Icons";

function FormAttachments(props: any) {
    
    const [previewFileURL, setPreviewFileURL] = useState('');
    const [toggglePreviewModal, setPreviewModalTogggle] = useState(false);

    // -> download-file-function-> converts base64 to file
    const downloadFile = (formID: string) => {
        api.downloadFile(formID); 
    };
  const previewFile = (formID: string) => {
        api.previewFile(formID)
        .then((res)=>{
            setPreviewModalTogggle(true)
            console.log(res);
            setPreviewFileURL(res);
        })
    };  
    const handleModal = () => {
        if(toggglePreviewModal){
            setPreviewModalTogggle(false);
        }
        else 
        setPreviewModalTogggle(true);
    };
        
    return (
        <div className='pt-5'>
            <label htmlFor="Documents" className="block text-gray-600 font-bold text-md flex">Attachments</label>

            <hr />

            {
                props.data.length > 0 &&
                <div className="container mx-auto mt-2">
                    {
                        props.data.map((item: any, i: any) => (
                            <div key={i} className="transition-all p-2 mt-1 rounded bg-gray-50 hover:bg-gray-100 border px-3 py-3 justify-between flex">
                                <div className='flex ml-3'>
                                    <FaPaperclip className='text-gray-600 mt-1' />
                                    <span className="ml-4 text-sm mt-1 lowercase">{item.attachmentType.name}-{item.name}.{item.contentType.split("/")[1]}</span>
                                    <br />
                                </div>
                                <div className='flex ml-3'>
                                    <button className='transition-all cursor-pointer text-sm font-bold text-blue-700 p-1 px-2 gap-2 flex border bg-gray-100 hover:bg-blue-100 rounded' onClick={() => { previewFile(item.id) }}>
                                        <FaEye />  <span>Preview</span>
                                    </button>
                                    <button className='transition-all cursor-pointer text-sm font-bold text-blue-700 p-1 px-2 gap-2 flex border bg-gray-100 hover:bg-blue-100 rounded' onClick={() => { downloadFile(item.id) }}>
                                        <FaDownload />  <span>Download</span>
                                    </button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            }

            {
                toggglePreviewModal &&
                <div className="fixed inset-0 flex items-center justify-center z-50" style={{background:'rgb(0 0 0 / 52%)'}}>
                    <div className="modal-container bg-white w-9/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">
                        <div className='flex justify-between'>
                            <div></div>
                            <div className="modal-content py-4 text-left px-1">
                                <button onClick={handleModal} className="p-2" title='Close Modal'><XMark /></button>
                            </div>
                        </div>

                        <div className="modal-content mb-4 text-left px-1 pb-4 attach-result">
                            {/* {previewFileURL} */}
                            <iframe  src={previewFileURL}  height="700" width="100%" title="Invoice" style={{paddingBottom: '15px'}}></iframe>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default FormAttachments;
