import { useEffect, useState, useRef } from 'react';
import Chart from 'chart.js/auto';
import { axiosError, closeAlert, preloader, twoDecimalPlace, _$http } from '../../utils';
import { formatDDMMYYYY } from '../../services/_config.services';
import api from '../../services/form.services';
import { OptionProps } from "../../interfaces/forms.interface";
import { formTypesID } from '../../utils/data';



function Reporting() {

  // COMPONENT STATE\
  const ValueDivider = 1000000;
  const [currentTab, setCurrentTab] = useState("contract");

  const [ByStartDate, setByStartDate] = useState('');
  const [byEndDate, setByEndDate] = useState('');

  const [FormID, setFormID] = useState<any[]>([]);
  const [isOpenFormTypesID, setIsOpenFormTypesID] = useState(false);

  const [DepartmentID, setDepartmentID] = useState<any[]>([]);
  const [department, setDepartment] = useState<OptionProps[]>([]);
  const [isOpenDepartment, setIsOpenDepartment] = useState(false);

  // contract value tab record
  const [totalCompleted, setTotalCompleted] = useState(0);
  const [totalPendingForms, settotalPendingForms] = useState(0);
  const [contractValue, setcontractValue] = useState(0);
  const [AverageContractValue, setAverageContractValue] = useState(0);

  // graph chartContractValueByForm
  const chartInstanceRef = useRef<Chart | null>(null);
  const chartContractValueByForm = useRef<HTMLCanvasElement | null>(null);
  const [chartContractValueByFormlabel, setchartContractValueByFormlabel] = useState([]);
  const [chartContractValueByFormdata, setchartContractValueByFormdata] = useState([]);

  // graph chartContractValueByFormPending
  const chartInstanceRefPending = useRef<Chart | null>(null);
  const chartContractValueByFormPending = useRef<HTMLCanvasElement | null>(null);
  const [chartContractValueByFormPendinglabel, setchartContractValueByFormPendinglabel] = useState([]);
  const [chartContractValueByFormPendingdata, setchartContractValueByFormPendingdata] = useState([]);


  // graph chartContractValueByForm
  const chartInstanceDeptRef = useRef<Chart | null>(null);
  const chartContractValueByDept = useRef<HTMLCanvasElement | null>(null);
  const [chartContractValueByDeptlabel, setchartContractValueByDeptlabel] = useState([]);
  const [chartContractValueByDeptdata, setchartContractValueByDeptdata] = useState([]);

  // graph of value against dept pending
  const chartInstanceRefDeptPending = useRef<Chart | null>(null);
  const chartContractValueByDeptPending = useRef<HTMLCanvasElement | null>(null);
  const [chartContractValueByDeptPendinglabel, setchartContractValueByDeptPendinglabel] = useState([]);
  const [chartContractValueByDeptPendingdata, setchartContractValueByDeptPendingdata] = useState([]);


  // USE EFFECT HOOK
  useEffect(() => {
    console.log(chartRefQ, )
      api.fetchDepartment().then((res) => setDepartment(res));
  }, []);

  useEffect(() => {
    getAverageContractValue(DepartmentID, FormID, ByStartDate, byEndDate);

    TotalFormCountByStatus('APPROVED', DepartmentID, FormID, ByStartDate, byEndDate);
    TotalFormCountByStatus('PENDING', DepartmentID, FormID, ByStartDate, byEndDate);

    getTotalContractValue('APPROVED', DepartmentID, FormID, ByStartDate, byEndDate);
    getTotalContractValue('PENDING',DepartmentID, FormID, ByStartDate, byEndDate);

    getTotalContractValueDept('APPROVED', DepartmentID, FormID, ByStartDate, byEndDate);
    getTotalContractValueDept('PENDING',DepartmentID, FormID, ByStartDate, byEndDate);
  }, [DepartmentID, FormID, ByStartDate, byEndDate]);

  useEffect(() => {
    if (chartContractValueByForm.current) {
      const ctx = chartContractValueByForm.current.getContext('2d');
      if (ctx) {
        if (chartInstanceRef.current) chartInstanceRef.current.destroy();
        
        chartInstanceRef.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels:chartContractValueByFormlabel,
            datasets: [
              {
                label: 'Data (Millions)',
                data: chartContractValueByFormdata,
                borderColor: '#0053e3',
                backgroundColor: '#0053e3',
              },
            ],
          },
          options: {
            scales: {
              x: {
                beginAtZero: true
              }
            },
            plugins: {
              legend: {
                display: false,
                labels: {
                  filter: function(item) {
                    return !item.text.includes('(MUSD)'); // Exclude the legend entry for 'Data (Million)'
                  }
                }
              }
            }
          }
        });
      }
    }

  }, [chartContractValueByFormlabel, chartContractValueByFormdata, currentTab]);

  useEffect(() => {
    if (chartContractValueByFormPending.current) {
      const ctx = chartContractValueByFormPending.current.getContext('2d');
      if (ctx) {
        if (chartInstanceRefPending.current) chartInstanceRefPending.current.destroy();

        chartInstanceRefPending.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: chartContractValueByFormPendinglabel,
            datasets: [
              {
                label: 'Data (MUSD)',
                data: chartContractValueByFormPendingdata,
                borderColor: 'red',
                backgroundColor: 'red',
              },
            ],
          },
          options: {
            scales: {
              x: {
                beginAtZero: true
              }
            },
            plugins: {
              legend: {
                display: true,
                labels: {
                  filter: function(item) {
                    return !item.text.includes('(MUSD)'); // Exclude the legend entry for 'Data (Million)'
                  }
                }
              }
            }
          }
        });
      }
    }

  }, [chartContractValueByFormPendinglabel, chartContractValueByFormPendingdata, currentTab]);


  useEffect(() => {
    if (chartContractValueByDept.current) {
      const ctx = chartContractValueByDept.current.getContext('2d');
      if (ctx) {
        if (chartInstanceDeptRef.current) chartInstanceDeptRef.current.destroy();
        
        chartInstanceDeptRef.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels:chartContractValueByDeptlabel,
            datasets: [
              {
                label: 'Data',
                data: chartContractValueByDeptdata,
                borderColor: '#0053e3',
                backgroundColor: '#0053e3',
              },
            ],
          },
          options: {
            scales: {
              x: {
                beginAtZero: true,
                ticks: {
                    callback: function(value, index, values) {
                      // Cast value to number
                      const numericValue = value as number;

                      const label = this.getLabelForValue(numericValue);
                      return label.length > 5 ? label.substr(0, 5) + '...' : label;
                    }
                }
              }
            },
            plugins: {
              legend: {
                display: false,
                labels: {
                  filter: function(item) {
                    return !item.text.includes('(MUSD)'); // Exclude the legend entry for 'Data (Million)'
                  }
                }
              },
              tooltip: {
                  callbacks: {
                      title: function(tooltipItems) {
                          const idx = tooltipItems[0].dataIndex;
                          return chartContractValueByDeptlabel[idx];
                      }
                  }
              }
            }
          }
        });
      }
    }

  }, [chartContractValueByDeptlabel, chartContractValueByDeptdata, currentTab]);

  useEffect(() => {
    if (chartContractValueByDeptPending.current) {
      const ctx = chartContractValueByDeptPending.current.getContext('2d');
      if (ctx) {
        if (chartInstanceRefDeptPending.current) chartInstanceRefDeptPending.current.destroy();

        chartInstanceRefDeptPending.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: chartContractValueByDeptPendinglabel,
            datasets: [
              {
                label: 'Data',
                data: chartContractValueByDeptPendingdata,
                borderColor: 'red',
                backgroundColor: 'red',
              },
            ],
          },
          options: {
            scales: {
              x: {
                beginAtZero: true,
                ticks: {
                    callback: function(value, index, values) {
                        // Cast value to number
                        const numericValue = value as number;
                        const label = this.getLabelForValue(numericValue);
                        return label.length > 5 ? label.substr(0, 5) + '...' : label;
                    }
                }
              }
            },
            plugins: {
              legend: {
                display: false, // This disables the display of the legend
                labels: {
                  filter: function(item) {
                    return !item.text.includes('(MUSD)'); // Exclude the legend entry for 'Data (Million)'
                  }
                }
              },
              tooltip: {
                  callbacks: {
                      title: function(tooltipItems) {
                          const idx = tooltipItems[0].dataIndex;
                          return chartContractValueByDeptPendinglabel[idx];
                      }
                  }
              }
            }
          }
        });
      }
    }
  }, [chartContractValueByDeptPendinglabel, chartContractValueByDeptPendingdata, currentTab]);



  // 1. TotalFormCountByStatus
  const TotalFormCountByStatus = (status: string, DepartmentID:any, FormID:any, ByStartDate:any, byEndDate:any) => {
    preloader();
    let data:any = {};
    data.statuses = [status];

    if(!!DepartmentID.length) data.departments = DepartmentID;
    if(!!FormID.length) data.formTypes = FormID;

    if(!!byEndDate && !!ByStartDate){
      data.StartDate = ByStartDate;
      data.EndDate = byEndDate;
    }

    _$http('POST', 'Reports/TotalFormCountByStatus', data).then(res => {
      closeAlert();
      if (status === 'PENDING') settotalPendingForms(res.data.data)
      else setTotalCompleted(res.data.data)
    })
    .catch(error => {
      axiosError(error);
    })
  }

  //2. getTotalContractValue
  const getTotalContractValue = (status: string, DepartmentID:any, FormID:any, ByStartDate:any, byEndDate:any) => {
    preloader();
    let data:any = {};
    data.statuses = [status];

    if(!!DepartmentID.length) data.departments = DepartmentID;
    if(!!FormID.length) data.formTypes = FormID;

    if(!!byEndDate && !!ByStartDate){
      data.StartDate = ByStartDate;
      data.EndDate = byEndDate;
    }

    _$http('POST', 'Reports/Form/TotalContractValue', data).then(res => {
      closeAlert();
      if(status === 'APPROVED')
        setcontractValue(res.data.data.totalOrAverageContractValue);

      const FormType: any = [];
      const contractValue: any = [];

      // Iterate through data and populate arrays
      res.data.data.formContractValueResponses.forEach((item: any) => {
        FormType.push(`${item.formType}.`);
        contractValue.push(item.contractValue/ValueDivider);
      });

      
      if(status === 'APPROVED'){
        setchartContractValueByFormlabel(FormType);
        setchartContractValueByFormdata(contractValue)
      }
      else{
        setchartContractValueByFormPendinglabel(FormType);
        setchartContractValueByFormPendingdata(contractValue);
      }

    })
    .catch(error => {
      axiosError(error);
    })
  }
  
  //3. getAverageContractValue
  const getAverageContractValue = (DepartmentID:any, FormID:any, ByStartDate:any, byEndDate:any) => {
    preloader();
    let data:any = {};
    data.statuses = ["APPROVED"];

    if(!!DepartmentID.length) data.departments = DepartmentID;
    if(!!FormID.length) data.formTypes = FormID;

    if(!!byEndDate && !!ByStartDate){
      data.StartDate = ByStartDate;
      data.EndDate = byEndDate;
    }

    _$http('POST', 'Reports/Form/AverageContractValue', data).then(res => {
      closeAlert();
      setAverageContractValue(res.data.data.totalOrAverageContractValue)
    })
      .catch(error => {
        axiosError(error);
      })
  }

  //4. getTotalContractValueDept
  const getTotalContractValueDept = (status: string, DepartmentID:any, FormID:any, ByStartDate:any, byEndDate:any) => {
    preloader();
    let data:any = {};
    data.statuses = [status];

    if(!!DepartmentID.length) data.departments = DepartmentID;
    if(!!FormID.length) data.formTypes = FormID;

    if(!!byEndDate && !!ByStartDate){
      data.StartDate = ByStartDate;
      data.EndDate = byEndDate;
    }

    _$http('POST', 'Reports/Form/TotalContractValueByDepartmentGrouping', data).then(res => {
      closeAlert();

      const FormType: any = [];
      const contractValue: any = [];

      // Iterate through data and populate arrays
      res.data.data.forEach((item: any) => {
        FormType.push(`${item.departmentName}`); 
        contractValue.push(item.contractValue/ValueDivider);
      });
      
      if(status === 'APPROVED'){
        setchartContractValueByDeptlabel(FormType);
        setchartContractValueByDeptdata(contractValue)
      }
      else{
        setchartContractValueByDeptPendinglabel(FormType);
        setchartContractValueByDeptPendingdata(contractValue);
      }
    })
      .catch(error => {
        axiosError(error);
      })
  }


  const [totalSavingsPending, settotalSavingsPending] = useState(0);
  const [totalSavingsCompleted, settotalSavingsCompleted] = useState(0);
  const [SavingsPending, setSavingsPending] = useState(0);
  const [SavingsCompleted, setSavingsCompleted] = useState(0);


  // graph 
  const chartRefQ = useRef<HTMLCanvasElement | null>(null);
  // const chartRef1Pending = useRef<HTMLCanvasElement | null>(null);
  const chartRef2 = useRef<HTMLCanvasElement | null>(null);
  const chartRef3 = useRef<HTMLCanvasElement | null>(null);
  const chartRef33 = useRef<HTMLCanvasElement | null>(null);
  const chartRef4 = useRef<HTMLCanvasElement | null>(null);
  const chartRef5 = useRef<HTMLCanvasElement | null>(null);

  
  const chartRefdepartmentCDSCount = useRef<HTMLCanvasElement | null>(null);
  const chartRefdepartmentCDSCountPending = useRef<HTMLCanvasElement | null>(null);
  const chartRefformTypeCDSCountCompleted = useRef<HTMLCanvasElement | null>(null);
  const chartRefformTypeCDSCountPending = useRef<HTMLCanvasElement | null>(null);


  // const chartInstanceRefQ = useRef<any>(null);
  // const chartInstanceRef1 = useRef<Chart | null>(null);
  // const chartInstanceRef1Pending = useRef<Chart | null>(null);
  const chartInstanceRef2 = useRef<any>(null);
  const chartInstanceRef3 = useRef<Chart | null>(null);
  const chartInstanceRef33 = useRef<Chart | null>(null);
  const chartInstanceRef4 = useRef<Chart | null>(null);
  const chartInstanceRef5 = useRef<Chart | null>(null);

  
  const chartInstanceRefdepartmentCDSCount = useRef<Chart | null>(null);
  const chartInstanceRefdepartmentCDSCountPending = useRef<Chart | null>(null);
  const chartInstanceRefformTypeCDSCountCompleted = useRef<Chart | null>(null);
  const chartInstanceRefformTypeCDSCountPending = useRef<Chart | null>(null);






  // const [chartTypeQ, setChartTypeQ] = useState('pie');
  const [chartType, setChartType] = useState('bar');
  // const [chartType1, setChartType1] = useState('bar');
  const [chartType2, setChartType2] = useState('bar');
  const [chartType3, setChartType3] = useState('bar');
  const [chartType33, setChartType33] = useState('bar');
  const [chartType4, setChartType4] = useState('bar');
  const [chartType5, setChartType5] = useState('bar');
  const [byDate, setByDate] = useState(1);
  // const [start_date, setStartDate] = useState('');
  // const [end_date, setEndDate] = useState('');


  const [averageLeadTimeForComepletedApprovals, setaverageLeadTimeForComepletedApprovals] = useState(0);
  const [maximumLeadTime, setmaximumLeadTime] = useState(0);

  const [AverageHoursForPending, setAverageHoursForPending] = useState(0);


  // const [contractByFormType, setcontractByFormType] = useState('');
  // const [contractByDepartment, setcontractByDepartment] = useState('');

  // const piedata = {
  //   labels: ['Red', 'Blue', 'Yellow'],
  //   datasets: [
  //     {
  //       label: 'My First Dataset',
  //       data: [300, 50, 100],
  //       backgroundColor: ['red', 'blue', 'yellow'],
  //     },
  //   ],
  // };





  // const [graphlabel1, setgraphlabel1] = useState([]);
  // const [graphdata1, setgraphdata1] = useState([]);
  // const [graphlabel1Pending, setgraphlabel1Pending] = useState([]);
  // const [graphdata1Pending, setgraphdata1Pending] = useState([]);

  const [graphlabel2, setgraphlabel2] = useState([]);
  const [graphdata2, setgraphdata2] = useState([]);

  const [graphlabel3, setgraphlabel3] = useState([]);
  const [graphlabel33, setgraphlabel33] = useState([]);
  const [graphdata3, setgraphdata3] = useState([]);
  const [graphdata33, setgraphdata33] = useState([]);

  const [graphlabel4, setgraphlabel4] = useState([]);
  const [graphdata4, setgraphdata4] = useState([]);

  const [graphlabel5, setgraphlabel5] = useState([]);
  const [graphdata5, setgraphdata5] = useState([]);

  const [departmentCDSCount, setdepartmentCDSCount] = useState([]);
  const [departmentCBS, setdepartmentCBS] = useState([]);
  const [departmentCDSCountPending, setdepartmentCDSCountPending] = useState([]);
  const [departmentCBSPending, setdepartmentCBSPending] = useState([]);
  const [formTypeCDSCountCompleted, setformTypeCDSCountCompleted] = useState([]);
  const [formTypeCBSCompleted, setformTypeCBSCompleted] = useState([]);
  const [formTypeCBSPending, setformTypeCBSPending] = useState([]);
  const [formTypeCDSCountPending, setformTypeCDSCountPending] = useState([]);



  // useEffect(() => {
  //   setChartType1('bar')
  //   if (chartRef1Pending.current) {
  //     const ctx1 = chartRef1Pending.current.getContext('2d');
  //     if (ctx1) {
  //       if (chartInstanceRef1Pending.current) chartInstanceRef1Pending.current.destroy();

  //       chartInstanceRef1Pending.current = new Chart(ctx1, {
  //         type: chartType1 === 'bar' ? 'bar' : 'bar',
  //         data: {
  //           labels: graphlabel1Pending,// ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',],
  //           datasets: [
  //             {
  //               label: 'Data',
  //               data: graphdata1Pending, //[65, 29, 80, 59, 80, 81, 56, 60,],
  //               borderColor: 'red',
  //               backgroundColor: 'red',
  //               // tension: 0.2,
  //               // borderWidth: 3,
  //             },
  //           ],
  //         },
  //         options: {
  //           // indexAxis: 'y', // Display bars along the y-axis
  //           scales: {
  //             x: {
  //               beginAtZero: true
  //             }
  //           },
  //           plugins: {
  //             legend: {
  //               display: false // This disables the display of the legend
  //             }
  //           }
  //         }
  //       });
  //     }
  //   }
  // }, [graphlabel1Pending, graphdata1Pending, chartType1, currentTab]);



  useEffect(() => {
    setChartType2('pie')

    if (chartRef2.current) {
      const ctx = chartRef2.current.getContext('2d');
      if (ctx) {
        if (chartInstanceRef2.current) chartInstanceRef2.current.destroy();

        chartInstanceRef2.current = new Chart(ctx, {
          type: chartType2 === 'pie' ? 'pie' : 'bar',
          data: {
            labels: graphlabel2,// ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',],
            datasets: [
              {
                label: 'Data',
                data: graphdata2, //[65, 29, 80, 59, 80, 81, 56, 60,],
                // borderColor: '#0053e3',
                backgroundColor: [
                  "#8B0000", // Dark Red
                  "#DC143C", // Crimson
                  "#B22222", // Firebrick
                  "#800000", // Maroon
                  "#CD5C5C", // Indian Red
                  "#FF6347", // Tomato
                  "#FF2400", // Scarlet
                  "#FF7F50"  // Coral
                ]
                // tension: 0.1,
                // borderWidth: 1,
              },
            ],
          },
          options: {
            // indexAxis: 'y',
            // scales: {
            //   x: {
            //     beginAtZero: true
            //   }
            // },
            plugins: {
              legend: {
                display: true // This disables the display of the legend
              }
            }
          }
        });
      }
    }

  }, [graphlabel2, graphdata2, chartType2, currentTab]);

  useEffect(() => {
    setChartType3('bar')
    if (chartRef3.current) {
      const ctx3 = chartRef3.current.getContext('2d');
      if (ctx3) {
        if (chartInstanceRef3.current) chartInstanceRef3.current.destroy();

        chartInstanceRef3.current = new Chart(ctx3, {
          type: chartType3 === 'bar' ? 'bar' : 'bar',
          data: {
            labels: graphlabel3,// ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',],
            datasets: [
              {
                label: 'Data',
                data: graphdata3, //[65, 29, 80, 59, 80, 81, 56, 60,],
                borderColor: 'red',
                backgroundColor: 'red',
                // tension: 0.2,
                // borderWidth: 3,
              },
            ],
          },
          options: {
            // indexAxis: 'y', // Display bars along the y-axis
            scales: {
              x: {
                beginAtZero: true
              }
            },
            plugins: {
              legend: {
                display: false // This disables the display of the legend
              }
            }
          }
        });
      }
    }
  }, [graphlabel3, graphdata3, chartType3, currentTab]);

  useEffect(() => {
    setChartType33('bar')
    if (chartRef33.current) {
      const ctx33 = chartRef33.current.getContext('2d');
      
      if (ctx33) {
        if (chartInstanceRef33.current) chartInstanceRef33.current.destroy();

        chartInstanceRef33.current = new Chart(ctx33, {
          type: chartType33 === 'bar' ? 'bar' : 'bar',
          data: {
            labels: graphlabel33,// ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',],
            datasets: [
              {
                label: 'Data',
                data: graphdata33, //[65, 29, 80, 59, 80, 81, 56, 60,],
                borderColor: 'red',
                backgroundColor: 'red',
                // tension: 0.2,
                // borderWidth: 3,
              },
            ],
          },
          options: {
            // indexAxis: 'y', // Display bars along the y-axis
            scales: {
              x: {
                beginAtZero: true
              }
            },
            plugins: {
              legend: {
                display: false // This disables the display of the legend
              }
            }
          }
        });
      }
    }
  }, [graphlabel33, graphdata33, chartType33, currentTab]);

  useEffect(() => {
    setChartType('bar')

    if (chartRef5.current) {
      const ctx = chartRef5.current.getContext('2d');
      if (ctx) {
        if (chartInstanceRef5.current) chartInstanceRef5.current.destroy();

        chartInstanceRef5.current = new Chart(ctx, {
          type: chartType5 === 'bar' ? 'bar' : 'bar',
          data: {
            labels: graphlabel5,// ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',],
            datasets: [
              {
                label: 'Data',
                data: graphdata5, //[65, 29, 80, 59, 80, 81, 56, 60,],
                borderColor: '#0053e3',
                backgroundColor: '#0053e3',
                // tension: 0.1,
                // borderWidth: 1,
              },
            ],
          },
          options: {
            // indexAxis: 'y',
            scales: {
              x: {
                beginAtZero: true
              }
            },
            plugins: {
              legend: {
                display: false // This disables the display of the legend
              }
            }
          }
        });
      }
    }

  }, [graphlabel5, graphdata5, chartType5, currentTab]);

  useEffect(() => {
    setChartType5('bar')
    setChartType4('bar')

    if (chartRef4.current) {
      const ctx = chartRef4.current.getContext('2d');
      if (ctx) {
        if (chartInstanceRef4.current) chartInstanceRef4.current.destroy();

        chartInstanceRef4.current = new Chart(ctx, {
          type: chartType4 === 'bar' ? 'bar' : 'bar',
          data: {
            labels: graphlabel4,// ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',],
            datasets: [
              {
                label: 'Data',
                data: graphdata4, //[65, 29, 80, 59, 80, 81, 56, 60,],
                borderColor: '#0053e3',
                backgroundColor: '#0053e3',
                // tension: 0.1,
                // borderWidth: 1,
              },
            ],
          },
          options: {
            // indexAxis: 'y',
            scales: {
              x: {
                beginAtZero: true
              }
            },
            plugins: {
              legend: {
                display: false // This disables the display of the legend
              }
            }
          }
        });
      }
    }

  }, [chartType, currentTab, chartType4, graphlabel4, graphdata4]);


  useEffect(() => {
    setChartType('bar')

    if (chartRefdepartmentCDSCount.current) {
      const ctx = chartRefdepartmentCDSCount.current.getContext('2d');

      if (ctx) {
        if (chartInstanceRefdepartmentCDSCount.current) chartInstanceRefdepartmentCDSCount.current.destroy();

        chartInstanceRefdepartmentCDSCount.current = new Chart(ctx, {
          type: chartType5 === 'bar' ? 'bar' : 'bar',
          data: {
            labels: departmentCBS,// ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',],
            datasets: [
              {
                label: 'Data',
                data: departmentCDSCount, //[65, 29, 80, 59, 80, 81, 56, 60,],
                borderColor: '#0053e3',
                backgroundColor: '#0053e3',
                // tension: 0.1,
                // borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              x: {
                beginAtZero: true,
                ticks: {
                    callback: function(value, index, values) {
                      // Cast value to number
                      const numericValue = value as number;

                      const label = this.getLabelForValue(numericValue);
                      return label.length > 5 ? label.substr(0, 5) + '...' : label;
                    }
                }
              }
            },
            plugins: {
              legend: {
                display: false,
                labels: {
                  filter: function(item) {
                    return !item.text.includes('(MUSD)'); // Exclude the legend entry for 'Data (Million)'
                  }
                }
              },
              tooltip: {
                  callbacks: {
                      title: function(tooltipItems) {
                          const idx = tooltipItems[0].dataIndex;
                          return departmentCBS[idx];
                      }
                  }
              }
            }
          }
        });
      }
    }

  }, [departmentCDSCount, departmentCBS, chartType5, currentTab]);

  useEffect(() => {
    setChartType('bar')

    if (chartRefdepartmentCDSCountPending.current) {
      const ctx = chartRefdepartmentCDSCountPending.current.getContext('2d');

      if (ctx) {
        if (chartInstanceRefdepartmentCDSCountPending.current) chartInstanceRefdepartmentCDSCountPending.current.destroy();

        chartInstanceRefdepartmentCDSCountPending.current = new Chart(ctx, {
          type: chartType5 === 'bar' ? 'bar' : 'bar',
          data: {
            labels: departmentCBSPending,// ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',],
            datasets: [
              {
                label: 'Data',
                data: departmentCDSCountPending, //[65, 29, 80, 59, 80, 81, 56, 60,],
                borderColor: '#0053e3',
                backgroundColor: '#0053e3',
                // tension: 0.1,
                // borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              x: {
                beginAtZero: true,
                ticks: {
                    callback: function(value, index, values) {
                      // Cast value to number
                      const numericValue = value as number;

                      const label = this.getLabelForValue(numericValue);
                      return label.length > 5 ? label.substr(0, 5) + '...' : label;
                    }
                }
              }
            },
            plugins: {
              legend: {
                display: false,
                labels: {
                  filter: function(item) {
                    return !item.text.includes('(MUSD)'); // Exclude the legend entry for 'Data (Million)'
                  }
                }
              },
              tooltip: {
                  callbacks: {
                      title: function(tooltipItems) {
                          const idx = tooltipItems[0].dataIndex;
                          return departmentCBSPending[idx];
                      }
                  }
              }
            }
          }
        });
      }
    }

  }, [departmentCDSCountPending, departmentCBSPending, chartType5, currentTab]);

  useEffect(() => {
    setChartType('bar')

    if (chartRefformTypeCDSCountPending.current) {
      const ctx = chartRefformTypeCDSCountPending.current.getContext('2d');

      if (ctx) {
        if (chartInstanceRefformTypeCDSCountPending.current) chartInstanceRefformTypeCDSCountPending.current.destroy();

        chartInstanceRefformTypeCDSCountPending.current = new Chart(ctx, {
          type: chartType5 === 'bar' ? 'bar' : 'bar',
          data: {
            labels: formTypeCBSPending,// ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',],
            datasets: [
              {
                label: 'Data',
                data: formTypeCDSCountPending, //[65, 29, 80, 59, 80, 81, 56, 60,],
                borderColor: '#0053e3',
                backgroundColor: '#0053e3',
                // tension: 0.1,
                // borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              x: {
                beginAtZero: true,
                ticks: {
                    callback: function(value, index, values) {
                      // Cast value to number
                      const numericValue = value as number;

                      const label = this.getLabelForValue(numericValue);
                      return label.length > 5 ? label.substr(0, 5) + '...' : label;
                    }
                }
              }
            },
            plugins: {
              legend: {
                display: false,
                labels: {
                  filter: function(item) {
                    return !item.text.includes('(MUSD)'); // Exclude the legend entry for 'Data (Million)'
                  }
                }
              },
              tooltip: {
                  callbacks: {
                      title: function(tooltipItems) {
                          const idx = tooltipItems[0].dataIndex;
                          return formTypeCBSPending[idx];
                      }
                  }
              }
            }
          }
        });
      }
    }

  }, [formTypeCDSCountPending, formTypeCBSPending, chartType5, currentTab]);

  useEffect(() => {
    setChartType('bar')

    if (chartRefformTypeCDSCountCompleted.current) {
      const ctx = chartRefformTypeCDSCountCompleted.current.getContext('2d');

      if (ctx) {
        if (chartInstanceRefformTypeCDSCountCompleted.current) chartInstanceRefformTypeCDSCountCompleted.current.destroy();

        chartInstanceRefformTypeCDSCountCompleted.current = new Chart(ctx, {
          type: chartType5 === 'bar' ? 'bar' : 'bar',
          data: {
            labels: formTypeCBSCompleted,// ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',],
            datasets: [
              {
                label: 'Data',
                data: formTypeCDSCountCompleted, //[65, 29, 80, 59, 80, 81, 56, 60,],
                borderColor: '#0053e3',
                backgroundColor: '#0053e3',
                // tension: 0.1,
                // borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              x: {
                beginAtZero: true,
                ticks: {
                    callback: function(value, index, values) {
                      // Cast value to number
                      const numericValue = value as number;

                      const label = this.getLabelForValue(numericValue);
                      return label.length > 5 ? label.substr(0, 5) + '...' : label;
                    }
                }
              }
            },
            plugins: {
              legend: {
                display: false,
                labels: {
                  filter: function(item) {
                    return !item.text.includes('(MUSD)'); // Exclude the legend entry for 'Data (Million)'
                  }
                }
              },
              tooltip: {
                  callbacks: {
                      title: function(tooltipItems) {
                          const idx = tooltipItems[0].dataIndex;
                          return formTypeCBSCompleted[idx];
                      }
                  }
              }
            }
          }
        });
      }
    }

  }, [formTypeCDSCountCompleted, formTypeCBSCompleted, chartType5, currentTab]);











  useEffect(() => {
    // getAverageContractValue();
    // getTotalContractValueDept();
    // getTotalContractValue();
    // getTotalPendingCContractValueDept();
    getAverageLeadTimeForCompletedApprovals();
    MaximumTimeForCompletedApprovals();
    AverageHoursForFormsPendingApprovals();
    TotalHoursSpentOnFormsPercentageApproval();
    // // TotalContractValueByDay();
    // TotalFormCountByStatus('APPROVED');
    // TotalFormCountByStatus('PENDING');
    TotalFormCountBySavingsStatus('APPROVED');
    TotalFormCountBySavingsStatus('PENDING');
    getAverageContractValuePendingTUCN();
    getAverageContractValueCompletedTUCN();
    TUCNSavingsValueByMonth();
    TUCNValueSavingsAcrossAllYears();

    DepartmentsCountByStatusCompleted();
    DepartmentsCountByStatusPending();
    FormTypesCountByStatusCompleted();
    FormTypesCountByStatusPending();
  }, []);

  useEffect(() => {
    TotalContractValueByDay(byDate);
  }, [byDate]);


  useEffect(() => {
    SavingsAmountByFormType(byDate);
  }, [byDate]);






  const getAverageLeadTimeForCompletedApprovals = () => {
    preloader();
    _$http('POST', 'Reports/Form/AverageLeadTimeForCompletedApprovals').then(res => {
      closeAlert();
      setaverageLeadTimeForComepletedApprovals(res.data.data.leadTimeValue);
    })
      .catch(error => {
        axiosError(error);
      })
  }
  const MaximumTimeForCompletedApprovals = () => {
    preloader();
    _$http('POST', 'Reports/Form/MaximumTimeForCompletedApprovals').then(res => {
      closeAlert();
      setmaximumLeadTime(res.data.data.leadTimeValue);
    })
      .catch(error => {
        axiosError(error);
      })
  }
  const AverageHoursForFormsPendingApprovals = () => {
    preloader();
    _$http('POST', 'Reports/Form/AverageHoursForFormsPendingApprovals').then(res => {
      closeAlert();
      setAverageHoursForPending(res.data.data.leadTimeValue);

      const FormType: any = [];
      const contractValue: any = [];

      // Iterate through data and populate arrays
      res.data.data.leadTimePerForm.forEach((item: any) => {
        FormType.push(`${item.formType}.`);
        contractValue.push(item.timeValue);
      });

      setgraphlabel2(FormType);
      setgraphdata2(contractValue)
    })
      .catch(error => {
        axiosError(error);
      })
  }
  const TotalHoursSpentOnFormsPercentageApproval = () => {
    preloader();
    _$http('POST', 'Reports/Form/TotalHoursSpentOnFormsPercentageApproval').then(res => {
      closeAlert();
      // setAverageContractValue(res.data.data.totalOrAverageContractValue)
    })
      .catch(error => {
        axiosError(error);
      })
  }

  const TotalContractValueByDay = (byDate:number) => {
    preloader();
    let data = {

      "ContractValueSelector": byDate

    }

    _$http('POST', 'Reports/Form/TotalContractValueByDate', data).then(res => {
      closeAlert();
      const FormType: any = [];
      const contractValue: any = [];

      console.log('i see');

      // Iterate through data and populate arrays
      res.data.data.forEach((item: any) => {
        FormType.push(`${formatDDMMYYYY(item.value)}.`);
        contractValue.push(item.totalContractValue);
      });

      setgraphlabel3(FormType);
      setgraphdata3(contractValue)

      console.log(FormType, contractValue, 'i see');


    })
      .catch(error => {
        axiosError(error);
      })
  }




  const TotalFormCountBySavingsStatus = (status: string) => {
    preloader();
    let data = {
      "formTypes": [
        "TUCN"
      ],
      "statuses": [status]

    }
    _$http('POST', 'Reports/TotalFormCountByStatus', data).then(res => {
      closeAlert();
      if (status === 'PENDING')
        settotalSavingsPending(res.data.data)

      else
        settotalSavingsCompleted(res.data.data)
    })
      .catch(error => {
        axiosError(error);
      })
  }


  const DepartmentsCountByStatusCompleted = () => {
    preloader();
    let data = {
      // "formTypes": [
      //   "TUCN"
      // ],
      "statuses": ['APPROVED']

    }
    _$http('POST', 'Reports/DepartmentsCountByStatus', data).then(res => {
      closeAlert();

      const departmentCBS: any = [];
      const departmentCDSCount: any = [];

      // Iterate through data and populate arrays
      res.data.data.departmentCountByStatusResponses.forEach((item: any) => {
        departmentCBS.push(item.name);
        departmentCDSCount.push(item.count);
      });

      setdepartmentCBS(departmentCBS);
      setdepartmentCDSCount(departmentCDSCount)


      
    })
      .catch(error => {
        axiosError(error);
      })
  }
  const DepartmentsCountByStatusPending = () => {
    preloader();
    let data = {
      // "formTypes": [
      //   "TUCN"
      // ],
      "statuses": ['PENDING']

    }
    _$http('POST', 'Reports/DepartmentsCountByStatus', data).then(res => {
      closeAlert();
      const departmentCBSPending: any = [];
      const departmentCDSCountPending: any = [];

      // Iterate through data and populate arrays
      res.data.data.departmentCountByStatusResponses.forEach((item: any) => {
        departmentCBSPending.push(item.name);
        departmentCDSCountPending.push(item.count);
      });

      setdepartmentCBSPending(departmentCBSPending);
      setdepartmentCDSCountPending(departmentCDSCountPending)


    })
      .catch(error => {
        axiosError(error);
      })
  }
  const FormTypesCountByStatusCompleted = () => {
    preloader();
    let data = {
      // "formTypes": [
      //   "TUCN"
      // ],
      "statuses": ['APPROVED']

    }
    _$http('POST', 'Reports/FormTypesCountByStatus', data).then(res => {
      closeAlert();
      const formTypeCBSCompleted: any = [];
      const formTypeCDSCountCompleted: any = [];

      // Iterate through data and populate arrays
      res.data.data.formTypesCountByStatusResponses.forEach((item: any) => {
        formTypeCBSCompleted.push(item.formType);
        formTypeCDSCountCompleted.push(item.count);
      });

      setformTypeCBSCompleted(formTypeCBSCompleted);
      setformTypeCDSCountCompleted(formTypeCDSCountCompleted)

    })
      .catch(error => {
        axiosError(error);
      })
  }
  const FormTypesCountByStatusPending = () => {
    preloader();
    let data = {
      // "formTypes": [
      //   "TUCN"
      // ],
      "statuses": ['PENDING']

    }
    _$http('POST', 'Reports/FormTypesCountByStatus', data).then(res => {
      closeAlert();
      const formTypeCBSPending: any = [];
      const formTypeCDSCountPending: any = [];

      // Iterate through data and populate arrays
      res.data.data.formTypesCountByStatusResponses.forEach((item: any) => {
        formTypeCBSPending.push(item.formType);
        formTypeCDSCountPending.push(item.count);
      });

      setformTypeCBSPending(formTypeCBSPending);
      setformTypeCDSCountPending(formTypeCDSCountPending)
    })
      .catch(error => {
        axiosError(error);
      })
  }



  const getAverageContractValuePendingTUCN = () => {
    preloader();
    let data = {
      "formTypes": [
        "TUCN"
      ],
      "statuses": ['PENDING']

    }
    _$http('POST', 'Reports/Form/TotalContractValue', data).then(res => {
      closeAlert();
      setSavingsPending(res.data.data.formContractValueResponses[0].contractValue)
    })
      .catch(error => {
        axiosError(error);
      })
  }
  const getAverageContractValueCompletedTUCN = () => {
    preloader();
    preloader(); let data = {
      "formTypes": [
        "TUCN"
      ],
      "statuses": ['APPROVED']

    }
    _$http('POST', 'Reports/Form/TotalContractValue', data).then(res => {
      closeAlert();
      setSavingsCompleted(res.data.data.formContractValueResponses[0].contractValue)
    })
      .catch(error => {
        axiosError(error);
      })

  }

  const SavingsAmountByFormType = (byDate:number) => {
    preloader();
    let data = {
      "statuses": [
        "APPROVED"
      ],
      // "departments": [
      //   "3fa85f64-5717-4562-b3fc-2c963f66afa6"
      // ],
      // "formTypes": [
      //   "AAF"
      // ],
      "year": 0,
      "reportGroupingSelector": byDate
    }

    _$http('POST', 'Reports/Form/SavingsAmountByFormType', data).then(res => {
      closeAlert();

      const FormType: any = [];
      const contractValue: any = [];

      console.log('i see', res.data);

      // Iterate through data and populate arrays
      res.data.data.savingsAmountByFormTypeResponses.forEach((item: any) => {
        FormType.push(`${formatDDMMYYYY(item.value)}.`);
        contractValue.push(item.totalContractValue);
      });

      setgraphlabel33(FormType);
      setgraphdata33(contractValue)

      console.log(FormType, contractValue, 'i see');

      
    })
      .catch(error => {
        axiosError(error);
      })

  }

  const TUCNValueSavingsAcrossAllYears = () => {
    preloader();
    _$http('GET', 'Reports/Form/TUCNValueSavingsAcrossAllYears').then(res => {
      closeAlert();
      const FormType: any = [];
      const contractValue: any = [];

      // Iterate through data and populate arrays
      res.data.data.forEach((item: any) => {
        FormType.push(`${item.year}.`);
        contractValue.push(item.totalSavings);
      });
      console.log(FormType, contractValue)

      setgraphlabel4(FormType);
      setgraphdata4(contractValue)

    })
      .catch(error => {
        axiosError(error);
      })

  }

  const TUCNSavingsValueByMonth = () => {
    preloader();
    _$http('GET', 'Reports/Form/TUCNSavingsValueByMonth').then(res => {
      closeAlert();

      const FormType: any = [];
      const contractValue: any = [];

      // Iterate through data and populate arrays
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', '',]
      res.data.data.forEach((item: any) => {

        // months.map((c:any)=>{

        // });

        FormType.push(`${months[item.month - 1]}.`);
        contractValue.push(item.totalSavings);

      });
      console.log(FormType, contractValue)

      setgraphlabel5(FormType);
      setgraphdata5(contractValue)
    })
      .catch(error => {
        axiosError(error);
      })
  }





  return (
    <div className='w-full'>

      {/* top tab nav */}
      <section className="bg-white shadow flex rounded-tr-lg rounded-tl-lg pt-4 text-sm font-medium text-center text-gray-500 dark:text-gray-400 border-b">
        <ul className="flex flex-wrap justify-start">
          <span className="me hover:bg-gray-50">
            <button onClick={() => { setCurrentTab('contract') }} className={currentTab === "contract" ? "inline-block py-4  px-12 font-bold text-red-500 border-red-500 border-b-2 dark:text-red-500 dark:border-blue-500" : "inline-block py-4 px-12 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}>Contract Values Overview</button>
          </span>

          <span className="me hover:bg-gray-50">
            <button onClick={() => { setCurrentTab('time') }} className={currentTab === "time" ? "inline-block py-4 px-12 font-bold text-red-500 border-red-500 border-b-2  dark:text-red-500 dark:border-blue-500" : "inline-block py-4 px-12 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}>Contract Time Overview</button>
          </span>

          <span className="me hover:bg-gray-50">
            <button onClick={() => { setCurrentTab('tucn') }} className={currentTab === "tucn" ? "inline-block py-4 px-12 font-bold text-red-500 border-red-500 border-b-2  dark:text-red-500 dark:border-blue-500" : "inline-block py-4 px-12 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}>Saving Sheet Overview</button>
          </span>
        </ul>
      </section>

      {/* filter by date; department and form area */}
      <div className='bg-white flex border-b p-4 mb-4 gap-5 shadow'>
        
        {/* start date */}
        <div>
          <span className="text-xs">Start Month/Year</span>
          <div className='flex gap-1'>
            <input className='text-xs h-[28px] border border-gray-400 px-3 py-1' type="date" id="startmonth" name="startmonth" onChange={(e:any) => setByStartDate((e.target as HTMLInputElement).value)}/>
          </div>
        </div>

        {/* end date */}
        <div>
          <span className="text-xs">End Month/Year</span>
          <div className='flex gap-1'>
            <input className='text-xs h-[28px] border border-gray-400 px-3 py-1' type="date" id="endmonth" name="endmonth" onChange={(e:any) => setByEndDate((e.target as HTMLInputElement).value)}/>
          </div>
        </div>

        {/* Department/Entity */}
        <div className="relative">
          <span className="text-xs">Department/Entity</span>

          <select multiple className="hidden" id="departmentSelect" onChange={(e) => setDepartmentID(Array.from(e.target.selectedOptions, option => option.value))}>
            <option value=''>Department</option>
            {department?.map((item: any, i: any) => (
              <option value={item.value} key={i}>{item.label}</option>
            ))}
          </select>

          <div className="flex flex-col space-y-1 text-xs">
            <div className="relative" onClick={() => setIsOpenDepartment(!isOpenDepartment)}>
              <div className="flex items-center justify-between w-[150px] h-[28px] border border-gray-400 px-3 py-1 cursor-pointer">
                <span className="block truncate">Department</span>
                <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className={isOpenDepartment ? 'h-5 w-5 text-gray-500 transform rotate-180' : 'h-5 w-5 text-gray-500'}>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7"></path>
                </svg>
              </div>

              <div className="absolute z-10 w-[200px] bg-white border border-gray-300 mt-1 h-[300px] overflow-y-scroll" style={{ display: isOpenDepartment ? 'block' : 'none' }}>
                <div className="max-h-38 overflow-y-auto">
                  {department?.map((item: any, i: any) => (
                    <label className="block px-4 py-2 flex gap-2 cursor-pointer hover:bg-gray-100" key={i}>
                      <input type="checkbox" className="form-checkbox" value={item.value} checked={DepartmentID.includes(item.value)} onChange={(e) => {
                        const isChecked = e.target.checked;
                        if (isChecked) {
                          setDepartmentID(prev => [...prev, item.value]);
                        } else {
                          setDepartmentID(prev => prev.filter(id => id !== item.value));
                        }
                      }} />
                      <span>{item.label}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Form Type */}
        <div className="relative">
          <span className="text-xs">Form Type</span>

          <select multiple className="hidden" id="formtypeSelect" onChange={(e) => setFormID(Array.from(e.target.selectedOptions, option => option.value))}>
            <option value=''>Form Type</option>
            {formTypesID?.map((item: any, i: any) => (
              <option value={item.type} key={i}>{item.name}</option>
            ))}
          </select>

          <div className="flex flex-col space-y-1 text-xs">
            <div className="relative" onClick={() => setIsOpenFormTypesID(!isOpenFormTypesID)}>
              <div className="flex items-center justify-between w-[150px] h-[28px] border border-gray-400 px-3 py-1 cursor-pointer">
                <span className="block truncate">Form Type</span>
                <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className={isOpenFormTypesID ? 'h-5 w-5 text-gray-500 transform rotate-180' : 'h-5 w-5 text-gray-500'}>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7"></path>
                </svg>
              </div>

              <div className="absolute z-10 w-[200px] bg-white border border-gray-300 mt-1 h-[300px] overflow-y-scroll" style={{ display: isOpenFormTypesID ? 'block' : 'none' }}>
                <div className="max-h-38 overflow-y-auto">
                  {formTypesID?.map((item: any, i: any) => (
                    <label className="block px-4 py-2 flex gap-2 cursor-pointer hover:bg-gray-100" key={i}>
                      <input type="checkbox" className="form-checkbox" value={item.type} checked={FormID.includes(item.type)} onChange={(e) => {
                        const isChecked = e.target.checked;
                        if (isChecked) {
                          setFormID(prev => [...prev, item.type]);
                        } else {
                          setFormID(prev => prev.filter(id => id !== item.type));
                        }
                      }} />
                      <span>{item.name}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* top panels for contract value count report ***** */}
      {
        currentTab === "contract" &&
        <section>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 mb-3'>

              <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg hover:shadow-2xl'>
                <div>
                  <h5 className='text-gray font-semibold'>Forms Completed</h5>
                  <h1 className='text-2xl font-extrabold'>{totalCompleted}</h1>
                </div>
                <svg className='mt-2' width="50" height="50" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="22" cy="22" r="22" fill="#EEEEEE" />
                  <mask id="mask0_264_247" maskUnits="userSpaceOnUse" x="10" y="10" width="24" height="24">
                    <rect x="10.4766" y="10.4762" width="23.0476" height="23.0476" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_264_247)">
                    <path d="M19.624 27.7621L29.8034 17.5827L28.435 16.2383L19.6 25.0492L15.5427 20.9678L14.1742 22.3363L19.624 27.7621ZM19.624 30.475L11.4854 22.3363L15.5427 18.2549L19.624 22.3363L28.4109 13.5254L32.5403 17.5587L19.624 30.475Z" fill="#37a745" />
                  </g>
                </svg>
              </div>

              <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg hover:shadow-2xl'>
                <div>
                  <h5 className='font-semibold text-gray'>Forms Pending</h5>
                  <h1 className='text-2xl font-extrabold'>{totalPendingForms}</h1>
                </div>

                <svg className='mt-2' width="50" height="50" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="22" cy="22" r="22" fill="#EEEEEE" />
                  <mask id="mask0_264_242" maskUnits="userSpaceOnUse" x="10" y="10" width="24" height="24">
                    <rect x="10.5059" y="10.5061" width="22.9878" height="22.9878" fill="#7C73AC" />
                  </mask>
                  <g mask="url(#mask0_264_242)">
                    <path d="M17.2102 26.7891H23.915V24.8735H17.2102V26.7891ZM17.2102 22.9578H26.7884V21.0422H17.2102V22.9578ZM17.2102 19.1265H26.7884V17.2109H17.2102V19.1265ZM15.2946 30.6204C14.7677 30.6204 14.3168 30.4328 13.9416 30.0577C13.5665 29.6825 13.3789 29.2316 13.3789 28.7047V15.2952C13.3789 14.7684 13.5665 14.3174 13.9416 13.9423C14.3168 13.5672 14.7677 13.3796 15.2946 13.3796H28.7041C29.2309 13.3796 29.6819 13.5672 30.057 13.9423C30.4321 14.3174 30.6197 14.7684 30.6197 15.2952V28.7047C30.6197 29.2316 30.4321 29.6825 30.057 30.0577C29.6819 30.4328 29.2309 30.6204 28.7041 30.6204H15.2946Z" fill="#0b00ff" />
                  </g>
                </svg>
              </div>

              <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg hover:shadow-2xl'>
                <div>
                  <h5 className='font-semibold text-gray'>Completed Contract Value</h5>
                  <h1 className='text-2xl font-extrabold'>${(twoDecimalPlace(contractValue/ValueDivider))?.toLocaleString()}M</h1>
                </div>
              </div>

              <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg hover:shadow-2xl'>
                <div>
                  <h5 className='font-semibold text-gray'>Average Contract Value</h5>
                  <h1 className='text-2xl font-extrabold'>${(AverageContractValue/ValueDivider)?.toLocaleString()}M</h1>
                </div>
              </div>

          </div>

          <div className='grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-4 gap-3 mb-5'>
            <div className='rounded-lg p-4 bg-white col-span-2 border shadow-sm hover:shadow-2xl'>
              <p className='font-semibold text-lg mb-4'>Completed contract value (MUSD) by form type</p>
              <canvas className='' id='chartContractValueByForm' ref={chartContractValueByForm} width={400} height={280}></canvas>
            </div>
            <div className='rounded-lg p-4 bg-white col-span-2 border shadow-sm hover:shadow-2xl'>
              <p className='font-semibold text-lg mb-4'>Pending contract value (MUSD) by form type</p>
              <canvas className='' id='chartContractValueByFormPending' ref={chartContractValueByFormPending} width={400} height={280}></canvas>
            </div>

            <div className='rounded-lg p-4 bg-white col-span-2 border shadow-sm hover:shadow-2xl'>
              <p className='font-semibold text-lg mb-4'>Completed contract count by form type</p>
              <canvas className='' id='chartRefformTypeCDSCountCompleted' ref={chartRefformTypeCDSCountCompleted} width={400} height={280}></canvas>
            </div>
            <div className='rounded-lg p-4 bg-white col-span-2 border shadow-sm hover:shadow-2xl'>
              <p className='font-semibold text-lg mb-4'>Pending contract count by form type</p>
              <canvas className='' id='chartRefformTypeCDSCountPending' ref={chartRefformTypeCDSCountPending} width={400} height={280}></canvas>
            </div>

            <div className='rounded-lg p-4 bg-white col-span-2 border shadow-sm hover:shadow-2xl'>
              <p className='font-semibold text-lg mb-4'>Completed contract value (MUSD) by department</p>
              <canvas className='' id='chartContractValueByDept' ref={chartContractValueByDept} width={400} height={280}></canvas>
            </div>
            <div className='rounded-lg p-4 bg-white col-span-2 border shadow-sm hover:shadow-2xl'>
              <p className='font-semibold text-lg mb-4'>Pending contract value (MUSD) by department</p>
              <canvas className='' id='chartContractValueByDeptPending' ref={chartContractValueByDeptPending} width={400} height={280}></canvas>
            </div>

            <div className='rounded-lg p-4 bg-white col-span-2 border shadow-sm hover:shadow-2xl'>
              <p className='font-semibold text-lg mb-4'>Completed contract count by department</p>
              <canvas className='' id='chartRefdepartmentCDSCount' ref={chartRefdepartmentCDSCount} width={400} height={280}></canvas>
            </div>

            <div className='rounded-lg p-4 bg-white col-span-2 border shadow-sm hover:shadow-2xl'>
              <p className='font-semibold text-lg mb-4'>Pending contract count by department</p>
              <canvas className='' id='chartRefdepartmentCDSCountPending' ref={chartRefdepartmentCDSCountPending} width={400} height={280}></canvas>
            </div>

          </div>

        </section>
      }












      {/* top panels for count report */}
      <section className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 mb-3 hidden'>
        {
          currentTab !== "tucn" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Total Forms Pending</h5>
              <h1 className='text-2xl font-extrabold'>{totalPendingForms}</h1>
            </div>
            <svg className='mt-2' width="50" height="50" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="22" cy="22" r="22" fill="#EEEEEE" />
              <mask id="mask0_264_242" maskUnits="userSpaceOnUse" x="10" y="10" width="24" height="24">
                <rect x="10.5059" y="10.5061" width="22.9878" height="22.9878" fill="#7C73AC" />
              </mask>
              <g mask="url(#mask0_264_242)">
                <path d="M17.2102 26.7891H23.915V24.8735H17.2102V26.7891ZM17.2102 22.9578H26.7884V21.0422H17.2102V22.9578ZM17.2102 19.1265H26.7884V17.2109H17.2102V19.1265ZM15.2946 30.6204C14.7677 30.6204 14.3168 30.4328 13.9416 30.0577C13.5665 29.6825 13.3789 29.2316 13.3789 28.7047V15.2952C13.3789 14.7684 13.5665 14.3174 13.9416 13.9423C14.3168 13.5672 14.7677 13.3796 15.2946 13.3796H28.7041C29.2309 13.3796 29.6819 13.5672 30.057 13.9423C30.4321 14.3174 30.6197 14.7684 30.6197 15.2952V28.7047C30.6197 29.2316 30.4321 29.6825 30.057 30.0577C29.6819 30.4328 29.2309 30.6204 28.7041 30.6204H15.2946Z" fill="#0b00ff" />
              </g>
            </svg>
          </div>
        }

        {
          currentTab === "contract" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Total Forms Completed</h5>
              <h1 className='text-2xl font-extrabold'>{totalCompleted}</h1>
            </div>
            <svg className='mt-2' width="50" height="50" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="22" cy="22" r="22" fill="#EEEEEE" />
              <mask id="mask0_264_247" maskUnits="userSpaceOnUse" x="10" y="10" width="24" height="24">
                <rect x="10.4766" y="10.4762" width="23.0476" height="23.0476" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_264_247)">
                <path d="M19.624 27.7621L29.8034 17.5827L28.435 16.2383L19.6 25.0492L15.5427 20.9678L14.1742 22.3363L19.624 27.7621ZM19.624 30.475L11.4854 22.3363L15.5427 18.2549L19.624 22.3363L28.4109 13.5254L32.5403 17.5587L19.624 30.475Z" fill="#37a745" />
              </g>
            </svg>
          </div>
        }

        {
          currentTab === "contract" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Completed Contract Value</h5>
              <h1 className='text-2xl font-extrabold'>${(contractValue/1000000)?.toLocaleString()}M</h1>
            </div>
          </div>
        }

        {
          currentTab === "contract" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Average Contract Value</h5>
              <h1 className='text-2xl font-extrabold'>${(AverageContractValue/1000000)?.toLocaleString()}M</h1>
            </div>
          </div>
        }

        {
          currentTab === "time" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Average Lead Time For Completed Approvals</h5>
              <h1 className='text-2xl font-extrabold'>{Math.round(averageLeadTimeForComepletedApprovals)?.toLocaleString()}</h1>
            </div>
          </div>
        }


        {
          currentTab === "time" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Maximum Lead Time For Completed Approvals</h5>
              <h1 className='text-2xl font-extrabold'>{maximumLeadTime?.toLocaleString()}</h1>
            </div>
          </div>
        }
        
        {
          currentTab === "time" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Average Time For Forms Pending Approval</h5>
              <h1 className='text-2xl font-extrabold'>{Math.round(AverageHoursForPending)?.toLocaleString()}</h1>
            </div>
          </div>
        }
        {
          currentTab === "tucn" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Savings Forms Completed</h5>
              <h1 className='text-2xl font-extrabold'>{totalSavingsCompleted?.toLocaleString()}</h1>
            </div>
          </div>
        }
        {
          currentTab === "tucn" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Savings Forms Pending Validation</h5>
              <h1 className='text-2xl font-extrabold'>{totalSavingsPending?.toLocaleString()}</h1>
            </div>
          </div>
        }
        {
          currentTab === "tucn" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Value of Savings Forms Completed</h5>
              <h1 className='text-2xl font-extrabold'>${(SavingsCompleted/1000000)?.toLocaleString()}</h1>
            </div>
          </div>
        }
        {
          currentTab === "tucn" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Value of Savings Forms Pending Validation</h5>
              <h1 className='text-2xl font-extrabold'>${(SavingsPending/1000000)?.toLocaleString()}</h1>
            </div>
          </div>
        }
      </section>


      {/* top panels for count report */}
      <section className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 mb-3 hidden'>
        {
          currentTab !== "tucn" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Total Forms Pending</h5>
              <h1 className='text-2xl font-extrabold'>{totalPendingForms}</h1>
            </div>
            <svg className='mt-2' width="50" height="50" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="22" cy="22" r="22" fill="#EEEEEE" />
              <mask id="mask0_264_242" maskUnits="userSpaceOnUse" x="10" y="10" width="24" height="24">
                <rect x="10.5059" y="10.5061" width="22.9878" height="22.9878" fill="#7C73AC" />
              </mask>
              <g mask="url(#mask0_264_242)">
                <path d="M17.2102 26.7891H23.915V24.8735H17.2102V26.7891ZM17.2102 22.9578H26.7884V21.0422H17.2102V22.9578ZM17.2102 19.1265H26.7884V17.2109H17.2102V19.1265ZM15.2946 30.6204C14.7677 30.6204 14.3168 30.4328 13.9416 30.0577C13.5665 29.6825 13.3789 29.2316 13.3789 28.7047V15.2952C13.3789 14.7684 13.5665 14.3174 13.9416 13.9423C14.3168 13.5672 14.7677 13.3796 15.2946 13.3796H28.7041C29.2309 13.3796 29.6819 13.5672 30.057 13.9423C30.4321 14.3174 30.6197 14.7684 30.6197 15.2952V28.7047C30.6197 29.2316 30.4321 29.6825 30.057 30.0577C29.6819 30.4328 29.2309 30.6204 28.7041 30.6204H15.2946Z" fill="#0b00ff" />
              </g>
            </svg>
          </div>
        }

        {
          currentTab === "contract" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Total Forms Completed</h5>
              <h1 className='text-2xl font-extrabold'>{totalCompleted}</h1>
            </div>
            <svg className='mt-2' width="50" height="50" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="22" cy="22" r="22" fill="#EEEEEE" />
              <mask id="mask0_264_247" maskUnits="userSpaceOnUse" x="10" y="10" width="24" height="24">
                <rect x="10.4766" y="10.4762" width="23.0476" height="23.0476" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_264_247)">
                <path d="M19.624 27.7621L29.8034 17.5827L28.435 16.2383L19.6 25.0492L15.5427 20.9678L14.1742 22.3363L19.624 27.7621ZM19.624 30.475L11.4854 22.3363L15.5427 18.2549L19.624 22.3363L28.4109 13.5254L32.5403 17.5587L19.624 30.475Z" fill="#37a745" />
              </g>
            </svg>
          </div>
        }

        {
          currentTab === "contract" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Completed Contract Value</h5>
              <h1 className='text-2xl font-extrabold'>${(contractValue/1000000)?.toLocaleString()}M</h1>
            </div>
          </div>
        }

        {
          currentTab === "contract" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Average Contract Value</h5>
              <h1 className='text-2xl font-extrabold'>${(AverageContractValue/1000000)?.toLocaleString()}M</h1>
            </div>
          </div>
        }

        {
          currentTab === "time" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Average Lead Time For Completed Approvals</h5>
              <h1 className='text-2xl font-extrabold'>{Math.round(averageLeadTimeForComepletedApprovals)?.toLocaleString()}</h1>
            </div>
          </div>
        }


        {
          currentTab === "time" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Maximum Lead Time For Completed Approvals</h5>
              <h1 className='text-2xl font-extrabold'>{maximumLeadTime?.toLocaleString()}</h1>
            </div>
          </div>
        }
        
        {
          currentTab === "time" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Average Time For Forms Pending Approval</h5>
              <h1 className='text-2xl font-extrabold'>{Math.round(AverageHoursForPending)?.toLocaleString()}</h1>
            </div>
          </div>
        }
        {
          currentTab === "tucn" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Savings Forms Completed</h5>
              <h1 className='text-2xl font-extrabold'>{totalSavingsCompleted?.toLocaleString()}</h1>
            </div>
          </div>
        }
        {
          currentTab === "tucn" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Savings Forms Pending Validation</h5>
              <h1 className='text-2xl font-extrabold'>{totalSavingsPending?.toLocaleString()}</h1>
            </div>
          </div>
        }
        {
          currentTab === "tucn" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Value of Savings Forms Completed</h5>
              <h1 className='text-2xl font-extrabold'>${(SavingsCompleted/1000000)?.toLocaleString()}</h1>
            </div>
          </div>
        }
        {
          currentTab === "tucn" &&
          <div className='rounded-lg p-5 flex justify-between bg-white border shadow-lg'>
            <div>
              <h5 className='font-medium text-gray'>Value of Savings Forms Pending Validation</h5>
              <h1 className='text-2xl font-extrabold'>${(SavingsPending/1000000)?.toLocaleString()}</h1>
            </div>
          </div>
        }
      </section>
































      {/* dashboard lists - report */}
      <section className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 mb-5'>






        {/* {
          currentTab === "time" &&
          <div className='rounded-lg p-4 bg-white col-span-2 px-24'>
            <p>Total Time Spent On Approved Forms by Form Type</p>
            <canvas className='' id='mycanvas2' ref={chartRef2} width={400} height={280}></canvas>
          </div>
        } */}

        {
          currentTab === "time" &&
          <div className='rounded-lg p-4 bg-white col-span-full border shadow-lg'>
            <div className='flex justify-between'>
              <p>Contract Time Overview</p>
              <select onChange={(e:any)=> setByDate(e.target.value)} className="p-1 text-xs">
                <option value={1}>By Month</option>
                <option value={2}>By Year</option>
                {/* <option value={3}>By Month</option> */}
              </select>
            </div>

            <canvas className='mt-12' id='mycanvas3' ref={chartRef3} width={400} height={200}></canvas>
          </div>
        }



        {
          currentTab === "tucn" &&
          <div className='rounded-lg p-4 bg-white col-span-2 px-12 border shadow-lg'>
            {/* <p className='font-semibold p-3'>Value of savings accross all the years of the contract</p> */}
            
            <div className='flex justify-between'>
              <p>Value of savings accross all the years of the contract</p>
              <select onChange={(e:any)=> setByDate(e.target.value)} className="p-1 text-xs">
                <option value={1}>By Month</option>
                <option value={2}>By Year</option>
                {/* <option value={3}>By Month</option> */}
              </select>
            </div>
            <canvas className='' id='mycanvas4' ref={chartRef4} width={400} height={280}></canvas>
          </div>
        }
        {
          currentTab === "tucn" &&
          <div className='font-semibold rounded-lg p-4 bg-white col-span-2 px-12 border shadow-lg'>
            
            <div className='flex justify-between'>
              <p>Value of savings</p>
              <select onChange={(e:any)=> setByDate(e.target.value)} className="p-1 text-xs">
                <option value={1}>By Month</option>
                <option value={2}>By Year</option>
                {/* <option value={3}>By Month</option> */}
              </select>
            </div>

            <canvas className='' id='mycanvas5' ref={chartRef5} width={400} height={280}></canvas>
          </div>
        }
        {
          currentTab === "tucn" &&
          <div className='rounded-lg p-4 bg-white col-span-full border shadow-lg'>
            <div className='flex justify-between'>
              <p>Savings Amount By Form Type</p>
              <select onChange={(e:any)=> setByDate(e.target.value)} className="p-1 text-xs">
                <option value={1}>By month</option>
                {/* <option value={2}>By Quarter</option> */}
                <option value={3}>By Year</option>
              </select>
            </div>

            <canvas className='mt-12' id='mycanvas33' ref={chartRef33} width={400} height={200}></canvas>
          </div>
        }

      </section>
    </div>
  );
}

export default Reporting;
