export const TUCNData = {
  OPEX: "OPEX",
  CAPEX: "CAPEX",
  ONSHORE: "OnShore",
  OFFSHORE: "OffShore",
  LOCAL: "Local",
  PILOTED: "Piloted",
  CENTRALIZED: "Centralized",
  REFERENCE: "Reference",
  NEGOTIATION: "Negotiation",
  COSTAVOIDANCE: "Cost Avoidance",
  PRODUCTIONIMPACT: "Production Impact",
  INITIATIVE: "Initiative",
  TUCNResults: [
    {
      "savingsType": "3C",
      "totalSavings": "",
      "year1": "",
      "year1Value": "",
      "year2": "",
      "year2Value": "",
      "year3": "",
      "year3Value": ""
    },
    {
      "savingsType": "Economic Impact",
      "totalSavings": "",
      "year1": "",
      "year1Value": "",
      "year2": "",
      "year2Value": "",
      "year3": "",
      "year3Value": ""
    },
    {
      "savingsType": "Performance",
      "totalSavings": "",
      "year1": "",
      "year1Value": "",
      "year2": "",
      "year2Value": "",
      "year3": "",
      "year3Value": ""
    },
    {
      "savingsType": "Cost Avoidance",
      "totalSavings": "",
      "year1": "",
      "year1Value": "",
      "year2": "",
      "year2Value": "",
      "year3": "",
      "year3Value": ""
    }
  ]
}