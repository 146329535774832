import { _$http, axiosError, closeAlert, preloader, successAlert, confirmAlert } from '../../utils';
import { ThreeDots, XMark } from '../../components/Icons';
import { useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight, FaMagnifyingGlass } from "react-icons/fa6";
import { LabelXS } from '../../components/FormUI';
import { tinyAlert } from '../../services/_config.services';

import api from '../../services/form.services';
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { OptionProps } from 'react-select';

const filterOptions = createFilterOptions({ limit: 100 });
const getOptionLabel = (option: any) => (<div dangerouslySetInnerHTML={{ __html: !!option.label ? option?.label : '' }} />);

function UserManagement() {
  const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false);
  const [actionType, setActionType] = useState(0);
  const [userId, setUserId] = useState(null);
  const [tepng_users, setTepngUsers] = useState<OptionProps[]>([]);

  //---
  const [formRecord, setFormRecord] = useState([]);
  const [roles, setRoles] = useState<any>([]);
  const [department, setDepartment] = useState([]);
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const [OrderBy] = useState(2);
  const [SearchString, setSearchString] = useState('');
  const [RoleId, setRoleId] = useState('');
  const [RoleIdForAssign, setRoleIdForAssign] = useState('');
  const [DeptIdForAssign, setDeptIdForAssign] = useState('');
  const [ApprovalStatus, setApprovalStatus] = useState('');
  const [TotalCount, setTotalCount] = useState(0);
  const [PageIndex, setPageIndex] = useState(0);
  const [HasNextPage, setHasNextPage] = useState(false);
  const [HasPreviousPage, setHasPreviousPage] = useState(false);
  const [toggleCreateModal, setToggleCreateModal] = useState(false);
  const [toggleCreateNewModal, setToggleCreateNewModal] = useState(false)
  const [selectedUserInfo, setselectedUserInfo] = useState<any>({})
  const [selectedEmail, setSelectedEmail] = useState('');

  useEffect(() => { getRoles(); api.fetchTepngUsers().then((res) => setTepngUsers(res)); }, []);
  useEffect(() => {
    fetchUsers(Page, PageSize, OrderBy, SearchString, ApprovalStatus, RoleId) // eslint-disable-next-line
  }, [Page, PageSize, OrderBy, SearchString, ApprovalStatus, RoleId]);

  useEffect(() => { getRoles() }, []);
  useEffect(() => {
    // setCurrentURL(window.location.href)
    getDepartments()
  }, []);

  
  const getDepartments = () => {
    preloader();  
    _$http('GET', 'Departments').then(res => {
        closeAlert();
        const record = res.data.data
        setDepartment(record)
    })
    .catch(error => {
        axiosError(error);
    })
  };
  const getRoles = () => {
    preloader();
    _$http('GET', 'Roles').then(res => {
      closeAlert();
      setRoles(res.data.data);
      setRoleId(res.data.data.find((item:any) => item.name === 'Administrator').id)

    })
      .catch(error => {
        axiosError(error);
      })
  };


  const fetchUsers = (Page: number, PageSize: number, OrderBy: number, SearchString: string, ApprovalStatus: string, RoleId:string) => {
    if (!!SearchString && SearchString.length < 3) {
      return;
    }

    if (!RoleId) {
      return;
    }

    let cc = 'Roles/GetAllUsersForARole?roleId='+RoleId
    //&departmentId=49b8a0d8-a074-4322-b7c4-0d96d0571f27

    preloader();
    // let url = `User/?pageNumber=${Page}&size=${PageSize}&OrderBy=${OrderBy}`;

    // if(!!SearchString)
    //   url += `&searchQuery=${SearchString}`;
        
    // if(!!ApprovalStatus)
    //   url += `&ApprovalStatus=${ApprovalStatus}`;


    _$http('GET', cc).then(res => {
      closeAlert();
      const record = res.data

      setFormRecord(record.data);
      setTotalCount(record.totalCount);
      setHasNextPage(record.hasNextPage);
      setHasPreviousPage(record.hasPreviousPage);
      setPageIndex(record.pageIndex);

    })
    .catch(error => {
      axiosError(error);
    })
  };

  const handleUpdateUser = (modal: any, type: any, id: any) => {
    setToggleConfirmationModal(modal);
    setActionType(type);
    setUserId(id)
  }

  const updateUser = () => {
    preloader();
    setToggleConfirmationModal(false)
    var request_url = '';
    if (actionType === 1) request_url = 'User/Disable?userId=' + userId
    if (actionType === 2) request_url = 'User/Enable?userId=' + userId

    _$http('PATCH', request_url).then(res => {
      closeAlert();
      setUserId(null);
      const message = res.data.message;
      console.log(message);
      successAlert(message);

      fetchUsers(Page, PageSize, OrderBy, SearchString, ApprovalStatus,RoleId);

    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleDelete = () => {
    preloader();
    setToggleConfirmationModal(false)
    _$http('DELETE', 'Users/' + userId).then(res => {
      closeAlert();
      setUserId(null)
      const message = res.data.message
      console.log(message)
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const passUserInfo = (item:any) =>{
    setToggleCreateModal(true);
    setRoleIdForAssign('')
    setselectedUserInfo(item)

  }

  
  const updateUserRole = () => {
    preloader();
    setToggleConfirmationModal(false)
    var request_url = 'Roles/AssignUserToRoles';
    let data:any = {};
     data.RoleId = RoleIdForAssign;
     data.Email = selectedUserInfo.email;


    if (!!DeptIdForAssign)
     data.DepartmentId = DeptIdForAssign;

    _$http('PATCH', request_url, data).then(res => {
      closeAlert();
      setUserId(null);
      const message = res.data.message;
      console.log(message);
      tinyAlert('success',message);
      setToggleCreateModal(false);

      fetchUsers(Page, PageSize, OrderBy, SearchString, ApprovalStatus,RoleId);

    })
      .catch(error => {
        axiosError(error);
      })
  };

  
  const createUserRole = () => {
    preloader();
    setToggleConfirmationModal(false)
    var request_url = 'Roles/AssignUserToRoles';
    let data:any = {};
     data.RoleId = RoleIdForAssign;
     data.Email = selectedEmail;

     if(!data.Email && !data.RoleId)
      tinyAlert('error', 'email and role is required')


    if (!!DeptIdForAssign)
     data.DepartmentId = DeptIdForAssign;

    _$http('PATCH', request_url, data).then(res => {
      closeAlert();
      setUserId(null);
      const message = res.data.message;
      console.log(message);
      tinyAlert('success',message);
      setToggleCreateNewModal(false)

      fetchUsers(Page, PageSize, OrderBy, SearchString, ApprovalStatus,RoleId);

    })
      .catch(error => {
        axiosError(error);
      })
  };

  const RemoveUserRole = (x:any) => {
    console.log(x)

    confirmAlert(`Do you want to remove ${x.email} from ${roles?.find((item:any)=> item.id === RoleId)?.name}?`).then((r:any) => {
      if (r.isConfirmed) {

          preloader();
          setToggleConfirmationModal(false)
          var request_url = 'Roles/RemoveUserFromRoles';
          let data:any = {};
          data.RoleId = roles?.find((item:any)=> item.id === RoleId)?.id;
          data.Email = x.email;
      
      
          if (!!x.departmentId)
            data.DepartmentId = x?.departmentId;
      
          _$http('PATCH', request_url, data).then(res => {
            closeAlert();
            setUserId(null);
            const message = res.data.message;
            console.log(message);
            tinyAlert('success', message);
      
            fetchUsers(Page, PageSize, OrderBy, SearchString, ApprovalStatus,RoleId);

          })
          .catch(error => {
            axiosError(error);
          })

      }
    });

  };

  return (
    <div className='bg-white w-full shadow-sm rounded-lg py-4 dark:bg-gray-800'>
      <div className='flex justify-between mb-3'>
        <div className='flex gap-2 px-4 w-3/5'>
          <div className='relative w-full'>
            <input onChange={(e) => setSearchString(e.target.value)} type="text" name='search' className="border w-full pl-8 px-3 py-2 text-sm mr-1 dark:bg-gray-800 dark:text-gray-300" placeholder="Search User by Email Or Name" />
            <FaMagnifyingGlass className='text-gray-400 absolute top-[10px] left-[10px]' />
          </div>

          <select className='border p-1 text-xs text-gray-500 dark:bg-gray-800 dark:text-gray-300' onChange={(e) => setApprovalStatus(e.target.value)}>
            <option value=''>All Status</option>
            <option value='Active'>Active</option>
            <option value='Inactive'>Inactive</option>
          </select>

          <select className='border p-1 text-xs text-gray-500 dark:bg-gray-800 dark:text-gray-300 w-[100px]' onChange={(e) => setRoleId(e.target.value)}>
            <option value=''>Roles</option>
            {
              roles.map((item:any) => <option value={item.id}>{item?.name}</option>)
            }
          </select>

          <select className='border p-1 text-xs text-gray-500 dark:bg-gray-800 dark:text-gray-300 w-[100px] hidden' onChange={(e) => setApprovalStatus(e.target.value)}>
            <option value=''>Department</option>
            {
              department.map((item:any) => <option value={item.id}>{item?.name}</option>)
            }
          </select>
        </div>

        <div className='text-xs text-gray-400 pr-8 flex gap-8'>
          <select value={PageSize} onChange={(e) => setPageSize(Number(e.target.value))} id="entries" className='border p-1 text-xs dark:bg-gray-800 dark:text-gray-300'>
            <option value='3'>Show 3 entries</option>
            <option value='10'>Show 10 entries</option>
            <option value='25'>Show 25 entries</option>
            <option value='50'>Show 50 entries</option>
            <option value='100'>Show 100 entries</option>
          </select>

          <span className='mt-3'>
            {formRecord?.length > 0 ? (PageSize * (PageIndex - 1)) + 1 : 0}-{(PageSize * (PageIndex - 1)) + formRecord?.length} of {TotalCount}
          </span>

          <span className='flex gap-5 mt-3'>
            <FaAngleLeft onClick={() => HasPreviousPage && setPage(PageIndex - 1)} className={`${HasPreviousPage && 'text-gray-900 hover-arrow-active dark:text-gray-400'}`} />
            <FaAngleRight onClick={() => HasNextPage && setPage(PageIndex + 1)} className={`${HasNextPage && 'text-gray-900 hover-arrow-active dark:text-gray-400'}`} />
          </span>
        </div>
      </div>

      <div  className='flex justify-end px-4'>
        <button onClick={() => setToggleCreateNewModal(true)} className='red-button rounded px-4 py-2 text-sm'>Create User</button>
      </div>

      <div className="relative overflow-x-none">
        <table className="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className=" text-[14px] text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              {
                roles?.find((item:any)=> item.id === RoleId)?.type === "Departmental" &&
                <th scope="col" className="px-6 py-3">
                  Department
                </th>
              }
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {
              formRecord?.map((item: any, index: number) => {
                return (
                  <tr key={index} className="bg-white border-t hover:bg-gray-5 text-[13px] dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.firstName} {item.lastName}
                    </th>
                    <td className="px-6 py-4">
                      {item.email}
                    </td>
                    <td className="px-6 py-4">
                      {roles?.find((item:any)=> item.id === RoleId)?.name}
                    </td>

                    {
                      roles?.find((item:any)=> item.id === RoleId)?.type === "Departmental" &&
                      <td className="px-6 py-4">
                        {item?.department?.name}
                      </td>
                    }
                    
                    <td className="px-6 py-4">
                      <span className={item.isEnabled ? 'bg-green-100 text-green-800 px-3 py-1 rounded-full' : 'bg-red-100 text-red-800 px-3 py-1 rounded-full'}>{item.isEnabled ? "Active" : "Inactive"}</span>
                    </td>

                    <td className="px-6 py-4">
                      <div className="dropdown">
                        <button title='More Options' className='text-blue-600 dropbtn'>
                          <ThreeDots />
                        </button>
                        <div className="dropdown-content shadow-sm" style={{ right: "0" }}>
                          {item.isEnabled && <button onClick={() => handleUpdateUser(true, 1, item.id)}>Deactivate</button>}
                          {!item.isEnabled && <button onClick={() => handleUpdateUser(true, 2, item.id)}>Activate</button>}
                          <button onClick={() => handleUpdateUser(true, 3, item.id)}>Delete</button>
                          <button onClick={() => passUserInfo(item)} className=''>Update Role</button>
                          <button onClick={() => RemoveUserRole(item)} className=''>Remove Role</button>
                        </div>
                      </div>

                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>

        {
          formRecord?.length < 1 &&
          <p className='text-center p-5 text-xs'>No record available...</p>
        }
      </div>

      {
        toggleConfirmationModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-sm">Are you sure you want to {actionType === 1 ? "deactivate" : actionType === 2 ? "activate" : actionType === 3 ? "delete" : ""} this user?</h2>
              <button onClick={() => setToggleConfirmationModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <div className="modal-content text-left px-6 flex justify-center gap-2 mt-4">
              <button onClick={() => setToggleConfirmationModal(false)} type="button" className="rounded text-black bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">Cancel</button>
              <button onClick={() => actionType < 3 ? updateUser() : handleDelete()} type="button" className="rounded text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Yes</button>
            </div>
          </div>
        </div>
      }

      {
        toggleCreateModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Assign Role</h2>
              <button onClick={() => setToggleCreateModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form>
              <div className="modal-content my-4">

                <div>
                  <LabelXS title="Email Address" required />
                  <input type="text" name='name' required style={{ minWidth: "300px" }} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={selectedUserInfo.email} disabled />
                </div>

                <div className='mt-3'>
                  <LabelXS title="Role" required />
                      <select className='w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' onChange={(e) => setRoleIdForAssign(e.target.value)}>
                          <option value=''>Roles</option>
                          {
                            roles.map((item:any) => <option value={item.id}>{item?.name}</option>)
                          }
                    </select>
                </div>
                
                {
                  roles?.find((item:any)=> item.id === RoleIdForAssign)?.type === "Departmental" && 
                  <div className='mt-3'>
                    <LabelXS title="Department" required />
                    
                    <select className='w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' onChange={(e) => setDeptIdForAssign(e.target.value)}>
                      <option value=''>Department</option>
                      {
                        department.map((item:any) => <option value={item.id}>{item?.name}</option>)
                      }
                    </select>
                    
                  </div>
                }
              </div>


            


              <div className='modal-footer flex justify-end'>
                <button type="button" onClick={updateUserRole} className="text-white w-full rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Assign Role</button>
              </div>

            </form>
          </div>
        </div>
      }

      {
        toggleCreateNewModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Create User Role</h2>
              <button onClick={() => setToggleCreateNewModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form>
              <div className="modal-content my-4  w-[250px]">

                <div>
                  <LabelXS title="Email Address" required />
                  <input type="text" name='name' required style={{ minWidth: "300px" }} className="hidden w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={selectedEmail}  onChange={(e) => setSelectedEmail(e.target.value)} />

                  <Autocomplete
                      className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
                      filterOptions={filterOptions}
                      disableClearable={true}
                      onChange={(v, e: any) => setSelectedEmail(e.value)}
                      id="AlternateEmailAddress"
                      options={tepng_users}
                      value={!!selectedEmail ? selectedEmail : ""}
                      renderInput={(params) => (
                          <TextField
                              placeholder="Select..."
                              variant="standard"
                              {...params}
                          />
                      )}
                      renderOption={(props, option) => (
                          <li
                              {...props}
                              style={{
                                  fontFamily: "Nunito",
                                  fontSize: "14px",
                                  fontWeight: "500",
                              }}
                          >
                              {getOptionLabel(option)}
                          </li>
                      )}
                  />



                </div>

                <div className='mt-3'>
                  <LabelXS title="Role" required />
                      <select className='w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' onChange={(e) => setRoleIdForAssign(e.target.value)}>
                          <option value=''>Roles</option>
                          {
                            roles.map((item:any) => <option value={item.id}>{item?.name}</option>)
                          }
                    </select>
                </div>
                
                {
                  roles?.find((item:any)=> item.id === RoleIdForAssign)?.type === "Departmental" && 
                  <div className='mt-3'>
                    <LabelXS title="Department" required />
                    
                    <select className='w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' onChange={(e) => setDeptIdForAssign(e.target.value)}>
                      <option value=''>Department</option>
                      {
                        department.map((item:any) => <option value={item.id}>{item?.name}</option>)
                      }
                    </select>
                    
                  </div>
                }
              </div>


            


              <div className='modal-footer flex justify-end'>
                <button type="button" onClick={createUserRole} className="text-white w-full rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Create User</button>
              </div>

            </form>
          </div>
        </div>
      }



    </div>
  );
}

export default UserManagement;
