export const formTypesID = [
  {
    "name": "admin-amendment-form",
    "type": 1,
  },
  {
    "name": "cpnc-routing-slip",
    "type": 3,
  },
  {
    "name": "routing-slip-for-tas-contracts",
    "type": 6,
  },
  {
    "name": "contract-approval-form-admin-amendment-for-decentralized-purchasing",
    "type": 8,
  },
  {
    "name": "contract-approval-form",
    "type": 2,
  },
  {
    "name": "routing-slip-for-material-purchase",
    "type": 4,
  },
  {
    "name": "routing-slip-for-deviation-from-standard-template",
    "type": 5,
  },
  // {
  //   "name": "tucn-savings-declaration-sheet",
  //   "type": 7,
  // }
];
