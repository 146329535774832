import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setTepngUser } from '../store/tepngUser';

import { _$http, axiosError, closeAlert, preloader, APP_AUTH_LOGOUT } from '../utils';

const useLogoutTepngUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const logout = () => {
      preloader();  
      _$http('POST','Authentication/Logout', {}, 'json', false).then(res => {
        closeAlert();
        dispatch(setTepngUser(null));

        let url = new URL(window.location.origin + '/login-tepnguser');
        url.searchParams.append('azureauthredirect', '1');

        window.location.replace(APP_AUTH_LOGOUT || '');
 
      })
      .catch(error => {
        const err: AxiosError = error;
        axiosError(err);
      })
    }

    logout();

  }, [dispatch, navigate]);

};

export default useLogoutTepngUser;
