import axios, { AxiosResponse, AxiosError } from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configureStore from '../store';
import { setTepngUser } from '../store/tepngUser';
import {TepngUser} from '../interfaces/users.interface'

axios.defaults.withCredentials = true;
export const MySwal = withReactContent(Swal);
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const APP_AUTH_URL = process.env.REACT_APP_AUTH_URL;
type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
type ResponseType = 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';


// tiny-alert-export
export function tinyAlert(type:string = 'close', message:string = '') {
  toast.dismiss();
  switch (type) {
    case 'info':
      toast.info(message,{
        autoClose: 30000,
      });
      break;
    case 'success':
      toast.success(message,{
        autoClose: 30000,
      });
      break;
    case 'warning':
      toast.warning(message,{
        autoClose: 30000,
      });
      break;
    case 'error':
      toast.error(message,{
        autoClose: 30000,
      });
      break;
    case 'close':
      toast.dismiss();
      break;
    default:
      toast.error(message,{
        autoClose: 30000,
      });
  }
}
export function printError(error: any) {
  let errorMessage = '';

  if (error.response?.status) {
    if (error.response.data) {
      errorMessage += `${error.response.data?.Message}`;
    }
  }
  else {
    errorMessage = error.message;
  }

  return errorMessage;
}
export function getAccessToken() {
  const state = configureStore.getState();  
  if(state.tepngUser.value != null){
    const tepngUser: TepngUser = state.tepngUser.value;
    return tepngUser.accessToken;
  }
  else return null;
}
export async function refreshToken(redirectToLoginIf401: boolean) {
  let success = false;
  let err_message = '';

  await axios({
    method: "post", url: SERVER_URL + "Authentication/RefreshToken", withCredentials: true,
    headers: { "Content-Type": "application/json" }
  })
  .then(res => {
    configureStore.dispatch(setTepngUser(res.data.data));
    success = true;
  })
  .catch(error => {
    const err: AxiosError = error;
    err_message = err.message;
  })

  if (success === false && redirectToLoginIf401) {
    window.alert(err_message);
    window.location.href = ('/logout-tepnguser');
  }

  return success
}
export function preloader() {
  MySwal.fire({
    title: "",
    html: '<div style="font-size:12px"><img style="width: 50px; display:inline" src="'+ process.env.PUBLIC_URL +'/loading-icon-red.gif" /><br> <strong> Please Wait</strong> </div>',
    showConfirmButton: false,
    showCancelButton: false,
    allowOutsideClick: false,
  });
}
export function closeAlert() {
  MySwal.close();
}

// dependencies -> axios
export async function makePOST(endpoint: string, data: any, requireToken: boolean = true) {
  let accessToken = getAccessToken();
  if (requireToken && (accessToken == null || new Date(accessToken.expiration) < new Date())) {
    await refreshToken(true)
    accessToken = getAccessToken();
  }

  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${accessToken?.token}`
  };
  
  preloader();
  return axios.post(SERVER_URL + endpoint, data, {headers})
  .finally(() => closeAlert());
}
export async function makePUT(endpoint: string, data: any, requireToken: boolean = true) {
  let accessToken = getAccessToken();
  if (requireToken && (accessToken == null || new Date(accessToken.expiration) < new Date())) {
    await refreshToken(true)
    accessToken = getAccessToken();
  }

  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${accessToken?.token}`
  };
  
  preloader();
  return axios.put(SERVER_URL + endpoint, data, {headers})
  .finally(() => closeAlert());
}
export async function makePATCH(endpoint: string, data: any, requireToken: boolean = true) {
  let accessToken = getAccessToken();
  if (requireToken && (accessToken == null || new Date(accessToken.expiration) < new Date())) {
    await refreshToken(true)
    accessToken = getAccessToken();
  }

  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${accessToken?.token}`
  };
  
  preloader();
  return axios.patch(SERVER_URL + endpoint, data, {headers})
  .finally(() => closeAlert());
}
export async function makeREQUEST(method: HttpMethod, endpoint: string, data: Record<string, any> = {}, responseType: ResponseType = "json", requireToken: boolean = true): Promise<AxiosResponse> {
  let accessToken = getAccessToken();
  if (requireToken && (accessToken == null || new Date(accessToken.expiration) < new Date())) {
    await refreshToken(true);
    accessToken = getAccessToken();
  }

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken?.token}`
  };
  
  // preloader();
  return axios({
    method: method, 
    url: (SERVER_URL + endpoint), 
    data: data,
    headers: headers,
    responseType: responseType,
  })
  .finally(() => closeAlert());
}












































































/**
 * get user information from store 
 */
export function getUser() {
  const state = configureStore.getState();
  if(state.tepngUser.value != null){
    const tepngUser: TepngUser = state.tepngUser.value;
    return tepngUser.user;
  }
  else return null;
}

/**
 * get access token from store 
 */


/**
 * Axios http methods and response type 
 */














/**
 * function to request for new access token 
 */

/**
 * function to perform AJAX call; 
 */
export async function _$http(method: HttpMethod, endpoint: string, data: Record<string, any> = {}, responseType: ResponseType = "json", requireToken: boolean = true): Promise<AxiosResponse> {
  let accessToken = getAccessToken();
  if (requireToken && (accessToken == null || new Date(accessToken.expiration) < new Date())) {
    console.log('refresh token called');
    await refreshToken(true);
    accessToken = getAccessToken();
  }
  
  return axios({
    method: method, url: (SERVER_URL + endpoint), data: data, withCredentials: true,
    headers: {"Content-Type": "application/json",  Authorization: `Bearer ${accessToken?.token}`,},
    responseType: responseType,
  });
}

/**
 * function to return axios ; 
 */
export async function axioshttp(endpoint: string, data: any, requireToken: boolean = true) {
  let accessToken = getAccessToken();
  if (requireToken && (accessToken == null || new Date(accessToken.expiration) < new Date())) {
    await refreshToken(true)
    accessToken = getAccessToken();
  }

  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${accessToken?.token}`
  };
  
  return axios.post(SERVER_URL + endpoint, data, {headers});
  //responseType: 'json'
}
/**
 * function to return axios ; 
 */
export async function axiosPut(endpoint: string, data: any, requireToken: boolean = true) {
  let accessToken = getAccessToken();
  if (requireToken && (accessToken == null || new Date(accessToken.expiration) < new Date())) {
    await refreshToken(true)
    accessToken = getAccessToken();
  }

  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${accessToken?.token}`
  };
  
  return axios.put(SERVER_URL + endpoint, data, {headers});
  //responseType: 'json'
}















export async function axiosget(endpoint: string, requireToken: boolean = true) {
  let accessToken = getAccessToken();
  if (requireToken && (accessToken == null || new Date(accessToken.expiration) < new Date())) {
    await refreshToken(true)
    accessToken = getAccessToken();
  }

  const headers = {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${accessToken?.token}`
  };
  
  return axios.get(SERVER_URL + endpoint, {headers});
}









// Custom css declarations
export const form_input_style = "border rounded-xs px-3 py-2 w-full font-normal input";












export function getnamefromemail(email:string){
  const atIndex = email.indexOf('@');
  const emailname =  email.slice(0, atIndex);
  return emailname.replace(/\./g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
}

export const formTypesData = [{
  "name": "CPNC Routing Slip",
  "type": 3,
  },
  {
    "name": "Routing Slip for TAS Contracts",
    "type": 6,
  },
  {
    "name": "Contract Approval Form/Admin Amendment for Decentralized Purchasing",
    "type": 8,
  },
  {
    "name": "Contract Approval Form-CAF",
    "type": 2,
  },
  {
    "name": "Routing Slip for Material Purchase",
    "type": 4,
  },
  {
    "name": "Routing Slip for Deviation from Standard Template",
    "type": 5,
  },
  {
    "name": "TUCN Savings Declaration Sheet",
    "type": 7,
  },
  {
    "name": "Admin Amendment Form",
    "type": 1,
  }];

/**
 * function to return axios error; 
 */
export function axiosError(error: any) {
  //AxiosError
  closeAlert();
  let errorMessage = '';

  if (error.response?.status) {
    // Handle errors with (e.g., 404, 500, etc.)
    errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
    
    // Check custom error message
    if (error.response.data) {
      errorMessage += `<br /> (${error.response.data?.Message})`;
    }
  }
  else {
    // Handle other errors (e.g., network issues, request setup errors)
    errorMessage = error.message;
  }

  // Display the error message
  errorAlert(errorMessage);
}









export function getFirstLetters(inputString: string) {
  const words = inputString.split(' ');
  const initials = words.map(word => word.charAt(0));
  return initials.join('');
}
export const formatDateTime = (datetime:Date) => {
  const dateString = datetime;
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString('en-GB', { 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit',
      hour: '2-digit',
      minute:'2-digit',
      hour12: false 
  });
  return formattedDate.replace(/,/g, '');;
}

export function getMonthsFromDates(dateList:string[]){
  const months = [];
  for (const dateString of dateList) {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' }) + ', ' + date.getFullYear();
    months.push(month);
  }
  return months;
}
// Utility helpers == below
export function formatDate(date: string, format?: string) {
  let d = new Date(date);

  if (format === "time") {
    return d.toLocaleTimeString("en-US");
  } else if (format === "date") {
    let y = d.getFullYear();
    let m = d.getMonth();
    let dd = d.getDate();
    let months_of_year = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${months_of_year[m] + " " + dd + ", " + y}`;
  } else {
    return d.toDateString() + " " + d.toLocaleTimeString("en-US");
  }
}
export function twoDecimalPlace(value: number) {
  if (value) return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return "0";
}
export function twoDP(value: number) {
  if (value) return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return "0";
}
export function errorAlert(message: string) {
  MySwal.fire({
    icon: 'error',
    title: '',
    timer: 80000,
    timerProgressBar: true,
    html: `<small>${message}</small>`,
    footer: '<a href="#!" style="font-size:12px">Why do I have this issue?</a>',
    showDenyButton: true,
    denyButtonText: `<small>Ok</small>`,
    showConfirmButton: false,
  })
}
export function successAlert(message: string) {
  MySwal.fire({
    icon: 'success',
    title: 'Success Alert',
    html: `<small>${message}</small>`
  })
}
export function confirmAlert(message: string) {
  return MySwal.fire({
    html: `<small style="font-size:14px">${message}</small>`,
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#0083ff !important',
    cancelButtonColor: 'red',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  })
}





export function formatYYYYMMDD(inputDate:any) {
  if(!!inputDate){
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
  else{
    return `---- -- --`;
  }
}
export function formatDDMMYYYY(inputDate:any) {
  if(!!inputDate){
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    // return `${year}-${month}-${day}`;
    return `${day}-${month}-${year}`;
  }
  else{
    return `---- -- --`;
  }
}

// export const displayExtendLoginSession = () => {
//   MySwal.fire({
//     icon: 'info',
//     timer: 150000,
//     timerProgressBar: true,
//     html: `<span style="font-size:14px"><b>Your current session is inactive</b><br /> Click OK to stay signed in.</span>`,
//     // footer: '<a href="/faq" style="font-size:12px">Why do I have this issue?</a>',
//     showDenyButton: false,
//     denyButtonText: `<span>Yes</span>`,
//     showConfirmButton: true,
//     allowOutsideClick: false,
//   })
//     .then(function (r) {
//       if (!r.isConfirmed)
//         logout();
//     })
// }

// const resetTimer = () => {
//   if (timerRefno != null) clearTimeout(timerRefno)
//   timerRefno = setTimeout(displayExtendLoginSession, (5 * 60 * 1000));
// }

// export function inactivityTime() {
//   document.onmousemove = resetTimer;
//   document.onkeydown = resetTimer;
//   document.onkeyup = resetTimer;
//   document.onclick = resetTimer;
// }

export const truncateText = (text:any, maxLength:any) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

export function convertToThousands (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
  const { name, value } = e.target;
  // const numericInput = value;
  // const inputValue = numericInput.trim();
  const sanitizedValue = value.replace(/,/g, '').replace(/\.+/g, '.');
  const numericValue = parseFloat(sanitizedValue);


  // value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  // Check !input
  // if (!isNaN(numericValue)) {
  //     const formattedValue = (numericValue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  //     setFormData({  ...formData, [name]: formattedValue,});
  // } 
  // else {
  //     setFormData({  ...formData, [name]: '0',});
  // }
  // if (!isNaN(numericValue)) {
  //     setFormData({  ...formData, [name]: numericValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),});
  // }

  if (!isNaN(numericValue)) {
      // Format the number as thousands with two decimal places
      // const formattedValue = numericValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      const decimalPosition = sanitizedValue.indexOf('.');

      // Format the number as thousands with two decimal places
      const formattedValue = numericValue.toLocaleString('en-US', {
        minimumFractionDigits: decimalPosition >= 0 ? 2 : 0,
        maximumFractionDigits: 2,
      });

      // Update the form data with the formatted value
      // setFormData({ ...formData, [name]: formattedValue });
      console.log(formattedValue)
  }
  else {
      // If the input is not a valid number, set the form data with an empty string
      // setFormData({ ...formData, [name]: '' });
      console.log('')
  }
  
  console.log(numericValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), name)

}
