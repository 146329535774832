import React from 'react';
import { useEffect, useState } from 'react';
import { _$http, axiosError, closeAlert, preloader } from '../../utils';
import { ThreeDots, XMark } from '../../components/Icons';
import { Label } from '../../components/FormUI';
import Select from "react-select";
import { ConstantProps, OptionProps } from '../../interfaces/forms.interface';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';

function ApprovalSequences() {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const workflowId = queryParams.get('workflowId');
  const workflowName = queryParams.get('workflowName');

  const [toggleCreateModal, setToggleCreateModal] = useState(false)
  const [toggleEditModal, setToggleEditModal] = useState(false)
  const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false);
  const [roleOptions, setRoleOptions] = useState<OptionProps[]>([{ label: "Select...", value: null, name: "currentrole" }])
  const approvalStatuses: OptionProps[] = ([
    { label: "Draft", value: "1", name: "approvalstatus" },
    { label: "Submitted", value: "2", name: "approvalstatus" },
    { label: "Pending", value: "3", name: "approvalstatus" },
    { label: "Clarify", value: "4", name: "approvalstatus" },
    { label: "Restart", value: "5", name: "approvalstatus" },
    { label: "Approved", value: "6", name: "approvalstatus" },
    { label: "Rejected", value: "7", name: "approvalstatus" },
    { label: "Closed", value: "8", name: "approvalstatus" },
  ])
  const [toggleAddRuleModal, setToggleAddRuleModal] = useState(false)
  const [toggleRemoveRuleModal, setToggleRemoveRuleModal] = useState(false)
  const [rules, setRules] = useState([])
  const [approvalSequenceRules, setApprovalSequenceRules] = useState<OptionProps[]>([])
  const [formData, setFormData] = useState([])
  const [roles, setRoles] = useState<ConstantProps[]>([])
  const [ruleId, setRuleId] = useState(null)
  const [formInputs, setFormInputs] = useState({
    currentRole: { label: "Select...", value: null, name: "currentrole" }, nextRole: { label: "Select...", value: null, name: "currentrole" }, approvalStatus: { label: "Select...", value: null, name: "approvalstatus" }
  })
  const [approvalSequenceId, setApprovalSequenceId] = useState(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getApprovalSequences(); getRoles(); getApprovalSequenceRules(); }, []);

  const getApprovalSequences = () => {
    preloader();
    _$http('GET', 'ApprovalSequences?workflowId=' + workflowId).then(res => {
      closeAlert();
      setFormData(res.data.data);
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const getApprovalSequenceRules = () => {
    preloader();
    _$http('GET', 'ApprovalSequenceRules').then(res => {
      closeAlert();
      setRules(res.data.data.map(
        (i: ConstantProps) => ({
          label: i.name,
          value: i.id,
          name: "rules",
        })
      ));
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleSelect = (fieldName: string, selectedOptions: any) => {
    setFormInputs({ ...formInputs, [fieldName]: selectedOptions });
  };

  // const handleInputChange = (e: any) => {
  //   const { name, value } = e.target
  //   setFormInputs({ ...formInputs, [name]: value })
  // }

  const handleAddRule = (e: any) => {
    e.preventDefault()
    preloader();
    _$http('POST', `ApprovalSequences/AddRulesToApprovalSequence/${approvalSequenceId}`, {
      approvalSequenceId,
      approvalSequenceRuleIds: [
        ruleId
      ]
    }).then(res => {
      closeAlert();
      setToggleAddRuleModal(false)
      getApprovalSequences()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleRemoveRule = (e: any) => {
    e.preventDefault()
    preloader();
    _$http('POST', `ApprovalSequences/RemoveRulesFromApprovalSequence/${approvalSequenceId}`, {
      approvalSequenceId,
      approvalSequenceRuleIds: [
        ruleId
      ]
    }).then(res => {
      closeAlert();
      setToggleRemoveRuleModal(false)
      getApprovalSequences()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleRule = (selectedOptions: any) => {
    setRuleId(selectedOptions?.value)
  };

  const handleAddRuleShow = (item: any) => {
    setApprovalSequenceId(item.id)
    let approvalSequenceRuleIds = item.approvalSequenceRules?.map((a: any) => a.approvalSequenceRule?.id)
    let newRules = rules.filter((a: OptionProps) => !approvalSequenceRuleIds.includes(a.value))
    setApprovalSequenceRules(newRules);
  }

  const handleRemoveRuleShow = (item: any) => {
    setApprovalSequenceId(item.id)
    let approvalSequenceRuleIds = item.approvalSequenceRules?.map((a: any) => a.approvalSequenceRule?.id)
    let newRules = rules.filter((a: OptionProps) => approvalSequenceRuleIds.includes(a.value))
    setApprovalSequenceRules(newRules);
  }

  const handleEditFields = (item: any) => {
    let currentrole = roleOptions.find(a => a.value === item.roleId) ?? { label: "Select...", value: null, name: "currentrole" }
    let nextrole = roleOptions.find(a => a.value === item.nextRoleId) ?? { label: "Select...", value: null, name: "nextrole" }
    let approvalStatus = approvalStatuses.find(a => a.label.toLowerCase() === item.status.toLowerCase()) ?? { label: "Select...", value: null, name: "approvalstatus" }

    setFormInputs({ ...formInputs, currentRole: currentrole, nextRole: nextrole, approvalStatus: approvalStatus })
    setApprovalSequenceId(item.id)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const data = {
      WorkflowId: workflowId,
      CurrentRoleId: formInputs.currentRole?.value,
      NextRoleId: formInputs.nextRole?.value,
      Status: formInputs.approvalStatus?.value
    }
    preloader();
    _$http('POST', 'ApprovalSequences', data).then(res => {
      closeAlert();
      setToggleCreateModal(false)
      getApprovalSequences()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleUpdate = (e: any) => {
    e.preventDefault()
    const data = {
      WorkflowId: workflowId,
      CurrentRoleId: formInputs.currentRole?.value,
      NextRoleId: formInputs.nextRole?.value,
      Status: formInputs.approvalStatus?.value
    }
    preloader();
    _$http('PUT', 'ApprovalSequences/' + approvalSequenceId, data).then(res => {
      closeAlert();
      setToggleEditModal(false)
      setApprovalSequenceId(null)
      getApprovalSequences()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleDelete = () => {
    preloader();
    setToggleConfirmationModal(false)
    _$http('DELETE', 'ApprovalSequences/' + approvalSequenceId).then(res => {
      closeAlert();
      setApprovalSequenceId(null)
      const message = res.data.message
      console.log(message)
      getApprovalSequences()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const getRoles = () => {
    preloader();
    _$http('GET', 'Roles').then(res => {
      closeAlert();
      setRoles(res.data.data);
      setRoleOptions(res.data.data.map(
        (i: ConstantProps) => ({
          label: i.name,
          value: i.id,
          name: "roles",
        })
      ));
    })
      .catch(error => {
        axiosError(error);
      })
  }

  return (
    <div className='bg-white w-full shadow-sm rounded-lg py-6'>
      <div className='flex justify-start mb-1 gap-5'>
        <span onClick={() => navigate(-1)} className="mb-2 flex gap-2 mt-1 cursor-pointer text-red-500">
          <FaArrowLeftLong />
          <span className='text-sm font-bold'>Go Back</span>
        </span>
      </div>
      <div className='flex justify-between px-6'>
        {/* <input type="text" name='search' className="border px-3 py-2 text-sm mr-1" placeholder="Search Approval Sequences" /> */}
        <div>Approval Sequence for {workflowName}</div>
        <button onClick={() => setToggleCreateModal(true)} className='red-button rounded px-4 py-2 text-sm'>Create Approval Sequence</button>
        <Link to={"/approval-sequence-rules"}>View Rules</Link>
      </div>

      <div className="relative overflow-x-auto py-4" style={{ minHeight: "600px" }}>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {/* <th scope="col" className="px-6 py-3">
                SN
              </th> */}
              <th scope="col" className="px-6 py-3">
                Current Level
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Next Level
              </th>
              <th scope="col" className="px-6 py-3">
                Rule
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {
              formData.map((item: any, index: number) => {
                return (
                  <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    {/* <th scope="row" className="px-6 py-4">
                      {index + 1}
                    </th> */}
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.role?.name}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.status}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {roles.find(a => a.id === item.nextRoleId)?.name}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.approvalSequenceRules?.map((a: any) => a.approvalSequenceRule?.name)?.join(", ")}
                    </th>
                    <td className="px-6 py-4">
                      <div className="dropdown">
                        <button title='More Options' className='text-blue-600 dropbtn'>
                          <ThreeDots />
                        </button>
                        <div className="dropdown-content" style={{ left: "0" }}>
                          <button onClick={() => { setToggleEditModal(true); handleEditFields(item) }}>Edit Approval Sequence</button>
                          <button onClick={() => { setToggleConfirmationModal(true); setApprovalSequenceId(item.id) }}>Delete Approval Sequence</button>
                          <button onClick={() => { setToggleAddRuleModal(true); handleAddRuleShow(item) }}>Add Rule</button>
                          <button onClick={() => { setToggleRemoveRuleModal(true); handleRemoveRuleShow(item) }}>Remove Rule</button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      {
        toggleCreateModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Create Approval Sequence</h2>
              <button onClick={() => setToggleCreateModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ApprovalSequence</label>

                <div>
                  <Label title="Current Role" required />
                  <Select
                    // value={formInputs.currentRole}
                    options={roleOptions}
                    isMulti={false}
                    isSearchable={false}
                    onChange={(e) => handleSelect("currentRole", e)}
                    className="font-normal"
                    placeholder="Select..."
                    required
                  />
                </div>

                <div>
                  <Label title="Next Role" required />
                  <Select
                    // value={formInputs.nextRole}
                    options={roleOptions}
                    isMulti={false}
                    isSearchable={false}
                    onChange={(e) => handleSelect("nextRole", e)}
                    className="font-normal"
                    placeholder="Select..."
                    required
                  />
                </div>

                <div>
                  <Label title="Status" required />
                  <Select
                    id="approvalStatus"
                    // value={formInputs.approvalStatus}
                    options={approvalStatuses}
                    isMulti={false}
                    isSearchable={false}
                    onChange={(e) => handleSelect("approvalStatus", e)}
                    className="font-normal"
                    placeholder="Select..."
                    required
                  />
                </div>
              </div>

              <div className='modal-footer flex justify-end'>
                <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Submit</button>
              </div>
            </form>
          </div>
        </div>
      }
      {
        toggleEditModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Edit Approval Sequence</h2>
              <button onClick={() => setToggleEditModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form onSubmit={handleUpdate}>
              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ApprovalSequence</label>

                <div>
                  <Label title="Current Role" required />
                  <Select
                    value={formInputs.currentRole}
                    options={roleOptions}
                    isMulti={false}
                    isSearchable={false}
                    onChange={(e) => handleSelect("currentRole", e)}
                    className="font-normal"
                    placeholder="Select..."
                    required
                  />
                </div>

                <div>
                  <Label title="Next Role" required />
                  <Select
                    value={formInputs.nextRole}
                    options={roleOptions}
                    isMulti={false}
                    isSearchable={false}
                    onChange={(e) => handleSelect("nextRole", e)}
                    className="font-normal"
                    placeholder="Select..."
                    required
                  />
                </div>

                <div>
                  <Label title="Status" required />
                  <Select
                    id="approvalStatus"
                    value={formInputs.approvalStatus}
                    options={approvalStatuses}
                    isMulti={false}
                    isSearchable={false}
                    onChange={(e) => handleSelect("approvalStatus", e)}
                    className="font-normal"
                    placeholder="Select..."
                    required
                  />
                </div>
              </div>

              <div className='modal-footer flex justify-end'>
                <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update</button>
              </div>
            </form>
          </div>
        </div>
      }
      {
        toggleConfirmationModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-md">Are you sure you want to delete this approval sequence? This cannot be reversed and may impact the system performance.</h2>
              <button onClick={() => setToggleConfirmationModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <div className="modal-content text-left px-6 flex justify-center gap-2 mt-4">
              <button onClick={() => setToggleConfirmationModal(false)} type="button" className="rounded text-black bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">Cancel</button>
              <button onClick={() => handleDelete()} type="button" className="rounded text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Yes</button>
            </div>
          </div>
        </div>
      }
      {
        toggleAddRuleModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Add Rule</h2>
              <button onClick={() => setToggleAddRuleModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form onSubmit={handleAddRule}>
              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-whitee">Rule</label>
                <Select
                  // value={selectedStatus}
                  options={approvalSequenceRules}
                  isMulti={false}
                  isSearchable={false}
                  onChange={(e) => handleRule(e)}
                  className="font-normal"
                  placeholder="Select..."
                  required
                />
              </div>

              <div className='modal-footer flex justify-end'>
                <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Add</button>
              </div>
            </form>
          </div>
        </div>
      }
      {
        toggleRemoveRuleModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Remove Rule</h2>
              <button onClick={() => setToggleRemoveRuleModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form onSubmit={handleRemoveRule}>
              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-whitee">Rule</label>
                <Select
                  // value={selectedStatus}
                  options={approvalSequenceRules}
                  isMulti={false}
                  isSearchable={false}
                  onChange={(e) => handleRule(e)}
                  className="font-normal"
                  placeholder="Select..."
                  required
                />
              </div>

              <div className='modal-footer flex justify-end'>
                <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Remove</button>
              </div>
            </form>
          </div>
        </div>
      }
    </div>
  );
}

export default ApprovalSequences;
