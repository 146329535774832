function FormField(props: any) {
    return (
        <div>
            <div className="block text-gray-600 font-medium text-sm flex" dangerouslySetInnerHTML={{ __html: props?.title}} />
            <div className="border rounded-xs px-3 py-2 w-full text-sm bg-gray-100 min-h-[38px]"> {props.value} </div>
        </div>
    )
}

export default FormField;
