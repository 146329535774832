import { formatDate, getnamefromemail } from "../utils";
import { FaPaperclip } from "react-icons/fa6";
import api from "../services/form.services";

function FormComment(props: any) {
    const downloadFile = (formID: string) => {
        api.downloadApprovalCommentFile(formID); 
    };


    // const data = props.data;


    return (
        <div className='pt-5'>
            <label htmlFor="Comments" className="block text-gray-600 font-bold text-md flex">Comments</label>

            <hr />

            <div className="container mt-2">
                {
                    props.data.map((item: any, i: number) => (
                        <div key={i} className={`${props.user.email === item.email ? 'rounded-tr-[0px] rounded-xl ml-auto' : 'rounded-tl-[0px] rounded-xl'} ${!(item?.formApproval?.approvalComments.length) && 'hidden'} `}>

                            <p className='text-xs mb-1 mt-5 text-gray-500'>
                                {item.role.name} - 
                                <span className='font-bold text-gray-600'>
                                    {getnamefromemail(item.email)}
                                </span>
                            </p>

                            {
                                item?.formApproval?.approvalComments.map((comment_item: any, index: number) => (
                                <div key={index} className={`${comment_item.commentByInitiator ? 'bg-gray-100 px-3 py-2 rounded-r-lg rounded-l-sm text-md mb-2 mt-2 border border-l-green-700 border-l-4 w-1/2 ml-4 ' : 'w-3/4 bg-gray-50 px-6 py-3 mt-1 border '}`}>
                                    <div>
                                        <p>{comment_item?.comment}</p>

                                        {
                                            comment_item?.approvalCommentDocuments.length > 0 && 
                                            comment_item?.approvalCommentDocuments.map((item_attachment: any, i: number) => (
                                                <div className="text-blue-500 underline text-[10px] flex cursor-pointer" onClick={() => { downloadFile(item_attachment?.id) }}>
                                                    <FaPaperclip className='text-gray-600 mt-1' />
                                                    {item_attachment?.name}
                                                </div>
                                            ))
                                        }

                                        <p className={`${comment_item.commentByInitiator && 'hidden'}`}>
                                            <span className='text-[10px] text-gray-400'>
                                                {formatDate(comment_item?.createdDate)} 
                                            </span>

                                            <span className='text-[10px] text-gray-600'> - {getnamefromemail(item.email)}</span>
                                        </p>
                                    </div>

                                    <div>
                                        {  
                                            comment_item.commentByInitiator && <span className='text-gray-400' style={{ fontSize: '10px' }}>{formatDate(comment_item?.createdDate)} - {comment_item?.createdBy}</span>
                                        }
                                    </div>
                                    
                                </div>
                                ))
                            }
                        </div>
                    ))
                }
                {
                    !props.data[0]?.formApproval && <p className='p-3 text-sm'> - No comment available</p>
                }
            </div>
        </div>
    )
}

export default FormComment;
