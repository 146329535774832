import { createSlice } from '@reduxjs/toolkit';

export const formDescription = createSlice({
  name: 'formdescription',
  initialState: {
    value: [],
  },
  reducers: {
    setFormDescription: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { setFormDescription } = formDescription.actions;
export default formDescription.reducer;