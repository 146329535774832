export default function FormApprovalOverlay(props:any) {
    const form_approval = props.data;
    return (
        <div className={`pt-5 ${!form_approval.length && "hidden"}`}>
            <hr />
            <label className="block text-gray-600 font-medium text-md flex mt-5 border-l-2 pl-2 border-gray-400 bg-gray-50 p-1">
                Approval of contracts
                <span className="text-xs text-gray-400 ml-2 mt-1">
                    {" "}
                    (see comments below)
                </span>
            </label>
        </div>
    )
}