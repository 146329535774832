import { Routes, Route } from "react-router-dom";
import { ReactProps } from "../../interfaces";

import useProtectedRoute from "../../hooks/UseProtectedRoute";

// protected->utilitiesRoutes
import Dashboard from "../../views/utilities/Dashboard";
import Notifications from "../../views/utilities/Notifications";
import Help from "../../views/utilities/Help";
import Manual from "../../views/utilities/Manual";

//[form *] protected->all forms
import FormHistory from "../../views/forms/FormHistory";

//[form 1] protected->AdminAmendmentForm
import AAFCreate from "../../views/forms/admin-amendment-form/AAF.create";
import AAFDetails from "../../views/forms/admin-amendment-form/AAF.details";

//[form 2] protected->ContractApprovalFormDecentralizedPurchasing
import CAFACreate from "../../views/forms/contract-approval-form-admin-amendment-for-decentralized-purchasing/CAFA.create";
import CAFADetails from "../../views/forms/contract-approval-form-admin-amendment-for-decentralized-purchasing/CAFA.details";

//[form 3] protected->ContractApprovalFormCAF
import CAFCreate from "../../views/forms/contract-approval-form/CAF.create";
import CAFDetails from "../../views/forms/contract-approval-form/CAF.details";

//[form 4] protected->CPNCRoutingSlipForm
import CPNCRSCreate from "../../views/forms/cpnc-routing-slip/CPNCRS.create";
import CPNCRSDetails from "../../views/forms/cpnc-routing-slip/CPNCRS.details";

//[form 5] protected->RoutingSlipForMaterialPurchaseForm
import RSMPCreate from "../../views/forms/routing-slip-for-material-purchase/RSMP.create";
import RSMPDetails from "../../views/forms/routing-slip-for-material-purchase/RSMP.details";

//[form 6] protected->TUCNSavingsDeclarationSheetForm
import DFSTCreate from "../../views/forms/routing-slip-for-deviation-from-standard-template/RST.create";
import DFSTDetails from "../../views/forms/routing-slip-for-deviation-from-standard-template/RST.details";

//[form 7] protected->TUCNSavingsDeclarationSheetForm
import TUCNCreate from "../../views/forms/tucn-savings-declaration-sheet/TUCN.create";
import TUCNDetails from "../../views/forms/tucn-savings-declaration-sheet/TUCN.details";

//[form 8] protected->RoutingSlipForTASContractsForm
import TASCreate from "../../views/forms/routing-slip-for-tas-contracts/TAS.create";
import TASDetails from "../../views/forms/routing-slip-for-tas-contracts/TAS.details";
import NoRouteMatch from "../../views/authentication/NoRouteMatch";

// fn component for handling protected routes
function ProtectedInitiatorRoute({ children, title = "CPNC" }: ReactProps) {
  const getProtectedRoute = useProtectedRoute();
  return getProtectedRoute(children, title);
}

function CPNCRoutes() {
  return (
    <Routes>
      <Route path="*" element={<NoRouteMatch />} />
      <Route
        path="dashboard"
        element={
          <ProtectedInitiatorRoute title="Dashboard">
            <Dashboard />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/history/:formType"
        element={
          <ProtectedInitiatorRoute title="Form History">
            <FormHistory />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="notifications"
        element={
          <ProtectedInitiatorRoute title="Notifications">
            <Notifications />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="help"
        element={
          <ProtectedInitiatorRoute title="FAQ & Help">
            <Help />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="user-manual"
        element={
          <ProtectedInitiatorRoute title="User's Manual">
            <Manual />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/create/admin-amendment-form"
        element={
          <ProtectedInitiatorRoute title="Create Form">
            <AAFCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/edit/admin-amendment-form/:id"
        element={
          <ProtectedInitiatorRoute title="Edit Form">
            <AAFCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/restart/admin-amendment-form/:id"
        element={
          <ProtectedInitiatorRoute title="Restart Form">
            <AAFCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/admin-amendment-form/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <AAFDetails />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/admin-amendment-form/:id/:id"
        element={
          <ProtectedInitiatorRoute title="Approve Form">
            <AAFDetails />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/create/contract-approval-form-admin-amendment-for-decentralized-purchasing"
        element={
          <ProtectedInitiatorRoute title="Create Form">
            <CAFACreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/edit/contract-approval-form-admin-amendment-for-decentralized-purchasing/:id"
        element={
          <ProtectedInitiatorRoute title="Edit Form">
            <CAFACreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/restart/contract-approval-form-admin-amendment-for-decentralized-purchasing/:id"
        element={
          <ProtectedInitiatorRoute title="Restart Form">
            <CAFACreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/contract-approval-form-admin-amendment-for-decentralized-purchasing/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <CAFADetails />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/contract-approval-form-admin-amendment-for-decentralized-purchasing/:id/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <CAFADetails />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/create/contract-approval-form"
        element={
          <ProtectedInitiatorRoute title="Create Form">
            <CAFCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/edit/contract-approval-form/:id"
        element={
          <ProtectedInitiatorRoute title="Edit Form">
            <CAFCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/restart/contract-approval-form/:id"
        element={
          <ProtectedInitiatorRoute title="Restart Form">
            <CAFCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/contract-approval-form/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <CAFDetails />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/contract-approval-form/:id/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <CAFDetails />
          </ProtectedInitiatorRoute>
        }
      />

      {/*[4] protected->cpnc-routing-slip */}
      <Route
        path="forms/create/cpnc-routing-slip"
        element={
          <ProtectedInitiatorRoute title="Create Form">
            <CPNCRSCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/edit/cpnc-routing-slip/:id"
        element={
          <ProtectedInitiatorRoute title="Edit Form">
            <CPNCRSCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/restart/cpnc-routing-slip/:id"
        element={
          <ProtectedInitiatorRoute title="Restart Form">
            <CPNCRSCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/cpnc-routing-slip/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <CPNCRSDetails />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/cpnc-routing-slip/:id/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <CPNCRSDetails />
          </ProtectedInitiatorRoute>
        }
      />

      {/*[5] protected->routing-slip-for-material-purchase */}
      <Route
        path="forms/create/routing-slip-for-material-purchase"
        element={
          <ProtectedInitiatorRoute title="Create Form">
            <RSMPCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/edit/routing-slip-for-material-purchase/:id"
        element={
          <ProtectedInitiatorRoute title="Edit Form">
            <RSMPCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/restart/routing-slip-for-material-purchase/:id"
        element={
          <ProtectedInitiatorRoute title="Restart Form">
            <RSMPCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/routing-slip-for-material-purchase/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <RSMPDetails />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/routing-slip-for-material-purchase/:id/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <RSMPDetails />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/create/tucn-savings-declaration-sheet"
        element={
          <ProtectedInitiatorRoute title="Create Form">
            <TUCNCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/edit/tucn-savings-declaration-sheet/:id"
        element={
          <ProtectedInitiatorRoute title="Edit Form">
            <TUCNCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/restart/tucn-savings-declaration-sheet/:id"
        element={
          <ProtectedInitiatorRoute title="Restart Form">
            <TUCNCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/tucn-savings-declaration-sheet/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <TUCNDetails />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/tucn-savings-declaration-sheet/:id/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <TUCNDetails />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/create/routing-slip-for-deviation-from-standard-template"
        element={
          <ProtectedInitiatorRoute title="Create Form">
            <DFSTCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/edit/routing-slip-for-deviation-from-standard-template/:id"
        element={
          <ProtectedInitiatorRoute title="Edit Form">
            <DFSTCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/restart/routing-slip-for-deviation-from-standard-template/:id"
        element={
          <ProtectedInitiatorRoute title="Restart Form">
            <DFSTCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/routing-slip-for-deviation-from-standard-template/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <DFSTDetails />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/routing-slip-for-deviation-from-standard-template/:id/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <DFSTDetails />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/create/routing-slip-for-tas-contracts"
        element={
          <ProtectedInitiatorRoute title="Create Form">
            <TASCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/edit/routing-slip-for-tas-contracts/:id"
        element={
          <ProtectedInitiatorRoute title="Edit Form">
            <TASCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/restart/routing-slip-for-tas-contracts/:id"
        element={
          <ProtectedInitiatorRoute title="Restart Form">
            <TASCreate />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/routing-slip-for-tas-contracts/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <TASDetails />
          </ProtectedInitiatorRoute>
        }
      />
      <Route
        path="forms/details/routing-slip-for-tas-contracts/:id/:id"
        element={
          <ProtectedInitiatorRoute title="View Form">
            <TASDetails />
          </ProtectedInitiatorRoute>
        }
      />
    </Routes>
  );
}

export default CPNCRoutes;
