import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { TepngUser } from "../interfaces/users.interface";

const useProtectConsentFormRoute = (defaultTitle: string = "Digital CPNC") => {
  const tepngUser: TepngUser = useSelector(
    (state: any) => state.tepngUser.value
  );
  const location = useLocation();

  useEffect(() => {
    if (tepngUser == null) {
      localStorage.setItem(
        "loginRedirectedFromUrl",
        location.pathname + location.search
      );
    }
  }, [tepngUser, location]);

  const getProtectedRoute = (
    children: React.ReactNode,
    title: string = defaultTitle
  ) => {
    if (tepngUser == null) {
      return <Navigate to="/login-tepnguser" replace />;
    } else {
      if (tepngUser.user.consent.consentToeForms) {
        const loginRedirectedFromUrl = localStorage.getItem(
          "loginRedirectedFromUrl"
        );
        localStorage.removeItem("loginRedirectedFromUrl");

        const navigateTo = loginRedirectedFromUrl ?? "/initiator/dashboard";
        return <Navigate to={navigateTo} replace />;
      } else {
        return <section title={title}>{children}</section>;
      }
    }
  };

  return getProtectedRoute;
  
};

export default useProtectConsentFormRoute;
