import { useEffect, useState, useCallback, FormEvent } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { _$http, axiosError, closeAlert, confirmAlert, formatDDMMYYYY, preloader, successAlert, twoDecimalPlace, axioshttp, tinyAlert, form_input_style } from '../../../utils';
import { XMark } from '../../../components/Icons';
import { FaRegTrashCan, FaPenToSquare, FaBell, FaFileContract } from "react-icons/fa6";
import { User } from '../../../interfaces/users.interface';
import { useSelector } from "react-redux";
import { useDropzone } from 'react-dropzone';
import Select from 'react-select';
import ApprovalSequenceFlow from '../../../components/ApprovalSequenceFlow';
import FormCurrentStatus from '../../../components/FormCurrentStatus';
import FormField from '../../../components/FormField';
import FormAttachments from '../../../components/FormAttachments';
import FormComment from '../../../components/FormComment';
import FormApproval from '../../../components/FormApproval';
import { FormIDLabel } from '../../../components/FormUI';

function TUCNDetails() {
    var param = useParams();
    // const location = useLocation();
    const navigate = useNavigate();

    const formIDParam: string = !!param.id ? param.id : '';
    const formApproverId = !!param.formApproverId ? param.formApproverId : '';

    // const queryParams = new URLSearchParams(location.search);
    // const formApproverId = queryParams.get('formApproverId');

    const user: User = useSelector((state: any) => state.tepngUser.value.user);

    // const [comment_err_msg, setCommentErr] = useState('');
    const [TUCNResults, setTUCNResults] = useState<any[]>([]);
    const [OthersText, setOtherstext] = useState('');
    const [clarificationComment, setClarificationComment] = useState('');
    const [togggleModal, setModalTogggle] = useState(false);
    const [CanEditOrDelete, setCanEditOrDelete] = useState(false);
    const [formID, setformID] = useState(''); //setformID
    const [currentStatus, setCurrentStatus] = useState(''); //currentStatus
    const [FormApprovers, setFormApprovers] = useState<any>([]);
    const [FormDocuments, setFormDocuments] = useState<any>([]);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [uploadedFilesAttachType, setUploadedFilesAttachType] = useState<string[]>([]);
    const [formInputs, setFormData] = useState<any>({
        PONumber: "",
        ManagementMode1: "",
        ManagementMode2: "",
        ManagementMode3: "",
        ServiceTypeCode: "",
        NatureOfMaterials: "",
        Supplier: "",
        ContractValue: "",
        OthersDescription: "",
        AlternateEmailAddress: "",
        ContractDurationMonths: 0,
        BriefMaterialDescription: "",
        CostImpactDetailsAndCalculationReferences: "",
        DescriptionOfOpportunityAndSummaryOfTheContext: "",
        ActionsImplementedAndLeverUsed: "",
        ResultsAndAchievement: "",
        MeasurementMethodsApplied: "",
        NatureOfCostAvoidanceOthers: "",
        id: "",
        isDraft: false,
        updatedBy: "",
        updatedDate: "",
        Department: "",
        TGPCategory2: "",
        MaterialType: "",
        ServiceType: "",
        NatureOfCostAvoidance: "",
        RoadMapNo: "",
        StartDate: "",
        EndDate: "",
    });
    const [attachment_type, setAttachmentType] = useState<any>({
        label: 'Select...', value: '', name: 'attachment_type_name',
    });
    const [attachmentList, setAttachmentList] = useState<any[]>([]);


    useEffect(() => { getFormById(formIDParam); }, [formIDParam])
    useEffect(() => { getFormAttachmentTypes(); }, []) //!load if clarification. refacto.


    const getFormById = (formIDParam: string) => {
        preloader();
        _$http('GET', 'TUCNSavingsDeclarationSheetForm/' + formIDParam).then(res => {
            closeAlert();
            const record: any = res.data.data;

            if (record) {
                setCanEditOrDelete(record.canEditOrDelete);
                
                const approval = record.formApprovers.sort((a:any, b:any) => a.step - b.step);
                setFormApprovers(approval);
                
                setformID(record.formID);
                setCurrentStatus(record.currentStatus);
                setFormDocuments(record.documents);

                console.log(record)
                setTUCNResults(record.tucnResults)
                setFormData({
                    PONumber: record.poNumber,
                    ManagementMode1: record.managementMode1,
                    ManagementMode2: record.managementMode2,
                    ManagementMode3: record.managementMode3,
                    ServiceTypeCode: record.serviceTypeCode,
                    NatureOfMaterials: record.natureOfMaterials,
                    Supplier: record.supplier,
                    ContractValue: record.contractValue,
                    OthersDescription: record.othersDescription,
                    AlternateEmailAddress: record.alternateEmailAddress,
                    CreatedBy: record.createdBy,
                    ContractDurationMonths: record.contractDurationMonths,
                    BriefMaterialDescription: record.briefMaterialDescription,
                    CostImpactDetailsAndCalculationReferences: record.costImpactDetailsAndCalculationReferences,
                    DescriptionOfOpportunityAndSummaryOfTheContext: record.descriptionOfOpportunityAndSummaryOfTheContext,
                    ActionsImplementedAndLeverUsed: record.actionsImplementedAndLeverUsed,
                    ResultsAndAchievement: record.resultsAndAchievement,
                    MeasurementMethodsApplied: record.measurementMethodsApplied,
                    NatureOfCostAvoidanceOthers: record.natureOfCostAvoidanceOthers,
                    id: record.id,
                    isDraft: false,
                    Department: record.department,
                    TGPCategory2: record.tgpCategory2Details.name,
                    MaterialType: record.materialType,
                    ServiceType: record.serviceType,
                    NatureOfCostAvoidance: record.natureOfCostAvoidanceDetails.name,
                    RoadMapNo: record.roadMapNoDetails.name,
                    StartDate: record.startDate,
                    EndDate: record.endDate,
                    updatedBy: record.updatedBy,
                    updatedDate: record.updatedDate,
                    referenceFormIds: record.referenceFormIds,
                });
            }

        })
            .catch(error => {
                axiosError(error);
            })
    };

    const deleteForm = () => {
        confirmAlert(`Do you want delete this form?`).then((r: any) => {
            if (r.isConfirmed) {
                preloader();
                _$http('DELETE', 'TUCNSavingsDeclarationSheetForm/' + formIDParam).then(res => {
                    closeAlert();
                    successAlert('Deleted Successfully');
                    navigate(-1);
                })
                    .catch(error => {
                        axiosError(error);
                    })
            }
        });
    }

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const newAttachTypeLabel = attachment_type.label === 'Others'
            ? OthersText : attachment_type.label;
        let uploadType = uploadedFilesAttachType;

        for (let i = 0; i < acceptedFiles.length; i++) {
            uploadType.push(newAttachTypeLabel)
        }

        setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, ...acceptedFiles]);
        setUploadedFilesAttachType(uploadType);

    }, [attachment_type.label, OthersText, uploadedFilesAttachType]);
    const { getRootProps, getInputProps } = useDropzone({
        //accept: ['.png', '.jpg', '.jpeg', '.gif'],//'image/*', // Specify the file types you want to allow
        onDrop,
    });
    const handleModal = () => {
        if (togggleModal) {
            setModalTogggle(false);
        }
        else
            setModalTogggle(true);
    };
    const removeFile = (index: number) => {
        const updatedNames = [...uploadedFilesAttachType];
        updatedNames.splice(index, 1);

        const updatedFiles2 = [...uploadedFiles];
        updatedFiles2.splice(index, 1);

        setUploadedFiles(updatedFiles2);
        setUploadedFilesAttachType(updatedNames);
    };
    const getFormAttachmentTypes = () => {
        preloader();
        _$http('GET', 'FormAttachmentTypes/ByFormType?FormType=7').then(res => {
            closeAlert();
            const list: any[] = res.data.data.map((i: any) => ({ label: i.name, value: i.id, name: 'attachment_type_name' }));
            setAttachmentList(list);
        })
            .catch(error => {
                axiosError(error);
            })
    };
    const handleSubmitClarify = (e: FormEvent) => {
        e.preventDefault();
        // setCommentErr('');
        if (!clarificationComment) {
            tinyAlert('error','Kindly enter your comment!');
            return;
        }
        console.log(!!uploadedFiles.length);

        // if (!uploadedFiles.length) {
        //     setCommentErr('Kindly upload the clarification attachments!');
        //     return;
        // }

        confirmAlert(`Do you want to send clarification response?`).then((r: any) => {
            if (r.isConfirmed) {
                preloader();


                const clarify_approval = FormApprovers.filter((approver: any) => approver.formApproval?.status === 'CLARIFY')[0];
                const formApproverId = clarify_approval.formApproval.formApproverId;
                console.log(formApproverId);

                // !!! use of ANY type 
                let form_data: any = new FormData();

                form_data.append("FormApproverId", formApproverId);
                form_data.append("Comment", clarificationComment);

                let others_description = "Others"
                for (let i = 0; i < uploadedFiles.length; i++) {
                        form_data.append('Others', uploadedFiles[i])
                        others_description = uploadedFilesAttachType[i];
                }
                form_data.append('OthersDescription', others_description);

                axioshttp('TUCNSavingsDeclarationSheetForm/ReplyToClarification', form_data).then(res => {
                    tinyAlert('success', res.data.message);
                    closeAlert();
                    navigate('/initiator/forms/history/tucn-savings-declaration-sheet');
                })
                    .catch(error => {
                        axiosError(error);
                    })
            }
        });
    };

    
    const NotifyApproval = () => {
        preloader();

        let pendingApproverEmail  = '';

        for (const approver of FormApprovers) {
            if (approver.formApproval && approver.formApproval.status === "PENDING") {
                pendingApproverEmail = approver.email;
            }
        }
        
        const data = {
            "approverEmail": pendingApproverEmail,
            "formId": formIDParam
        }

        _$http('POST', 'Notification/Pending/Approvals', data).then(res => {
            tinyAlert('success', 'Notification sent!')
            closeAlert();
        })
        .catch(error => {
            axiosError(error);
        })
    };

    // template->view;
    return (

        // template -> tucn-savings-declaration-sheet -> view-body-content;
        <div className='bg-white w-full shadow-sm rounded-lg py-6'>
            <div className='px-6'>
                {/* form-current-satus-area */}
                <FormCurrentStatus data={currentStatus} formID={formIDParam} />

                {/* approval-sequence-content */}
                <ApprovalSequenceFlow data={FormApprovers} />

                {/* reference-id and action buttons section */}
                <div className='pb-1 pr-2 mt-5 flex justify-between'>
                    <FormIDLabel form_ids={formInputs?.referenceFormIds} url="/initiator/forms/details/tucn-savings-declaration-sheet/" current_id={formID} />

                    {
                        !formApproverId && (user.email === formInputs.AlternateEmailAddress || user.email === formInputs.CreatedBy) && 
                        <div className='flex'>
                            <button onClick={() => { currentStatus === 'RESTART' && navigate(`/initiator/forms/restart/tucn-savings-declaration-sheet/${formIDParam}`) }} className={`flex gap-1 ml-6 ${currentStatus === 'RESTART' ? 'bg-blue-600 px-4 pt-1 text-white rounded-md hover:bg-blue-500 border relative top-[-8px]' : 'text-gray-400 font-bold cursor-not-allowed'}`}>
                                <FaFileContract /> <span>Restart</span>
                            </button>

                            <button className={`flex gap-1 ml-6 ${(currentStatus === 'PENDING') ? 'text-yellow-500' : 'text-gray-400 font-bold cursor-not-allowed'}`} onClick={NotifyApproval}>
                                <FaBell  /> <span>Notify</span>
                            </button>

                            <button onClick={() => { CanEditOrDelete && navigate(`/initiator/forms/edit/tucn-savings-declaration-sheet/${formIDParam}`) }} className={`flex gap-1 ml-6 ${CanEditOrDelete ? 'text-green-600' : 'text-gray-400 font-bold cursor-not-allowed'}`}>
                                <FaPenToSquare /> <span>Edit</span>
                            </button>

                            <button onClick={() => CanEditOrDelete && deleteForm()} className={`flex gap-1 ml-6 ${CanEditOrDelete ? 'text-red-600' : 'text-gray-400 font-bold cursor-not-allowed'}`}>
                                <FaRegTrashCan /> <span>Delete</span>
                            </button>
                        </div>
                    }
                </div>

                <hr />

                {/* display-form-details-section */}
                <div className="max-w-1xl mx-auto rounded space-y-3 mt-3">
                    <FormField title="PO/OA Number" value={formInputs.PONumber} />

                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4'>
                        <FormField title="TGPCategory2" value={formInputs.TGPCategory2} />
                        <FormField title="Department/Entity" value={formInputs.Department?.name} />
                    </div>

                    <div className='grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4'>

                        <FormField
                            title="Management Mode"
                            value={formInputs.ManagementMode1}
                        />
                        <FormField
                            title="_"
                            value={formInputs.ManagementMode2}
                        />
                        <FormField
                            title="_"
                            value={formInputs.ManagementMode3}
                        />
                    </div>

                    <div className='grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4'>

                        <FormField
                            title="Material/Service Type"
                            value={formInputs.MaterialType}
                        />
                        <FormField
                            title="_"
                            value={formInputs.ServiceType}
                        />
                        <FormField
                            title="_"
                            value={formInputs.ServiceTypeCode}
                        />
                    </div>

                    <FormField title="Nature of Materials(Material Savings Only)" value={formInputs.NatureOfMaterials} />
                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4'>
                        <FormField title="Supplier" value={formInputs.Supplier} />
                        <FormField title="Contract/PO Value(USD)" value={twoDecimalPlace(formInputs.ContractValue)} />

                        <FormField title="Start Date" value={formatDDMMYYYY(formInputs.StartDate)} />
                        <FormField title="End Date" value={formatDDMMYYYY(formInputs.EndDate)} />
                    </div>

                    <FormField title="Alternative Email Address" value={formInputs.AlternateEmailAddress} />

                    <FormField title="Contract Duration(Months)" value={`${formInputs.ContractDurationMonths} month(s)`} />
                    <FormField title="Brief Material / Scope of services description" value={formInputs.BriefMaterialDescription} />

                    <FormField title="Cost Impact Details & Calculation References" value={formInputs.CostImpactDetailsAndCalculationReferences} />
                    <FormField title="Description of Opportunity and Summary of the Context" value={formInputs.DescriptionOfOpportunityAndSummaryOfTheContext} />

                    <FormField title="Action(s) Implemented and Lever Used" value={formInputs.ActionsImplementedAndLeverUsed} />
                    <FormField title="Results and Achievement" value={formInputs.ResultsAndAchievement} />

                    <FormField title="Measurement Methods Applied" value={formInputs.MeasurementMethodsApplied} />

                    <FormField title="Nature of Cost Avoidance: (If others, please specify)" value={formInputs.NatureOfCostAvoidance} />
                    {formInputs.NatureOfCostAvoidance.value === 4 ? <FormField title="Specify" value={formInputs.NatureOfCostAvoidanceOthers} /> : ''}



                    { TUCNResults.length ? <div className="col-span-full relative overflow-y-scroll overflow-x-none table-scroll-bar">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Savings Type
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Total Savings (USD)
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        <input
                                            type="text"
                                            value={TUCNResults[0].year1}
                                            className={form_input_style}
                                            readOnly
                                        />
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        <input
                                            type="text"
                                            value={TUCNResults[0].year2}
                                            className={form_input_style}
                                            readOnly
                                        />
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        <input
                                            type="text"
                                            value={TUCNResults[0].year3}
                                            className={form_input_style}
                                            readOnly
                                        />
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    TUCNResults?.map((item: any, index: number) => {
                                        return (
                                            <tr key={index} className="bg-white border-t dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50">
                                                <td className="px-6 py-3">
                                                    {item.savingsType}
                                                </td>
                                                <td className="px-6 py-3">
                                                    <input
                                                        type="text"
                                                        value={item.totalSavings}
                                                        className={form_input_style}
                                                        readOnly
                                                    />
                                                </td>
                                                <td className="px-6 py-3">
                                                    <input
                                                        type="text"
                                                        value={item.year1Value}
                                                        className={form_input_style}
                                                        readOnly
                                                    />
                                                </td>
                                                <td className="px-6 py-3">
                                                    <input
                                                        type="text"
                                                        value={item.year2Value}
                                                        className={form_input_style}
                                                        readOnly
                                                    />
                                                </td>
                                                <td className="px-6 py-3">
                                                    <input
                                                        type="text"
                                                        value={item.year3Value}
                                                        className={form_input_style}
                                                        readOnly
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div> : ''}

                    <FormField title="Road Map No. (If already exists)" value={formInputs.RoadMapNo} />

                    {/* display-attached-files-section */}
                    {/* src={this.state.signingURL} */}
                    <FormAttachments data={FormDocuments} />

                    {/* display-all-comments-section */}
                    <FormComment data={FormApprovers} user={user} />

                    {/* form-approval-action-section */}
                    <FormApproval
                        formApproverId={!!formApproverId && formApproverId}
                        formName="TUCNSavingsDeclarationSheetForm"
                        redirectURL='tucn-savings-declaration-sheet'
                        FormApprovers={FormApprovers}
                    />

                    {/* //!!!clarify-form-section */}
                    {
                        currentStatus.toLocaleLowerCase() === 'clarify' && 
                        (!formApproverId && (user.email === formInputs.AlternateEmailAddress || user.email === formInputs.CreatedBy)) && 
                        
                        <div className='pt-5'>
                            <label htmlFor="Clarify" className="block text-gray-600 font-bold text-md flex">Clarification</label>

                            <hr />

                            {/* Documents uploaded list */}
                            <div className='mt-2'>
                                <button onClick={handleModal} type="button" className="py-2 px-3 text-xs text-gray-400 border border-gray-300 border-solid w-full focus:outline-none font-semibold text-start">
                                    Click to attach file
                                </button>

                                {
                                    uploadedFiles.length > 0 &&
                                    <div className="container mx-auto mt-1">
                                        <div className="overflow-x-auto">
                                            <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
                                                <thead>
                                                    <tr>
                                                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">Sn</th>
                                                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">Attachment Category</th>
                                                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">Attached File</th>
                                                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        uploadedFiles.map((item, i) =>
                                                            <tr key={i} className="transition-all hover:bg-gray-100 border">
                                                                <td className="px-3 py-2  text-xs">{i + 1}</td>
                                                                <td className="px-3 py-2  text-xs">{uploadedFilesAttachType[i]}</td>
                                                                <td className="px-3 py-2  text-xs">{item.name}</td>
                                                                <td className="px-3 py-2  text-xs">
                                                                    <button className='flex' onClick={() => removeFile(i)}>
                                                                        <span style={{ marginTop: '2px' }}><XMark /></span>
                                                                        <span className='ml-1'>Remove</span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                            </div>

                            <textarea
                                id="ClarificationComment"
                                name="ClarificationComment"
                                value={clarificationComment}
                                onChange={(e) => setClarificationComment(e.target.value.trimStart())}
                                className="border rounded-xs px-3 py-2 w-full text-sm mt-2"
                                placeholder="Enter comment"
                                style={{ height: '150px' }}
                            ></textarea>

                            {/* <div className={`text-red-600 font-semibold text-start mb-2 bg-red-100 p-1 px-3 border-l-2 border-l-red-600 rounded-r-sm ${!comment_err_msg && 'hidden'}`}>{comment_err_msg}</div> */}

                            <div className="text-left pb-8 flex gap-4">
                                <button onClick={handleSubmitClarify} type="button" className="px-4 py-2 text-green-600 border border-green-600 rounded w-1/4 hover:bg-green-600 hover:text-white focus:outline-none font-semibold" name='clarifyResponse'>
                                    Send Clarification
                                </button>
                            </div>
                        </div>
                    }

                    {/* attachment-uplooad-modal-section */}
                    {
                        togggleModal &&
                        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
                            <div className="modal-container bg-white w-7/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">
                                <div className='flex justify-between'>
                                    <div className="modal-content py-4 text-left px-6">
                                        <h2 className="text-2xl font-bold">Attach File</h2>
                                        <p className='text-xs'>Kindly upload your clarification document(s)</p>
                                    </div>
                                    <div className="modal-content py-4 text-left px-6">
                                        <button onClick={handleModal} className="p-2" title='Close Modal'><XMark /></button>
                                    </div>
                                </div>

                                <div className="modal-content mb-4 text-left px-6 pb-4 attach-result">
                                    <div className='mb-3'>
                                        <label htmlFor="AlternateEmailAddress" className="block text-gray-700 font-bold">Select Attachment to Upload</label>
                                        <Select
                                            value={attachment_type}
                                            options={attachmentList}
                                            isMulti={false}
                                            isSearchable={false}
                                            onChange={(e: any) => { setAttachmentType(e) }}
                                            className="text-sm"
                                            placeholder="Select..."
                                        />
                                        {
                                            attachment_type.label === 'Others' &&
                                            <input
                                                type="text"
                                                id="Otherstext"
                                                name="Otherstext"
                                                value={OthersText}
                                                onChange={(e: any) => { setOtherstext(e.target.value) }}
                                                className="border rounded-xs px-3 py-2 mt-2 w-full text-xs font-medium"
                                                placeholder="Enter others description"
                                                required
                                            />
                                        }
                                    </div>

                                    <div>
                                        <div {...getRootProps()} className='dropzoneStyle'>
                                            <input {...getInputProps()} />
                                            <p>Drag & drop some files here, or click to select files</p>
                                        </div>
                                        {uploadedFiles.length > 0 && (
                                            <div className="container mx-auto mt-3">
                                                <div className="overflow-x-auto">
                                                    <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
                                                        <thead>
                                                            <tr>
                                                                <th className="px-3 py-2 text-left bg-gray-100 text-xs">Sn</th>
                                                                <th className="px-3 py-2 text-left bg-gray-100 text-xs">Attachment Category</th>
                                                                <th className="px-3 py-2 text-left bg-gray-100 text-xs">Attached File</th>
                                                                <th className="px-3 py-2 text-left bg-gray-100 text-xs">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {uploadedFiles.map((item, i) => (
                                                                <tr key={i} className="transition-all hover:bg-gray-100 border">
                                                                    <td className="px-3 py-2  text-xs">{i + 1}</td>
                                                                    <td className="px-3 py-2  text-xs">{uploadedFilesAttachType[i]}</td>
                                                                    <td className="px-3 py-2  text-xs">{item.name}</td>
                                                                    <td className="px-3 py-2  text-xs">
                                                                        <button className='flex' onClick={() => removeFile(i)}>
                                                                            <span style={{ marginTop: '2px' }}><XMark /></span>
                                                                            <span className='ml-1'>Remove</span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default TUCNDetails;