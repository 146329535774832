import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTepngUser } from "../store/tepngUser";

import { AxiosError } from "axios";
import {
  _$http,
  preloader,
  axiosError,
  closeAlert,
  errorAlert,
  APP_AUTH_URL,
} from "../utils";

const useLoginTepngUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    preloader();
    _$http("POST", "Authentication/Login", {}, "json", false)
      .then((res) => {
        closeAlert();
        dispatch(setTepngUser(res.data.data));
        const loginRedirectedFromUrl = localStorage.getItem(
          "loginRedirectedFromUrl"
        );
        localStorage.removeItem("loginRedirectedFromUrl");
        navigate(loginRedirectedFromUrl ?? "/initiator/dashboard");
      })
      .catch((error) => {
        const err: AxiosError = error;
        const query = new URLSearchParams(window.location.search);

        if (err.response?.status == null) {
          errorAlert(err.message);
          return;
        }

        if (
          query.get("azureauthredirect") !== "1" &&
          (err.response?.status === 401 || err.response?.status === 404)
        ) {
          const login_url = new URL(
            window.location.origin + "/login-tepnguser"
          );
          login_url.searchParams.append("azureauthredirect", "1");
          window.location.replace(
            APP_AUTH_URL + window.encodeURIComponent(login_url.href)
          );
        } else axiosError(error);
      });
  }, [dispatch, navigate]);
};

export default useLoginTepngUser;
