/**
 * path: /forms/create/tucn-savings-declaration-sheet
 * path: /forms/edit/tucn-savings-declaration-sheet
 * note: component create & edit
 * last-refactored: 28/02/2024
 */
import { useState, useEffect, useCallback, FormEvent, useRef } from "react";
import Select from "react-select";
// import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { useDropzone } from "react-dropzone";
import TextField from "@mui/material/TextField";
import { XMark } from "../../../components/Icons";
import api from "../../../services/form.services";
import { formTypesID } from "../../../utils/data";
import { Label } from "../../../components/FormUI";
import FormNotes from "../../../components/FormNotes";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "@mui/material/Autocomplete";
// import CurrencyInput from "react-currency-input-field";
// import { User } from "../../../interfaces/users.interface";
import FormDescription from "../../../components/FormDescription";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ApprovalSequenceFlow from "../../../components/ApprovalSequenceFlow";
import FormApprovalOverlay from "../../../components/FormApprovalOverlay";
import { form_input_style, tinyAlert } from "../../../utils";
import { OptionProps, FormWorkflow, FormApproval, FormDetails, TUCNInputs, TUCNSelects, TUCNResultsProps, } from "../../../interfaces/forms.interface";
import tucn from "../../../services/forms/TUCNSavingsDeclarationSheetForm.services";
// import form_util from "../../../services/FormUtil.services";
import { TUCNData } from '../../../utils/tucn-data';
// import { displayName } from "react-quill";

const filterOptions = createFilterOptions({ limit: 100 });
const getOptionLabel = (option: any) => (<div dangerouslySetInnerHTML={{ __html: !!option.label ? option?.label : '' }} />);

function TUCNSavingsDeclarationSheetForm() {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    // global vars
    // const user: User = useSelector((state: any) => state.tepngUser.value.user);
    const formIDQuery: any = params.id;
    const formName: string = location.pathname.split("/")[4];
    const formType: any = (formTypesID.find((x: any) => x.name === formName))?.type;

    // const default state value;
    const defaultInputs: TUCNInputs = {
        PONumber: "",
        ManagementMode1: "",
        ManagementMode2: "",
        ManagementMode3: "",
        ServiceTypeCode: "",
        NatureOfMaterials: "",
        Supplier: "",
        ContractValue: "",
        OthersDescription: "",
        AlternateEmailAddress: "",
        ContractDurationMonths: 0,
        BriefMaterialDescription: "",
        CostImpactDetailsAndCalculationReferences: "",
        DescriptionOfOpportunityAndSummaryOfTheContext: "",
        ActionsImplementedAndLeverUsed: "",
        ResultsAndAchievement: "",
        MeasurementMethodsApplied: "",
        NatureOfCostAvoidanceOthers: "",
        isDraft: false
    };
    const defaultSelects: TUCNSelects = {
        Department: { label: "Select...", value: null, name: "department" },
        TGPCategory2: { label: "Select...", value: null, name: "TGPCategory2" },
        MaterialType: { label: "Select...", value: null, name: "MaterialType" },
        human_right: { label: "Select...", value: null, name: "human_right" },
        ServiceType: { label: "Select Servive Type...", value: null, name: "ServiceType" },
        NatureOfCostAvoidance: { label: "Select...", value: null, name: "NatureOfCostAvoidance", },
        ResultAchievedYear: { label: `${new Date().getFullYear()}`, value: `${new Date().getFullYear()}`, name: "ResultAchievedYear", },
        RoadMapNo: { label: "Select...", value: null, name: "RoadMapNo", },
        selected_attachment_type: { label: "Select...", value: null, name: "selected_attachment_type", },
        StartDate: null,
        EndDate: null,
    };

    // page data default states.
    const [isChecked, setbuttonChecked] = useState<boolean>(false);
    const [currentStatus, setCurrentStatus] = useState<string>("DRAFT");
    const [parentFormId, setParentFormId] = useState<string>("");
    const [ManagementMode1, setManagementMode1] = useState<string>("");
    const [ManagementMode2, setManagementMode2] = useState<string>("");
    const [ManagementMode3, setManagementMode3] = useState<string>("");
    const [MeasurementMethodsApplied, setMeasurementMethodsApplied] = useState<string[]>([]);
    const [Department, setDepartment] = useState<OptionProps[]>([]);
    const [TGPCategory2, setTGPCategory2] = useState<OptionProps[]>([]);
    const [ServiceType, setServiceType] = useState<OptionProps[]>([]);
    const MaterialType: OptionProps[] = [
        { label: "Material", value: "Material", name: "MaterialType" },
        { label: "Service", value: "Service", name: "MaterialType" },
    ];
    const [NatureOfCostAvoidance, setNatureOfCostAvoidance] = useState<OptionProps[]>([]);
    const [RoadMapNo, setRoadMapNo] = useState<OptionProps[]>([]);
    const [TUCNResults, setTUCNResults] = useState<TUCNResultsProps[]>(TUCNData.TUCNResults);
    const [ResultAchievedYear, setResultAchievedYear] = useState<OptionProps[]>([]);
    const [tepng_users, setTepngUsers] = useState<OptionProps[]>([]);
    const [attachment_type, setAttachmentType] = useState<OptionProps[]>([]);
    const [formDescription, setFormDescription] = useState<FormDetails>();
    const [form_workflow, setFormWorkflow] = useState<FormWorkflow>();
    const [form_approval, setFormApproval] = useState<FormApproval[]>([]);
    const [formDocuments, setFormDocuments] = useState<any>([]);
    const [removedDocumentIds, setRemovedDocumentIds] = useState<any>([]);
    const [formInputs, setFormInputs] = useState<TUCNInputs>(defaultInputs);
    const [formSelects, setFormSelects] = useState<TUCNSelects>(defaultSelects);
    const [togggleModal, setModalTogggle] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [uploadedFilesAttachType, setUploadedFilesAttachType] = useState<string[]>([]);
    const clickedButtonRef = useRef("");

    console.log(form_workflow)
    // form-details
    useEffect(() => { getFormById(formIDQuery, navigate); }, [formIDQuery, navigate]);
    useEffect(() => { api.fetchFormDescription(formName).then((res) => setFormDescription(res)); }, [formName]);

    // form-constants
    useEffect(() => {
        api.fetchTGPCategory2().then((res) => setTGPCategory2(res));
        api.fetchDepartment().then((res) => setDepartment(res));
        api.fetchServiceType().then((res) => setServiceType(res));
        api.fetchNatureOfCostAvoidance().then((res) => setNatureOfCostAvoidance(res));
        api.fetchRoadMapNo().then((res) => setRoadMapNo(res));
        api.fetchTepngUsers().then((res) => setTepngUsers(res));
        api.fetchFormWorkFlow(formType).then((res) => setFormWorkflow(res));
        api.fetchFormAttachmentTypes(formType).then((res) => setAttachmentType(res));
        let resultAchievedYear = []
        let currentYear = new Date().getFullYear()
        for (let i = 0; i < 100; i++) {
            resultAchievedYear.push({ label: `${currentYear + i}`, value: `${currentYear + i}`, name: "ResultAchievedYear" })
        }
        setResultAchievedYear(resultAchievedYear)
    }, [formType]);


    // form-workflow-values
    // useEffect(() => {
    //     const data = {
    //         ContractValue:formInputs.ContractValue,
    //         AmendmentValue:formInputs.AmendmentValue,
    //         currency:formSelects.currency,
    //         department:formSelects.department,
    //         form_workflow:form_workflow
    //     }

    //     form_util.currencyThresholds(data);
    // }, [
    //     formInputs.ContractValue,
    //     formInputs.AmendmentValue,
    //     formSelects.currency,
    //     formSelects.department,
    //     form_workflow,
    // ]);

    // fetch-approval-sequence
    useEffect(() => {
        const data = {
            IsTUCNService: formSelects.MaterialType.value === "Service"
        }

        if (!!formSelects.MaterialType.value) {
            tucn.fetchApprovalSequence(data)
                .then((res) => {
                    if (!!res?.length) {
                        if (form_approval.length !== res.length) setFormApproval(res);
                    }
                });
        }

    }, [
        formSelects.MaterialType,
        form_approval,
    ]);

    useEffect(() => {
        let diffInMonths = Number(new Date(Number(formSelects.EndDate ?? new Date())).getMonth()) - Number(new Date(Number(formSelects.StartDate ?? new Date())).getMonth())
        setFormInputs({ ...formInputs, ContractDurationMonths: diffInMonths });
    }, [
        formSelects.StartDate,
        formSelects.EndDate,
        formInputs
    ]);

    // form-hanlders
    const handleInput = (
        key: string,
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setFormInputs({ ...formInputs, [key]: e.target.value });
    };
    const handleSelect = (fieldName: string, selectedOptions: any) => {
        setFormSelects({ ...formSelects, [fieldName]: selectedOptions });
    };
    const handleServiceType = (fieldName: string, selectedOptions: any) => {
        setFormSelects({ ...formSelects, [fieldName]: selectedOptions });
        console.log(selectedOptions)
        setFormInputs({ ...formInputs, ServiceTypeCode: selectedOptions?.value });
    };
    const handleDate = (date: any, input: string) => {
        setFormSelects({ ...formSelects, [input]: date });
    };
    const handleMeasurementMethodsApplied = (checked: boolean, value: string) => {
        let mma = [...MeasurementMethodsApplied]
        console.log(mma)
        if (checked) {
            mma.push(value)
        } else {
            mma.splice(mma.indexOf(value), 1)
        }
        setMeasurementMethodsApplied(mma);
        setFormInputs({ ...formInputs, MeasurementMethodsApplied: mma.join(", ") });
    };
    const handleManagementMode1 = (input: string) => {
        setManagementMode1(input);
        setFormInputs({ ...formInputs, ManagementMode1: input })

    };
    const handleTUCNResult = (fieldType: string, fieldName: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let tucnResults = [...TUCNResults]
        tucnResults.map((item: any, index: number) => {
            item.year1 = formSelects.ResultAchievedYear.value;
            item.year2 = (Number(formSelects.ResultAchievedYear.value) + 1).toString();
            item.year3 = (Number(formSelects.ResultAchievedYear.value) + 2).toString();

            if (item.savingsType === fieldType) {
                item[fieldName] = Number(e.target.value)
            }

            if (Number(item.totalSavings) < (Number(item.year1Value) + Number(item.year2Value) + Number(item.year3Value))) {
                tinyAlert("error", `Sum total of three years saving cannot be greater than Total Saving Entered. Please enter values less than Total Saving in ${item.savingsType}.`);
            }

            return item
        })
        setTUCNResults(tucnResults);
    };
    const handleManagementMode2 = (input: string) => {
        setManagementMode2(input);
        setFormInputs({ ...formInputs, ManagementMode2: input })

    };
    const handleManagementMode3 = (input: string) => {
        setManagementMode3(input);
        setFormInputs({ ...formInputs, ManagementMode3: input })
    };
    // const handleCurrencyInput = (e: any) => {
    //     const { value, name } = e.target;
    //     let newValue = value.replace(/[^0-9]/g, '');
    //     setFormInputs({ ...formInputs, [name]: newValue });
    // };
    const handleFormApproval = (el: any, option: any, item: any) => {
        const new_form_approvals = form_approval.map((i: any) => {
            i.email = i.roleId === item.roleId ? option?.value : i.email;
            return i;
        });
        setFormApproval(new_form_approvals);
    };
    const handleAlternateEmailAddress = (el: any, option: any) => {
        setFormInputs({ ...formInputs, AlternateEmailAddress: option.value });
    };

    // file-local-upload
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            const newAttachTypeLabel =
                formSelects.selected_attachment_type.label === "Others"
                    ? formInputs.OthersDescription
                    : formSelects.selected_attachment_type.label;

            let uploadType = uploadedFilesAttachType;

            for (let i = 0; i < acceptedFiles.length; i++) {
                uploadType.push(newAttachTypeLabel);
            }

            setUploadedFiles((prevUploadedFiles) => [
                ...prevUploadedFiles,
                ...acceptedFiles,
            ]);
            setUploadedFilesAttachType(uploadType);
        },
        [
            formSelects.selected_attachment_type.label,
            formInputs.OthersDescription,
            uploadedFilesAttachType,
        ]
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    // remove attached file.
    const removeFile = (index: number) => {
        const updatedNames = [...uploadedFilesAttachType];
        updatedNames.splice(index, 1);

        const updated_files = [...uploadedFiles];
        updated_files.splice(index, 1);

        setUploadedFiles(updated_files);
        setUploadedFilesAttachType(updatedNames);
    };

    // remove attached file - when status is update form
    const removePrevFile = (index: number) => {
        const updated_files = [...formDocuments];
        const deletedFile = updated_files.splice(index, 1);
        setFormDocuments(updated_files);

        const removed_files = [...removedDocumentIds];
        removed_files.push(deletedFile[0].id);
        setRemovedDocumentIds(removed_files);

    };

    // component-handlers
    const toggleUploadCardModal = () => {
        if (togggleModal) setModalTogggle(false);
        else setModalTogggle(true);
    };

    // tucn-form-api-services-create-tucn-form
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const data = {
            form_approval: form_approval,
            formInputs: { ...formInputs },
            formSelects: { ...formSelects },
            tucnResults: [...TUCNResults],
            IsDraft: clickedButtonRef?.current === "post" ? false : true,
            uploadedFiles: uploadedFiles,
            uploadedFilesAttachType: uploadedFilesAttachType,
        }

        tucn.setForm(data).then((res: any) => {
            if (!!res?.id) navigate("/initiator/forms/history/tucn-savings-declaration-sheet");
        });
    };

    // tucn-form-api-services-update-tucn-form
    const handleUpdate = (e: FormEvent, action: string) => {
        e.preventDefault();

        const data = {
            form_approval: form_approval,
            formInputs: { ...formInputs },
            formSelects: { ...formSelects },
            tucnResults: [...TUCNResults],
            uploadedFiles: uploadedFiles,
            uploadedFilesAttachType: uploadedFilesAttachType,
            removedDocumentIds: removedDocumentIds,
            formIDQuery: formIDQuery,
            IsDraft: action === "update_and_submit" ? false : true,
        }

        tucn.updateForm(data)
            .then((res) => {
                if (!!res?.id) navigate("/initiator/forms/history/tucn-savings-declaration-sheet");
                // if (action === "update_and_submit") {
                //     tucn.submitDraft(formIDQuery).then((res) => {
                //     });
                // }
            });
    };

    // tucn-form-api-services-review-tucn-form
    const handleReview = (e: FormEvent) => {
        e.preventDefault();

        const data = {
            form_approval: form_approval,
            formInputs: { ...formInputs },
            formSelects: { ...formSelects },
            tucnResults: [...TUCNResults],
            uploadedFiles: uploadedFiles,
            uploadedFilesAttachType: uploadedFilesAttachType,
            formIDQuery: formIDQuery,
            parentFormId: !!parentFormId ? parentFormId : formIDQuery,
        }

        tucn.reviewForm(data)
            .then((res: any) => {
                if (!!res?.id) navigate("/initiator/forms/history/tucn-savings-declaration-sheet");
            });
    };

    // tucn-form-api-services-get-tucn-byid
    const getFormById = (formIDQuery: string, navigate:any) => {
        if (formIDQuery) {
            tucn.fetchFormById(formIDQuery)
                .then((res) => {
                    if (!!res?.id) {
                        setFormInputs({
                            PONumber: res.poNumber,
                            ManagementMode1: res.managementMode1,
                            ManagementMode2: res.managementMode2,
                            ManagementMode3: res.managementMode3,
                            ServiceTypeCode: res.serviceTypeCode,
                            NatureOfMaterials: res.natureOfMaterials,
                            Supplier: res.supplier,
                            ContractValue: res.contractValue,
                            OthersDescription: res.othersDescription,
                            AlternateEmailAddress: res.alternateEmailAddress,
                            ContractDurationMonths: res.contractDurationMonths,
                            BriefMaterialDescription: res.briefMaterialDescription,
                            CostImpactDetailsAndCalculationReferences: res.costImpactDetailsAndCalculationReferences,
                            DescriptionOfOpportunityAndSummaryOfTheContext: res.descriptionOfOpportunityAndSummaryOfTheContext,
                            ActionsImplementedAndLeverUsed: res.actionsImplementedAndLeverUsed,
                            ResultsAndAchievement: res.resultsAndAchievement,
                            MeasurementMethodsApplied: res.measurementMethodsApplied,
                            NatureOfCostAvoidanceOthers: res.natureOfCostAvoidanceOthers,
                            isDraft: res.isDraft,
                        });
                        setFormSelects({
                            TGPCategory2: {
                                label: res.tgpCategory2Details.value,
                                value: res.tgpCategory2Details.id,
                                name: "TGPCategory2",
                            },
                            Department: {
                                label: res.department.name,
                                value: res.department.id,
                                name: "Department",
                            },
                            MaterialType: {
                                label: res.materialType,
                                value: res.materialType,
                                name: "MaterialType",
                            },
                            ServiceType: {
                                label: res.serviceType,
                                value: res.serviceTypeCode,
                                name: "ServiceType",
                            },
                            human_right: {
                                label: res.humanRightsDetails?.name,
                                value: res.humanRightsDetails?.id,
                                name: "human_right",
                            },
                            NatureOfCostAvoidance: {
                                label: res.natureOfCostAvoidanceDetails.name,
                                value: res.natureOfCostAvoidanceDetails.id,
                                name: "NatureOfCostAvoidance",
                            },
                            RoadMapNo: {
                                label: res.roadMapNoDetails.name,
                                value: res.roadMapNoDetails.id,
                                name: "RoadMapNo",
                            },
                            selected_attachment_type: {
                                label: "Select...",
                                value: "",
                                name: "selected_attachment_type",
                            },
                            StartDate: new Date(res.startDate),
                            EndDate: new Date(res.endDate),
                            ResultAchievedYear: {
                                label: res.tucnResults[0]?.year1,
                                value: res.tucnResults[0]?.year1,
                                name: "ResultAchievedYear",
                            },
                        });
                        setTUCNResults(res.tucnResults)
                        setManagementMode1(res.managementMode1);
                        setManagementMode2(res.managementMode2);
                        setManagementMode3(res.managementMode3);
                        setMeasurementMethodsApplied(res.measurementMethodsApplied?.split(", "))


                        const approval_sort = res.formApprovers.sort((a:any, b:any) => a.step - b.step);
                        const approvals: any[] = approval_sort.map((i: any) => ({
                            email: i.email,
                            roleId: i.roleId,
                            roleName: i.role.name,
                        }));
                
                        // let currentStatus = ''
                        // for(let i = 0;  i < approval_sort.length; i++){
                        //     if(approval_sort[i].formApproval){
                        //         currentStatus = approval_sort[i]?.formApproval?.status
                        //     }
                        // }
    
                        // if(currentStatus === 'APPROVED'){
                        //     tinyAlert('error', 'Form cannot be edited because the first level approver has acted on it');
                        //     navigate("/forms/history/cpnc-routing-slip");
                        //     return;
                        // }
    
                        // if(res.currentStatus !== 'RESTART' && res.currentStatus !== 'DRAFT'){
                        //     tinyAlert('error', 'Form cannot be edited because the first level approver has acted on it');
                        //     navigate("/forms/history/cpnc-routing-slip");
                        //     return;
                        // }

                        if(res.canEditOrDelete !== true && res.currentStatus !== "RESTART"){
                            tinyAlert('error', 'Form cannot be edited because the first level approver has acted on it');
                            navigate("/initiator/forms/history/cpnc-routing-slip");
                            return;
                        }
                        
                        
                        setFormApproval(approvals);
                        setFormDocuments(res.documents);
                        setCurrentStatus(res.currentStatus);
                        setParentFormId(res.parentFormId);
                    }
                });
        }
    };


    // ui-component
    return (
        <div className="bg-white w-full rounded-lg mb-12 shadow border border-gray-100">
            <FormDescription data={formDescription} />

            <form onSubmit={handleSubmit} className="max-w-1xl mx-auto rounded space-y-2 px-8 mt-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3">
                    <div className="col-span-full">
                        <Label title="PO/OA Number" for="PONumber" required />
                        <input
                            id="PONumber"
                            type="text"
                            value={formInputs.PONumber}
                            onChange={(e) => handleInput("PONumber", e)}
                            className={form_input_style}
                            placeholder="PO/OA Number"
                            required
                        />
                    </div>

                    <div>
                        <Label title="TGPCategory2" for="TGPCategory2" required />
                        <Select
                            id="currency"
                            value={formSelects.TGPCategory2}
                            options={TGPCategory2}
                            isMulti={false}
                            isSearchable={false}
                            onChange={(e) => handleSelect("TGPCategory2", e)}
                            className="font-normal"
                            placeholder="Select..."
                            required
                        />
                    </div>

                    <div>
                        <Label title="Department/Entity" for="Department" required />
                        <Select
                            id="Department"
                            value={formSelects.Department}
                            options={Department}
                            isMulti={false}
                            isSearchable={true}
                            onChange={(e) => handleSelect("Department", e)}
                            className="font-normal"
                            placeholder="Select..."
                            required
                        />
                    </div>

                    <div className="col-span-full">
                        <Label title="Management Mode" for="managementmode2" required />
                        <div className="flex">
                            <div className="flex pt-3 mr-5 pb-1">
                                <input
                                    type="checkbox"
                                    name="isChecked"
                                    className="managementmode2"
                                    onChange={(e) => handleManagementMode1(e.target.checked ? TUCNData.LOCAL : "")}
                                    checked={ManagementMode1 === TUCNData.LOCAL}
                                    style={{ width: "20px" }}
                                />
                                <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                                    {TUCNData.LOCAL}
                                </label>
                            </div>
                            <div className="flex pt-3 mr-5 pb-1">
                                <input
                                    type="checkbox"
                                    name="isChecked"
                                    className="managementmode2"
                                    onChange={(e) => handleManagementMode1(e.target.checked ? TUCNData.PILOTED : "")}
                                    checked={ManagementMode1 === TUCNData.PILOTED}
                                    style={{ width: "20px" }}
                                />
                                <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                                    {TUCNData.PILOTED}
                                </label>
                            </div>
                            <div className="flex pt-3 pb-1">
                                <input
                                    type="checkbox"
                                    name="isChecked"
                                    className="managementmode2"
                                    onChange={(e) => handleManagementMode1(e.target.checked ? TUCNData.CENTRALIZED : "")}
                                    checked={ManagementMode1 === TUCNData.CENTRALIZED}
                                    style={{ width: "20px" }}
                                />
                                <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                                    {TUCNData.CENTRALIZED}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="flex">
                            <div className="flex pt-3 mr-5 pb-1">
                                <input
                                    type="checkbox"
                                    name="isChecked"
                                    className="managementmode2"
                                    onChange={(e) => handleManagementMode2(e.target.checked ? TUCNData.ONSHORE : "")}
                                    checked={ManagementMode2 === TUCNData.ONSHORE}
                                    style={{ width: "20px" }}
                                />
                                <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                                    {TUCNData.ONSHORE}
                                </label>
                            </div>
                            <div className="flex pt-3 pb-1">
                                <input
                                    type="checkbox"
                                    name="isChecked"
                                    className="managementmode2"
                                    onChange={(e) => handleManagementMode2(e.target.checked ? TUCNData.OFFSHORE : "")}
                                    checked={ManagementMode2 === TUCNData.OFFSHORE}
                                    style={{ width: "20px" }}
                                />
                                <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                                    {TUCNData.OFFSHORE}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="flex">
                            <div className="flex pt-3 mr-5 pb-1">
                                <input
                                    type="checkbox"
                                    name="isChecked"
                                    className="managementmode3"
                                    onChange={(e) => handleManagementMode3(e.target.checked ? TUCNData.OPEX : "")}
                                    checked={ManagementMode3 === TUCNData.OPEX}
                                    style={{ width: "20px" }}
                                />
                                <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                                    {TUCNData.OPEX}
                                </label>
                            </div>
                            <div className="flex pt-3 pb-1">
                                <input
                                    type="checkbox"
                                    name="isChecked"
                                    className="managementmode3"
                                    onChange={(e) => handleManagementMode3(e.target.checked ? TUCNData.CAPEX : "")}
                                    checked={ManagementMode3 === TUCNData.CAPEX}
                                    style={{ width: "20px" }}
                                />
                                <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                                    {TUCNData.CAPEX}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-full">
                        <Label title="Material/Service Type" for="MaterialType" required />
                        <div className="flex">
                            <div className="flex">
                                <Select
                                    id="MaterialType"
                                    value={formSelects.MaterialType}
                                    options={MaterialType}
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={(e) => handleSelect("MaterialType", e)}
                                    className="font-normal"
                                    placeholder="Select..."
                                    required
                                />
                            </div>
                            <div className="flex">
                                <Select
                                    id="ServiceType"
                                    value={formSelects.ServiceType}
                                    options={ServiceType}
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={(e) => handleServiceType("ServiceType", e)}
                                    className="font-normal"
                                    placeholder="Select..."
                                    required
                                />
                            </div>
                            <div className="flex">
                                <input
                                    id="ServiceTypeCode"
                                    type="text"
                                    value={formInputs.ServiceTypeCode}
                                    onChange={(e) => handleInput("ServiceTypeCode", e)}
                                    className={form_input_style}
                                    placeholder="Service Type Code"
                                    required
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-span-full">
                        <Label title="Nature of Materials(Material Savings Only)" for="NatureOfMaterials" required />
                        <input
                            id="NatureOfMaterials"
                            type="text"
                            value={formInputs.NatureOfMaterials}
                            onChange={(e) => handleInput("NatureOfMaterials", e)}
                            className={form_input_style}
                            placeholder="Nature of Materials(Material Savings Only)"
                            required
                        />
                    </div>

                    <div>
                        <Label title="Supplier" for="Supplier" required />
                        <input
                            id="Supplier"
                            type="text"
                            value={formInputs.Supplier}
                            onChange={(e) => handleInput("Supplier", e)}
                            className={form_input_style}
                            placeholder="Supplier"
                            required
                        />
                    </div>

                    <div>
                        <Label title="Contract/PO Value(USD)" for="ContractValue" required />
                        <input
                            id="ContractValue"
                            type="text"
                            value={formInputs.ContractValue}
                            onChange={(e) => handleInput("ContractValue", e)}
                            className={form_input_style}
                            placeholder="Contract/PO Value(USD)"
                            required
                        />
                    </div>

                    <div>
                        <Label title="Start Date" required />
                        <DatePicker
                            id="StartDate"
                            className="border rounded px-3 py-2 w-full font-normal input"
                            selected={formSelects.StartDate}
                            onChange={(date) => handleDate(date, "StartDate")}
                            placeholderText="dd/mm/yyyy"
                            dateFormat="dd/MM/yyyy"
                            required={true}
                        />
                    </div>

                    <div>
                        <Label title="End Date" />
                        <DatePicker
                            id="EndDate"
                            className="border rounded px-3 py-2 w-full font-normal input"
                            selected={formSelects.EndDate}
                            onChange={(date) => handleDate(date, "EndDate")}
                            placeholderText="dd/mm/yyyy"
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>

                    <div className="col-span-full">
                        <Label title="Alternative Email Address" />
                        <Autocomplete
                            className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
                            filterOptions={filterOptions}
                            disableClearable={true}
                            onChange={(e, v) => handleAlternateEmailAddress(e, v)}
                            id="AlternateEmailAddress"
                            options={tepng_users}
                            value={formInputs.AlternateEmailAddress}
                            renderInput={(params) => (
                                <TextField
                                    placeholder="Select..."
                                    variant="standard"
                                    {...params}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li
                                    {...props}
                                    style={{
                                        fontFamily: "Nunito",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                    }}
                                >
                                    {getOptionLabel(option)}
                                </li>
                            )}
                        />
                    </div>

                    <div className="col-span-full">
                        <Label title="Contract Duration(Months)" for="ContractDurationMonths" required />
                        <input
                            id="ContractDurationMonths"
                            type="text"
                            value={`${formInputs.ContractDurationMonths} month(s)`}
                            onChange={(e) => handleInput("ContractDurationMonths", e)}
                            className={form_input_style}
                            placeholder="Contract Duration(Months)"
                            required
                            disabled
                        />
                    </div>

                    <div className="col-span-full">
                        <Label title="Brief Material / Scope of services description" for="BriefMaterialDescription" required />
                        <input
                            id="BriefMaterialDescription"
                            type="text"
                            value={formInputs.BriefMaterialDescription}
                            onChange={(e) => handleInput("BriefMaterialDescription", e)}
                            className={form_input_style}
                            placeholder="Brief Material / Scope of services description"
                            required
                        />
                    </div>

                    <div className="col-span-full">
                        <Label title="Cost Impact Details & Calculation References" for="CostImpactDetailsAndCalculationReferences" required />
                        <input
                            id="CostImpactDetailsAndCalculationReferences"
                            type="text"
                            value={formInputs.CostImpactDetailsAndCalculationReferences}
                            onChange={(e) => handleInput("CostImpactDetailsAndCalculationReferences", e)}
                            className={form_input_style}
                            placeholder="Cost Impact Details & Calculation References"
                            required
                        />
                    </div>

                    <div className="col-span-full">
                        <Label title="Description of Opportunity and Summary of the Context" for="DescriptionOfOpportunityAndSummaryOfTheContext" required />
                        <input
                            id="DescriptionOfOpportunityAndSummaryOfTheContext"
                            type="text"
                            value={formInputs.DescriptionOfOpportunityAndSummaryOfTheContext}
                            onChange={(e) => handleInput("DescriptionOfOpportunityAndSummaryOfTheContext", e)}
                            className={form_input_style}
                            placeholder="Description of Opportunity and Summary of the Context"
                            required
                        />
                    </div>

                    <div className="col-span-full">
                        <Label title="Action(s) Implemented and Lever Used" for="ActionsImplementedAndLeverUsed" required />
                        <input
                            id="ActionsImplementedAndLeverUsed"
                            type="text"
                            value={formInputs.ActionsImplementedAndLeverUsed}
                            onChange={(e) => handleInput("ActionsImplementedAndLeverUsed", e)}
                            className={form_input_style}
                            placeholder="Action(s) Implemented and Lever Used"
                            required
                        />
                    </div>

                    <div className="col-span-full">
                        <Label title="Results and Achievement" for="ResultsAndAchievement" required />
                        <input
                            id="ResultsAndAchievement"
                            type="text"
                            value={formInputs.ResultsAndAchievement}
                            onChange={(e) => handleInput("ResultsAndAchievement", e)}
                            className={form_input_style}
                            placeholder="Results and Achievement"
                            required
                        />
                    </div>

                    <div className="col-span-full">
                        <Label title="Measurement Methods Applied" for="MeasurementMethodsApplied" required />
                        <div className="flex">
                            <div className="flex pt-3 mr-5 pb-1">
                                <input
                                    type="checkbox"
                                    name="isChecked"
                                    checked={MeasurementMethodsApplied.indexOf(TUCNData.REFERENCE) !== -1}
                                    onChange={(e) => handleMeasurementMethodsApplied(e.target.checked, TUCNData.REFERENCE)}
                                    style={{ width: "20px" }}
                                />
                                <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                                    {TUCNData.REFERENCE}
                                </label>
                            </div>
                            <div className="flex pt-3 mr-5 pb-1">
                                <input
                                    type="checkbox"
                                    name="isChecked"
                                    checked={MeasurementMethodsApplied.indexOf(TUCNData.NEGOTIATION) !== -1}
                                    onChange={(e) => handleMeasurementMethodsApplied(e.target.checked, TUCNData.NEGOTIATION)}
                                    style={{ width: "20px" }}
                                />
                                <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                                    {TUCNData.NEGOTIATION}
                                </label>
                            </div>
                            <div className="flex pt-3 pb-1">
                                <input
                                    type="checkbox"
                                    name="isChecked"
                                    checked={MeasurementMethodsApplied.indexOf(TUCNData.COSTAVOIDANCE) !== -1}
                                    onChange={(e) => handleMeasurementMethodsApplied(e.target.checked, TUCNData.COSTAVOIDANCE)}
                                    style={{ width: "20px" }}
                                />
                                <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                                    {TUCNData.COSTAVOIDANCE}
                                </label>
                            </div>
                            <div className="flex pt-3 pb-1">
                                <input
                                    type="checkbox"
                                    name="isChecked"
                                    checked={MeasurementMethodsApplied.indexOf(TUCNData.PRODUCTIONIMPACT) !== -1}
                                    onChange={(e) => handleMeasurementMethodsApplied(e.target.checked, TUCNData.PRODUCTIONIMPACT)}
                                    style={{ width: "20px" }}
                                />
                                <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                                    {TUCNData.PRODUCTIONIMPACT}
                                </label>
                            </div>
                            <div className="flex pt-3 pb-1">
                                <input
                                    type="checkbox"
                                    name="isChecked"
                                    checked={MeasurementMethodsApplied.indexOf(TUCNData.INITIATIVE) !== -1}
                                    onChange={(e) => handleMeasurementMethodsApplied(e.target.checked, TUCNData.INITIATIVE)}
                                    style={{ width: "20px" }}
                                />
                                <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                                    {TUCNData.INITIATIVE}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div>
                        <Label title="Nature of Cost Avoidance: (If others, please specify)" for="NatureOfCostAvoidance" required />
                        <Select
                            id="NatureOfCostAvoidance"
                            value={formSelects.NatureOfCostAvoidance}
                            options={NatureOfCostAvoidance}
                            isMulti={false}
                            isSearchable={true}
                            onChange={(e) => handleSelect("NatureOfCostAvoidance", e)}
                            className="font-normal"
                            placeholder="Select..."
                            isDisabled={MeasurementMethodsApplied.indexOf(TUCNData.COSTAVOIDANCE) !== -1}
                        />
                    </div>

                    <div style={{ display: formSelects.NatureOfCostAvoidance.value === 4 ? "block" : "none" }}>
                        <Label title="Specify" for="NatureOfCostAvoidanceOthers" required />
                        <input
                            id="NatureOfCostAvoidanceOthers"
                            type="text"
                            value={formInputs.NatureOfCostAvoidanceOthers}
                            onChange={(e) => handleInput("NatureOfCostAvoidanceOthers", e)}
                            className={form_input_style}
                            placeholder="Specify"
                            hidden={formSelects.NatureOfCostAvoidance.value !== 4}
                        />
                    </div>

                    <div className="col-span-full relative overflow-y-scroll overflow-x-none table-scroll-bar">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Savings Type
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Total Savings (USD)
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        <Select
                                            id="ResultAchievedYear"
                                            value={formSelects.ResultAchievedYear}
                                            options={ResultAchievedYear}
                                            isMulti={false}
                                            isSearchable={true}
                                            onChange={(e) => handleSelect("ResultAchievedYear", e)}
                                            className="font-normal"
                                            placeholder="Select..."
                                        />
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        <input
                                            type="text"
                                            value={Number(formSelects.ResultAchievedYear.value) + 1}
                                            className={form_input_style}
                                            readOnly
                                        />
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        <input
                                            type="text"
                                            value={Number(formSelects.ResultAchievedYear.value) + 2}
                                            className={form_input_style}
                                            readOnly
                                        />
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    TUCNResults?.map((item: any, index: number) => {
                                        return (
                                            <tr key={index} className="bg-white border-t dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50">
                                                <td className="px-6 py-3">
                                                    {item.savingsType}
                                                </td>
                                                <td className="px-6 py-3">
                                                    <input
                                                        type="text"
                                                        value={item.totalSavings}
                                                        onChange={(e) => handleTUCNResult(item.savingsType, "totalSavings", e)}
                                                        className={form_input_style}
                                                    />
                                                </td>
                                                <td className="px-6 py-3">
                                                    <input
                                                        type="text"
                                                        value={item.year1Value}
                                                        onChange={(e) => handleTUCNResult(item.savingsType, "year1Value", e)}
                                                        className={form_input_style}
                                                    />
                                                </td>
                                                <td className="px-6 py-3">
                                                    <input
                                                        type="text"
                                                        value={item.year2Value}
                                                        onChange={(e) => handleTUCNResult(item.savingsType, "year2Value", e)}
                                                        className={form_input_style}
                                                    />
                                                </td>
                                                <td className="px-6 py-3">
                                                    <input
                                                        type="text"
                                                        value={item.year3Value}
                                                        onChange={(e) => handleTUCNResult(item.savingsType, "year3Value", e)}
                                                        className={form_input_style}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="col-span-full">
                        <Label title="Road Map No. (If already exists)" for="RoadMapNo" required />
                        <Select
                            id="RoadMapNo"
                            value={formSelects.RoadMapNo}
                            options={RoadMapNo}
                            isMulti={false}
                            isSearchable={true}
                            onChange={(e) => handleSelect("RoadMapNo", e)}
                            className="font-normal"
                            placeholder="Select..."
                            required
                        />
                    </div>

                    <div className="col-span-full">
                        <button
                            onClick={toggleUploadCardModal}
                            type="button"
                            className={`bg-gray-50 hover:bg-blue-50 font-[700] border border-gray-300 rounded-xs px-3 py-2 w-full text-sm`}
                        >
                            Upload Attachment
                        </button>
                    </div>
                </div>

                {/* attach-document-area */}
                <div className={`container mx-auto ${(!!formDocuments.length || !!uploadedFiles.length) ? '' : 'hidden'}`}>
                    <div className="overflow-x-auto">
                        <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                        Attachment Category
                                    </th>
                                    <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                        Attached File
                                    </th>
                                    <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {formDocuments.length > 0 && formDocuments.map((item: any, i: any) => (
                                    <tr key={i} className="transition-all hover:bg-gray-100 border">
                                        <td className="px-3 py-2  text-xs">
                                            {item.attachmentType.name}
                                        </td>
                                        <td className="px-3 py-2  text-xs">{item.name}</td>
                                        <td className="px-3 py-2  text-xs">
                                            <button type="button" className="flex" onClick={() => removePrevFile(i)}>
                                                <span style={{ marginTop: "2px" }}>
                                                    <XMark />
                                                </span>
                                                <span className="ml-1">Remove</span>
                                            </button>
                                        </td>
                                    </tr>
                                ))}

                                {uploadedFiles.length > 0 && uploadedFiles.map((item, i) => (
                                    <tr key={i} className="transition-all hover:bg-gray-100 border">
                                        <td className="px-3 py-2  text-xs">
                                            {uploadedFilesAttachType[i]}
                                        </td>
                                        <td className="px-3 py-2  text-xs">{item.name}</td>
                                        <td className="px-3 py-2  text-xs">
                                            <button type="button" className="flex" onClick={() => removeFile(i)}>
                                                <span style={{ marginTop: "2px" }}>
                                                    <XMark />
                                                </span>
                                                <span className="ml-1">Remove</span>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* approval personnel */}
                <FormApprovalOverlay data={form_approval} />

                {
                    !!form_approval.length && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3 pb-3">
                            {form_approval?.map((item: FormApproval, i) => {
                                return (
                                    <div key={i}>
                                        <label className="block text-gray-600 font-medium text-sm flex">
                                            {item.roleName}
                                        </label>
                                        <Autocomplete
                                            className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
                                            filterOptions={filterOptions}
                                            disableClearable={true}
                                            onChange={(e, option) =>
                                                handleFormApproval(e, option, item)
                                            }
                                            id="combo-box-demo"
                                            options={tepng_users}
                                            value={!!item.email ? item.email : ""}
                                            renderInput={(params) => (
                                                <TextField
                                                    placeholder="Select..."
                                                    variant="standard"
                                                    {...params}
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <li
                                                    {...props}
                                                    style={{
                                                        fontFamily: "Nunito",
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    {getOptionLabel(option)}
                                                </li>
                                            )}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )
                }

                {
                    // approval-workflow-diagram
                    !!form_approval.length && (
                        <>
                            <Label title="Approval Sequence" for="ApprovalSequence" />
                            <ApprovalSequenceFlow data={form_approval} />
                        </>
                    )
                }

                <div className="mx-auto w-2/4">
                    {
                        (!formIDQuery || currentStatus === "DRAFT") &&
                        <div className="flex pt-12 pb-3">
                            <input
                                type="checkbox"
                                name="isChecked"
                                id="validationCheck"
                                onChange={(e) => setbuttonChecked(e.target.checked)}
                                style={{ width: "20px" }}
                            />
                            <label className="ml-3 mt-1 text-sm" htmlFor="validationCheck">
                                I validate that this form is ready to be routed{" "}
                            </label>
                        </div>
                    }

                    {!formIDQuery ?
                        <div className="flex gap-4 justify-start">
                            <button
                                type="submit"
                                disabled={!!isChecked}
                                className="px-8 py-3 text-white bg-blue-700 rounded hover:bg-blue-800 text-sm w-full font-bold"
                                name="save"
                                onClick={(e) => (clickedButtonRef.current = "save")}
                            >
                                Save as Draft
                            </button>

                            <button
                                type="submit"
                                disabled={!isChecked}
                                className="px-8 py-3 text-white bg-blue-700 rounded hover:bg-blue-800 text-sm w-full font-bold"
                                name="post"
                                onClick={(e) => (clickedButtonRef.current = "post")}
                            >
                                Submit Form
                            </button>
                        </div>
                        :
                        <div className="flex gap-4 pt-3 justify-start">
                            <button
                                type="button"
                                className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm w-1/4 ${currentStatus !== "RESTART" ? "" : "hidden"}`}
                                name="update"
                                disabled={!!isChecked}
                                onClick={(e) => handleUpdate(e, "update_only")}
                            >
                                Update Form
                            </button>

                            <button
                                type="button"
                                disabled={!isChecked}
                                className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm w-full ${currentStatus === "DRAFT" ? "" : "hidden"}`}
                                name="post"
                                onClick={(e) => handleUpdate(e, "update_and_submit")}
                            >
                                Update & Submit Form
                            </button>

                            <button
                                type="button"
                                disabled={!!isChecked}
                                className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm w-full ${currentStatus === "RESTART" ? "" : "hidden"}`}
                                name="post"
                                onClick={handleReview}
                            >
                                Submit Restart
                            </button>
                        </div>
                    }
                </div>
            </form>

            {/* form-footer-note */}
            <FormNotes data={formDescription} />

            {/* Uplooad modal */}
            {togggleModal && (
                <div
                    className="fixed inset-0 flex items-center justify-center z-50"
                    style={{ background: "rgb(0 0 0 / 52%)" }}
                >
                    <div className="modal-container bg-white w-7/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">
                        <div className="flex justify-between">
                            <div className="modal-content py-4 text-left px-6">
                                <h2 className="text-2xl font-bold">Attach File</h2>
                                <p className="text-xs">Kindly upload your attachment</p>
                            </div>
                            <div className="modal-content py-4 text-left px-6">
                                <button
                                    onClick={toggleUploadCardModal}
                                    className="p-2"
                                    title="Close Modal"
                                >
                                    <XMark />
                                </button>
                            </div>
                        </div>

                        <div className="modal-content mb-4 text-left px-6 pb-4 attach-result">
                            <div className="mb-3">
                                <label className="block text-gray-700 font-bold">
                                    Select Attachment to Upload
                                </label>
                                <Select
                                    value={formSelects.selected_attachment_type}
                                    options={attachment_type}
                                    isMulti={false}
                                    isSearchable={false}
                                    onChange={(e) => handleSelect("selected_attachment_type", e)}
                                    className="text-sm"
                                    placeholder="Select..."
                                />
                                {formSelects.selected_attachment_type.label === "Others" && (
                                    <input
                                        id="OthersDescription"
                                        type="text"
                                        value={formInputs.OthersDescription}
                                        onChange={(e) => handleInput("OthersDescription", e)}
                                        className={form_input_style}
                                        placeholder="Enter description"
                                        required
                                    />
                                )}
                            </div>

                            <div>
                                <div style={{display: !formSelects.selected_attachment_type.value ? "none" : formSelects.selected_attachment_type.label === "Others" && !formInputs.OthersDescription ? "none" : "block"}} {...getRootProps()} className="dropzoneStyle">
                                    <input {...getInputProps()} />
                                    <p>Drag & drop some files here, or click to select files</p>
                                </div>
                                {uploadedFiles.length > 0 && (
                                    <div className="container mx-auto mt-3">
                                        <div className="overflow-x-auto">
                                            <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
                                                <thead>
                                                    <tr>
                                                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                                            Sn
                                                        </th>
                                                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                                            Attachment Category
                                                        </th>
                                                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                                            Attached File
                                                        </th>
                                                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {uploadedFiles.map((item, i) => (
                                                        <tr
                                                            key={i}
                                                            className="transition-all hover:bg-gray-100 border"
                                                        >
                                                            <td className="px-3 py-2  text-xs">{i + 1}</td>
                                                            <td className="px-3 py-2  text-xs">
                                                                {uploadedFilesAttachType[i]}
                                                            </td>
                                                            <td className="px-3 py-2  text-xs">
                                                                {item.name}
                                                            </td>
                                                            <td className="px-3 py-2  text-xs">
                                                                <button
                                                                    className="flex"
                                                                    onClick={() => removeFile(i)}
                                                                >
                                                                    <span style={{ marginTop: "2px" }}>
                                                                        <XMark />
                                                                    </span>
                                                                    <span className="ml-1">Remove</span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TUCNSavingsDeclarationSheetForm;
