import React, { useState } from 'react';
import { ReactProps } from '../../interfaces';
import Header from '../grids/Header';
import AdminSideNav from '../admin/AdminSideNav';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import { TepngUser } from '../../interfaces/users.interface';
import { useSelector } from "react-redux";


const AdminLayout = ({ children, title = 'CPNC' }: ReactProps) => {
    const [closeNav, setCloseNav] = useState(false);
    const closeSubNav = () => setCloseNav(!closeNav);
    
    const location = useLocation();
    const active_path = location.pathname.split('/')[1];
    const adminPath = ['workflows', 'departments', 'users', 'roles-and-permission', 'workflow-thresholds', 'approval-sequence-rules'];
    const tepngUser: TepngUser = useSelector((state: any) => state.tepngUser.value);

    const isAdmin = () =>{
        if(tepngUser.roles.includes('Administrator'))
            return true;
        
        else if(adminPath.includes(active_path) && !tepngUser.roles.includes('Administrator'))
            return false;
        
        else if(!adminPath.includes(active_path) && !tepngUser.roles.includes('Administrator'))
            return true;

        else         
            return false;
    }


    return (
        <div className="flex">

            <AdminSideNav closeNav={closeNav} />

            {/* main content with fixed header */}
            <main className="main-container dark:bg-[#414b5a] h-[100vh]" onClick={closeSubNav}>
                <Header title={ title }/>

                <section className='lg:px-6 lg:pt-12 pb-3 flex justify-between font-medium mt-16 dark:bg-[#414b5a]'>
                    { isAdmin() ? children : <>You are not allowed to use this page</> }
                </section>

                <ToastContainer />
            </main>
        </div>
    )
}

export default AdminLayout
