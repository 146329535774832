export default function FormNotes(props:any) {
    const formDescription = props.data;
    return (
        <div className="bg-gray-50 border-t dark:border-gray-700 mt-20 p-5 rounded-lg font-12 rounded-tl-[0px] rounded-tr-[0] shadow-lg dark:bg-gray-700 dark:text-blue-500">
            <label className="block text-gray-700 font-bold flex">
                <span className='pl-2 text-justify dark:text-gray-300'>Approval of Contracts Comment</span>
            </label>
            <div className='text-justify text-sm form-description mt-2 pr-2 mb-12' dangerouslySetInnerHTML={{ __html: !!formDescription ? formDescription?.note : ''  }} />
        </div>
    )
}