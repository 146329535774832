// seen - but not optimized

import { Link } from "react-router-dom";


const NoRouteMatch = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <div className="max-w-md mx-auto px-4 text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Oops! Page Not Found</h1>
        <p className="text-lg text-gray-600 mb-8">The page you are looking for does not exist.</p>
        
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-full mb-8">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-9-9m0 0l-9 9m9-9V3"/>
        </svg>

        <Link className="bg-blue-700 hover:bg-blue-600 text-white mt-12 font-bold py-3 px-12 rounded focus:outline-none focus:shadow-outline" to='/'>
          Go Back Home
        </Link>
      </div>
    </div>
  );
};

export default NoRouteMatch;