import { _$http } from "../../utils";
function Console() {
  const ContractApprovalFormAdminAmendmentMigration = () => {
    console.log("ContractApprovalFormAdminAmendmentMigration started");
    _$http("POST", "V1Migration/ContractApprovalFormAdminAmendmentMigration")
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ContractApprovalMigration = () => {
    console.log("ContractApprovalMigration started");
    _$http("POST", "V1Migration/ContractApprovalMigration")
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AdminAmendmentMigration = () => {
    console.log("AdminAmendmentMigration started");
    _$http("POST", "V1Migration/AdminAmendmentMigration")
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CPNCRoutingSlipMigration = () => {
    console.log("CPNCRoutingSlipMigration started");
    _$http("POST", "V1Migration/CPNCRoutingSlipMigration")
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const RoutingSlipForDeviationFromStandardTemplate = () => {
    console.log("RoutingSlipForDeviationFromStandardTemplate started");
    _$http("POST", "V1Migration/RoutingSlipForDeviationFromStandardTemplate")
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const RoutingSlipForMaterialPurchase = () => {
    console.log("RoutingSlipForMaterialPurchase started");
    _$http("POST", "V1Migration/RoutingSlipForMaterialPurchase")
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const RoutingSlipForTASContracts = () => {
    console.log("RoutingSlipForTASContracts started");
    _$http("POST", "V1Migration/RoutingSlipForTASContracts")
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const TUCNSavingsDeclarationSheetMigration = () => {
    console.log("TUCNSavingsDeclarationSheetMigration started");
    _$http("POST", "V1Migration/TUCNSavingsDeclarationSheetMigration")
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="w-full flex gap-5">
      <button className="bg-gray p-3" onClick={ContractApprovalFormAdminAmendmentMigration}>ContractApprovalFormAdminAmendmentMigration</button>
      <button className="bg-gray p-3" onClick={ContractApprovalMigration}>ContractApprovalMigration</button>
      <button className="bg-gray p-3" onClick={AdminAmendmentMigration}>AdminAmendmentMigration</button>
      <button className="bg-gray p-3" onClick={CPNCRoutingSlipMigration}>CPNCRoutingSlipMigration</button>
      <button className="bg-gray p-3" onClick={RoutingSlipForDeviationFromStandardTemplate}>RoutingSlipForDeviationFromStandardTemplate</button>
      <button className="bg-gray p-3" onClick={RoutingSlipForMaterialPurchase}>RoutingSlipForMaterialPurchase</button>
      <button className="bg-gray p-3" onClick={RoutingSlipForTASContracts}>RoutingSlipForTASContracts</button>
      <button className="bg-gray p-3" onClick={TUCNSavingsDeclarationSheetMigration}>TUCNSavingsDeclarationSheetMigration</button>
    </div>
  );
}

export default Console;

/**
 * path: /forms/raised forms/:ID
 * refactored: No
 */
