import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminRoutes from "./router/AdminRoutes";
import ApprovalRoutes from "./router/ApprovalRoutes";
import InitiatorRoutes from "./router/InitiatorRoutes";
import AuthRoutes from "./router/AuthRoutes";
import LoginTepngUser from "../views/authentication/LoginTepngUser";

function CPNCRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginTepngUser />} />
        <Route path="/*" element={<AuthRoutes />} />
        <Route path="/admin/*" element={<AdminRoutes />} />
        <Route path="/approval/*" element={<ApprovalRoutes />} />
        <Route path="/initiator/*" element={<InitiatorRoutes />} />
      </Routes>
    </Router>
  );
}

export default CPNCRoutes;
