// import { iteratorSymbol } from 'immer/dist/internal';
import { useEffect, useState} from 'react';
// import { Link } from "react-router-dom";
// import { formatDateTime } from '../../utils';
import { FaAngleLeft, FaAngleRight  } from "react-icons/fa6";
// import { Eye } from '../../components/Icons';
import api from '../../services/form.services';
import DOMPurify from 'dompurify';

function Notifications() {

  const [formRecord, setFormRecord] = useState([]);
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const [OrderBy] = useState(2);
  const [TotalCount, setTotalCount] = useState(0);
  const [PageIndex, setPageIndex] = useState(0);
  const [HasNextPage, setHasNextPage] = useState(false);
  const [HasPreviousPage, setHasPreviousPage] = useState(false);

  useEffect(() => {
    fetchNotification(Page, PageSize, OrderBy);
  }, [Page, PageSize, OrderBy]);

  const fetchNotification = (Page:number, PageSize:number, OrderBy:number) =>{
    api.fetchNotification(Page, PageSize, OrderBy)
    .then(res => {
      const data = res.data;
      setFormRecord(data);
      setTotalCount(res.totalCount);
      setHasNextPage(res.hasNextPage);
      setHasPreviousPage(res.hasPreviousPage);
      setPageIndex(res.pageIndex);

      const unreadIds:any = res.data.map((item:any)=> item.id);

      const Payload:any = {
        "notificationMessageIds": unreadIds,
        "status": true
      }

      api.updateNotification('Notification',Payload)

    });
  }

  const sanitizeHTML = (html: string | undefined) => {
    return { __html: DOMPurify.sanitize(html || '') };
  };

  return (
    <div className='bg-white w-full shadow-sm rounded-lg py-4 dark:bg-gray-800'>
      <div className='flex justify-between mb-3'>
        <div className='flex gap-2 px-4 w-2/5'>
          
        </div>

        <div className='text-xs text-gray-400 pr-8 flex gap-8'>
          <select value={PageSize} onChange={(e) => setPageSize(Number(e.target.value))} id="entries" className='border p-1 text-xs dark:bg-gray-800 dark:text-gray-300'>
            <option value='3'>Show 3 entries</option>
            <option value='10'>Show 10 entries</option>
            <option value='25'>Show 25 entries</option>
            <option value='50'>Show 50 entries</option>
            <option value='100'>Show 100 entries</option>
          </select>

          <span className='mt-3'>
            {formRecord?.length > 0 ? (PageSize * (PageIndex - 1)) + 1 : 0}-{(PageSize * (PageIndex - 1)) + formRecord?.length} of {TotalCount}
          </span>

          <span className='flex gap-5 mt-3'>
            <FaAngleLeft  onClick={() => HasPreviousPage && setPage(PageIndex - 1)}  className={`${HasPreviousPage && 'text-gray-900 hover-arrow-active dark:text-gray-400'}`}  />
            <FaAngleRight onClick={() => HasNextPage && setPage(PageIndex + 1)} className={`${HasNextPage && 'text-gray-900 hover-arrow-active dark:text-gray-400'}`} />
          </span>
        </div>
      </div>

      <div className="relative overflow-x-none">
        <table className="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
          {/* <thead className=" text-[14px] text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 hidden">
                SN
              </th>
              <th scope="col" className="px-6 py-3">
                Form ID
              </th>
              <th scope="col" className="px-6 py-3">
                Contract Title
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Date Initiated
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead> */}
          
          <tbody>
            {
              formRecord?.map((item: any, index: number) => {
                return (
                  <tr key={index} className="bg-white border-t hover:bg-gray-5 text-[13px] dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 px-5">
                    <td className='text-justify text-sm form-description mt-2 pr-2 mb-12 px-5 py-5'>
                      {/* <div  dangerouslySetInnerHTML={{ __html: !!item ? item?.message : ''  }}  /> */}
                      <div dangerouslySetInnerHTML={sanitizeHTML(item?.message)} />
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>

        {
          formRecord?.length < 1 &&
          <p className='text-center p-5 text-xs'>No record available...</p>
        }
      </div>
    </div>
  );
}

export default Notifications;
