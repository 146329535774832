import { Routes, Route } from "react-router-dom";
import { ReactProps } from "../../interfaces";

import useProtectConsentFormRoute from "../../hooks/UseProtectConsentFormRoute";

// authenticationRoutes;
import LoginTepngUser from "../../views/authentication/LoginTepngUser";
import LogoutTepngUser from "../../views/authentication/LogoutTepngUser";
import Policy from "../../views/authentication/Policy";
import NoRouteMatch from "../../views/authentication/NoRouteMatch";

function ProtectConsentFormRoute({ children, title = "CPNC" }: ReactProps) {
  const getProtectedRoute = useProtectConsentFormRoute();
  return getProtectedRoute(children, title);
}

function AuthRoutes() {
  return (
    <Routes>
      <Route path="*" element={<NoRouteMatch />} />
      <Route path="login-tepnguser" element={<LoginTepngUser />} />
      <Route path="logout-tepnguser" element={<LogoutTepngUser />} />
      <Route
        path="personal-data-and-cookies-consent-form"
        element={
          <ProtectConsentFormRoute title="personal data and cookies consent form">
            <Policy />
          </ProtectConsentFormRoute>
        }
      />
    </Routes>
  );
}

export default AuthRoutes;
