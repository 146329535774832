import { useEffect, useState, useCallback, FormEvent } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
  _$http,
  axiosError,
  closeAlert,
  confirmAlert,
  formatDDMMYYYY,
  preloader,
  successAlert,
  axioshttp,
  tinyAlert,
  twoDecimalPlace,
} from "../../../utils";
import { XMark } from "../../../components/Icons";
import {
  FaRegTrashCan,
  FaPenToSquare,
  FaBell,
  FaFileContract,
} from "react-icons/fa6";
import { User } from "../../../interfaces/users.interface";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import ApprovalSequenceFlow from "../../../components/ApprovalSequenceFlow";
import FormCurrentStatus from "../../../components/FormCurrentStatus";
import FormField from "../../../components/FormField";
import FormAttachments from "../../../components/FormAttachments";
import FormComment from "../../../components/FormComment";
import FormApproval from "../../../components/FormApproval";
import { FormIDLabel } from "../../../components/FormUI";

function TASDetails() {
  var param = useParams();
  // const location = useLocation();
  const navigate = useNavigate();

  const formIDParam: string = !!param.id ? param.id : "";
  const formApproverId = !!param.formApproverId ? param.formApproverId : '';

  // const queryParams = new URLSearchParams(location.search);
  // const formApproverId = queryParams.get("formApproverId");

  const user: User = useSelector((state: any) => state.tepngUser.value.user);

  // const [comment_err_msg, setCommentErr] = useState('');
  const [OthersText, setOtherstext] = useState("");
  const [clarificationComment, setClarificationComment] = useState("");
  const [togggleModal, setModalTogggle] = useState(false);
  const [CanEditOrDelete, setCanEditOrDelete] = useState(false);
  const [formID, setformID] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [FormApprovers, setFormApprovers] = useState<any>([]);
  const [FormDocuments, setFormDocuments] = useState<any>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadedFilesAttachType, setUploadedFilesAttachType] = useState<
    string[]
  >([]);
  const [formInputs, setFormData] = useState<any>({
    alternateEmailAddress: "",
    createdBy: "",
    setFormData: "",
    averageCFTRate: "",
    benchmarkRate: "",
    cftEndDate: "",
    cftStartDate: "",
    contractProposal: 0,
    // createdBy  : 0,
    createdDate: "",
    currentStatus: "",
    department: null,
    departmentId: "",
    designeeType: 0,
    documents: [],
    formNumber: "",
    formType: "",
    generatedFormNumber: "",
    isReviewedForm: "",
    noOfRevisions: "",
    numberOfCandidatesParticipatedInOralDiscussion: 0,
    numberOfCandidatesSelectedForOralDiscussion: 0,
    positionRequest: "",
    referenceFormIds: "",
    routingSlipForTasContractsFormTypes: [],
    savingsAmount: 0,
    referenceCost: 0,
    newCost: 0,
    contractJustification: "",
    savingsJustification: "",
    updatedBy: 0,
    updatedDate: "",
    comment: "",
    isHumanRight: "",
    tasContractFormType: "",
  });
  const [attachment_type, setAttachmentType] = useState<any>({
    label: "Select...",
    value: "",
    name: "attachment_type_name",
  });
  const [attachmentList, setAttachmentList] = useState<any[]>([]);

  useEffect(() => {
    getFormById(formIDParam);
  }, [formIDParam]);
  useEffect(() => {
    getFormAttachmentTypes();
  }, []); //!load if clarification. refacto.

  const getFormById = (formIDParam: string) => {
    preloader();
    _$http("GET", "RoutingSlipForTASContractsForm/" + formIDParam)
      .then((res) => {
        closeAlert();
        const record: any = res.data.data;

        if (record) {
          setCanEditOrDelete(record.canEditOrDelete);

          const approval = record.formApprovers.sort(
            (a: any, b: any) => a.step - b.step
          );
          setFormApprovers(approval);

          setformID(record.formID);
          setCurrentStatus(record.currentStatus);
          setFormDocuments(record.documents);

          setFormData({
            alternateEmailAddress: record.alternateEmailAddress,
            createdBy: record.createdBy,

            averageCFTRate: record.averageCFTRate,
            setFormData: record.setFormData,
            benchmarkRate: record.benchmarkRate,
            cftEndDate: record.cftEndDate,
            cftStartDate: record.cftStartDate,
            contractProposal: record.contractProposal,
            // createdBy  : record.createdBy,
            createdDate: record.createdDate,
            currentStatus: record.currentStatus,
            department: record.department,
            departmentId: record.departmentId,
            designeeType: record.designeeType,
            documents: record.documents,
            formNumber: record.formNumber,
            formType: record.formType,
            generatedFormNumber: record.generatedFormNumber,
            isReviewedForm: record.isReviewedForm,
            noOfRevisions: record.noOfRevisions,
            numberOfCandidatesParticipatedInOralDiscussion:
              record.numberOfCandidatesParticipatedInOralDiscussion,
            numberOfCandidatesSelectedForOralDiscussion:
              record.numberOfCandidatesSelectedForOralDiscussion,
            positionRequest: record.positionRequest,
            referenceFormIds: record.referenceFormIds,
            routingSlipForTasContractsFormTypes:
              record.routingSlipForTasContractsFormTypes,
            savingsAmount: record?.genericFormBase?.savingsAmount,
            referenceCost: record.referenceCost,
            newCost: record.newCost,
            contractJustification: record.contractJustification,
            savingsJustification: record.savingsJustification,
            updatedBy: record.updatedBy,
            updatedDate: record.updatedDate,
            comment: record.comment,
            isHumanRight: record?.humanRightsDetails?.name,
            tasContractFormType: record?.tasContractFormType,
          });
        }
      })
      .catch((error) => {
        axiosError(error);
      });
  };

  const deleteForm = () => {
    confirmAlert(`Do you want delete this form?`).then((r: any) => {
      if (r.isConfirmed) {
        preloader();
        _$http("DELETE", "RoutingSlipForTASContractsForm/" + formIDParam)
          .then((res) => {
            closeAlert();
            successAlert("Deleted Successfully");
            navigate(-1);
          })
          .catch((error) => {
            axiosError(error);
          });
      }
    });
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newAttachTypeLabel =
        attachment_type.label === "Others" ? OthersText : attachment_type.label;
      let uploadType = uploadedFilesAttachType;

      for (let i = 0; i < acceptedFiles.length; i++) {
        uploadType.push(newAttachTypeLabel);
      }

      setUploadedFiles((prevUploadedFiles) => [
        ...prevUploadedFiles,
        ...acceptedFiles,
      ]);
      setUploadedFilesAttachType(uploadType);
    },
    [attachment_type.label, OthersText, uploadedFilesAttachType]
  );
  const { getRootProps, getInputProps } = useDropzone({
    //accept: ['.png', '.jpg', '.jpeg', '.gif'],//'image/*', // Specify the file types you want to allow
    onDrop,
  });
  const handleModal = () => {
    if (togggleModal) {
      setModalTogggle(false);
    } else setModalTogggle(true);
  };
  const removeFile = (index: number) => {
    const updatedNames = [...uploadedFilesAttachType];
    updatedNames.splice(index, 1);

    const updatedFiles2 = [...uploadedFiles];
    updatedFiles2.splice(index, 1);

    setUploadedFiles(updatedFiles2);
    setUploadedFilesAttachType(updatedNames);
  };
  const getFormAttachmentTypes = () => {
    preloader();
    _$http("GET", "FormAttachmentTypes/ByFormType?FormType=6")
      .then((res) => {
        closeAlert();
        const list: any[] = res.data.data.map((i: any) => ({
          label: i?.name,
          value: i.id,
          name: "attachment_type_name",
        }));
        setAttachmentList(list);
      })
      .catch((error) => {
        axiosError(error);
      });
  };
  const handleSubmitClarify = (e: FormEvent) => {
    e.preventDefault();

    if (!clarificationComment) {
      tinyAlert("error", "Kindly enter a comment!");
      return;
    }

    confirmAlert(`Do you want to send clarification response?`).then(
      (r: any) => {
        if (r.isConfirmed) {
          preloader();

          const clarify_approval = FormApprovers.filter(
            (approver: any) => approver.formApproval?.status === "CLARIFY"
          )[0];
          const formApproverId = clarify_approval.formApproval.formApproverId;
          console.log(formApproverId);

          // !!! use of ANY type
          let form_data: any = new FormData();

          form_data.append("FormApproverId", formApproverId);
          form_data.append("Comment", clarificationComment);

          let others_description = "Others";
          for (let i = 0; i < uploadedFiles.length; i++) {
            if (uploadedFilesAttachType[i] === "Signed RTA by Duet")
            form_data.append("SignedRtaByDuet", uploadedFiles[i]);
    
            else if (uploadedFilesAttachType[i] === "Draft Contract/PO to be reviewed by Signatories")
              form_data.append(
                "DraftContractPoToBeReviewedBySignatories",
                uploadedFiles[i]
              );

              else if (uploadedFilesAttachType[i] === "TAS Recruitment Request")
            form_data.append("TASRecruitmentRequest", uploadedFiles[i]);
  
            else if (uploadedFilesAttachType[i] === "Technical Recommendation")
            form_data.append("TechnicalRecommendation", uploadedFiles[i]);
  
            else if (uploadedFilesAttachType[i] === "Final Contractor Quotation")
            form_data.append("FinalContractorQuotation", uploadedFiles[i]);
  
            else if (uploadedFilesAttachType[i] === "Mobilization Request Form (New)")
            form_data.append("MobilizationRequestFormNew", uploadedFiles[i]);
  
            else if (uploadedFilesAttachType[i] === "Commitment Data Sheet")
            form_data.append("CommitmentDataSheet", uploadedFiles[i]);
  
            else if (uploadedFilesAttachType[i] === "PO Contract (1 Original)")
            form_data.append("POContract1Original", uploadedFiles[i]);
  
            else {
              form_data.append("Others", uploadedFiles[i]);
              others_description = uploadedFilesAttachType[i];
            }
        }
          form_data.append("OthersDescription", others_description);

          axioshttp(
            "RoutingSlipForTASContractsForm/ReplyToClarification",
            form_data
          )
            .then((res) => {
              tinyAlert("success", res.data.message);
              closeAlert();
              navigate("/initiator/forms/history/routing-slip-for-tas-contracts");
            })
            .catch((error) => {
              axiosError(error);
            });
        }
      }
    );
  };

  const NotifyApproval = () => {
    preloader();

    let pendingApproverEmail = "";

    for (const approver of FormApprovers) {
      if (approver.formApproval && approver.formApproval.status === "PENDING") {
        pendingApproverEmail = approver.email;
      }
    }

    const data = {
      approverEmail: pendingApproverEmail,
      formId: formIDParam,
    };

    _$http("POST", "Notification/Pending/Approvals", data)
      .then((res) => {
        tinyAlert("success", "Notification sent!");
        closeAlert();
      })
      .catch((error) => {
        axiosError(error);
      });
  };
  // template->view;
  return (
    // template -> admin-amendment-form -> view-body-content;
    <div className="bg-white w-full shadow-sm rounded-lg py-6">
      <div className="px-6">
        {/* form-current-satus-area */}
        <FormCurrentStatus data={currentStatus} formID={formIDParam} />

        {/* approval-sequence-content */}
        <ApprovalSequenceFlow data={FormApprovers} />

        {/* reference-id and action buttons section */}
        <div className="pb-1 pr-2 mt-5 flex justify-between">
          <FormIDLabel
            form_ids={formInputs?.referenceFormIds}
            url="/initiator/forms/details/routing-slip-for-tas-contracts/"
            current_id={formID}
          />


          {!formApproverId &&
            (user.email === formInputs.alternateEmailAddress ||
              user.email === formInputs.createdBy) && (
              <div className="flex">
                <button
                  onClick={() => {
                    currentStatus === "RESTART" &&
                      navigate(
                        `/initiator/forms/restart/routing-slip-for-tas-contracts/${formIDParam}`
                      );
                  }}
                  className={`flex gap-1 ml-6 ${
                    currentStatus === "RESTART"
                      ? "bg-blue-600 px-4 pt-1 text-white rounded-md hover:bg-blue-500 border relative top-[-8px]"
                      : "text-gray-400 font-bold cursor-not-allowed"
                  }`}
                >
                  <FaFileContract /> <span>Restart</span>
                </button>

                <button
                  className={`flex gap-1 ml-6 ${
                    currentStatus === "PENDING"
                      ? "text-yellow-500"
                      : "text-gray-400 font-bold cursor-not-allowed"
                  }`}
                  onClick={NotifyApproval}
                >
                  <FaBell /> <span>Notify</span>
                </button>

                <button
                  onClick={() => {
                    CanEditOrDelete &&
                      navigate(
                        `/initiator/forms/edit/routing-slip-for-tas-contracts/${formIDParam}`
                      );
                  }}
                  className={`flex gap-1 ml-6 ${
                    CanEditOrDelete
                      ? "text-green-600"
                      : "text-gray-400 font-bold cursor-not-allowed"
                  }`}
                >
                  <FaPenToSquare /> <span>Edit</span>
                </button>

                <button
                  onClick={() => CanEditOrDelete && deleteForm()}
                  className={`flex gap-1 ml-6 ${
                    CanEditOrDelete
                      ? "text-red-600"
                      : "text-gray-400 font-bold cursor-not-allowed"
                  }`}
                >
                  <FaRegTrashCan /> <span>Delete</span>
                </button>
              </div>
            )}
        </div>

        <hr />

        {/* display-form-details-section */}
        <div className="max-w-1xl mx-auto rounded space-y-3 mt-3">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mb-12">
            <FormField title="Designee Type" value={formInputs.designeeType} />
            <FormField
              title="Position Request"
              value={formInputs.positionRequest}
            />

            <div>
              <FormField
                title="Contractor Proposal"
                value={formInputs.contractProposal}
              />
            </div>

            <FormField
              title="CFT Start Date"
              value={formatDDMMYYYY(formInputs.cftStartDate)}
            />
            <FormField
              title="CFT End Date"
              value={formatDDMMYYYY(formInputs.cftEndDate)}
            />

            <FormField
              title="Internal Reference / Benchmark rates"
              value={formInputs.benchmarkRate}
            />
            <FormField
              title="Average CFT rate"
              value={formInputs.averageCFTRate}
            />

            <FormField
              title="Number of Candidates Selected for Oral Discusssion"
              value={formInputs.numberOfCandidatesSelectedForOralDiscussion}
            />

            <div className="col-span-full ">
            <FormField
              title="Number of Candidates that participate in Oral Discusssion"
              value={formInputs.numberOfCandidatesParticipatedInOralDiscussion}
            />
            </div>

            {/* <FormField title="Human Rights" value={formInputs.isHumanRight} />
            <FormField
              title="Alternative Email Address"
              value={formInputs.alternateEmailAddress}
            /> */}

            {/* <FormField
              title="New Cost"
              value={twoDecimalPlace(formInputs.newCost)}
            /> */}
            {/* <FormField
              title="Reference Cost"
              value={twoDecimalPlace(formInputs.referenceCost)}
            /> */}
            {/* <FormField
              title="Savings Amount"
              value={twoDecimalPlace(
                Number(formInputs.newCost) - Number(formInputs.referenceCost)
              )}
            /> */}

            <div className="col-span-full grid  gap-4">
              <FormField
                title="Contract Justification"
                value={formInputs.contractJustification}
              />
              {/* <FormField
                title="Savings Justification / Calculation Method"
                value={formInputs.savingsJustification}
              /> */}
              {/* <FormField title="Comment" value={formInputs.comment} /> */}
              <FormField title="TAS Contract Form Type" value={formInputs.tasContractFormType} />
            </div>

            {/* <FormField title="Alternative Email Address" value={formInputs.alternateEmailAddress} /> */}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3 bg-gray-100 dark:bg-gray-700 shadow px-2 py-3 dark:text-gray-300 font-semibold">
            <h1>
              ({formInputs?.routingSlipForTasContractsFormTypes.length}) Form
              Delegates
            </h1>
          </div>

          {formInputs?.routingSlipForTasContractsFormTypes.map(
            (item: any, i: any) => (
              <div className="pb-3" key={i}>
                <p className="text-xs font-bold w-full">
                  Delegate Form {i + 1}
                </p>
                <table className="w-full border">
                  <tbody className="text-[10px]">
                    <tr className="text-[10px] text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                      <td className="px-1 pt-2 font-bold"> PO/OA Number </td>
                      <td className="px-1 pt-2 font-bold"> PO Value </td>
                      <td className="px-1 pt-2 font-bold"> Contractor </td>
                      <td className="px-1 pt-2 font-bold"> Currency </td>
                      <td className="px-1 pt-2 font-bold"> Amendment No </td>
                      <td className="px-1 pt-2 font-bold"> DDQ Status </td>
                      <td className="px-1 pt-2 font-bold"> Position Title </td>
                      <td className="px-1 pt-2 font-bold"> Position Number </td>
                    </tr>
                    <tr className="bg-white border-t hover:bg-gray-5 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                      <td className="px-1 py-1"> {item.pooaNumber} </td>
                      <td className="px-1 py-1"> {item.poValue} </td>
                      <td className="px-1 py-1"> {item.contractor} </td>
                      <td className="px-1 py-1"> {item.currency} </td>
                      <td className="px-1 py-1"> {item.amendmentNo} </td>
                      <td className="px-1 py-1"> {item.ddqStatus} </td>
                      <td className="px-1 py-1"> {item.positionTitle} </td>
                      <td className="px-1 py-1"> {item.positionNumber} </td>
                    </tr>

                    <tr className="text-[10px] text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                      <td className="px-1 pt-2 font-bold"> PR Number </td>
                      <td className="px-1 pt-2 font-bold">
                        Commitment Start Date
                      </td>
                      <td className="px-1 pt-2 font-bold">
                        Commitment End Date
                      </td>
                      <td className="px-1 pt-2 font-bold">
                        Mobilization Date
                      </td>
                      {/* <td className="px-1 pt-2 font-bold"> New PO Value </td> */}
                      <td className="px-1 pt-2 font-bold"> New Rate </td>
                      <td className="px-1 pt-2 font-bold"> Old PO Value </td>
                      <td className="px-1 pt-2 font-bold"> Old Rate </td>
                      <td className="px-1 pt-2 font-bold"> Human Right </td>
                    </tr>
                    <tr className="bg-white border-t hover:bg-gray-5 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                      <td className="px-1 py-1"> {item.prNumber} </td>
                      <td className="px-1 py-1">
                        {" "}
                        {formatDDMMYYYY(item.commitmentStartDate)}{" "}
                      </td>
                      <td className="px-1 py-1">
                        {" "}
                        {formatDDMMYYYY(item.commitmentEndDate)}{" "}
                      </td>
                      <td className="px-1 py-1">
                        {" "}
                        {formatDDMMYYYY(item.mobilizationDate)}{" "}
                      </td>
                      {/* <td className="px-1 py-1"> {item.newPOValue} </td> */}
                      <td className="px-1 py-1"> {twoDecimalPlace(item.newRate)} </td>
                      <td className="px-1 py-1"> {twoDecimalPlace(item.oldPOValue)} </td>
                      <td className="px-1 py-1"> {twoDecimalPlace(item.oldRate)} </td>
                      <td className="px-1 py-1"> {item.humanRightsDetails?.name} </td>
                    </tr>

                    <tr className="text-[10px] text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                      <td className="px-1 pt-2 font-bold"> New Cost </td>
                      <td className="px-1 pt-2 font-bold">
                        Reference Cost
                      </td>
                      <td className="px-1 pt-2 font-bold">
                        Savings Amount
                      </td>
                      <td className="px-1 pt-2 font-bold" colSpan={5}>
                        Savings Justification
                      </td>
                    </tr>
                    <tr className="bg-white border-t hover:bg-gray-5 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                      <td className="px-1 py-1"> {twoDecimalPlace(item.newCost)} </td>
                      <td className="px-1 py-1">
                        {twoDecimalPlace(item.referenceCost)}
                      </td>
                      <td className="px-1 py-1">
                        {twoDecimalPlace(item.savingsAmount)}
                      </td>
                      <td className="px-1 py-1" colSpan={5}> {item.savingsJustification} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          )}

          {formInputs?.routingSlipForTasContractsFormTypes.map(
            (item: any, i: any) => (
              <div
                className="grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-4 gap-1 hidden"
                key={i}
              >
                <FormField title="PO/OA Number" value={item.pooaNumber} />
                <FormField title="PO Value" value={item.poValue} />
                <FormField title="Contractor" value={item.contractor} />
                <FormField title="Currency" value={item.currency} />
                <FormField title="Amendment No" value={item.amendmentNo} />

                <FormField title="DDQ Status" value={item.ddqStatus} />
                <FormField title="Position Title" value={item.positionTitle} />
                <FormField
                  title="Position Number"
                  value={item.positionNumber}
                />
                <FormField title="PR Number" value={item.prNumber} />
                <FormField
                  title="Commitment Start Date"
                  value={formatDDMMYYYY(item.commitmentStartDate)}
                />

                <FormField
                  title="Commitment End Date"
                  value={formatDDMMYYYY(item.commitmentEndDate)}
                />
                <FormField
                  title="Mobilization Date"
                  value={formatDDMMYYYY(item.mobilizationDate)}
                />
                <FormField title="New PO Value" value={item.newPOValue} />
                <FormField title="New Rate" value={item.newRate} />
                <FormField title="Old PO Value" value={item.oldPOValue} />

                <FormField title="Old Rate" value={item.oldRate} />

                <div className="col-span-full">
                  <hr />
                </div>
              </div>
            )
          )}

          {/* display-attached-files-section */}
          {/* src={this.state.signingURL} */}
          <FormAttachments data={FormDocuments} />

          {/* display-all-comments-section */}
          <FormComment data={FormApprovers} user={user} />

          {/* form-approval-action-section */}
          <FormApproval
            formApproverId={!!formApproverId && formApproverId}
            formName="RoutingSlipForTASContractsForm"
            redirectURL="routing-slip-for-tas-contracts"
            FormApprovers={FormApprovers}
          />

          {/* //!!!clarify-form-section */}
          {currentStatus.toLocaleLowerCase() === "clarify" &&
            !formApproverId &&
            (user.email === formInputs.alternateEmailAddress ||
              user.email === formInputs.createdBy) && (
              <div className="pt-5">
                <label
                  htmlFor="Clarify"
                  className="block text-gray-600 font-bold text-md flex"
                >
                  Clarification
                </label>

                <hr />

                {/* Documents uploaded list */}
                <div className="mt-2">
                  <button
                    onClick={handleModal}
                    type="button"
                    className="py-2 px-3 text-xs text-gray-400 border border-gray-300 border-solid w-full focus:outline-none font-semibold text-start"
                  >
                    Click to attach file
                  </button>

                  {uploadedFiles.length > 0 && (
                    <div className="container mx-auto mt-1">
                      <div className="overflow-x-auto">
                        <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                Sn
                              </th>
                              <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                Attachment Category
                              </th>
                              <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                Attached File
                              </th>
                              <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {uploadedFiles.map((item, i) => (
                              <tr
                                key={i}
                                className="transition-all hover:bg-gray-100 border"
                              >
                                <td className="px-3 py-2  text-xs">{i + 1}</td>
                                <td className="px-3 py-2  text-xs">
                                  {uploadedFilesAttachType[i]}
                                </td>
                                <td className="px-3 py-2  text-xs">
                                  {item?.name}
                                </td>
                                <td className="px-3 py-2  text-xs">
                                  <button
                                    className="flex"
                                    onClick={() => removeFile(i)}
                                  >
                                    <span style={{ marginTop: "2px" }}>
                                      <XMark />
                                    </span>
                                    <span className="ml-1">Remove</span>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>

                <textarea
                  id="ClarificationComment"
                  name="ClarificationComment"
                  value={clarificationComment}
                  onChange={(e) =>
                    setClarificationComment(e.target.value.trimStart())
                  }
                  className="border rounded-xs px-3 py-2 w-full text-sm mt-2"
                  placeholder="Enter comment"
                  style={{ height: "150px" }}
                ></textarea>

                {/* <div className={`text-red-600 font-semibold text-start mb-2 bg-red-100 p-1 px-3 border-l-2 border-l-red-600 rounded-r-sm ${!comment_err_msg&&'hidden'}`}>{comment_err_msg}</div> */}

                <div className="text-left pb-8 flex gap-4">
                  <button
                    onClick={handleSubmitClarify}
                    type="button"
                    className="px-4 py-2 text-green-600 border border-green-600 rounded w-1/4 hover:bg-green-600 hover:text-white focus:outline-none font-semibold"
                    name="clarifyResponse"
                  >
                    Send Clarification
                  </button>
                </div>
              </div>
            )}

          {/* attachment-uplooad-modal-section */}
          {togggleModal && (
            <div
              className="fixed inset-0 flex items-center justify-center z-50"
              style={{ background: "rgb(0 0 0 / 52%)" }}
            >
              <div className="modal-container bg-white w-7/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">
                <div className="flex justify-between">
                  <div className="modal-content py-4 text-left px-6">
                    <h2 className="text-2xl font-bold">Attach File</h2>
                    <p className="text-xs">
                      Kindly upload your clarification document(s)
                    </p>
                  </div>
                  <div className="modal-content py-4 text-left px-6">
                    <button
                      onClick={handleModal}
                      className="p-2"
                      title="Close Modal"
                    >
                      <XMark />
                    </button>
                  </div>
                </div>

                <div className="modal-content mb-4 text-left px-6 pb-4 attach-result">
                  <div className="mb-3">
                    <label
                      htmlFor="AlternateEmailAddress"
                      className="block text-gray-700 font-bold"
                    >
                      Select Attachment to Upload
                    </label>
                    <Select
                      value={attachment_type}
                      options={attachmentList}
                      isMulti={false}
                      isSearchable={false}
                      onChange={(e: any) => {
                        setAttachmentType(e);
                      }}
                      className="text-sm"
                      placeholder="Select..."
                    />
                    {attachment_type.label === "Others" && (
                      <input
                        type="text"
                        id="Otherstext"
                        name="Otherstext"
                        value={OthersText}
                        onChange={(e: any) => {
                          setOtherstext(e.target.value);
                        }}
                        className="border rounded-xs px-3 py-2 mt-2 w-full text-xs font-medium"
                        placeholder="Enter others description"
                        required
                      />
                    )}
                  </div>

                  <div>
                    <div {...getRootProps()} className="dropzoneStyle">
                      <input {...getInputProps()} />
                      <p>
                        Drag & drop some files here, or click to select files
                      </p>
                    </div>
                    {uploadedFiles.length > 0 && (
                      <div className="container mx-auto mt-3">
                        <div className="overflow-x-auto">
                          <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                  Sn
                                </th>
                                <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                  Attachment Category
                                </th>
                                <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                  Attached File
                                </th>
                                <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {uploadedFiles.map((item, i) => (
                                <tr
                                  key={i}
                                  className="transition-all hover:bg-gray-100 border"
                                >
                                  <td className="px-3 py-2  text-xs">
                                    {i + 1}
                                  </td>
                                  <td className="px-3 py-2  text-xs">
                                    {uploadedFilesAttachType[i]}
                                  </td>
                                  <td className="px-3 py-2  text-xs">
                                    {item?.name}
                                  </td>
                                  <td className="px-3 py-2  text-xs">
                                    <button
                                      className="flex"
                                      onClick={() => removeFile(i)}
                                    >
                                      <span style={{ marginTop: "2px" }}>
                                        <XMark />
                                      </span>
                                      <span className="ml-1">Remove</span>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TASDetails;
