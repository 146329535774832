
import { getnamefromemail } from '../utils';

export default function ApprovalSequenceFlow(props:any) {
    const form_approval = props.data;
    const approvalStatus = (status:string) =>{
        switch(status){
            case 'DRAFT':
                return 'text-gray-600'; // draft
            case 'SUBMITTED':
                return 'text-gray-600'; // SUBMITTED
            case 'PENDING':
                return 'text-gray-600'; // pending
            case 'CLARIFY':
                return 'text-yellow-600'; // clarify
            case 'RESTART':
                return 'text-blue-600'; // review
            case 'APPROVED':
                return 'text-green-600'; // approved
            case 'DECLINED':
                return 'text-red-600'; // reject
            case 'CLOSED':
                return 'text-green-700'; // CLOSED
        }
    }
    const approvalStatusLabel = (status:string) =>{
        switch(status){
            case 'DRAFT':
                return ''; // draft
            case 'SUBMITTED':
                return 'bg-gray-200'; // SUBMITTED
            case 'PENDING':
                return 'bg-gray-200'; // pending
            case 'CLARIFY':
                return 'border-yellow-600 bg-yellow-200'; // clarify
            case 'RESTART':
                return 'border-blue-600 bg-blue-200'; // review
            case 'APPROVED':
                return 'border-green-600 bg-green-200'; // approved
            case 'DECLINED':
                return 'border-red-600 bg-red-200'; // reject
            case 'CLOSED':
                return 'border-green-700 bg-green-300'; // CLOSED
        }
    }
    return (
        <div className='block m-0'>
            <ol className="flex items-center justify-between w-full p-4 text-sm font-medium text-gray-500 bg-white border border-gray-150 rounded-lg horizontal-scroll-bar">
                {
                    form_approval.map((item: any, i: any) => {
                        return (
                            <li key={i} className={`flex items-center ${approvalStatus(item?.formApproval?.status)}`}>
                                <span className={`flex items-center justify-center w-10 h-10 text-xs border rounded-full shrink-0 font-bold ${approvalStatusLabel(item?.formApproval?.status)}`}>
                                    {i + 1}
                                </span>
                                <span className="sm:ms-2 mt-2 w-3/4 ml-2">
                                    {!!item.roleName ? item.roleName : item?.role?.name}<br />
                                    <span className='font-semibold text-gray-400 relative' style={{ fontSize: '10px', top: '-5px' }}>{getnamefromemail(item?.email)}</span>
                                </span>
                                {
                                    form_approval.length - 1 > i && <div className={`ml-5 mr-5 border w-[100px]`}></div>
                                }
                            </li>
                        )
                    })
                }
            </ol>
        </div>
    )
}