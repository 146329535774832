import { useEffect, useState, useCallback, FormEvent } from 'react';
import { useParams, useNavigate  } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { _$http, axiosError, closeAlert, confirmAlert, preloader, successAlert, tinyAlert} from '../../../utils';
import { XMark } from '../../../components/Icons';
import { FaRegTrashCan, FaPenToSquare, FaBell, FaFileContract } from "react-icons/fa6";
import { User } from '../../../interfaces/users.interface';
import { useSelector } from "react-redux";
import { useDropzone } from 'react-dropzone';
import Select from 'react-select';
import ApprovalSequenceFlow from '../../../components/ApprovalSequenceFlow';
import FormCurrentStatus from '../../../components/FormCurrentStatus';
import FormField from '../../../components/FormField';
import FormAttachments from '../../../components/FormAttachments';
import FormComment from '../../../components/FormComment';
import FormApproval from '../../../components/FormApproval';
import { FormIDLabel } from '../../../components/FormUI';

import {
    // AssetDetail,
    RoutingSlipForDeviationFromStandardTemplateFormDetailsInputs,
    // RoutingSlipForDeviationFromStandardTemplateFormInputs
} from "../../../interfaces/forms.interface";
import routingSlipForDeviationFromStandardTemplateFormService from "../../../services/forms/RoutingSlipForDeviationFromStandardTemplateForm.services";
import routingSlipForDeviationFromStandardTemplateFormServices from "../../../services/forms/RoutingSlipForDeviationFromStandardTemplateForm.services";



function DFSTDetails() {
    var param = useParams();
    // const location = useLocation();
    const navigate = useNavigate ();

    const formIDParam:string = !!param.id ? param.id : '';
    const formApproverId = !!param.formApproverId ? param.formApproverId : '';

    // const queryParams = new URLSearchParams(location.search);
    // const formApproverId = queryParams.get('formApproverId');

    const user: User = useSelector((state: any) => state.tepngUser.value.user);

    // const [comment_err_msg, setCommentErr] = useState('');
    const [OthersText, setOtherstext] = useState('');
    const [clarificationComment, setClarificationComment] = useState('');
    const [togggleModal, setModalTogggle] = useState(false);
    const [CanEditOrDelete, setCanEditOrDelete] = useState(false);
    const [formID, setformID] = useState(''); //setformID
    const [currentStatus, setCurrentStatus] = useState(''); //currentStatus
    const [FormApprovers, setFormApprovers] = useState<any>([]);
    const [FormDocuments, setFormDocuments] = useState<any>([]);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [uploadedFilesAttachType, setUploadedFilesAttachType] = useState<string[]>([]);

    const routingSlipForDeviationFromStandardTemplateFormInputs: RoutingSlipForDeviationFromStandardTemplateFormDetailsInputs = {
        id: "",
        description: "",
        nameOfBuyer: "",
        alternateEmailAddress: "",
        justification: "",
        asset: 0,
        linkedToCategory: 0,
        validatedByCATMAN: 0,
        updatedByInsurance: 0,
        updatedByLegal: 0,
        updatedByTax: 0,
        updatedByFinance: 0,
        updatedByCompliance: 0,
        updatedByHSE: 0,
        othersDescription: "",
        isDraft: false,
        departmentId: "",
        department: {},
        documents: [],
        updatedBy: "",
        updatedDate: "",
        createdBy: "",
        createdDate: "",
        currentStatus: "",
        referenceFormIds: []
    };

    const [formInputs, setFormData] = useState<RoutingSlipForDeviationFromStandardTemplateFormDetailsInputs>(routingSlipForDeviationFromStandardTemplateFormInputs);
    const [attachment_type, setAttachmentType] = useState<any>({
        label: 'Select...', value: '', name: 'attachment_type_name',
    });
    const [attachmentList, setAttachmentList] = useState<any[]>([]);


    useEffect(() => { getFormById(formIDParam); }, [formIDParam])
    useEffect(() => { getFormAttachmentTypes();}, []) //!load if clarification. refacto.

    const getFormById = (formIDParam: string) => {
        preloader();
        routingSlipForDeviationFromStandardTemplateFormService.fetchFormById(formIDParam)
            .then(res => {
            closeAlert();

            if(res){
                setCanEditOrDelete(res.canEditOrDelete);
                const approval = res.formApprovers.sort((a:any, b:any) => a.step - b.step);
                setFormApprovers(approval);

                setformID(res.formID);
                setCurrentStatus(res.currentStatus);
                setFormDocuments(res.documents);

                setFormData({
                    id : res.id,
                    description: res.description,
                    nameOfBuyer: res.nameOfBuyer,
                    alternateEmailAddress: res.alternateEmailAddress,
                    linkedToCategory: res.linkedToCategoryDetails.name,
                    validatedByCATMAN: res.validatedByCATMANDetails.name,
                    updatedByInsurance: res.updatedByInsuranceDetails.name,
                    updatedByLegal: res.updatedByLegalDetails.name,
                    updatedByTax: res.updatedByTaxDetails.name,
                    updatedByFinance: res.updatedByFinanceDetails.name,
                    updatedByCompliance: res.updatedByComplianceDetails.name,
                    updatedByHSE: res.updatedByHSEDetails.name,
                    asset  : res.assetDetails.name,
                    createdBy : res.createdBy,
                    createdDate :  res.createdDate,
                    currentStatus :  res.currentStatus,
                    department :  res.department,
                    departmentId : res.departmentId,
                    documents : res.documents,
                    isDraft :  res.isDraft,
                    justification  :  res.justification,
                    othersDescription : res.othersDescription,
                    updatedBy : res.updatedBy,
                    updatedDate : res.updatedDate,
                    referenceFormIds: res.referenceFormIds,
                });
            }

        })
        .catch(error => {
            axiosError(error);
        })
    };
    const deleteForm = () => {
        confirmAlert(`Do you want delete this form?`).then((r: any) => {
        if (r.isConfirmed) {
            preloader();
            routingSlipForDeviationFromStandardTemplateFormService.deleteForm(formIDParam)
                .then(res => {
                    closeAlert();
                    successAlert('Deleted Successfully');
                    navigate(-1);
            })
            .catch(error => {
                axiosError(error);
            })
        }
        });
    }

    const onDrop = useCallback((acceptedFiles : File[]) => {
        const newAttachTypeLabel = attachment_type.label === 'Others' 
            ? OthersText : attachment_type.label;
        let uploadType = uploadedFilesAttachType;

        for(let i = 0; i < acceptedFiles.length; i++){
            uploadType.push(newAttachTypeLabel)
        }
        
        setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, ...acceptedFiles]);
        setUploadedFilesAttachType(uploadType);

    }, [attachment_type.label, OthersText, uploadedFilesAttachType]);
    const { getRootProps, getInputProps } = useDropzone({
    //accept: ['.png', '.jpg', '.jpeg', '.gif'],//'image/*', // Specify the file types you want to allow
        onDrop,
    }); 
    const handleModal = () => {
        if(togggleModal){
            setModalTogggle(false);
        }
        else 
            setModalTogggle(true);
    };
    const removeFile = (index: number) => {
        const updatedNames = [...uploadedFilesAttachType];
        updatedNames.splice(index, 1);
        
        const updatedFiles2 = [...uploadedFiles];
        updatedFiles2.splice(index, 1);

        setUploadedFiles(updatedFiles2);
        setUploadedFilesAttachType(updatedNames);
    };
    const getFormAttachmentTypes = () => {
        preloader();
        _$http('GET', 'FormAttachmentTypes/ByFormType?FormType=5').then(res => {
            closeAlert();
            const list: any[] = res.data.data.map((i:any) =>  ({ label: i.name, value: i.id, name: 'attachment_type_name' }) );
            setAttachmentList(list);
        })
        .catch(error => {
            axiosError(error);
        })
    };
    const NotifyApproval = () => {
        preloader();

        let pendingApproverEmail  = '';

        for (const approver of FormApprovers) {
            if (approver.formApproval && approver.formApproval.status === "PENDING") {
                pendingApproverEmail = approver.email;
            }
        }
        
        const data = {
            "approverEmail": pendingApproverEmail,
            "formId": formIDParam
        }

        _$http('POST', 'Notification/Pending/Approvals', data).then(res => {
            tinyAlert('success', 'Notification sent!')
            closeAlert();
        })
        .catch(error => {
            axiosError(error);
        })
    };
    const handleSubmitClarify = (e: FormEvent) => {
        e.preventDefault();
        if(!clarificationComment){
            tinyAlert('error', 'Kindly enter a comment!');
            return;
        }


        confirmAlert(`Do you want to send clarification response?`).then((r:any) => {
            if (r.isConfirmed) {
                preloader();

                const clarify_approval = FormApprovers.filter((approver:any)=> approver.formApproval?.status === 'CLARIFY')[0];
                const formApproverId = clarify_approval.formApproval.formApproverId;
                console.log(formApproverId);

                const data = {
                    formApproverId: formApproverId,
                    clarificationComment: clarificationComment,
                    uploadedFiles: uploadedFiles,
                    uploadedFilesAttachType: uploadedFilesAttachType
                }

                routingSlipForDeviationFromStandardTemplateFormServices.replyToClarification(data).then(res => {
                    // successAlert(res.data.message);
                    navigate('/initiator/forms/history/routing-slip-for-deviation-from-standard-template');
                })
                .catch(error => {
                    axiosError(error);
                })
            }
        });
    };


    // template->view;
    return (

        // template -> routing-slip-for-deviation-from-standard-template -> view-body-content;
        <div className='bg-white w-full shadow-sm rounded-lg py-6'>
            <div className='px-6'>

                {/* form-current-satus-area */}
                <FormCurrentStatus data={currentStatus} formID={formIDParam} />

                {/* approval-sequence-content */}
                <ApprovalSequenceFlow data={FormApprovers} />


                {/* reference-id and action buttons section */}
                <div className='pb-1 pr-2 mt-5 flex justify-between'>

                    <FormIDLabel form_ids={formInputs?.referenceFormIds} url="/initiator/forms/details/routing-slip-for-deviation-from-standard-template/" current_id={formID} />

                    {
                        !formApproverId && (user.email === formInputs.alternateEmailAddress || user.email === formInputs.createdBy) && 
                        <div className='flex'>
                            <button onClick={()=>{ currentStatus === 'RESTART' && navigate(`/initiator/forms/restart/routing-slip-for-deviation-from-standard-template/${formIDParam}`)}} className={`flex gap-1 ml-6 ${currentStatus === 'RESTART' ? 'bg-blue-600 px-4 pt-1 text-white rounded-md hover:bg-blue-500 border relative top-[-8px]' : 'text-gray-400 font-bold cursor-not-allowed'}`}>
                                <FaFileContract  /> <span>Restart</span>
                            </button>

                            <button className={`flex gap-1 ml-6 ${(currentStatus === 'PENDING') ? 'text-yellow-500' : 'text-gray-400 font-bold cursor-not-allowed'}`} onClick={NotifyApproval}>
                                <FaBell  /> <span>Notify</span>
                            </button>

                            <button onClick={()=>{ CanEditOrDelete&&navigate(`/initiator/forms/edit/routing-slip-for-deviation-from-standard-template/${formIDParam}`)}} className={`flex gap-1 ml-6 ${CanEditOrDelete ? 'text-green-600' : 'text-gray-400 font-bold cursor-not-allowed'}`}>
                                <FaPenToSquare /> <span>Edit</span>
                            </button>

                            <button onClick={() => CanEditOrDelete&&deleteForm()} className={`flex gap-1 ml-6 ${CanEditOrDelete ? 'text-red-600' : 'text-gray-400 font-bold cursor-not-allowed'}`}>
                                <FaRegTrashCan /> <span>Delete</span>
                            </button>
                        </div>
                    }
                </div>

                <hr />

                {/* display-form-details-section */}
                <div className="max-w-1xl mx-auto rounded space-y-3 mt-3">
                    <FormField title="Description" value={formInputs.description} />

                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4'>
                        <FormField title="Asset" value={formInputs.asset} />
                        <FormField title="Linked to Category" value={formInputs.linkedToCategory} />
                        <FormField title="Validated by CATMAN" value={formInputs.validatedByCATMAN} />
                        <FormField title="Template reviewed and updated by insurance" value={formInputs.updatedByInsurance} />
                        <FormField title="Template reviewed and updated by Legal" value={formInputs.updatedByLegal} />
                        <FormField title="Template reviewed and updated by Tax" value={formInputs.updatedByTax} />
                        <FormField title="Template reviewed and updated by Cost Control/Finance" value={formInputs.updatedByFinance} />
                        <FormField title="Template reviewed and updated by Compliance" value={formInputs.updatedByCompliance} />
                        <FormField title="Template reviewed and updated by HSE" value={formInputs.updatedByHSE} />
                        <FormField title="Department/Entity" value={formInputs.department?.name} />
                        <FormField title="Name of Buyer/CE" value={formInputs.nameOfBuyer} />
                        <FormField title="Alternative Email Address" value={formInputs.alternateEmailAddress} />
                    </div>
                    
                    <FormField title="Justification" value={formInputs.justification} />


                    {/* display-attached-files-section */}
                    <FormAttachments data={FormDocuments} />

                    {/* display-all-comments-section */}
                    <FormComment data={FormApprovers} user={user} />
                    
                    {/* form-approval-action-section */}
                    <FormApproval 
                        formApproverId={!!formApproverId && formApproverId} 
                        formName="RoutingSlipForDeviationFromStandardTemplateForm"
                        redirectURL='routing-slip-for-deviation-from-standard-template' 
                        FormApprovers={FormApprovers}
                    />

                    {/* clarify-form-section */}
                    {
                        currentStatus.toLocaleLowerCase() === 'clarify' && 
                        (!formApproverId && (user.email === formInputs.alternateEmailAddress || user.email === formInputs.createdBy)) && 
                        
                        <div className='pt-5'>
                            <label htmlFor="Clarify" className="block text-gray-600 font-bold text-md flex">Clarification</label>

                            <hr />
                            
                            {/* Documents uploaded list */}
                            <div className='mt-2'>
                                <button onClick={handleModal} type="button" className="py-2 px-3 text-xs text-gray-400 border border-gray-300 border-solid w-full focus:outline-none font-semibold text-start">
                                    Click to attach file
                                </button>

                                {
                                    uploadedFiles.length > 0 &&
                                    <div className="container mx-auto mt-1">
                                        <div className="overflow-x-auto">
                                            <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
                                                <thead>
                                                    <tr>
                                                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">Sn</th>
                                                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">Attachment Category</th>
                                                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">Attached File</th>
                                                        <th className="px-3 py-2 text-left bg-gray-100 text-xs">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    uploadedFiles.map((item, i) => 
                                                        <tr key={i} className="transition-all hover:bg-gray-100 border">
                                                            <td className="px-3 py-2  text-xs">{i + 1}</td>
                                                            <td className="px-3 py-2  text-xs">{uploadedFilesAttachType[i]}</td>
                                                            <td className="px-3 py-2  text-xs">{item.name}</td>
                                                            <td className="px-3 py-2  text-xs">
                                                                <button className='flex' onClick={() => removeFile(i)}>
                                                                    <span style={{marginTop:'2px'}}><XMark /></span>
                                                                    <span className='ml-1'>Remove</span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                            </div>
                            
                            <textarea
                                id="ClarificationComment"
                                name="ClarificationComment"
                                value={clarificationComment}
                                onChange={(e) => setClarificationComment(e.target.value.trimStart())}
                                className="border rounded-xs px-3 py-2 w-full text-sm mt-2"
                                placeholder="Enter comment"
                                style={{height:'150px'}}
                            ></textarea>
                            
                            {/* <div className={`text-red-600 font-semibold text-start mb-2 bg-red-100 p-1 px-3 border-l-2 border-l-red-600 rounded-r-sm ${!comment_err_msg && 'hidden'}`}>{comment_err_msg}</div> */}
                            
                            <div className="text-left pb-8 flex gap-4">
                                <button onClick={handleSubmitClarify} type="button" className="px-4 py-2 text-green-600 border border-green-600 rounded w-1/4 hover:bg-green-600 hover:text-white focus:outline-none font-semibold" name='clarifyResponse'>
                                    Send Clarification
                                </button>
                            </div>
                        </div>
                    }

                    {/* attachment-uplooad-modal-section */}
                    {
                        togggleModal &&
                        <div className="fixed inset-0 flex items-center justify-center z-50" style={{background:'rgb(0 0 0 / 52%)'}}>
                            <div className="modal-container bg-white w-7/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">
                                <div className='flex justify-between'>
                                    <div className="modal-content py-4 text-left px-6">
                                        <h2 className="text-2xl font-bold">Attach File</h2>
                                        <p className='text-xs'>Kindly upload your clarification document(s)</p>
                                    </div>
                                    <div className="modal-content py-4 text-left px-6">
                                        <button onClick={handleModal} className="p-2" title='Close Modal'><XMark /></button>
                                    </div>
                                </div>

                                <div className="modal-content mb-4 text-left px-6 pb-4 attach-result">
                                    <div className='mb-3'>
                                        <label htmlFor="AlternateEmailAddress" className="block text-gray-700 font-bold">Select Attachment to Upload</label>
                                        <Select
                                            value={attachment_type}
                                            options={attachmentList}
                                            isMulti={false}
                                            isSearchable={false}
                                            onChange={(e:any)=>{setAttachmentType(e)}}
                                            className="text-sm"
                                            placeholder="Select..."
                                        />
                                        {
                                            attachment_type.label === 'Others' &&
                                            <input
                                                type="text"
                                                id="Otherstext"
                                                name="Otherstext"
                                                value={OthersText}
                                                onChange={(e:any)=>{setOtherstext(e.target.value)}}
                                                className="border rounded-xs px-3 py-2 mt-2 w-full text-xs font-medium"
                                                placeholder="Enter others description"
                                                required 
                                            />
                                        }
                                    </div>

                                    <div>
                                        <div {...getRootProps()} className='dropzoneStyle'>
                                            <input {...getInputProps()} />
                                            <p>Drag & drop some files here, or click to select files</p>
                                        </div>
                                        {uploadedFiles.length > 0 && (
                                            <div className="container mx-auto mt-3">
                                                <div className="overflow-x-auto">
                                                    <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
                                                        <thead>
                                                            <tr>
                                                                <th className="px-3 py-2 text-left bg-gray-100 text-xs">Sn</th>
                                                                <th className="px-3 py-2 text-left bg-gray-100 text-xs">Attachment Category</th>
                                                                <th className="px-3 py-2 text-left bg-gray-100 text-xs">Attached File</th>
                                                                <th className="px-3 py-2 text-left bg-gray-100 text-xs">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {uploadedFiles.map((item, i) => (
                                                                <tr key={i} className="transition-all hover:bg-gray-100 border">
                                                                    <td className="px-3 py-2  text-xs">{i + 1}</td>
                                                                    <td className="px-3 py-2  text-xs">{uploadedFilesAttachType[i]}</td>
                                                                    <td className="px-3 py-2  text-xs">{item.name}</td>
                                                                    <td className="px-3 py-2  text-xs">
                                                                        <button className='flex' onClick={() => removeFile(i)}>
                                                                            <span style={{marginTop:'2px'}}><XMark /></span>
                                                                            <span className='ml-1'>Remove</span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default DFSTDetails;