import React from 'react';
import { useEffect, useState } from 'react';
import { _$http, axiosError, closeAlert, preloader, twoDecimalPlace } from '../../utils';
import { ThreeDots, XMark } from '../../components/Icons';
import { Label } from '../../components/FormUI';
import Select from "react-select";
import api from '../../services/form.services';
import { OptionProps } from '../../interfaces/forms.interface';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';

function ApprovalSequenceRules() {
  const navigate = useNavigate();
  const [toggleCreateModal, setToggleCreateModal] = useState(false)
  const [currencies, setCurrencies] = useState<OptionProps[]>([]);
  const [toggleEditModal, setToggleEditModal] = useState(false)
  const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<OptionProps>({ label: "Select...", value: null, name: "currency" })
  const [formData, setFormData] = useState([])
  const [formInputs, setFormInputs] = useState({
    name: "", maxContractValue: 0, minContractValue: 0, currency: ""
  })
  const [approvalSequenceRuleId, setApprovalSequenceRuleId] = useState(null)
  useEffect(() => { getApprovalSequenceRules(); api.fetchCurrency().then((res) => setCurrencies(res)); }, []);

  const getApprovalSequenceRules = () => {
    preloader();
    _$http('GET', 'ApprovalSequenceRules').then(res => {
      closeAlert();
      setFormData(res.data.data);
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleSelect = (fieldName: string, selectedOptions: any) => {
    setFormInputs({ ...formInputs, [fieldName]: selectedOptions?.value });
    setSelectedCurrency({ label: selectedOptions?.label, value: selectedOptions?.value, name: "currency" })
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormInputs({ ...formInputs, [name]: value })
  }

  const handleEditFields = (item: any) => {
    setFormInputs({ ...formInputs, name: item.name, maxContractValue: item.maxContractValue, minContractValue: item.minContractValue, currency: item.currency })
    let curr = currencies.find(a => a.label === item.currency) ?? { label: "Select...", value: null, name: "currency" }
    setSelectedCurrency(curr)
    setApprovalSequenceRuleId(item.id)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    preloader();
    _$http('POST', 'ApprovalSequenceRules', formInputs).then(res => {
      closeAlert();
      setToggleCreateModal(false)
      getApprovalSequenceRules()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleUpdate = (e: any) => {
    e.preventDefault()

    preloader();
    _$http('PUT', 'ApprovalSequenceRules/' + approvalSequenceRuleId, formInputs).then(res => {
      closeAlert();
      setToggleEditModal(false)
      setApprovalSequenceRuleId(null)
      getApprovalSequenceRules()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleDelete = () => {
    preloader();
    setToggleConfirmationModal(false)
    _$http('DELETE', 'ApprovalSequenceRules/' + approvalSequenceRuleId).then(res => {
      closeAlert();
      setApprovalSequenceRuleId(null)
      const message = res.data.message
      console.log(message)
      getApprovalSequenceRules()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  return (
    <div className='bg-white w-full shadow-sm rounded-lg py-6'>
    <div className='flex justify-start mb-1 gap-5'>
      <span onClick={() => navigate(-1)} className="mb-2 flex gap-2 mt-1 cursor-pointer text-red-500">
        <FaArrowLeftLong />
        <span className='text-sm font-bold'>Go Back</span>
      </span>
    </div>
      <div className='flex justify-between px-6'>
        {/* <input type="text" name='search' className="border px-3 py-2 text-sm mr-1" placeholder="Search Approval Sequence Rules" /> */}
        <button onClick={() => setToggleCreateModal(true)} className='red-button rounded px-4 py-2 text-sm'>Create Approval Sequence Rule</button>
      </div>

      <div className="relative overflow-x-auto py-4" style={{ minHeight: "600px" }}>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                SN
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Min Contract Value
              </th>
              <th scope="col" className="px-6 py-3">
                Max Contract Value
              </th>
              <th scope="col" className="px-6 py-3">
                Currency
              </th>
              <th scope="col" className="px-6 py-3">
              Less than 3 bids / deviation from C&P Procedure
              </th>
              <th scope="col" className="px-6 py-3">
                TUCN Service Type
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {
              formData.map((item: any, index: number) => {
                return (
                  <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="px-6 py-4">
                      {index + 1}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.name}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.minContractValue > 0 ? twoDecimalPlace(item.minContractValue) : "-"}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.maxContractValue > 0 ? twoDecimalPlace(item.maxContractValue) : "-"}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.currency === 0 ? "-" : item.currency}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.lessThanThreeBidsInCFT ? item.lessThanThreeBidsInCFT.toString() : "-"}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.isTUCNService ? item.isTUCNService.toString() : "-"}
                    </th>
                    <td className="px-6 py-4">
                      {item.maxContractValue > 0 ? <div className="dropdown">
                        <button title='More Options' className='text-blue-600 dropbtn'>
                          <ThreeDots />
                        </button>
                        <div className="dropdown-content" style={{ left: "0" }}>
                          <button onClick={() => { setToggleEditModal(true); handleEditFields(item) }}>Edit Approval Sequence Rule</button>
                          <button onClick={() => { setToggleConfirmationModal(true); setApprovalSequenceRuleId(item.id) }}>Delete Approval Sequence Rule</button>
                        </div>
                      </div> : <></>}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      {
        toggleCreateModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Create Approval Sequence Rule</h2>
              <button onClick={() => setToggleCreateModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ApprovalSequenceRule</label>

                <div>
                  <Label title="Name" required />
                  <input onChange={handleInputChange} type="text" name='name' required style={{ minWidth: "300px" }} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                </div>

                <div>
                  <Label title="Min Contract Value" required />
                  <input onChange={handleInputChange} type="text" name='minContractValue' required style={{ minWidth: "300px" }} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                </div>

                <div>
                  <Label title="Max Contract Value" required />
                  <input onChange={handleInputChange} type="text" name='maxContractValue' required style={{ minWidth: "300px" }} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                </div>

                <div>
                  <Label title="Currency" required />
                  <Select
                    id="po_currency"
                    // value={selectedCurrency}
                    options={currencies}
                    isMulti={false}
                    isSearchable={false}
                    onChange={(e) => handleSelect("currency", e)}
                    className="font-normal"
                    placeholder="Select..."
                    required
                  />
                </div>
              </div>

              <div className='modal-footer flex justify-end'>
                <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Submit</button>
              </div>
            </form>
          </div>
        </div>
      }
      {
        toggleEditModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Edit Approval Sequence Rule</h2>
              <button onClick={() => setToggleEditModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form onSubmit={handleUpdate}>
              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ApprovalSequenceRule</label>

                <div>
                  <Label title="Name" required />
                  <input onChange={handleInputChange} value={formInputs.name} type="text" name='name' required style={{ minWidth: "300px" }} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                </div>

                <div>
                  <Label title="Min Contract Value" required />
                  <input onChange={handleInputChange} value={formInputs.minContractValue} type="text" name='minContractValue' required style={{ minWidth: "300px" }} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                </div>

                <div>
                  <Label title="Max Contract Value" required />
                  <input onChange={handleInputChange} value={formInputs.maxContractValue} type="text" name='maxContractValue' required style={{ minWidth: "300px" }} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                </div>

                <div>
                  <Label title="Currency" required />
                  <Select
                    id="po_currency"
                    value={selectedCurrency}
                    options={currencies}
                    isMulti={false}
                    isSearchable={false}
                    onChange={(e) => handleSelect("currency", e)}
                    className="font-normal"
                    placeholder="Select..."
                    required
                  />
                </div>
              </div>

              <div className='modal-footer flex justify-end'>
                <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update</button>
              </div>
            </form>
          </div>
        </div>
      }
      {
        toggleConfirmationModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-md">Are you sure you want to delete this approval sequence rule? This cannot be reversed and may impact the system performance.</h2>
              <button onClick={() => setToggleConfirmationModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <div className="modal-content text-left px-6 flex justify-center gap-2 mt-4">
              <button onClick={() => setToggleConfirmationModal(false)} type="button" className="rounded text-black bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">Cancel</button>
              <button onClick={() => handleDelete()} type="button" className="rounded text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Yes</button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default ApprovalSequenceRules;
