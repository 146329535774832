import { formatYYYYMMDD } from "../../utils";
import { makePOST, makePUT, makeREQUEST, tinyAlert, printError, } from "../_config.services";

class TUCNSavingsDeclarationSheetFormService {
  // constructor() { }

  async setForm(data: any): Promise<any> {
    // try{
      const form_approval_emails = data.form_approval.every((obj:any) => obj.email.trim() !== '');
      if (!form_approval_emails) {
          tinyAlert('error','Some or all emails in approval sequence are empty.');
          return;
      }
      const input = data.formInputs;
      const select = data.formSelects;
      let others_description = "Others";

      const form: any = new FormData();
      form.append("PONumber", input.PONumber);
      form.append("ManagementMode1", input.ManagementMode1);
      form.append("ManagementMode2", input.ManagementMode2);
      form.append("ManagementMode3", input.ManagementMode3);
      form.append("ServiceTypeCode", input.ServiceTypeCode);
      form.append("NatureOfMaterials", input.NatureOfMaterials);
      form.append("Supplier", input.Supplier);
      form.append("ContractValue", input.ContractValue);
      form.append("AlternateEmailAddress", input.AlternateEmailAddress);
      form.append("ContractDurationMonths", input.ContractDurationMonths);
      form.append("BriefMaterialDescription", input.BriefMaterialDescription);
      form.append("CostImpactDetailsAndCalculationReferences", input.CostImpactDetailsAndCalculationReferences);
      form.append("DescriptionOfOpportunityAndSummaryOfTheContext", input.DescriptionOfOpportunityAndSummaryOfTheContext);
      form.append("ActionsImplementedAndLeverUsed", input.ActionsImplementedAndLeverUsed);
      form.append("ResultsAndAchievement", input.ResultsAndAchievement);
      form.append("MeasurementMethodsApplied", input.MeasurementMethodsApplied);
      form.append("NatureOfCostAvoidanceOthers", input.NatureOfCostAvoidanceOthers);
      form.append("OthersDescription", input.OthersDescription);
      form.append("TUCNResults", JSON.stringify(data.tucnResults));
      form.append("TGPCategory2", select.TGPCategory2.value);
      form.append("DepartmentId", select.Department.value);
      form.append("MaterialType", select.MaterialType.value);
      form.append("ServiceType", select.ServiceType.label);
      form.append("StartDate", formatYYYYMMDD(select.StartDate));
      form.append("EndDate", formatYYYYMMDD(select.EndDate));
      form.append("NatureOfCostAvoidance", select.NatureOfCostAvoidance.value);
      form.append("RoadMapNo", select.RoadMapNo.value);
      form.append("IsDraft", data.IsDraft);
      form.append("FormApprovers", JSON.stringify(data.form_approval));

      for (let i = 0; i < data.uploadedFiles.length; i++) {
            form.append("Others", data.uploadedFiles[i]);
            others_description = data.uploadedFilesAttachType[i];
      }

      form.append("OthersDescription", others_description);

      return await makePOST('TUCNSavingsDeclarationSheetForm/Initiator/Submit', form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    // } catch (error) {
    //   tinyAlert("error", 'An error occurred!');
    //   console.error('Error:', error);
      
    // }
  }

  async updateForm(data: any): Promise<any> {
    try{
      const input = data.formInputs;
      const select = data.formSelects;
      let others_description = "Others";

      const form: any = new FormData();
      form.append("PONumber", input.PONumber);
      form.append("ManagementMode1", input.ManagementMode1);
      form.append("ManagementMode2", input.ManagementMode2);
      form.append("ManagementMode3", input.ManagementMode3);
      form.append("ServiceTypeCode", input.ServiceTypeCode);
      form.append("NatureOfMaterials", input.NatureOfMaterials);
      form.append("Supplier", input.Supplier);
      form.append("ContractValue", input.ContractValue);
      form.append("AlternateEmailAddress", input.AlternateEmailAddress);
      form.append("ContractDurationMonths", input.ContractDurationMonths);
      form.append("BriefMaterialDescription", input.BriefMaterialDescription);
      form.append("CostImpactDetailsAndCalculationReferences", input.CostImpactDetailsAndCalculationReferences);
      form.append("DescriptionOfOpportunityAndSummaryOfTheContext", input.DescriptionOfOpportunityAndSummaryOfTheContext);
      form.append("ActionsImplementedAndLeverUsed", input.ActionsImplementedAndLeverUsed);
      form.append("ResultsAndAchievement", input.ResultsAndAchievement);
      form.append("MeasurementMethodsApplied", input.MeasurementMethodsApplied);
      form.append("NatureOfCostAvoidanceOthers", input.NatureOfCostAvoidanceOthers);
      form.append("OthersDescription", input.OthersDescription);
      form.append("TUCNResults", JSON.stringify(data.tucnResults));
      form.append("TGPCategory2", select.TGPCategory2.value);
      form.append("DepartmentId", select.Department.value);
      form.append("MaterialType", select.MaterialType.value);
      form.append("ServiceType", select.ServiceType.label);
      form.append("StartDate", formatYYYYMMDD(select.StartDate));
      form.append("EndDate", formatYYYYMMDD(select.EndDate));
      form.append("NatureOfCostAvoidance", select.NatureOfCostAvoidance.value);
      form.append("RoadMapNo", select.RoadMapNo.value)
      form.append("IsDraft", data.isDraft);
      form.append("FormApprovers", JSON.stringify(data.form_approval));

      if (!!data.removedDocumentIds.length) {
          for (let i = 0; i < data.removedDocumentIds.length; i++) {
              form.append("RemovedDocumentIds", data.removedDocumentIds[i]);
          }
      }

      // processing-attachment-area

      for (let i = 0; i < data.uploadedFiles.length; i++) {
        form.append("Others", data.uploadedFiles[i]);
        others_description = data.uploadedFilesAttachType[i];
  }


      form.append("OthersDescription", others_description);

      return await makePUT(`TUCNSavingsDeclarationSheetForm/Initiator/Update/${data.formIDQuery}`, form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } catch (error) {
      tinyAlert("error", printError(error));
      console.error('Error:', error);
    }
  }

  async submitDraft(id: any): Promise<any> {
    try{
      const data = {
        "tucnSavingsDeclarationSheetFormId": id
      }
      return await makeREQUEST("PUT", 'TUCNSavingsDeclarationSheetForm/Draft', data)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } catch (error) {
      tinyAlert("error", 'there is an error');
      console.error('Error:', error);
    }
  }

  async reviewForm(data: any): Promise<any> {
    try{
      const form_approval_emails = data.form_approval.every((obj:any) => obj.email.trim() !== '');
      if (!form_approval_emails) {
          tinyAlert('error','Some or all emails in approval sequence are empty.');
          return;
      }

      const form: any = new FormData();
      const input = data.formInputs;
      const select = data.formSelects;
      let others_description = "Others";

      form.append("PreviousTUCNSavingsDeclarationSheetFormId", data.parentFormId);
      form.append("PONumber", input.PONumber);
      form.append("ManagementMode1", input.ManagementMode1);
      form.append("ManagementMode2", input.ManagementMode2);
      form.append("ManagementMode3", input.ManagementMode3);
      form.append("ServiceTypeCode", input.ServiceTypeCode);
      form.append("NatureOfMaterials", input.NatureOfMaterials);
      form.append("Supplier", input.Supplier);
      form.append("ContractValue", input.ContractValue);
      form.append("AlternateEmailAddress", input.AlternateEmailAddress);
      form.append("ContractDurationMonths", input.ContractDurationMonths);
      form.append("BriefMaterialDescription", input.BriefMaterialDescription);
      form.append("CostImpactDetailsAndCalculationReferences", input.CostImpactDetailsAndCalculationReferences);
      form.append("DescriptionOfOpportunityAndSummaryOfTheContext", input.DescriptionOfOpportunityAndSummaryOfTheContext);
      form.append("ActionsImplementedAndLeverUsed", input.ActionsImplementedAndLeverUsed);
      form.append("ResultsAndAchievement", input.ResultsAndAchievement);
      form.append("MeasurementMethodsApplied", input.MeasurementMethodsApplied);
      form.append("NatureOfCostAvoidanceOthers", input.NatureOfCostAvoidanceOthers);
      form.append("OthersDescription", input.OthersDescription);
      form.append("TUCNResults", JSON.stringify(data.tucnResults));
      form.append("TGPCategory2", select.TGPCategory2.value);
      form.append("DepartmentId", select.Department.value);
      form.append("MaterialType", select.MaterialType.value);
      form.append("ServiceType", select.ServiceType.label);
      form.append("StartDate", formatYYYYMMDD(select.StartDate));
      form.append("EndDate", formatYYYYMMDD(select.EndDate));
      form.append("NatureOfCostAvoidance", select.NatureOfCostAvoidance.value);
      form.append("RoadMapNo", select.RoadMapNo.value)
      form.append("IsDraft", false);
      form.append("FormApprovers", JSON.stringify(data.form_approval));

      for (let i = 0; i < data.uploadedFiles.length; i++) {
            form.append("Others", data.uploadedFiles[i]);
            others_description = data.uploadedFilesAttachType[i];
      }

      form.append("OthersDescription", others_description);

      return await makePOST('TUCNSavingsDeclarationSheetForm/Review', form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } catch (error) {
      tinyAlert("error", 'An error occurred!');
      console.error('Error:', error);
    }
  }

  async fetchFormById(id: string): Promise<any> {
    try{
      return await makeREQUEST("GET", "TUCNSavingsDeclarationSheetForm/" + id)
      .then((res) => {
        return res.data.data;

      })

    } catch (error) {
      tinyAlert("error", 'there is an error');
      console.error('Error:', error);
    }
  }

  async fetchApprovalSequence(data: any): Promise<any> {
    try{
      return await makeREQUEST("GET", `TUCNSavingsDeclarationSheetForm/GetApprovalSequence?IsTUCNService=${data.IsTUCNService}`)
      .then((res) => {
        tinyAlert("success", "approval sequence updated!");
        return res.data.data;
      })
    } catch (error) {
      tinyAlert("error", printError(error));
      console.error('Error:', error);
    }
  }

  async deleteForm(id: string): Promise<any> {
    try{
      return await makeREQUEST("DELETE", "TUCNSavingsDeclarationSheetForm/" + id)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;

      })

    } catch (error) {
      tinyAlert("error", 'there is an error');
      console.error('Error:', error);
    }
  }


};

const aaf = new TUCNSavingsDeclarationSheetFormService();
export default aaf;