import { makeREQUEST, tinyAlert, printError, } from "../_config.services";

class AuthenticationService {

  async fetchRefreshToken(): Promise<any> {
    try{
      return await makeREQUEST('POST', 'Authentication/RefreshToken', {}, 'json', false)
      .then((res) => {
        return res.data?.data;
      })

    } catch (error) {
      console.error('Error:', error);
      tinyAlert("error", printError(error));
      return false;      
    }
  }

  async ConsentToPersonalDataAndCookies(): Promise<any> {
    try{
      return await makeREQUEST('POST', 'User/Consent', {}, 'json', false)
      .then((res) => {
        tinyAlert("success", res.data?.message);
        return true;
      })
    } catch (error) {
      tinyAlert("error", printError(error));
      return false;
    }
  }
};

const auth = new AuthenticationService();
export default auth;