import React from 'react';
import { useEffect, useState } from 'react';
import { _$http, axiosError, closeAlert, preloader } from '../../utils';
import { ThreeDots, XMark } from '../../components/Icons';
import Select from "react-select";
import { ConstantProps, OptionProps } from '../../interfaces/forms.interface';
import { Link } from 'react-router-dom';
import api from '../../services/form.services';

function Workflows() {
  const [toggleCreateModal, setToggleCreateModal] = useState(false)
  const [toggleEditModal, setToggleEditModal] = useState(false)
  const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false);
  const [toggleAddDepartmentModal, setToggleAddDepartmentModal] = useState(false)
  const [toggleRemoveDepartmentModal, setToggleRemoveDepartmentModal] = useState(false)
  const [departments, setDepartments] = useState([])
  const [workflowDepartments, setWorkflowDepartments] = useState<OptionProps[]>([])
  const [toggleAddThresholdModal, setToggleAddThresholdModal] = useState(false)
  const [toggleRemoveThresholdModal, setToggleRemoveThresholdModal] = useState(false)
  const [thresholds, setThresholds] = useState([])
  const [workflowThresholds, setWorkflowThresholds] = useState<OptionProps[]>([])
  const [formData, setFormData] = useState([])
  const [formInputs, setFormInputs] = useState({
    name: "", isEnabled: false
  })
  const [selectedStatus, setSelectedStatus] = useState<OptionProps>({ label: "Select...", value: null, name: "isEnabled" })
  const [workflowId, setWorkflowId] = useState(null)
  const [departmentId, setDepartmentId] = useState(null)
  const [thresholdId, setThresholdId] = useState(null)

  const statuses: OptionProps[] = [
    { label: "Enable", value: true, name: "isEnabled" },
    { label: "Disable", value: false, name: "isEnabled" },
  ];

  const handleSelect = (fieldName: string, selectedOptions: any) => {
    setFormInputs({ ...formInputs, [fieldName]: selectedOptions?.value });
    setSelectedStatus({ label: selectedOptions?.value ? "Enable" : "Disable", value: selectedOptions?.value, name: "isEnabled" })
  };

  const handleAddDepartment = (e: any) => {
    e.preventDefault()
    preloader();
    _$http('POST', 'workflowdepartment', {
      workflowId, departmentId
    }).then(res => {
      closeAlert();
      setToggleAddDepartmentModal(false)
      getWorkflows()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleRemoveDepartment = (e: any) => {
    e.preventDefault()
    preloader();
    _$http('DELETE', 'workflowdepartment', {
      workflowId, departmentId
    }).then(res => {
      closeAlert();
      setToggleRemoveDepartmentModal(false)
      getWorkflows()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleDepartment = (selectedOptions: any) => {
    setDepartmentId(selectedOptions?.value)
  };

  const handleAddDepartmentShow = (item: any) => {
    setWorkflowId(item.id)
    let workflowDepartmentIds = item.workflowDepartments?.map((a: any) => a.department?.id)
    let newDepartments = departments.filter((a: OptionProps) => !workflowDepartmentIds.includes(a.value))
    setWorkflowDepartments(newDepartments);
  }

  const handleRemoveDepartmentShow = (item: any) => {
    setWorkflowId(item.id)
    let workflowDepartmentIds = item.workflowDepartments?.map((a: any) => a.department?.id)
    let newDepartments = departments.filter((a: OptionProps) => workflowDepartmentIds.includes(a.value))
    setWorkflowDepartments(newDepartments);
  }

  useEffect(() => { getWorkflows(); api.fetchDepartment().then((res:any) => setDepartments(res)); getWorkflowThresholds(); }, []);

  const getWorkflows = () => {
    preloader();
    _$http('GET', 'Workflow').then(res => {
      closeAlert();
      setFormData(res.data.data);
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const getWorkflowThresholds = () => {
    preloader();
    _$http('GET', 'WorkflowThresholds').then(res => {
      closeAlert();
      setThresholds(res.data.data.map(
        (i: ConstantProps) => ({
          label: i.name,
          value: i.id,
          name: "thresholds",
        })
      ));
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormInputs({ ...formInputs, [name]: value })
  }

  const handleAddThreshold = (e: any) => {
    e.preventDefault()
    preloader();
    _$http('POST', `WorkflowThresholds/AddWorkflowThresholdsToWorkflow/${workflowId}`, {
       workflowThresholdIds: [
        thresholdId
      ]
    }).then(res => {
      closeAlert();
      setToggleAddThresholdModal(false)
      getWorkflows()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleRemoveThreshold = (e: any) => {
    e.preventDefault()
    preloader();
    _$http('POST', `WorkflowThresholds/RemoveWorkflowThresholdsFromWorkflow/${workflowId}`, {
       workflowThresholdIds: [
        thresholdId
      ]
    }).then(res => {
      closeAlert();
      setToggleRemoveThresholdModal(false)
      getWorkflows()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleThreshold = (selectedOptions: any) => {
    setThresholdId(selectedOptions?.value)
  };

  const handleAddThresholdShow = (item: any) => {
    setWorkflowId(item.id)
    let workflowThresholdIds = item.workflowWithThresholds?.map((a: any) => a.workflowThreshold?.id)
    let newThresholds = thresholds.filter((a: OptionProps) => !workflowThresholdIds.includes(a.value))
    setWorkflowThresholds(newThresholds);
  }

  const handleRemoveThresholdShow = (item: any) => {
    setWorkflowId(item.id)
    let workflowThresholdIds = item.workflowWithThresholds?.map((a: any) => a.workflowThreshold?.id)
    let newThresholds = thresholds.filter((a: OptionProps) => workflowThresholdIds.includes(a.value))
    setWorkflowThresholds(newThresholds);
  }

  // const handleEditFields = (name: any, id: any) => {
  //   setFormInputs({ ...formInputs, name: name })
  //   setWorkflowId(id)
  // }

  const handleStatus = (item: any) => {
    setFormInputs({ ...formInputs, isEnabled: item.isEnabled, name: item.name })
    setSelectedStatus({ label: item.isEnabled ? "Enable" : "Disable", value: item.isEnabled, name: "isEnabled" })
    setWorkflowId(item.id)
  }


  const handleSubmit = (e: any) => {
    e.preventDefault()
    preloader();
    _$http('POST', 'Workflow', formInputs).then(res => {
      closeAlert();
      setToggleCreateModal(false)
      getWorkflows()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleUpdate = (e: any) => {
    e.preventDefault()

    preloader();
    _$http('PATCH', `Workflow/${workflowId}/EnableDisable`, { ...formInputs, "workflowId": workflowId }).then(res => {
      closeAlert();
      setToggleEditModal(false)
      setWorkflowId(null)
      getWorkflows()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  const handleDelete = () => {
    preloader();
    setToggleConfirmationModal(false)
    _$http('DELETE', 'Workflow/' + workflowId).then(res => {
      closeAlert();
      setWorkflowId(null)
      const message = res.data.message
      console.log(message)
      getWorkflows()
    })
      .catch(error => {
        axiosError(error);
      })
  };

  return (
    <div className='bg-white w-full shadow-sm rounded-lg py-6'>
      <div className='flex justify-between px-6'>
        {/* <button onClick={() => setToggleCreateModal(true)} className='red-button rounded px-4 py-2 text-sm'>Create Workflow</button> */}
        <Link to={"/admin/workflow-thresholds"}>View Thresholds</Link>
      </div>

      <div className="relative overflow-x-auto py-4" style={{ minHeight: "600px" }}>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                SN
              </th>
              <th scope="col" className="px-6 py-3">
                Workflow
              </th>
              <th scope="col" className="px-6 py-3">
                Thresholds
              </th>
              <th scope="col" className="px-6 py-3">
                Department
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {
              formData.map((item: any, index: number) => {
                return (
                  <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="px-6 py-4">
                      {index + 1}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.name}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.workflowWithThresholds?.map((a: any, i: any) => {
                        return (
                          <span key={i}>
                              {a.workflowThreshold?.name}
                              <br/>
                          </span>
                       )
                        })}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.workflowDepartments?.map((a: any, i: any) => {
                        return (
                          <span key={i}>
                              {a.department?.name}
                              <br/>
                          </span>
                       )
                        })}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.isEnabled ? 'Active' : 'Disabled'}
                    </th>
                    <td className="px-6 py-4">
                      <div className="dropdown">
                        <button title='More Options' className='text-blue-600 dropbtn'>
                          <ThreeDots />
                        </button>
                        <div className="dropdown-content" style={{ left: "0" }}>
                          <button onClick={() => { setToggleEditModal(true); handleStatus(item) }}>Change Status</button>
                          <Link to={"/admin/approval-sequences?workflowId=" + item.id + "&workflowName=" + item.name}>Manage Approval Sequence</Link>
                          <button onClick={() => { setToggleAddDepartmentModal(true); handleAddDepartmentShow(item) }}>Add Department</button>
                          <button onClick={() => { setToggleRemoveDepartmentModal(true); handleRemoveDepartmentShow(item) }}>Remove Department</button>
                          <button onClick={() => { setToggleAddThresholdModal(true); handleAddThresholdShow(item) }}>Add Threshold</button>
                          <button onClick={() => { setToggleRemoveThresholdModal(true); handleRemoveThresholdShow(item) }}>Remove Threshold</button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      {
        toggleCreateModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Create Workflow</h2>
              <button onClick={() => setToggleCreateModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Workflow</label>
                <input onChange={handleInputChange} type="text" name='name' required style={{ minWidth: "300px" }} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
              </div>

              <div className='modal-footer flex justify-end'>
                <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Submit</button>
              </div>
            </form>
          </div>
        </div>
      }
      {
        toggleEditModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Edit Workflow</h2>
              <button onClick={() => setToggleEditModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form onSubmit={handleUpdate}>
              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Workflow</label>
                {/* <input onChange={handleInputChange} value={formInputs.name} type="text" name='name' required style={{ minWidth: "300px" }} className="w-full bg-white-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-800 focus:border-blue-800 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" /> */}
                <Select
                  id="currency"
                  value={selectedStatus}
                  options={statuses}
                  isMulti={false}
                  isSearchable={false}
                  onChange={(e) => handleSelect("isEnabled", e)}
                  className="font-normal"
                  placeholder="Select..."
                  required
                />
              </div>

              <div className='modal-footer flex justify-end'>
                <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update</button>
              </div>
            </form>
          </div>
        </div>
      }
      {
        toggleConfirmationModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-md">Are you sure you want to delete this workflow? This cannot be reversed and may impact the system performance.</h2>
              <button onClick={() => setToggleConfirmationModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <div className="modal-content text-left px-6 flex justify-center gap-2 mt-4">
              <button onClick={() => setToggleConfirmationModal(false)} type="button" className="rounded text-black bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">Cancel</button>
              <button onClick={() => handleDelete()} type="button" className="rounded text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Yes</button>
            </div>
          </div>
        </div>
      }
      {
        toggleAddDepartmentModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Add Department</h2>
              <button onClick={() => setToggleAddDepartmentModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form onSubmit={handleAddDepartment}>
              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-whitee">Department</label>
                <Select
                  // value={selectedStatus}
                  options={workflowDepartments}
                  isMulti={false}
                  isSearchable={false}
                  onChange={(e) => handleDepartment(e)}
                  className="font-normal"
                  placeholder="Select..."
                  required
                />
              </div>

              <div className='modal-footer flex justify-end'>
                <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Add</button>
              </div>
            </form>
          </div>
        </div>
      }
      {
        toggleRemoveDepartmentModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Remove Department</h2>
              <button onClick={() => setToggleRemoveDepartmentModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form onSubmit={handleRemoveDepartment}>
              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-whitee">Department</label>
                <Select
                  // value={selectedStatus}
                  options={workflowDepartments}
                  isMulti={false}
                  isSearchable={false}
                  onChange={(e) => handleDepartment(e)}
                  className="font-normal"
                  placeholder="Select..."
                  required
                />
              </div>

              <div className='modal-footer flex justify-end'>
                <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Remove</button>
              </div>
            </form>
          </div>
        </div>
      }
      {
        toggleAddThresholdModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Add Threshold</h2>
              <button onClick={() => setToggleAddThresholdModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form onSubmit={handleAddThreshold}>
              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-whitee">Threshold</label>
                <Select
                  // value={selectedStatus}
                  options={workflowThresholds}
                  isMulti={false}
                  isSearchable={false}
                  onChange={(e) => handleThreshold(e)}
                  className="font-normal"
                  placeholder="Select..."
                  required
                />
              </div>

              <div className='modal-footer flex justify-end'>
                <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Add</button>
              </div>
            </form>
          </div>
        </div>
      }
      {
        toggleRemoveThresholdModal &&
        <div className="fixed inset-0 flex items-center justify-center z-50" style={{ background: 'rgb(0 0 0 / 52%)' }}>
          <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4">
            <div className='flex justify-between'>
              <h2 className="text-lg">Remove Threshold</h2>
              <button onClick={() => setToggleRemoveThresholdModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
            </div>

            <form onSubmit={handleRemoveThreshold}>
              <div className="modal-content my-4">
                <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-whitee">Threshold</label>
                <Select
                  // value={selectedStatus}
                  options={workflowThresholds}
                  isMulti={false}
                  isSearchable={false}
                  onChange={(e) => handleThreshold(e)}
                  className="font-normal"
                  placeholder="Select..."
                  required
                />
              </div>

              <div className='modal-footer flex justify-end'>
                <button type="submit" className="text-white rounded bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Remove</button>
              </div>
            </form>
          </div>
        </div>
      }
    </div>
  );
}

export default Workflows;
