import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CloseNav } from "../../interfaces";
import { FormDetails } from "../../interfaces/forms.interface";
import api from "../../services/form.services";
import { FaTimes } from "react-icons/fa";
import { LuAlignJustify, LuX } from "react-icons/lu";
import { APP_ENV } from "../../utils";

const SideNav = ({ closeNav }: CloseNav) => {
  const location = useLocation();

  // const account_type = location.pathname.split("/")[1];
  const active_path = location.pathname.split("/")[2];
  const secondary_path = location.pathname.split("/")[3];
  const formType = location.pathname.split("/")[4];

  const [isSubNav, setIsSubNav] = useState(false);
  const [isMainSubNav, setMainSubNav] = useState("hidden");

  const [subNavLabel, setSubNavLabel] = useState("");
  const [crudAction, setCrudAction] = useState("");
  const [formDescription, setFormDescription] = useState<FormDetails[]>();

  const openSubNav = (label: string, type: string) => {
    setIsSubNav(true);
    setSubNavLabel(label);
    setCrudAction(type);
  };

  const closeSubNav = () => {
    setIsSubNav(false);
  };

  useEffect(() => {
    if (closeNav) setIsSubNav(false);
  }, [closeNav]);

  useEffect(() => {
    api.fetchAvailableFormsCount().then((res) => setFormDescription(res));
  }, []);

  return (
    <div>
      <div className="block sm:hidden">
        <button
          onClick={() =>
            setMainSubNav(isMainSubNav === "hidden" ? "" : "hidden")
          }
          className="bg-gray-300 shadow p-1 left-[15px] top-[15px] fixed z-9999"
        >
          {isMainSubNav === "hidden" ? (
            <LuAlignJustify />
          ) : (
            <LuX className="text-red-600" />
          )}
        </button>
      </div>

      <div className={`sm:flex dark:bg-[#414b5a] ${isMainSubNav}`}>
        <nav className={`nav-area bg-white border-r border-gray-200 h-screen overflow-hidden dark:bg-[#414b5a] dark:border-[#374151] ${APP_ENV !== "prod"  ? 'mt-[30px]':'mt-0' }`}>
          <div className="shadow-lg mb-3 flex justify-center items-center dark:rounded-[3px] border-[#f6f8fc] border-b">
            <img src="/logo.png" alt="logo_image" className={APP_ENV !== "prod"  ? 'w-[90%]':'' }/>
          </div>

          {/* side nav - links */}
          <ul className="font-medium mb-5">
            <li className="mb-3 mt-5">
              <Link
                to="/approval/approval-dashboard"
                className={
                  ["approval-dashboard"].includes(active_path)
                    ? "active block py-3 text-center"
                    : "block py-3 text-center"
                }
              >
                <div className="flex justify-center text-3xl">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M32 32c17.7 0 32 14.3 32 32V400c0 8.8 7.2 16 16 16H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-44.2 0-80-35.8-80-80V64C0 46.3 14.3 32 32 32zM160 224c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm128-64V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V160c0-17.7 14.3-32 32-32s32 14.3 32 32zm64 32c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32zM480 96V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V96c0-17.7 14.3-32 32-32s32 14.3 32 32z" />
                  </svg>
                </div>
                <label className="block px-3">Stats</label>
              </Link>
            </li>

            <li className="mb-3">
              <span
                onClick={() => openSubNav("Pending Approval", "pending")}
                className={
                  (["create", "pending"].includes(secondary_path)
                    ? "active"
                    : "") +
                  " block py-3 text-center hover:bg-gray-50 cursor-pointer"
                }
              >
                <div className="flex justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M48 96V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V170.5c0-4.2-1.7-8.3-4.7-11.3l33.9-33.9c12 12 18.7 28.3 18.7 45.3V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H309.5c17 0 33.3 6.7 45.3 18.7l74.5 74.5-33.9 33.9L320.8 84.7c-.3-.3-.5-.5-.8-.8V184c0 13.3-10.7 24-24 24H104c-13.3 0-24-10.7-24-24V80H64c-8.8 0-16 7.2-16 16zm80-16v80H272V80H128zm32 240a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z" />
                  </svg>
                </div>
                <label className="block mt-1 px-3">Pending Approval</label>
              </span>
            </li>

            <li className="">
              <span
                onClick={() => openSubNav("Approval History", "history")}
                className={
                  (["history", "details"].includes(secondary_path)
                    ? "active"
                    : "") +
                  " block py-3 text-center hover:bg-gray-50  cursor-pointer"
                }
              >
                <div className="flex justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M0 96C0 60.7 28.7 32 64 32H196.1c19.1 0 37.4 7.6 50.9 21.1L289.9 96H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16H286.6c-10.6 0-20.8-4.2-28.3-11.7L213.1 87c-4.5-4.5-10.6-7-17-7H64z" />
                  </svg>
                </div>
                <label className="block mt-1 px-3">Approval History</label>
              </span>
            </li>
          </ul>
        </nav>

        {
          /* sub nav section */
          isSubNav && (
            <nav className="fixed nav-forms overflow-y-auto shadow-sm dark:bg-[#414b5a]">
              <div className="modal-container">
                <div
                  className="flex justify-between shadow-sm dark:bg-gray-700 bg-white dark:text-gray-300 dark:border-[#474747] border-b border-[#d6d6d6]"
                  style={{ position: "fixed", width: "250px", padding: "2px" }}
                >
                  <div className="modal-content py-5 text-left px-5 w-full">
                    <div className="flex justify-between">
                      <h2 className="text-sm font-bold mt-9 block uppercase">
                        {subNavLabel}
                      </h2>
                      <button
                        className="text-red-500 text-md font-bold mt-6 block text-end bg-gray-100 p-2 border hover:shadow"
                        onClick={closeSubNav}
                      >
                        <FaTimes />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="modal-content mb-4 text-left pb-4 capitalize">
                  <ul
                    style={{ paddingTop: "95px" }}
                    className="shadow-sm dark:bg-gray-700 bg-white dark:text-gray-300 dark:border-[#474747]"
                  >
                    <li className="mb-1">
                      <Link
                        onClick={closeSubNav}
                        to={`/approval/forms/${crudAction}/admin-amendment-form`}
                        className={
                          ([
                            "admin-amendment-form"
                              .toLocaleLowerCase()
                              .replace(/ /g, "-")
                              .replace(/\//g, "-"),
                          ].includes(formType)
                            ? "active"
                            : "") +
                          " block py-4 px-5 text-left hover:text-[#fff] hover:bg-[#e0424d] dark:hover:bg-[#162c51] dark:text-gray-300 flex justify-between text-sm"
                        }
                      >
                        <span>admin amendment form</span>
                        <div>
                          {crudAction === "pending" && (
                            <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                              {
                                formDescription?.find(
                                  (item: FormDetails) =>
                                    item.name
                                      .toLocaleLowerCase()
                                      .replace(/ /g, "-")
                                      .replace(/\//g, "-") ===
                                    "admin-amendment-form"
                                )?.pendingCount
                              }

                              {/* {JSON.stringify(formDescription)} */}
                            </span>
                          )}
                        </div>
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link
                        onClick={closeSubNav}
                        to={`/approval/forms/${crudAction}/cpnc-routing-slip`}
                        className={
                          ([
                            "cpnc-routing-slip"
                              .toLocaleLowerCase()
                              .replace(/ /g, "-")
                              .replace(/\//g, "-"),
                          ].includes(formType)
                            ? "active"
                            : "") +
                          " block py-4 px-5 text-left hover:text-[#fff] hover:bg-[#e0424d] dark:hover:bg-[#162c51] dark:text-gray-300 flex justify-between text-sm"
                        }
                      >
                        <span>CPNC routing slip</span>
                        <div>
                          {crudAction === "pending" && (
                            <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                              {
                                formDescription?.find(
                                  (item: FormDetails) =>
                                    item.name
                                      .toLocaleLowerCase()
                                      .replace(/ /g, "-")
                                      .replace(/\//g, "-") ===
                                    "cpnc-routing-slip"
                                )?.pendingCount
                              }
                            </span>
                          )}
                        </div>
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link
                        onClick={closeSubNav}
                        to={`/approval/forms/${crudAction}/routing-slip-for-tas-contracts`}
                        className={
                          ([
                            "routing-slip-for-tas-contracts"
                              .toLocaleLowerCase()
                              .replace(/ /g, "-")
                              .replace(/\//g, "-"),
                          ].includes(formType)
                            ? "active"
                            : "") +
                          " block py-4 px-5 text-left hover:text-[#fff] hover:bg-[#e0424d] dark:text-gray-300 dark:hover:bg-[#162c51] flex justify-between text-sm"
                        }
                      >
                        <span>routing slip for TAS contracts</span>
                        <div>
                          {crudAction === "pending" && (
                            <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                              {
                                formDescription?.find(
                                  (item: FormDetails) =>
                                    item.name
                                      .toLocaleLowerCase()
                                      .replace(/ /g, "-")
                                      .replace(/\//g, "-") ===
                                    "routing-slip-for-tas-contracts"
                                )?.pendingCount
                              }
                            </span>
                          )}
                        </div>
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link
                        onClick={closeSubNav}
                        to={`/approval/forms/${crudAction}/contract-approval-form-admin-amendment-for-decentralized-purchasing`}
                        className={
                          ([
                            "contract-approval-form-admin-amendment-for-decentralized-purchasing"
                              .toLocaleLowerCase()
                              .replace(/ /g, "-")
                              .replace(/\//g, "-"),
                          ].includes(formType)
                            ? "active"
                            : "") +
                          " block py-4 px-5 text-left hover:text-[#fff] dark:text-gray-300 dark:hover:bg-[#162c51] hover:bg-[#e0424d] flex justify-between text-sm"
                        }
                      >
                        <span>
                          contract approval form admin amendment for
                          decentralized purchasing
                        </span>
                        <div>
                          {crudAction === "pending" && (
                            <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                              {
                                formDescription?.find(
                                  (item: FormDetails) =>
                                    item.name
                                      .toLocaleLowerCase()
                                      .replace(/ /g, "-")
                                      .replace(/\//g, "-") ===
                                    "contract-approval-form-admin-amendment-for-decentralized-purchasing"
                                )?.pendingCount
                              }
                            </span>
                          )}
                        </div>
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link
                        onClick={closeSubNav}
                        to={`/approval/forms/${crudAction}/contract-approval-form`}
                        className={
                          ([
                            "contract-approval-form"
                              .toLocaleLowerCase()
                              .replace(/ /g, "-")
                              .replace(/\//g, "-"),
                          ].includes(formType)
                            ? "active"
                            : "") +
                          " block py-4 px-5 text-left hover:text-[#fff] hover:bg-[#e0424d] dark:hover:bg-[#162c51] dark:text-gray-300 flex justify-between text-sm"
                        }
                      >
                        <span>contract approval form</span>
                        <div>
                          {crudAction === "pending" && (
                            <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                              {
                                formDescription?.find(
                                  (item: FormDetails) =>
                                    item.name
                                      .toLocaleLowerCase()
                                      .replace(/ /g, "-")
                                      .replace(/\//g, "-") ===
                                    "contract-approval-form"
                                )?.pendingCount
                              }
                            </span>
                          )}
                        </div>
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link
                        onClick={closeSubNav}
                        to={`/approval/forms/${crudAction}/routing-slip-for-material-purchase`}
                        className={
                          ([
                            "routing-slip-for-material-purchase"
                              .toLocaleLowerCase()
                              .replace(/ /g, "-")
                              .replace(/\//g, "-"),
                          ].includes(formType)
                            ? "active"
                            : "") +
                          " block py-4 px-5 text-left hover:text-[#fff] hover:bg-[#e0424d] dark:text-gray-300 dark:hover:bg-[#162c51] flex justify-between text-sm"
                        }
                      >
                        <span>routing slip for material purchase</span>
                        <div>
                          {crudAction === "pending" && (
                            <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                              {
                                formDescription?.find(
                                  (item: FormDetails) =>
                                    item.name
                                      .toLocaleLowerCase()
                                      .replace(/ /g, "-")
                                      .replace(/\//g, "-") ===
                                    "routing-slip-for-material-purchase"
                                )?.pendingCount
                              }
                            </span>
                          )}
                        </div>
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link
                        onClick={closeSubNav}
                        to={`/approval/forms/${crudAction}/routing-slip-for-deviation-from-standard-template`}
                        className={
                          ([
                            "routing-slip-for-deviation-from-standard-template"
                              .toLocaleLowerCase()
                              .replace(/ /g, "-")
                              .replace(/\//g, "-"),
                          ].includes(formType)
                            ? "active"
                            : "") +
                          " block py-4 px-5 text-left hover:text-[#fff] dark:text-gray-300 dark:hover:bg-[#162c51] hover:bg-[#e0424d] flex justify-between text-sm"
                        }
                      >
                        <span>
                          routing slip for deviation from standard template
                        </span>
                        <div>
                          {crudAction === "pending" && (
                            <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                              {
                                formDescription?.find(
                                  (item: FormDetails) =>
                                    item.name
                                      .toLocaleLowerCase()
                                      .replace(/ /g, "-")
                                      .replace(/\//g, "-") ===
                                    "routing-slip-for-deviation-from-standard-template"
                                )?.pendingCount
                              }
                            </span>
                          )}
                        </div>
                      </Link>
                    </li>
                    <li className="mb-1 hidden">
                      <Link
                        onClick={closeSubNav}
                        to={`/approval/forms/${crudAction}/tucn-savings-declaration-sheet`}
                        className={
                          ([
                            "tucn-savings-declaration-sheet"
                              .toLocaleLowerCase()
                              .replace(/ /g, "-")
                              .replace(/\//g, "-"),
                          ].includes(formType)
                            ? "active"
                            : "") +
                          " block py-4 px-5 text-left hover:text-[#fff] dark:text-gray-300 hover:bg-[#e0424d] dark:hover:bg-[#162c51] flex justify-between text-sm"
                        }
                      >
                        
                        <span>TUCN savings declaration sheet</span>
                        <div>
                          {crudAction === "pending" && (
                            <span className="bg-red-600 text-white rounded-sm font-semibold px-1 ml-8">
                              {
                                formDescription?.find(
                                  (item: FormDetails) =>
                                    item.name
                                      .toLocaleLowerCase()
                                      .replace(/ /g, "-")
                                      .replace(/\//g, "-") ===
                                    "tucn-savings-declaration-sheet"
                                )?.pendingCount
                              }
                            </span>
                          )}
                        </div>

                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          )
        }
      </div>
    </div>
  );
};

export default SideNav;
