import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  CartesianGrid,
} from "recharts";

interface BarChartComponentProps {
  data: {
    [key: string]: { total: number; statuses: { [key: string]: number } };
  };
}

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
          fontSize: "10px",
        }}
      >
        <p className="label" style={{ margin: 0 }}>{`${label}`}</p>
        {payload.map((entry, index) => (
          <p
            key={index}
            style={{ margin: 0, color: entry.color }}
          >{`${entry.name}: ${entry.value}`}</p>
        ))}
      </div>
    );
  }

  return null;
};

const BarChartComponent: React.FC<BarChartComponentProps> = ({ data }) => {
  const chartData = Object.keys(data).map((department) => ({
    department,
    ...data[department].statuses,
  }));

  return (
    <ResponsiveContainer width="100%" height={230}>
      <BarChart data={chartData}>
        <CartesianGrid strokeDasharray="2 4" vertical={false} />

        <XAxis dataKey="department" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />

        <Bar dataKey="PENDING" fill="#216ba5" radius={[10, 10, 0, 0]} />
        <Bar dataKey="CLARIFY" fill="#ca8a04" radius={[10, 10, 0, 0]} />
        <Bar dataKey="RESTART" fill="#2563eb" radius={[10, 10, 0, 0]} />
        <Bar dataKey="APPROVED" fill="#008000" radius={[10, 10, 0, 0]} />
        <Bar dataKey="DECLINED" fill="#FF0000" radius={[10, 10, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
