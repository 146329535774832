/**
 * path: /forms/pending/:ID
 * last-refactored: yes - 14/02/2024
 */
import { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { formatDateTime } from "../../utils";
import { FaAngleLeft, FaAngleRight, FaMagnifyingGlass } from "react-icons/fa6";
import { Eye } from "../../components/Icons";
import api from "../../services/form.services";
import { formTypesID } from "../../utils/data";

function PendingForms() {
  var param = useParams();
    const location = useLocation();

  const formTypeParam: string = !!param.formType ? param.formType : "";
  const formTypeId: any = formTypesID.find(
    (x: any) => x.name === formTypeParam
  )?.type;

  const [formRecord, setFormRecord] = useState([]);
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const [OrderBy] = useState(2);
  const [SearchString, setSearchString] = useState("");
  const [activePath, setActivePath] = useState("");
  const [ApprovalStatus, setApprovalStatus] = useState("3");
  const [TotalCount, setTotalCount] = useState(0);
  const [PageIndex, setPageIndex] = useState(0);
  const [HasNextPage, setHasNextPage] = useState(false);
  const [HasPreviousPage, setHasPreviousPage] = useState(false);

  useEffect(() => {
    const active_path = location.pathname.split("/")[3];
    setActivePath(active_path);
    console.log(active_path);
  }, [location]);

  useEffect(() => {
    if(activePath === "history"){ 
      setApprovalStatus("");
    }
  }, [activePath]);

  useEffect(() => {
    fetchPendingHistory(
      formTypeId,
      Page,
      PageSize,
      OrderBy,
      SearchString,
      ApprovalStatus
    );
  }, [formTypeId, Page, PageSize, OrderBy, SearchString, ApprovalStatus]);

  const fetchPendingHistory = (
    formTypeId: number,
    Page: number,
    PageSize: number,
    OrderBy: number,
    SearchString: string,
    ApprovalStatus: string
  ) => {
    if (!!SearchString && SearchString.length < 3) {
      return;
    }

    api
      .fetchPendingHistory(
        formTypeId,
        Page,
        PageSize,
        OrderBy,
        SearchString,
        ApprovalStatus
      )
      .then((res) => {
        setFormRecord(res.data);
        setTotalCount(res.totalCount);
        setHasNextPage(res.hasNextPage);
        setHasPreviousPage(res.hasPreviousPage);
        setPageIndex(res.pageIndex);
      });
  };

  return (
    <div className="bg-white w-full shadow-sm rounded-lg py-4 dark:bg-gray-800">
      <div className="flex justify-between mb-3">
        <div className="flex gap-2 px-4 w-2/5">
          <div className="relative w-full">
            <input
              onChange={(e) => setSearchString(e.target.value)}
              type="text"
              name="search"
              className="border w-full pl-8 px-3 py-2 text-sm mr-1 dark:bg-gray-800 dark:text-gray-300"
              placeholder="Search Forms by Form ID & Form Title"
            />
            <FaMagnifyingGlass className="text-gray-400 absolute top-[10px] left-[10px]" />
          </div>

          {activePath === "history" && (
            <select
              className="border p-1 text-xs text-gray-500 dark:bg-gray-800 dark:text-gray-300"
              onChange={(e) => setApprovalStatus(e.target.value)}
            >
              <option value="">All Status</option>
              <option value="1">Saved in Draft</option>
              <option value="3">Pending</option>
              <option value="6">Approved</option>
              <option value="5">Restart</option>
              <option value="4">Clarification</option>
              <option value="7">Rejected</option>
            </select>
          )}
        </div>

        <div className="text-xs text-gray-400 pr-8 flex gap-8">
          <select
            value={PageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            id="entries"
            className="border p-1 text-xs dark:bg-gray-800 dark:text-gray-300"
          >
            <option value="3">Show 3 entries</option>
            <option value="10">Show 10 entries</option>
            <option value="25">Show 25 entries</option>
            <option value="50">Show 50 entries</option>
            <option value="50">Show 100 entries</option>
          </select>

          <span className="mt-3">
            {formRecord?.length > 0 ? PageSize * (PageIndex - 1) + 1 : 0}-
            {PageSize * (PageIndex - 1) + formRecord?.length} of {TotalCount}
          </span>

          <span className="flex gap-5 mt-3">
            <FaAngleLeft
              onClick={() => HasPreviousPage && setPage(PageIndex - 1)}
              className={`${
                HasPreviousPage && "text-gray-900 hover-arrow-active"
              }`}
            />
            <FaAngleRight
              onClick={() => HasNextPage && setPage(PageIndex + 1)}
              className={`${HasNextPage && "text-gray-900 hover-arrow-active"}`}
            />
          </span>
        </div>
      </div>

      <div
        className="relative overflow-y-scroll overflow-x-none table-scroll-bar"
        style={{ height: "calc(100vh - 190px)" }}
      >
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 hidden">
                SN
              </th>
              <th scope="col" className="px-6 py-3">
                Form ID
              </th>
              <th scope="col" className="px-6 py-3">
                {[4, 5, 6, 7].includes(formTypeId)
                  ? "Description"
                  : "Contract Title"}
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Date Initiated
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {formRecord?.map((item: any, index: number) => {
              return (
                <tr
                  key={index}
                  className="bg-white border-t dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50"
                >
                  <th scope="row" className="px-6 py-3 hidden">
                    {index + 1 + PageSize * (PageIndex - 1)}
                  </th>
                  <td className="px-6 py-3">{item.generatedFormId}</td>
                  <td className="px-6 py-3">{item.contractTitle}</td>
                  <td className="px-6 py-3">{item.status}</td>
                  <td className="px-6 py-3">
                    {formatDateTime(item.createdDate)}
                  </td>
                  <td className="px-6 py-3 flex">
                    <Link
                      to={`/approval/forms/details/${formTypeParam}/${item.formId}/${item.formApproverId}`}
                      className="text-blue-600 flex gap-2"
                    >
                      <Eye /> <span>View</span>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {formRecord?.length < 1 && (
          <p className="text-center p-5 text-xs">No record available...</p>
        )}
      </div>
    </div>
  );
}

export default PendingForms;
