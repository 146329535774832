import React from "react";
import { LuFileHeart, LuFileLock, LuFileSignature } from "react-icons/lu";

function Manual() {
  return (
    <div className="flex gap-5">
      <a
        href="/user-manual-1.pdf"
        target="_blank"
        className="bg-white p-12 shadow-sm border rounded-sm text-center"
      >
        <div className="text-[30px] text-gray-600 justify-center flex">
          <LuFileHeart />
        </div>
        <p className="text-sm mt-5">
          User manual <br />
          for initiators
        </p>
      </a>

      <a
        href="/user-manual-3.pdf"
        target="_blank"
        className="bg-white p-12 shadow-sm border rounded-sm text-center"
      >
        <div className="text-[30px] text-gray-600 justify-center flex">
          <LuFileSignature />
        </div>
        <p className="text-sm mt-5">
          User manual <br />
          for aprovals
        </p>
      </a>

      <a
        href="/user-manual-2.pdf"
        target="_blank"
        className="bg-white p-12 shadow-sm border rounded-sm text-center"
      >
        <div className="text-[30px] text-gray-600 justify-center flex">
          <LuFileLock />
        </div>
        <p className="text-sm mt-5">
          User manual <br />
          for administrators
        </p>
      </a>
    </div>
  );
}

export default Manual;
