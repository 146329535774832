import { useState, useEffect, useCallback, FormEvent, useRef } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useDropzone } from "react-dropzone";
import TextField from "@mui/material/TextField";
import { XMark } from "../../../components/Icons";
import api from "../../../services/form.services";
import { formTypesID } from "../../../utils/data";
import { Label, LabelXS } from "../../../components/FormUI";
import FormNotes from "../../../components/FormNotes";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "@mui/material/Autocomplete";
import CurrencyInput from "react-currency-input-field";
import FormDescription from "../../../components/FormDescription";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ApprovalSequenceFlow from "../../../components/ApprovalSequenceFlow";
import FormApprovalOverlay from "../../../components/FormApprovalOverlay";
import { form_input_style, twoDecimalPlace } from "../../../utils";
import {
  OptionProps,
  FormWorkflow,
  FormApproval,
  FormDetails,
  TASInputs,
  TASSelects,
  DelegateForm,
} from "../../../interfaces/forms.interface";
import { CSVLink } from "react-csv";
import CSVReader from "react-csv-reader";
import tas from "../../../services/forms/RoutingSlipForTASContractsForm.services";
import { tinyAlert } from "../../../services/_config.services";
import { User } from "../../../interfaces/users.interface";
import { useSelector } from "react-redux";

const filterOptions = createFilterOptions({ limit: 100 });
const getOptionLabel = (option: any) => (
  <div
    dangerouslySetInnerHTML={{ __html: !!option.label ? option?.label : "" }}
  />
);

function RoutingSlipForTASContracts() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  // global vars
  // const user: User = useSelector((state: any) => state.tepngUser.value.user);
  const formIDQuery: any = params.id;
  const formName: string = location.pathname.split("/")[4];
  console.log(formName);

  const user: User = useSelector((state: any) => state.tepngUser.value.user);
  const formType: any = formTypesID.find((x: any) => x.name === formName)?.type;

  // const default state value;
  const defaultInputs: TASInputs = {
    OthersDescription: "",
    DesigneeType: "",
    PositionRequest: "",
    ContractProposal: "",
    BenchmarkRate: "",
    NumberOfCandidatesSelectedForOralDiscussion: "",
    AverageCFTRate: "",
    NumberOfCandidatesParticipatedInOralDiscussion: "",
    TasContractFormType: "",
    AlternateEmailAddress: "",
    DDQStatus: "",
    SavingsAmount: "",
    ReferenceCost: "",
    NewCost: "",
    ContractJustification: "",
    SavingsJustification: "",
    Comment: "",
  };
  const defaultSelects: TASSelects = {
    CFTStartDate: null,
    CFTEndDate: null,

    currency: { label: "USD", value: 1, name: "currency" },
    department: { label: "Select...", value: null, name: "department" },
    ddq_status: { label: "Select...", value: null, name: "ddq_status" },
    assets: { label: "Select...", value: null, name: "assets" },
    human_right: { label: "Select...", value: null, name: "human_right" },
    nuprc_status: { label: "Select...", value: null, name: "nuprc_status" },
    procurement_method: {
      label: "Select...",
      value: null,
      name: "procurement_method",
    },
    market_intelligence: {
      label: "Select...",
      value: null,
      name: "market_intelligence",
    },
    selected_attachment_type: {
      label: "Select...",
      value: null,
      name: "selected_attachment_type",
    },
    new_end_date: null,
  };
  const csvTemplate = [
    [
      "pooaNumber",
      "poValue",
      "contractor",
      "currency",
      "departmentId",
      "ddqStatus",
      "positionTitle",
      "mobilizationDate",
      "positionNumber",
      "commitmentStartDate",
      "prNumber",
      "commitmentEndDate",
      "newPOValue",
      "newRate",
      "oldPOValue",
      "oldRate",
      "human_right",
      "ReferenceCost",
      "NewCost",
      "SavingsJustification",
    ],
    [
      "282882",
      "38283",
      "Salvation STANLEY KEMUEL",
      "USD",
      "IST",
      "COMPLIANT",
      "Engineer",
      "2024-03-04",
      "282982",
      "2024-03-04",
      "28292",
      "2024-03-04",
      "389283",
      "382983",
      "38382",
      "4843",
      "",
      "4843",
      "4843",
      "",
    ],
  ];

  // page data default states.
  const [formInputs, setFormInputs] = useState<TASInputs>(defaultInputs);
  const [formSelects, setFormSelects] = useState<TASSelects>(defaultSelects);
  const [deligateForm, setDeligateForm] = useState<DelegateForm[]>([]);

  const [isChecked, setbuttonChecked] = useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = useState<string>("DRAFT");
  const [parentFormId, setParentFormId] = useState<string>("");
  const [currencies, setCurrencies] = useState<OptionProps[]>([]);
  const [department, setDepartment] = useState<OptionProps[]>([]);
  const [human_right, setHumanRight] = useState<OptionProps[]>([]);
  const [ddq_status, setDDQStatus] = useState<OptionProps[]>([]);
  const [assets, setAssets] = useState<OptionProps[]>([]);
  const [nuprc_status, setDPRStatus] = useState<OptionProps[]>([]);
  const [procurement_method, setProcurementMethod] = useState<OptionProps[]>(
    []
  );
  const [yesno_notapplicable, setYesNoNotApplicable] = useState<OptionProps[]>(
    []
  );
  const [tepng_users, setTepngUsers] = useState<OptionProps[]>([]);
  const [attachment_type, setAttachmentType] = useState<OptionProps[]>([]);
  const [formDescription, setFormDescription] = useState<FormDetails>();
  const [form_workflow, setFormWorkflow] = useState<FormWorkflow>();
  const [form_approval, setFormApproval] = useState<FormApproval[]>([]);
  const [formDocuments, setFormDocuments] = useState<any>([]);
  const [removedDocumentIds, setRemovedDocumentIds] = useState<any>([]);
  const [togggleModal, setModalTogggle] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadedFilesAttachType, setUploadedFilesAttachType] = useState<
    string[]
  >([]);
  const clickedButtonRef = useRef("");

  console.log(
    CurrencyInput,
    currencies,
    department,
    ddq_status,
    assets,
    nuprc_status,
    procurement_method,
    yesno_notapplicable,
    form_workflow,
    tepng_users
  );
  // form-details
  useEffect(() => {
    getFormById(formIDQuery, department, ddq_status, currencies, human_right, navigate);
  }, [formIDQuery, department, ddq_status, currencies, human_right, navigate]);

  
  useEffect(() => {
    api.fetchFormDescription(formName).then((res) => setFormDescription(res));
  }, [formName]);

  // form-constants
  useEffect(() => {
    api.fetchCurrency().then((res) => setCurrencies(res));
    api.fetchDepartment().then((res) => setDepartment(res));
    api.fetchDdqStatus().then((res) => setDDQStatus(res));
    api.fetchAssets().then((res) => setAssets(res));
    api.fetchDPRStatus().then((res) => setDPRStatus(res));
    api.fetchHumanRight().then((res) => setHumanRight(res));
    api.fetchProcurementMethod().then((res) => setProcurementMethod(res));
    api.fetchYesNoNotApplicable().then((res) => setYesNoNotApplicable(res));
    api.fetchTepngUsers().then((res) => setTepngUsers(res));
    api.fetchFormWorkFlow(formType).then((res) => setFormWorkflow(res));
    api
      .fetchFormAttachmentTypes(formType)
      .then((res) => setAttachmentType(res));
  }, [formType]);

  // fetch-approval-sequence
  useEffect(() => {
    const data = {
      DepartmentId: "",
    };

    tas.fetchApprovalSequence(data).then((res) => {
      if (!!res?.length) {
        if (form_approval.length !== res.length) setFormApproval(res);
      }
    });
  }, [form_approval]);

  // form-hanlders
  const handleInput = (
    key: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormInputs({ ...formInputs, [key]: e.target.value });
  };
  const handleChecked = (e: any, key: string) => {
    setFormInputs({ ...formInputs, [key]: e.target.checked && e.target.value });
  };
  const handleSelect = (fieldName: string, selectedOptions: any) => {
    setFormSelects({ ...formSelects, [fieldName]: selectedOptions });
  };
  const handleDate = (date: any, input: string) => {
    setFormSelects({ ...formSelects, [input]: date });
  };
  // const handleCurrencyInput = (e: any) => {
  //   const { value, name } = e.target;
  //   let newValue = value.replace(/[^0-9]/g, "");
  //   setFormInputs({ ...formInputs, [name]: newValue });
  // };
  const handleFormApproval = (el: any, option: any, item: any) => {
    const new_form_approvals = form_approval.map((i: any) => {
      i.email = i.roleId === item.roleId ? option?.value : i.email;
      return i;
    });

    setFormApproval(new_form_approvals);
  };
  const handleDeligates = (el: any, index: any = {}, value: any = "") => {
    const deligates: any = [...deligateForm];
    deligates[index][el] = value;

    setDeligateForm(deligates);
  };
  const handleAlternateEmailAddress = (el: any, option: any) => {
    setFormInputs({ ...formInputs, AlternateEmailAddress: option.value });
  };
  const removeDeligateForm = (index: number) => {
    const deligates = [...deligateForm];
    deligates.splice(index, 1);
    setDeligateForm(deligates);
    tinyAlert("info", "Form removed");
  };
  const addDeligateByForm = () => {
    const deligates = [...deligateForm];

    const item: DelegateForm = {
      pooaNumber: undefined,
      poValue: undefined,
      contractor: "",
      currency: "",
      departmentId: "",
      ddqStatus: "",
      positionTitle: "",
      mobilizationDate: null,
      positionNumber: undefined,
      commitmentStartDate: null,
      prNumber: undefined,
      commitmentEndDate: null,
      newPOValue: undefined,
      newRate: undefined,
      oldPOValue: undefined,
      oldRate: undefined,
      human_right: "",
      SavingsJustification: '',
      SavingsAmount: undefined,
      ReferenceCost: undefined,
      NewCost: undefined,
    };

    deligates.push(item);
    setDeligateForm(deligates);
    tinyAlert("info", "New form added!");
  };
  const addDeligateByCSV = (data: any, fileInfo: any) => {
    const deligates = [...deligateForm];

    for (let i = 0; i < data.length; i++) {
      if (!!data[i].pooaNumber) {
        const departmentObject = department.find((item) =>
          item.label
            .toLocaleLowerCase()
            .includes(data[i]?.departmentId.toLocaleLowerCase())
        );
        const ddqStatusObject = ddq_status.find((item) =>
          item.label
            .toLocaleLowerCase()
            .includes(data[i].ddqStatus.toLocaleLowerCase())
        );
        const currencyObject = currencies.find((item) =>
          item.label
            .toLocaleLowerCase()
            .includes(data[i].currency.toLocaleLowerCase())
        );
        const humanRightObject = human_right.find((item) =>
          item.label
            .toLocaleLowerCase()
            .includes(data[i].human_right.toLocaleLowerCase())
        );

        console.log(typeof data[i].commitmentEndDate, typeof data[i].mobilizationDate)
        const item: DelegateForm = {
          pooaNumber: data[i].pooaNumber,
          poValue: data[i].poValue,
          contractor: data[i].contractor,
          currency: currencyObject, //obj
          departmentId: departmentObject, //obj
          ddqStatus: ddqStatusObject, //obj
          positionTitle: data[i].positionTitle,
          mobilizationDate: new Date(data[i].mobilizationDate),
          positionNumber: data[i].positionNumber,
          commitmentStartDate: new Date(data[i].commitmentStartDate),
          prNumber: data[i].prNumber,
          commitmentEndDate: new Date(),
          newPOValue: data[i].newPOValue,
          newRate: data[i].newRate,
          oldPOValue: data[i].oldPOValue,
          oldRate: data[i].oldRate,
          human_right: humanRightObject,
          SavingsJustification: data[i].SavingsJustification,
          SavingsAmount: data[i].SavingsAmount,
          ReferenceCost: data[i].ReferenceCost,
          NewCost: data[i].NewCost,
        };

        deligates.push(item);
      }
    }

    setDeligateForm(deligates);

    const inputFile = document.getElementById(
      "react-csv-reader-input"
    ) as HTMLInputElement | null;
    if (inputFile) {
      inputFile.value = "";
    }
    tinyAlert("info", "CSV import completed!");
  };

  // file-local-upload
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newAttachTypeLabel =
        formSelects.selected_attachment_type.label === "Others"
          ? formInputs.OthersDescription
          : formSelects.selected_attachment_type.label;

      let uploadType = uploadedFilesAttachType;

      for (let i = 0; i < acceptedFiles.length; i++) {
        uploadType.push(newAttachTypeLabel);
      }

      setUploadedFiles((prevUploadedFiles) => [
        ...prevUploadedFiles,
        ...acceptedFiles,
      ]);
      setUploadedFilesAttachType(uploadType);
    },
    [
      formSelects.selected_attachment_type.label,
      formInputs.OthersDescription,
      uploadedFilesAttachType,
    ]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const removeFile = (index: number) => {
    const updatedNames = [...uploadedFilesAttachType];
    updatedNames.splice(index, 1);

    const updated_files = [...uploadedFiles];
    updated_files.splice(index, 1);

    setUploadedFiles(updated_files);
    setUploadedFilesAttachType(updatedNames);
  };
  const removePrevFile = (index: number) => {
    const updated_files = [...formDocuments];
    const deletedFile = updated_files.splice(index, 1);
    setFormDocuments(updated_files);

    const removed_files = [...removedDocumentIds];
    removed_files.push(deletedFile[0].id);
    setRemovedDocumentIds(removed_files);
  };
  const toggleUploadCardModal = () => {
    if (togggleModal) setModalTogggle(false);
    else setModalTogggle(true);
  };

  // tas-form-api-services-create-aaf-form
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const data = {
      form_approval: form_approval,
      formInputs: { ...formInputs },
      formSelects: { ...formSelects },
      deligateForm: [...deligateForm],
      IsDraft: clickedButtonRef?.current === "post" ? false : true,
      uploadedFiles: uploadedFiles,
      uploadedFilesAttachType: uploadedFilesAttachType,
      user: user,
    };

    tas.setForm(data).then((res: any) => {
      if (!!res?.id) navigate("/initiator/forms/history/routing-slip-for-tas-contracts");
    });
  };

  // tas-form-api-services-update-aaf-form
  const handleUpdate = (e: FormEvent, action: string) => {
    e.preventDefault();
    const data = {
      form_approval: form_approval,
      formInputs: { ...formInputs },
      formSelects: { ...formSelects },
      deligateForm: [...deligateForm],
      IsDraft: action === "update_and_submit" ? false : true,
      uploadedFiles: uploadedFiles,
      uploadedFilesAttachType: uploadedFilesAttachType,
      removedDocumentIds: removedDocumentIds,
      formIDQuery: formIDQuery,
      user: user,
    };

    console.log(data);

    tas.updateForm(data).then((res) => {
      if (!!res?.id) navigate("/initiator/forms/history/routing-slip-for-tas-contracts");
    });
  };

  // tas-form-api-services-review-aaf-form
  const handleReview = (e: FormEvent) => {
    e.preventDefault();
    const data = {
      form_approval: form_approval,
      formInputs: { ...formInputs },
      formSelects: { ...formSelects },
      deligateForm: [...deligateForm],
      uploadedFiles: uploadedFiles,
      uploadedFilesAttachType: uploadedFilesAttachType,
      formIDQuery: formIDQuery,
      parentFormId: !!parentFormId ? parentFormId : formIDQuery,
      user: user,
      IsDraft:false,
    };

    tas.reviewForm(data).then((res: any) => {
      if (!!res?.id) navigate("/initiator/forms/history/routing-slip-for-tas-contracts");
    });
  };

  // tas-form-api-services-get-aaf-byid
  const getFormById = (
    formIDQuery: string,
    department: any,
    ddq_status: any,
    currencies: any,
    human_right: any,
    navigate: any
  ) => {
    if (formIDQuery) {
      tas.fetchFormById(formIDQuery).then((res) => {
        if (!!res?.id) {
          setFormInputs({
            OthersDescription: "",
            DesigneeType: res.designeeType,
            PositionRequest: res.positionRequest,
            ContractProposal: res.contractProposal,
            BenchmarkRate: res.benchmarkRate,
            NumberOfCandidatesSelectedForOralDiscussion:
              res.numberOfCandidatesParticipatedInOralDiscussion,
            AverageCFTRate: res.averageCFTRate,
            NumberOfCandidatesParticipatedInOralDiscussion:
              res.numberOfCandidatesSelectedForOralDiscussion,
            TasContractFormType: res.tasContractFormType,
            AlternateEmailAddress: res.alternateEmailAddress,
            DDQStatus: "",
            SavingsAmount: res.savingsAmount,
            ReferenceCost: res.referenceCost,
            NewCost: res.newCost,
            ContractJustification: res.contractJustification,
            SavingsJustification: res?.savingsJustification,
            Comment: res.comment,
          });

          setFormSelects({
            CFTStartDate: new Date(res.cftStartDate),
            CFTEndDate: new Date(res.cftEndDate),

            human_right: {
              label: res.humanRightsDetails?.name,
              value: res.humanRightsDetails?.id,
              name: "human_right",
            },
            currency: { label: "USD", value: 1, name: "currency" },
            department: {
              label: res.department.name,
              value: res.department.id,
              name: "department",
            },
            ddq_status: { label: "Select...", value: null, name: "ddq_status" },
            assets: { label: "Select...", value: null, name: "assets" },
            nuprc_status: {
              label: "Select...",
              value: null,
              name: "nuprc_status",
            },
            procurement_method: {
              label: "Select...",
              value: null,
              name: "procurement_method",
            },
            market_intelligence: {
              label: "Select...",
              value: null,
              name: "market_intelligence",
            },
            selected_attachment_type: {
              label: "Select...",
              value: null,
              name: "selected_attachment_type",
            },
            new_end_date: null,
          });

          let deligates = [];

          for (
            let i = 0;
            i < res.routingSlipForTasContractsFormTypes.length;
            i++
          ) {
            if (!!res.routingSlipForTasContractsFormTypes[i].pooaNumber) {
              const departmentObject = department.find((item: any) =>
                item?.label
                  ?.toLocaleLowerCase()
                  .includes(
                    res.routingSlipForTasContractsFormTypes[
                      i
                    ]?.department?.name?.toLocaleLowerCase()
                  )
              );
              const ddqStatusObject = ddq_status.find((item: any) =>
                item?.label
                  ?.toLocaleLowerCase()
                  .includes(
                    res.routingSlipForTasContractsFormTypes[
                      i
                    ].ddqStatus?.toLocaleLowerCase()
                  )
              );
              const currencyObject = currencies.find((item: any) =>
                item?.label
                  ?.toLocaleLowerCase()
                  .includes(
                    res.routingSlipForTasContractsFormTypes[
                      i
                    ].currency?.toLocaleLowerCase()
                  )
              );
              const humanRightObject = human_right.find((item: any) =>
                item?.label
                  ?.toLocaleLowerCase()
                  .includes(
                    res.routingSlipForTasContractsFormTypes[
                      i
                    ].humanRights?.toLocaleLowerCase()
                  )
              );

              const item: DelegateForm = {
                pooaNumber:
                  res.routingSlipForTasContractsFormTypes[i].pooaNumber,
                poValue: res.routingSlipForTasContractsFormTypes[i].poValue,
                contractor:
                  res.routingSlipForTasContractsFormTypes[i].contractor,
                currency: currencyObject, //obj
                departmentId: departmentObject, //obj
                ddqStatus: ddqStatusObject, //obj
                positionTitle:
                  res.routingSlipForTasContractsFormTypes[i].positionTitle,
                mobilizationDate: new Date(
                  res.routingSlipForTasContractsFormTypes[i].mobilizationDate
                ),
                positionNumber:
                  res.routingSlipForTasContractsFormTypes[i].positionNumber,
                commitmentStartDate: new Date(
                  res.routingSlipForTasContractsFormTypes[i].commitmentStartDate
                ),
                prNumber: res.routingSlipForTasContractsFormTypes[i].prNumber,
                commitmentEndDate: new Date(
                  res.routingSlipForTasContractsFormTypes[i].commitmentEndDate
                ),
                newPOValue:
                  res.routingSlipForTasContractsFormTypes[i].newPOValue,
                newRate: res.routingSlipForTasContractsFormTypes[i].newRate,
                oldPOValue:
                  res.routingSlipForTasContractsFormTypes[i].oldPOValue,
                oldRate: res.routingSlipForTasContractsFormTypes[i].oldRate,

                
                human_right: humanRightObject,
                SavingsJustification: res.routingSlipForTasContractsFormTypes[i].savingsJustification,
                SavingsAmount: res.routingSlipForTasContractsFormTypes[i].savingsAmount,
                ReferenceCost: res.routingSlipForTasContractsFormTypes[i].referenceCost,
                NewCost: res.routingSlipForTasContractsFormTypes[i].newCost,
              };

              deligates.push(item);
            }
          }

          setDeligateForm(deligates);

          const approval_sort = res.formApprovers.sort(
            (a: any, b: any) => a.step - b.step
          );
          const approvals: any[] = approval_sort.map((i: any) => ({
            email: i.email,
            roleId: i.roleId,
            roleName: i.role.name,
          }));

          if (res.canEditOrDelete !== true && res.currentStatus !== "RESTART") {
            tinyAlert(
              "error",
              "Form cannot be edited because the first level approver has acted on it"
            );
            navigate("/initiator/forms/history/routing-slip-for-tas-contracts");
            return;
          }

          setFormApproval(approvals);
          setFormDocuments(res.documents);
          setCurrentStatus(res.currentStatus);
          setParentFormId(res.parentFormId);
        }
      });
    }
  };

  // ui-component
  return (
    <div className="bg-white w-full rounded-lg mb-12 shadow border border-gray-100 dark:bg-gray-800 dark:border-gray-500">
      <FormDescription data={formDescription} />

      <form
        onSubmit={handleSubmit}
        className="max-w-1xl mx-auto rounded space-y-2 px-8 mt-8"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3 bg-gray-100 dark:bg-gray-700 shadow px-12 py-8 dark:text-gray-300">
          <div>
            <h2 className="font-bold mb-1">Designee Type</h2>
            <div className="flex gap-5">
              <div className="flex">
                <input
                  value="LOCAL"
                  checked={formInputs.DesigneeType === "LOCAL"}
                  onChange={(e) => handleChecked(e, "DesigneeType")}
                  type="checkbox"
                  name="isChecked"
                  id="LOCAL"
                  className="w-[20px] border-2 border-gray-800 dark:bg-gray-800 dark:text-gray-300"
                />
                <label
                  className="ml-2 mt-1 text-sm font-semibold"
                  htmlFor="LOCAL"
                >
                  {" "}
                  Local{" "}
                </label>
              </div>

              <div className="flex">
                <input
                  value="EXPAT"
                  checked={formInputs.DesigneeType === "EXPAT"}
                  onChange={(e) => handleChecked(e, "DesigneeType")}
                  type="checkbox"
                  name="isChecked"
                  id="EXPAT"
                  className="w-[20px] border-2 border-gray-800 dark:bg-gray-800 dark:text-gray-300"
                />
                <label
                  className="ml-2 mt-1 text-sm font-semibold"
                  htmlFor="EXPAT"
                >
                  {" "}
                  Expat{" "}
                </label>
              </div>
            </div>
          </div>

          <div>
            <h2 className="font-bold mb-1">Position Request</h2>
            <div className="flex gap-5">
              <div className="flex">
                <input
                  value="NEW_POSITION"
                  checked={formInputs.PositionRequest === "NEW_POSITION"}
                  onChange={(e) => handleChecked(e, "PositionRequest")}
                  type="checkbox"
                  name="isChecked"
                  id="NEW_POSITION"
                  className="w-[20px] border-2 border-gray-800"
                />
                <label
                  className="ml-2 mt-1 text-sm font-semibold"
                  htmlFor="NEW_POSITION"
                >
                  {" "}
                  New Position{" "}
                </label>
              </div>

              <div className="flex">
                <input
                  value="RENEWAL"
                  checked={formInputs.PositionRequest === "RENEWAL"}
                  onChange={(e) => handleChecked(e, "PositionRequest")}
                  type="checkbox"
                  name="isChecked"
                  id="RENEWAL"
                  className="w-[20px] border-2 border-gray-800"
                />
                <label
                  className="ml-2 mt-1 text-sm font-semibold"
                  htmlFor="RENEWAL"
                >
                  {" "}
                  Renewal{" "}
                </label>
              </div>

              <div className="flex">
                <input
                  value="REPLACEMENT"
                  checked={formInputs.PositionRequest === "REPLACEMENT"}
                  onChange={(e) => handleChecked(e, "PositionRequest")}
                  type="checkbox"
                  name="isChecked"
                  id="REPLACEMENT"
                  className="w-[20px] border-2 border-gray-800"
                />
                <label
                  className="ml-2 mt-1 text-sm font-semibold"
                  htmlFor="REPLACEMENT"
                >
                  {" "}
                  Replacement{" "}
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* Details of CFT section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3 pt-1">
          <div className="col-span-full bg-gray-100 dark:bg-gray-700 dark:text-gray-300 border-t dark:border-[#135099] mb-3 p-5 rounded-lg font-12 rounded-tl-[0px] rounded-tr-[0] shadow-lg font-bold">
            {" "}
            Details of CFT{" "}
          </div>

          <div>
            <Label title="Contractor Proposal (USD)" required={formInputs.PositionRequest !== "RENEWAL"} />
            <input
              id="ContractProposal"
              type="number"
              value={formInputs.ContractProposal}
              onChange={(e) => handleInput("ContractProposal", e)}
              className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
              placeholder="Contractor Proposal"
              required={formInputs.PositionRequest !== "RENEWAL"}
            />
          </div>

          <div>
            <Label title="CFT Start Date" required={formInputs.PositionRequest !== "RENEWAL"} />
            <DatePicker
              id="start_date"
              className="border rounded px-3 py-2 w-full font-normal input dark:bg-gray-800 dark:text-gray-300"
              selected={formSelects.CFTStartDate}
              onChange={(date) => handleDate(date, "CFTStartDate")}
              placeholderText="dd/mm/yyyy"
              dateFormat="dd/MM/yyyy"
              required={formInputs.PositionRequest !== "RENEWAL"}
              autoComplete="off"
            />
          </div>

          <div>
            <Label title="CFT End Date" required={formInputs.PositionRequest !== "RENEWAL"} />
            <DatePicker
              id="CFTEndDate"
              className="border rounded px-3 py-2 w-full font-normal input dark:bg-gray-800 dark:text-gray-300"
              selected={formSelects.CFTEndDate}
              onChange={(date) => handleDate(date, "CFTEndDate")}
              placeholderText="dd/mm/yyyy"
              dateFormat="dd/MM/yyyy"
              required={formInputs.PositionRequest !== "RENEWAL"}
              autoComplete="off"
            />
          </div>

          <div>
            <Label title="Internal Reference / Benchmark rates" required={formInputs.PositionRequest !== "RENEWAL"} />
            <input
              id="BenchmarkRate"
              type="number"
              value={formInputs.BenchmarkRate}
              onChange={(e) => handleInput("BenchmarkRate", e)}
              className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
              placeholder="Benchmark Rate"
              required={formInputs.PositionRequest !== "RENEWAL"}
            />
          </div>

          <div>
            <Label title="Average CFT rate" required={formInputs.PositionRequest !== "RENEWAL"} />
            <input
              id="AverageCFTRate"
              type="number"
              value={formInputs.AverageCFTRate}
              onChange={(e) => handleInput("AverageCFTRate", e)}
              className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
              placeholder="Average CFT Rate"
              required={formInputs.PositionRequest !== "RENEWAL"}
            />
          </div>

          <div>
            <Label
              title="Number of Candidates Selected for Oral Discusssion"
              required={formInputs.PositionRequest !== "RENEWAL"}
            />
            <input
              id="NumberOfCandidatesSelectedForOralDiscussion"
              type="number"
              value={formInputs.NumberOfCandidatesSelectedForOralDiscussion}
              onChange={(e) =>
                handleInput("NumberOfCandidatesSelectedForOralDiscussion", e)
              }
              className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
              placeholder="Number Of Candidates Selected For Oral Discussion"
              required={formInputs.PositionRequest !== "RENEWAL"}
            />
          </div>

          <div>
            <Label
              title="Number of Candidates that participate in  Oral Discusssion"
              required={formInputs.PositionRequest !== "RENEWAL"}
            />
            <input
              id="NumberOfCandidatesParticipatedInOralDiscussion"
              type="number"
              value={formInputs.NumberOfCandidatesParticipatedInOralDiscussion}
              onChange={(e) =>
                handleInput("NumberOfCandidatesParticipatedInOralDiscussion", e)
              }
              className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
              placeholder="Number Of Candidates Participated In Oral Discussion"
              required={formInputs.PositionRequest !== "RENEWAL"}
            />
          </div>

          <div>
            <Label title="Alternative Email Address" />
            <Autocomplete
              className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
              filterOptions={filterOptions}
              disableClearable={true}
              onChange={(e, v) => handleAlternateEmailAddress(e, v)}
              id="AlternateEmailAddress"
              options={tepng_users}
              value={formInputs.AlternateEmailAddress}
              renderInput={(params) => (
                <TextField
                  placeholder="Select..."
                  variant="standard"
                  {...params}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    fontFamily: "Nunito",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {getOptionLabel(option)}
                </li>
              )}
            />
          </div>

          {/* <div>
            <Label
              title={`Human Rights`}
              info={
                "Not Applicable can be selected by CE /Buyer if the contract is below CPSC threshold, no report on HR Audit from Ethics and there is no pending case on HR for the Contractor"
              }
              required={formInputs.PositionRequest !== "RENEWAL"}
            />

            <Select
              id="human_right"
              value={formSelects.human_right}
              options={human_right}
              isMulti={false}
              isSearchable={true}
              onChange={(e) => handleSelect("human_right", e)}
              className="font-normal"
              placeholder="Select..."
              required={formInputs.PositionRequest !== "RENEWAL"}
            />
          </div> */}

          {/* <div>
            <Label title="New Cost (USD)" for="NewCost" required={formInputs.PositionRequest !== "RENEWAL"} />
            <CurrencyInput
              id="NewCost"
              name="NewCost"
              value={formInputs.NewCost}
              className="border rounded-xs px-3 py-2 w-full font-normal input"
              onChange={handleCurrencyInput}
              decimalsLimit={2}
              decimalScale={2}
              placeholder="New Cost"
              required={formInputs.PositionRequest !== "RENEWAL"}
            />
          </div> */}

          {/* <div>
            <Label title="Reference Cost (USD)" for="ReferenceCost" required={formInputs.PositionRequest !== "RENEWAL"} />
            <CurrencyInput
              id="ReferenceCost"
              name="ReferenceCost"
              value={formInputs.ReferenceCost}
              className="border rounded-xs px-3 py-2 w-full font-normal input"
              onChange={handleCurrencyInput}
              decimalsLimit={2}
              decimalScale={2}
              placeholder="Reference Cost"
              required={formInputs.PositionRequest !== "RENEWAL"}
            />
          </div> */}

          {/* <div>
            <Label title="Savings Amount (USD)" for="SavingsAmount" required={formInputs.PositionRequest !== "RENEWAL"} />
            <CurrencyInput
              id="SavingsAmount"
              name="SavingsAmount"
              value={
                Number(formInputs?.NewCost) - Number(formInputs?.ReferenceCost)
              }
              className="border rounded-xs px-3 py-2 w-full font-normal input"
              onChange={handleCurrencyInput}
              decimalsLimit={2}
              decimalScale={2}
              placeholder="Savings Amount"
              disabled
              required={formInputs.PositionRequest !== "RENEWAL"}
            />
          </div> */}

          <div className="col-span-full">
            <Label title="Contract Justification" required={formInputs.PositionRequest !== "RENEWAL"} />
            <textarea
              id="ContractJustification"
              value={formInputs.ContractJustification}
              onChange={(e) => handleInput("ContractJustification", e)}
              className="border rounded-xs px-3 py-2 w-full font-normal"
              placeholder="Contract Justification"
              style={{ height: "150px" }}
              required={formInputs.PositionRequest !== "RENEWAL"}
            ></textarea>
          </div>

          {/* <div>
            <Label
              title="Savings Justification / Calculation Method"
              required={formInputs.PositionRequest !== "RENEWAL"}
            />
            <textarea
              id="SavingsJustification"
              value={formInputs.SavingsJustification}
              onChange={(e) => handleInput("SavingsJustification", e)}
              className="border rounded-xs px-3 py-2 w-full font-normal"
              placeholder="Savings Justification"
              style={{ height: "150px" }}
              required={formInputs.PositionRequest !== "RENEWAL"}
            ></textarea>
          </div> */}

          {/* <div className="col-span-full">
            <Label title="Comment" required />
            <textarea
              id="Comment"
              value={formInputs.Comment}
              onChange={(e) => handleInput("Comment", e)}
              className="border rounded-xs px-3 py-2 w-full font-normal"
              placeholder="Comment"
              style={{ height: "150px" }}
              required
            ></textarea>
          </div> */}
        </div>

        {/* Form actions section */}
        <div className="gap-2 flex justify-end font-bold pt-12">
          <button
            type="button"
            onClick={toggleUploadCardModal}
            className="bg-gray-100 text-sm border p-2 dark:bg-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 rounded-[3px]"
          >
            Upload Attachment
          </button>
          <button
            type="button"
            onClick={addDeligateByForm}
            className="bg-gray-100 border p-2 text-sm dark:bg-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 rounded-[3px]"
          >
            Add Delegate Form
          </button>

          <CSVLink
            data={csvTemplate}
            className="bg-gray-100 border p-2 text-sm dark:bg-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 rounded-[3px]"
          >
            Download CSV Template
          </CSVLink>
          <CSVReader
            onFileLoaded={addDeligateByCSV}
            parserOptions={{
              header: true,
            }}
          />
        </div>

        {/* Renewal/Amendment Contract section */}
        {/* <div className="mt-8">
          <div className="gap-8 bg-gray-100 shadow px-12 py-8 bg-gray-100 dark:bg-gray-700 dark:text-gray-300">
            <h2 className="font-bold mb-1">Tas Contract Type:</h2>
            <div className="flex">
              <div className="flex w-1/2">
                <input
                  value="RENEWAL_OR_AMENDMENT_CONTRACT"
                  checked={
                    formInputs.TasContractFormType ===
                    "RENEWAL_OR_AMENDMENT_CONTRACT"
                  }
                  onChange={(e) => handleChecked(e, "TasContractFormType")}
                  type="checkbox"
                  name="isChecked"
                  id="RENEWAL_OR_AMENDMENT_CONTRACT"
                  className="w-[20px] border-2 border-gray-800"
                />
                <label
                  className="ml-2 mt-1 text-sm font-semibold"
                  htmlFor="RENEWAL_OR_AMENDMENT_CONTRACT"
                >
                  {" "}
                  Renewal/Amendment Contract{" "}
                </label>
              </div>
              <div className="flex w-1/2">
                <input
                  value="AMENDMENT_OR_MODIFICATION_PO_VALUE"
                  checked={
                    formInputs.TasContractFormType ===
                    "AMENDMENT_OR_MODIFICATION_PO_VALUE"
                  }
                  onChange={(e) => handleChecked(e, "TasContractFormType")}
                  type="checkbox"
                  name="isChecked"
                  id="AMENDMENT_OR_MODIFICATION_PO_VALUE"
                  className="w-[20px] border-2 border-gray-800"
                />
                <label
                  className="ml-2 mt-1 text-sm font-semibold"
                  htmlFor="AMENDMENT_OR_MODIFICATION_PO_VALUE"
                >
                  {" "}
                  Amendment/Modification PO Value{" "}
                </label>
              </div>
            </div>
          </div>
        </div> */}

        {/* Add delegate form section */}
        {deligateForm.map((item, i) => {
          return (
            <div
              className="grid grid-cols-2 sm:grid-cols-5 lg:grid-cols-5 gap-1 border-b pb-5 hover:bg-gray-50"
              key={i}
            >
              <div>
                <LabelXS title="PO/OA Number" required />
                <input
                  type="text"
                  value={
                    !!item.pooaNumber
                      ? item.pooaNumber?.toString().replace(/[^0-9]/g, "")
                      : ""
                  }
                  onChange={(e) =>
                    handleDeligates("pooaNumber", i, e.target.value)
                  }
                  className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
                  placeholder="PO/OA Number"
                  required
                  maxLength={10}
                  minLength={10}
                />
              </div>

              <div>
                <LabelXS title="PO Value" required />
                <input
                  type="number"
                  value={item.poValue}
                  onChange={(e) =>
                    handleDeligates("poValue", i, e.target.value)
                  }
                  className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
                  placeholder="PO Value"
                  required
                />
              </div>

              <div>
                <LabelXS title="Contractor" required />
                <input
                  type="text"
                  value={item.contractor}
                  onChange={(e) =>
                    handleDeligates("contractor", i, e.target.value)
                  }
                  className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
                  placeholder="Contractor"
                  required
                />
              </div>

              <div>
                <LabelXS title="Human Right" required />
                <Select
                  id="human_right"
                  value={item.human_right}
                  options={human_right}
                  isMulti={false}
                  isSearchable={true}
                  onChange={(e) => handleDeligates("human_right", i, e)}
                  className="font-normal"
                  placeholder="Select..."
                  required
                />
              </div>
              
              <div>
                <LabelXS title="Currency" required />
                <Select
                  value={item.currency}
                  options={currencies}
                  isMulti={false}
                  isSearchable={false}
                  onChange={(e) => handleDeligates("currency", i, e)}
                  className="font-normal"
                  placeholder="Select..."
                  required
                />
              </div>

              <div>
                <LabelXS title="Department" />
                <Select
                  value={item?.departmentId}
                  options={department}
                  isMulti={false}
                  isSearchable={true}
                  onChange={(e) => handleDeligates("departmentId", i, e)}
                  className="font-normal dark:bg-gray-800 dark:text-gray-300"
                  placeholder="Select..."
                />
              </div>

              <div>
                <LabelXS title="DDQ Status" />
                <Select
                  value={item.ddqStatus}
                  options={ddq_status}
                  isMulti={false}
                  isSearchable={true}
                  onChange={(e) => handleDeligates("ddqStatus", i, e)}
                  className="font-normal"
                  placeholder="Select..."
                />
              </div>

              <div>
                <LabelXS title="Position Title" required />
                <input
                  type="text"
                  value={item.positionTitle}
                  onChange={(e) =>
                    handleDeligates("positionTitle", i, e.target.value)
                  }
                  className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
                  placeholder="Position Title"
                  required
                />
              </div>

              <div>
                <LabelXS title="Position Number" required />
                <input
                  type="text"
                  value={item.positionNumber}
                  onChange={(e) =>
                    handleDeligates("positionNumber", i, e.target.value)
                  }
                  className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
                  placeholder="Position Number"
                  required
                />
              </div>

              <div>
                <LabelXS title="PR Number" required />
                <input
                  type="text"
                  value={!!item.prNumber ? item.prNumber.toString().replace(/[^0-9]/g, "") : ''}
                  onChange={(e) =>
                    handleDeligates("prNumber", i, e.target.value)
                  }
                  className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
                  placeholder="PR Number"
                  required
                  maxLength={8}
                  minLength={8}
                />
              </div>

              <div>
                <LabelXS title="Commitment Start Date" />
                <DatePicker
                  className="border rounded px-3 py-2 w-full font-normal input"
                  selected={item.commitmentStartDate}
                  onChange={(date) =>
                    handleDeligates("commitmentStartDate", i, date)
                  }
                  placeholderText="dd/mm/yyyy"
                  dateFormat="dd/MM/yyyy"
                />
              </div>

              <div>
                <LabelXS title="Commitment End Date" />
                <DatePicker
                  className="border rounded px-3 py-2 w-full font-normal input"
                  selected={item.commitmentEndDate}
                  onChange={(date) =>
                    handleDeligates("commitmentEndDate", i, date)
                  }
                  placeholderText="dd/mm/yyyy"
                  dateFormat="dd/MM/yyyy"
                />
              </div>

              <div>
                <LabelXS title="Mobilization Date" />
                <DatePicker
                  className="border rounded px-3 py-2 w-full font-normal input"
                  selected={item.mobilizationDate}
                  onChange={(date) =>
                    handleDeligates("mobilizationDate", i, date)
                  }
                  placeholderText="dd/mm/yyyy"
                  dateFormat="dd/MM/yyyy"
                />
              </div>

              {/* <div>
                <LabelXS title="New PO Value" required />
                <input
                  type="number"
                  value={item.newPOValue}
                  onChange={(e) =>
                    handleDeligates("newPOValue", i, e.target.value)
                  }
                  className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
                  placeholder="New PO Value"
                  required
                />
              </div> */}

              <div>
                <LabelXS title="New Rate" />
                <input
                  type="number"
                  value={item.newRate}
                  onChange={(e) =>
                    handleDeligates("newRate", i, e.target.value)
                  }
                  className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
                  placeholder="New Rate"
                />
              </div>

              <div>
                <LabelXS title="Old PO Value" required />
                <input
                  type="number"
                  value={item.oldPOValue}
                  onChange={(e) =>
                    handleDeligates("oldPOValue", i, e.target.value)
                  }
                  className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
                  placeholder="Old PO Value"
                  required
                />
              </div>

              <div>
                <LabelXS title="Old Rate" />
                <input
                  type="number"
                  value={item.oldRate}
                  onChange={(e) =>
                    handleDeligates("oldRate", i, e.target.value)
                  }
                  className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
                  placeholder="Old Rate"
                />
              </div>

              <div>
                <LabelXS title="PO Variation" required />

                <span
                  className={`border rounded-xs px-3 py-2 w-full text-sm bg-gray-100 block`}
                >
                  {!!item.newPOValue &&
                    !!item.oldPOValue &&
                    twoDecimalPlace(
                      (item?.newPOValue - item?.oldPOValue) / item?.oldPOValue
                    )}
                  %
                </span>
              </div>

              <div>
                <LabelXS title="Rate Variation" />
                <span
                  className={`border rounded-xs px-3 py-2 w-full text-sm bg-gray-100 block`}
                >
                  {!!item.newRate &&
                    !!item.oldRate &&
                    twoDecimalPlace(
                      (item?.newRate - item?.oldRate) / item?.oldRate
                    )}
                  %
                </span>
              </div>

              <div>
                <LabelXS title="New Cost" />
                <input
                  type="number"
                  value={item.NewCost}
                  onChange={(e) =>
                    handleDeligates("NewCost", i, e.target.value)
                  }
                  className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
                  placeholder="New Cost"
                />
              </div>

              <div>
                <LabelXS title="Reference Cost " />
                <input
                  type="number"
                  value={item.ReferenceCost}
                  onChange={(e) =>
                    handleDeligates("ReferenceCost", i, e.target.value)
                  }
                  className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
                  placeholder="Reference Cost"
                />
              </div>

              <div>
                <LabelXS title="Savings Amount" />
                <input
                  type="number" 
                  value={
                    Number(item?.NewCost) - Number(item?.ReferenceCost)
                  }
                  className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
                  placeholder=""
                  disabled
                />
              </div>

              <div className="col-span-3">
                <LabelXS title="Savings Justification " />
                <input
                  type="text"
                  value={item?.SavingsJustification}
                  onChange={(e) =>
                    handleDeligates("SavingsJustification", i, e.target.value)
                  }
                  className={`${form_input_style} dark:bg-gray-800 dark:text-gray-300`}
                  placeholder="Savings Justification "
                />
              </div>

              <div>
                <button
                  type="button"
                  onClick={() => removeDeligateForm(i)}
                  className="mt-4 w-full h-[38px] bg-red-600 px-3 text-xs py-1 text-white"
                >
                  Remove
                </button>
              </div>
            </div>
          );
        })}

        {/* attach-document-area */}
        <div
          className={`container mx-auto ${
            !!formDocuments.length || !!uploadedFiles.length ? "" : "hidden"
          }`}
        >
          <div className="overflow-x-auto">
            <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
              <thead>
                <tr>
                  <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                    Attachment Category
                  </th>
                  <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                    Attached File
                  </th>
                  <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {formDocuments.length > 0 &&
                  formDocuments.map((item: any, i: any) => (
                    <tr
                      key={i}
                      className="transition-all hover:bg-gray-100 border"
                    >
                      <td className="px-3 py-2  text-xs">
                        {item.attachmentType.name}
                      </td>
                      <td className="px-3 py-2  text-xs">{item.name}</td>
                      <td className="px-3 py-2  text-xs">
                        <button
                          type="button"
                          className="flex"
                          onClick={() => removePrevFile(i)}
                        >
                          <span style={{ marginTop: "2px" }}>
                            <XMark />
                          </span>
                          <span className="ml-1">Remove</span>
                        </button>
                      </td>
                    </tr>
                  ))}

                {uploadedFiles.length > 0 &&
                  uploadedFiles.map((item, i) => (
                    <tr
                      key={i}
                      className="transition-all hover:bg-gray-100 border"
                    >
                      <td className="px-3 py-2  text-xs">
                        {uploadedFilesAttachType[i]}
                      </td>
                      <td className="px-3 py-2  text-xs">{item.name}</td>
                      <td className="px-3 py-2  text-xs">
                        <button
                          type="button"
                          className="flex"
                          onClick={() => removeFile(i)}
                        >
                          <span style={{ marginTop: "2px" }}>
                            <XMark />
                          </span>
                          <span className="ml-1">Remove</span>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* approval personnel */}
        <FormApprovalOverlay data={form_approval} />

        {!!form_approval.length && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3 pb-3">
            {form_approval?.map((item: FormApproval, i) => {
              return (
                <div key={i}>
                  <label className="block text-gray-600 font-medium text-sm flex">
                    {item.roleName}
                  </label>
                  <Autocomplete
                    className="w-full border rounded-xs px-3 py- w-full font-normal mu-input"
                    filterOptions={filterOptions}
                    disableClearable={true}
                    onChange={(e, option) =>
                      handleFormApproval(e, option, item)
                    }
                    id="combo-box-demo"
                    options={tepng_users}
                    value={!!item.email ? item.email : ""}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Select..."
                        variant="standard"
                        {...params}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          fontFamily: "Nunito",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        {getOptionLabel(option)}
                      </li>
                    )}
                  />
                </div>
              );
            })}
          </div>
        )}

        {
          // approval-workflow-diagram
          !!form_approval.length && (
            <>
              <Label title="Approval Sequence" for="ApprovalSequence" />
              <ApprovalSequenceFlow data={form_approval} />
            </>
          )
        }

        <div className="mx-auto w-2/4">
          {(!formIDQuery || currentStatus === "DRAFT") && (
            <div className="flex pt-28 pb-3">
              <input
                type="checkbox"
                name="isChecked"
                id="validationCheck"
                onChange={(e) => setbuttonChecked(e.target.checked)}
                style={{ width: "20px" }}
              />
              <label
                className="ml-3 mt-1 text-sm dark:text-gray-300"
                htmlFor="validationCheck"
              >
                I validate that this form is ready to be routed{" "}
              </label>
            </div>
          )}

          {!formIDQuery ? (
            <div className="flex gap-4 justify-start">
              <button
                type="submit"
                disabled={!!isChecked}
                className="px-8 py-3 text-white bg-blue-700 rounded hover:bg-blue-800 text-sm w-full font-bold"
                name="save"
                onClick={(e) => (clickedButtonRef.current = "save")}
              >
                Save as Draft
              </button>

              <button
                type="submit"
                disabled={!isChecked}
                className="px-8 py-3 text-white bg-blue-700 rounded hover:bg-blue-800 text-sm w-full font-bold"
                name="post"
                onClick={(e) => (clickedButtonRef.current = "post")}
              >
                Submit Form
              </button>
            </div>
          ) : (
            <div className="flex gap-4 pt-3 justify-start">
              <button
                type="button"
                className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm  ${
                  currentStatus !== "RESTART" ? "w-full" : "hidden"
                }`}
                name="update"
                disabled={!!isChecked}
                // onClick={(e) => handleUpdate(e, "update_only")}
                onClick={(e) =>
                  handleUpdate(
                    e,
                    currentStatus === "DRAFT"
                      ? "update_only"
                      : "update_and_submit"
                  )
                }
              >
                {currentStatus === "DRAFT" ? "Save As Draft" : "Update Form"}
              </button>

              <button
                type="button"
                disabled={!isChecked}
                className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm w-full ${
                  currentStatus === "DRAFT" ? "" : "hidden"
                }`}
                name="post"
                onClick={(e) => handleUpdate(e, "update_and_submit")}
              >
                Submit Form
              </button>

              <button
                type="button"
                disabled={!!isChecked}
                className={`px-8 py-3 text-white bg-blue-500 rounded hover:bg-blue-600 text-sm w-full ${
                  currentStatus === "RESTART" ? "" : "hidden"
                }`}
                name="post"
                onClick={handleReview}
              >
                Submit Restart
              </button>
            </div>
          )}
        </div>
      </form>

      <FormNotes data={formDescription} />

      {/* Uplooad modal */}
      {togggleModal && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50"
          style={{ background: "rgb(0 0 0 / 52%)" }}
        >
          <div className="modal-container bg-white w-7/12 mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div className="flex justify-between">
              <div className="modal-content py-4 text-left px-6">
                <h2 className="text-2xl font-bold">Attach File</h2>
                <p className="text-xs">Kindly upload your attachment</p>
              </div>
              <div className="modal-content py-4 text-left px-6">
                <button
                  onClick={toggleUploadCardModal}
                  className="p-2"
                  title="Close Modal"
                >
                  <XMark />
                </button>
              </div>
            </div>

            <div className="modal-content mb-4 text-left px-6 pb-4 attach-result">
              <div className="mb-3">
                <label className="block text-gray-700 font-bold">
                  Select Attachment to Upload
                </label>
                <Select
                  value={formSelects.selected_attachment_type}
                  options={attachment_type}
                  isMulti={false}
                  isSearchable={false}
                  onChange={(e) => handleSelect("selected_attachment_type", e)}
                  className="text-sm"
                  placeholder="Select..."
                />
                {formSelects.selected_attachment_type.label === "Others" && (
                  <input
                    id="OthersDescription"
                    type="text"
                    value={formInputs.OthersDescription}
                    onChange={(e) => handleInput("OthersDescription", e)}
                    className={form_input_style}
                    placeholder="Enter description"
                    required
                  />
                )}
              </div>

              <div>
                <div
                  style={{
                    display: !formSelects.selected_attachment_type.value
                      ? "none"
                      : formSelects.selected_attachment_type.label ===
                          "Others" && !formInputs.OthersDescription
                      ? "none"
                      : "block",
                  }}
                  {...getRootProps()}
                  className="dropzoneStyle"
                >
                  <input {...getInputProps()} />
                  <p>Drag & drop some files here, or click to select files</p>
                </div>
                {uploadedFiles.length > 0 && (
                  <div className="container mx-auto mt-3">
                    <div className="overflow-x-auto">
                      <table className="min-w-full border border-gray-300 divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                              Sn
                            </th>
                            <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                              Attachment Category
                            </th>
                            <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                              Attached File
                            </th>
                            <th className="px-3 py-2 text-left bg-gray-100 text-xs">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {uploadedFiles.map((item, i) => (
                            <tr
                              key={i}
                              className="transition-all hover:bg-gray-100 border"
                            >
                              <td className="px-3 py-2  text-xs">{i + 1}</td>
                              <td className="px-3 py-2  text-xs">
                                {uploadedFilesAttachType[i]}
                              </td>
                              <td className="px-3 py-2  text-xs">
                                {item.name}
                              </td>
                              <td className="px-3 py-2  text-xs">
                                <button
                                  className="flex"
                                  onClick={() => removeFile(i)}
                                >
                                  <span style={{ marginTop: "2px" }}>
                                    <XMark />
                                  </span>
                                  <span className="ml-1">Remove</span>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RoutingSlipForTASContracts;
