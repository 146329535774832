import {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import { AxiosError } from "axios";
import { errorAlert } from "./utils";
import { setTepngUser } from './store/tepngUser';
import CPNCRoutes from './routes';
import { makeREQUEST } from "./services/_config.services";

function App() {
  const dispatch = useDispatch();

  const [pageMounted, setpageMounted] = useState(false);
  const [tepUser, setTepUser] = useState(null);

  useEffect(() => {  
    loadRefreshToken(); 
  }, []);

  useEffect(() => {  
    dispatch(setTepngUser(tepUser));
  }, [tepUser, dispatch]);


  const loadRefreshToken = async () => {
    try {
      const response = await makeREQUEST('POST', 'Authentication/RefreshToken', {}, 'json', false);
      setTepUser(response.data?.data);
    }
    catch (error) {
      const err = error as AxiosError;
      errorAlert(err.message);
      console.error("Error Log:", error);
    }
    finally {
      setpageMounted(true);
    }
  };

  return (
    <> { pageMounted && <CPNCRoutes /> }  </>
  );
}

export default App;