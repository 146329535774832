import { Arsterisks } from '../components/Icons';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { FaCircleInfo } from 'react-icons/fa6';

interface InputProps {
    name: string;
    value: any;
    handler: (data: { key: string; value: any }) => void;
    placeholder?: string;
    required?: boolean;
    maxLength?:number;
    minLength?:number
}

export function FormIDLabel(props:any) {
    const data = props.form_ids
    if(!!data){

        return (
            <div>
                <span className='text-gray-400'>FORM ID:</span>
                <span>
                    {
                        data.map((item:any, i:any)=>{
                            return(
                                <span>
                                    <Link to={props.url+data[i].Id} className={`font-semibold text-sm px-2 ${props.current_id === data[i].FormId ? 'text-green-700 underline' : 'text-blue-700'}`}> {data[i].FormId} </Link>
                                    {
                                        (data.length - 1 > i) &&
                                        <span className='text-gray-400'>/</span>
                                    }
                                </span>
                            )
                        })
                    }
                </span>
            </div>
        )

    }
    else{
        return <div>
                    <span className='text-gray-400'>FORM ID:</span>
                    <span className='font-bold text-sm'> {props.current_id}</span>
                </div>
    }
}


export function Label(props:any) {
    return ( 
        <label className="block text-gray-600 font-bold text-sm flex" htmlFor={props.for}>
            { props?.required && <Arsterisks /> }
            <span dangerouslySetInnerHTML={{ __html: props?.title}} />
            { props?.info && <FaCircleInfo className='text-gray-400 text-sm ml-2' title={props.info} /> }
        </label>
    )
}


export function LabelXS(props:any) {
    return ( 
        <label className="block text-gray-600 font-bold text-xs flex" htmlFor={props.for}>
            { props?.required && <Arsterisks /> }
            <span dangerouslySetInnerHTML={{ __html: props?.title}} />
        </label>
    )
}

export const Input: React.FC<InputProps> = ({ name, value, handler, placeholder, required, maxLength, minLength }) => {
    // const handleInput3 = (data: { key: string; value: any }) => { 
    //     const {key, value} = data;
    //     setFormInputs({  ...formInputs, [key]: value,}); 
    // }; !parent fn
    const [childData, setChildData] = useState(value);

    useEffect(() => {
        setChildData(value);
    }, [value]);

    const updateChildData = (e: ChangeEvent<HTMLInputElement>) => {
        setChildData(e.target.value);
        const data = {
            key: name,
            value: e.target.value,
        };
        handler(data);
    };

    return (
        <input
            type="text"
            value={childData}
            onChange={updateChildData}
            className="border rounded-xs px-3 py-2 w-full font-normal input"
            placeholder={placeholder}
            required={required}
            maxLength={maxLength} 
            minLength={minLength}
        />
    );
};