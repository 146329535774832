import { useNavigate  } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { axiosget, closeAlert, errorResponse, preloader, tinyAlert } from "../utils";

function FormCurrentStatus(props:any) {
    const navigate = useNavigate ();
    const downloadFile = () => {
        preloader();
        axiosget(
          "Form/DownloadFormFinalPDF?formId="+ props.formID
        )
          .then((res) => {
            console.log(res)
            var a = document.createElement("a");
            a.href = "data:image/png;base64," + res.data.data.base64String;
            a.download = res.data.data.fileName; // res.data.data.attachmentType.name+'-'+res.data.data.name
            a.click();
            // return res;
          })
          .catch((err) => {
            tinyAlert("error", errorResponse(err));
            console.log(err);
          })
          .finally(() => closeAlert());
    }

    return (
        <div className="flex justify-between">
            <div className='flex justify-start mb-1 gap-5'>
                <span onClick={() => navigate(-1)} className="mb-2 flex gap-2 mt-1 cursor-pointer text-red-500">
                    <FaArrowLeftLong />    
                    <span className='text-sm font-bold'>Go Back</span> 
                </span> 
                <span className='text-gray-400'>|</span>
                <span className='mr-2'>
                    <span className='text-gray-400'>Current status:</span> <span className='font-bold text-sm'> {props.data} </span> 
                </span>
            </div>
            {
                props.data === 'APPROVED' && <button className="bg-blue-600 px-5 py-2 font-bold text-sm rounded text-white mb-2" onClick={downloadFile}>Final PDF</button>
            }
            
        </div>
    )
}

export default FormCurrentStatus;
