import React, { FormEvent } from 'react';
// import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { _$http, axiosError, closeAlert, preloader } from '../../utils';
import SettingsHeader from '../../layouts/admin/SettingsHeader';
import {  useLocation } from 'react-router-dom';
import { tinyAlert } from '../../services/_config.services';
import api from '../../services/form.services';
// import { FormDetails } from "../../interfaces/forms.interface";


function ManageFormContent() {
  
  const location = useLocation();

  
  const formName: string = location.pathname.split("/")[4];

  const [description, setFormDescription] = useState<any>();
  const [note, setFormNote] = useState<any>();
  const [type, setFormType] = useState<any>();

  useEffect(() => {
    api.fetchFormDescription(formName).then((res) => {

      
      
      setFormDescription(res.description);
      setFormNote(res.note);
      setFormType(res.type);
  
  });

  }, [formName]);




  const handleQuill1 = (html: string) => { setFormDescription(html); };
  const handleQuill2 = (html: string) => { setFormNote(html); };

  const updateFormDescription =  (e: FormEvent) => {
    e.preventDefault();

    preloader();
    const body = {  "description": description,  "note": note}
    _$http('PUT', 'Form/UpdateDescription?formType='+type, body).then(res => {
      closeAlert();
      console.log(res.data.message)
      tinyAlert('success', res.data.message)
    })
      .catch(error => {
        axiosError(error);
      })
  };


  return (
    <div className='bg-white w-full shadow-sm rounded-lg py-4'>
      <SettingsHeader />

      <div className="relative overflow-x-auto py-4 px-12" style={{ minHeight: "520px" }}>
        <div className='my-8'>
          <label htmlFor="handleQuill1">Form Description</label>
          <ReactQuill style={{ minHeight: "200px" }} id="handleQuill1" value={description} className='rounded mt-2' theme="snow" onChange={handleQuill1} />
        </div>

        <div className='my-8'>
          <label htmlFor="handleQuill2">Form Notes</label>
          <ReactQuill style={{ minHeight: "200px" }} id="handleQuill2" value={note} className='rounded mt-2' theme="snow" onChange={handleQuill2}/>
        </div>


        <hr />

        <div className='flex justify-end my-4'>
          <button className='red-button rounded px-4 py-2 text-sm' onClick={updateFormDescription}>Update</button>
        </div>
      </div>

    </div>

  );
}

export default ManageFormContent;
