import { formatYYYYMMDD } from "../../utils";
import { makePOST, makeREQUEST, tinyAlert, printError, makePUT, } from "../_config.services";

class RoutingSlipForTASContractsFormService {
  // constructor() { }

  async setForm(data: any): Promise<any> {
    try{
      
        const user = data.user;
        const input = data.formInputs;
        const select = data.formSelects;
        const deligateForm = data.deligateForm;
        let others_description = "Others";
        const form_approval = data.form_approval;


        // [validation 2] validate alternative email && user emails
        if(user.email === input.AlternateEmailAddress){
          tinyAlert('error', 'You cannot use yourself as the alternative email address.');
          return;
        }
  
        // check if approval sequence is not all empty
        const form_approval_emails = form_approval.every((obj:any) => obj.email.trim() !== '');
        if (!form_approval_emails) {
            tinyAlert('error','Some or all emails in approval sequence are empty.');
            return;
        }
  
        const invalid_emails = form_approval.filter((obj: any) => (obj.email.trim() === user.email) || (obj.email.trim() === input.AlternateEmailAddress));
        console.log(invalid_emails);
  
        if (invalid_emails.length > 0) {
          tinyAlert('error','Some or all emails in approval sequence cannot have your email address or alternative email address.');
          return;
        }
  
        // [validation 3] check if approval sequence does not have same email address;
        const emails = form_approval.map((obj:any) => obj.email);
        const uniqueEmails = new Set(emails);
        if (emails.length !== uniqueEmails.size) {
          tinyAlert('error','Approval sequence with the same approval emails are not allowed!.');
          return;
        }

        if(deligateForm.length < 1){
          tinyAlert('error','Delegate form is required!.');
          return;

        }


      const form: any = new FormData();
      form.append("DesigneeType", input.DesigneeType);
      form.append("PositionRequest", input.PositionRequest);
      form.append("CFTStartDate", formatYYYYMMDD(select.CFTStartDate));
      form.append("CFTEndDate", formatYYYYMMDD(select.CFTEndDate));
      form.append("ContractProposal", input.ContractProposal);
      form.append("BenchmarkRate", input.BenchmarkRate);
      form.append("AverageCFTRate", input.AverageCFTRate);
      form.append("NumberOfCandidatesSelectedForOralDiscussion", input.NumberOfCandidatesSelectedForOralDiscussion);
      form.append("NumberOfCandidatesParticipatedInOralDiscussion", input.NumberOfCandidatesParticipatedInOralDiscussion);
      form.append("TasContractFormType", input.TasContractFormType);
      form.append("IsDraft", data.IsDraft);
      form.append("FormApprovers", JSON.stringify(data.form_approval));
      form.append("AlternateEmailAddress", input.AlternateEmailAddress);
      form.append("SavingsAmount", input.SavingsAmount);
      form.append("ReferenceCost", input.ReferenceCost);
      form.append("NewCost", input.NewCost);
      form.append("ContractJustification", input.ContractJustification);
      form.append("SavingsJustification", input.SavingsJustification);
      form.append("Comment", input.Comment);
      form.append("IsHumanRight", !!select.human_right.value ? select.human_right.value  : '' );


      for (let i = 0; i < data.uploadedFiles.length; i++) {
        if (data.uploadedFilesAttachType[i] === "Signed RTA by Duet")
        form.append("SignedRtaByDuet", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Draft Contract/PO to be reviewed by Signatories")
          form.append(
            "DraftContractPoToBeReviewedBySignatories",
            data.uploadedFiles[i]
          );

          else if (data.uploadedFilesAttachType[i] === "TAS Recruitment Request")
            form.append("TASRecruitmentRequest", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Technical Recommendation")
            form.append("TechnicalRecommendation", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Final Contractor Quotation")
            form.append("FinalContractorQuotation", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Mobilization Request Form (New)")
            form.append("MobilizationRequestFormNew", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Commitment Data Sheet")
            form.append("CommitmentDataSheet", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "PO Contract (1 Original)")
            form.append("POContract1Original", data.uploadedFiles[i]);

          else {
            form.append("Others", data.uploadedFiles[i]);
            others_description = data.uploadedFilesAttachType[i];
          }
      }


      form.append("OthersDescription", others_description);

      deligateForm.forEach((delegate:any, index:any) => {
        form.append(`TasDelegates[${index}][pooaNumber]`, delegate.pooaNumber);
        form.append(`TasDelegates[${index}][poValue]`, delegate.poValue);
        form.append(`TasDelegates[${index}][contractor]`, delegate.contractor);
        form.append(`TasDelegates[${index}][currency]`, delegate.currency.value);
        form.append(`TasDelegates[${index}][departmentId]`, delegate.departmentId.value);

        form.append(`TasDelegates[${index}][ddqStatus]`, delegate.ddqStatus.value);
        form.append(`TasDelegates[${index}][positionTitle]`, delegate.positionTitle);
        form.append(`TasDelegates[${index}][mobilizationDate]`, formatYYYYMMDD(delegate.mobilizationDate));

        form.append(`TasDelegates[${index}][positionNumber]`, delegate.positionNumber);
        form.append(`TasDelegates[${index}][commitmentStartDate]`, formatYYYYMMDD(delegate.commitmentStartDate));
        form.append(`TasDelegates[${index}][prNumber]`, delegate.prNumber);
        form.append(`TasDelegates[${index}][commitmentEndDate]`, formatYYYYMMDD(delegate.commitmentEndDate));

        form.append(`TasDelegates[${index}][newPOValue]`, delegate.newPOValue);
        form.append(`TasDelegates[${index}][newRate]`, delegate.newRate);

        form.append(`TasDelegates[${index}][oldPOValue]`, delegate.oldPOValue);
        form.append(`TasDelegates[${index}][oldRate]`, delegate.oldRate);
        form.append(`TasDelegates[${index}][IsHumanRights]`, delegate.human_right.value);

        form.append(`TasDelegates[${index}][ReferenceCost]`, delegate.ReferenceCost);
        form.append(`TasDelegates[${index}][NewCost]`, delegate.NewCost);
        form.append(`TasDelegates[${index}][SavingsJustification]`, delegate.SavingsJustification );
      });


      return await makePOST('RoutingSlipForTASContractsForm/Initiator/Submit', form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } 
    catch (error:any) {

      const allKeysRequired = !!error.response.data.errors ? Object.keys(error.response.data.errors).join(', ') : error.response.data.Message;
      tinyAlert("error", 'Complete all required field: '+ JSON.stringify(allKeysRequired) );
      
    }
  }

  async updateForm(data: any): Promise<any> {
    try{
      const input = data.formInputs;
      const select = data.formSelects;
      const deligateForm = data.deligateForm;
      let others_description = "Others";

      const form: any = new FormData();
      form.append("DesigneeType", input.DesigneeType);
      form.append("PositionRequest", input.PositionRequest);
      form.append("CFTStartDate", formatYYYYMMDD(select.CFTStartDate));
      form.append("CFTEndDate", formatYYYYMMDD(select.CFTEndDate));
      form.append("ContractProposal", input.ContractProposal);
      form.append("BenchmarkRate", input.BenchmarkRate);
      form.append("AverageCFTRate", input.AverageCFTRate);
      form.append("NumberOfCandidatesSelectedForOralDiscussion", input.NumberOfCandidatesSelectedForOralDiscussion);
      form.append("NumberOfCandidatesParticipatedInOralDiscussion", input.NumberOfCandidatesParticipatedInOralDiscussion);
      form.append("TasContractFormType", input.TasContractFormType);
      form.append("IsDraft", data.IsDraft);
      form.append("FormApprovers", JSON.stringify(data.form_approval));
      form.append("AlternateEmailAddress", input.AlternateEmailAddress);
      form.append("SavingsAmount", input.SavingsAmount);
      form.append("ReferenceCost", input.ReferenceCost);
      form.append("NewCost", input.NewCost);
      form.append("ContractJustification", input.ContractJustification);
      form.append("SavingsJustification", input.SavingsJustification);
      form.append("Comment", input.Comment);

      if (!!data.removedDocumentIds.length) {
          for (let i = 0; i < data.removedDocumentIds.length; i++) {
              form.append("RemovedDocumentIds", data.removedDocumentIds[i]);
          }
      }

      for (let i = 0; i < data.uploadedFiles.length; i++) {
        if (data.uploadedFilesAttachType[i] === "Signed RTA by Duet")
        form.append("SignedRtaByDuet", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Draft Contract/PO to be reviewed by Signatories")
          form.append(
            "DraftContractPoToBeReviewedBySignatories",
            data.uploadedFiles[i]
          );

          else if (data.uploadedFilesAttachType[i] === "TAS Recruitment Request")
            form.append("TASRecruitmentRequest", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Technical Recommendation")
            form.append("TechnicalRecommendation", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Final Contractor Quotation")
            form.append("FinalContractorQuotation", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Mobilization Request Form (New)")
            form.append("MobilizationRequestFormNew", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Commitment Data Sheet")
            form.append("CommitmentDataSheet", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "PO Contract (1 Original)")
            form.append("POContract1Original", data.uploadedFiles[i]);

          else {
            form.append("Others", data.uploadedFiles[i]);
            others_description = data.uploadedFilesAttachType[i];
          }
      }

      deligateForm.forEach((delegate:any, index:any) => {
        console.log(delegate)
        form.append(`TasDelegates[${index}][pooaNumber]`, delegate.pooaNumber);
        form.append(`TasDelegates[${index}][poValue]`, delegate.poValue);
        form.append(`TasDelegates[${index}][contractor]`, delegate.contractor);
        form.append(`TasDelegates[${index}][currency]`, delegate.currency.value);
        form.append(`TasDelegates[${index}][departmentId]`, delegate.departmentId?.value);

        form.append(`TasDelegates[${index}][ddqStatus]`, delegate.ddqStatus.value);
        form.append(`TasDelegates[${index}][positionTitle]`, delegate.positionTitle);
        form.append(`TasDelegates[${index}][mobilizationDate]`, formatYYYYMMDD(delegate.mobilizationDate));

        form.append(`TasDelegates[${index}][positionNumber]`, delegate.positionNumber);
        form.append(`TasDelegates[${index}][commitmentStartDate]`, formatYYYYMMDD(delegate.commitmentStartDate));
        form.append(`TasDelegates[${index}][prNumber]`, delegate.prNumber);
        form.append(`TasDelegates[${index}][commitmentEndDate]`, formatYYYYMMDD(delegate.commitmentEndDate));

        form.append(`TasDelegates[${index}][newPOValue]`, delegate.newPOValue);
        form.append(`TasDelegates[${index}][newRate]`, delegate.newRate);

        form.append(`TasDelegates[${index}][oldPOValue]`, delegate.oldPOValue);
        form.append(`TasDelegates[${index}][oldRate]`, delegate.oldRate);

        form.append(`TasDelegates[${index}][IsHumanRights]`, delegate.human_right.value);

        form.append(`TasDelegates[${index}][ReferenceCost]`, delegate.ReferenceCost);
        form.append(`TasDelegates[${index}][NewCost]`, delegate.NewCost);
        form.append(`TasDelegates[${index}][SavingsJustification]`, delegate.SavingsJustification );
      });

      form.append("OthersDescription", others_description);

      return await makePUT(`RoutingSlipForTASContractsForm/Initiator/Update/${data.formIDQuery}`, form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })
    } 
    catch (error:any) {

      const allKeysRequired = !!error?.response?.data?.errors ? Object.keys(error.response.data.errors).join(', ') : error.response.data.Message;
      tinyAlert("error", 'Complete all required field: '+ JSON.stringify(allKeysRequired) );
      
    }
  }

  async submitDraft(id: any): Promise<any> {
    try{
      const data = {
        "routingSlipForTASContractsFormId": id
      }
      return await makeREQUEST("PUT", 'RoutingSlipForTASContractsForm/Draft', data)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } 
    catch (error:any) {

      const allKeysRequired = !!error.response.data.errors ? Object.keys(error.response.data.errors).join(', ') : error.response.data.Message;
      tinyAlert("error", 'Complete all required field: '+ JSON.stringify(allKeysRequired) );
      
    }
  }

  async reviewForm(data: any): Promise<any> {
    try{
      const form_approval_emails = data.form_approval.every((obj:any) => obj.email.trim() !== '');
      if (!form_approval_emails) {
          tinyAlert('error','Some or all emails in approval sequence are empty.');
          return;
      }

      const form: any = new FormData();
      const input = data.formInputs;
      const select = data.formSelects;
      const deligateForm = data.deligateForm;
      let others_description = "Others";

      form.append("PreviousRoutingSlipForTASContractsId", data.parentFormId);
      form.append("DesigneeType", input.DesigneeType);
      form.append("PositionRequest", input.PositionRequest);
      form.append("CFTStartDate", formatYYYYMMDD(select.CFTStartDate));
      form.append("CFTEndDate", formatYYYYMMDD(select.CFTEndDate));
      form.append("ContractProposal", input.ContractProposal);
      form.append("BenchmarkRate", input.BenchmarkRate);
      form.append("AverageCFTRate", input.AverageCFTRate);
      form.append("NumberOfCandidatesSelectedForOralDiscussion", input.NumberOfCandidatesSelectedForOralDiscussion);
      form.append("NumberOfCandidatesParticipatedInOralDiscussion", input.NumberOfCandidatesParticipatedInOralDiscussion);
      form.append("TasContractFormType", input.TasContractFormType);
      form.append("IsDraft", data.IsDraft);
      form.append("FormApprovers", JSON.stringify(data.form_approval));
      form.append("AlternateEmailAddress", input.AlternateEmailAddress);
      form.append("SavingsAmount", input.SavingsAmount);
      form.append("ReferenceCost", input.ReferenceCost);
      form.append("NewCost", input.NewCost);
      form.append("ContractJustification", input.ContractJustification);
      form.append("SavingsJustification", input.SavingsJustification);
      form.append("Comment", input.Comment);

      for (let i = 0; i < data.uploadedFiles.length; i++) {
                if (data.uploadedFilesAttachType[i] === "Signed RTA by Duet")
        form.append("SignedRtaByDuet", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Draft Contract/PO to be reviewed by Signatories")
          form.append(
            "DraftContractPoToBeReviewedBySignatories",
            data.uploadedFiles[i]
          );
          
          else if (data.uploadedFilesAttachType[i] === "TAS Recruitment Request")
            form.append("TASRecruitmentRequest", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Technical Recommendation")
            form.append("TechnicalRecommendation", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Final Contractor Quotation")
            form.append("FinalContractorQuotation", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Mobilization Request Form (New)")
            form.append("MobilizationRequestFormNew", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Commitment Data Sheet")
            form.append("CommitmentDataSheet", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "PO Contract (1 Original)")
            form.append("POContract1Original", data.uploadedFiles[i]);

          else {
            form.append("Others", data.uploadedFiles[i]);
            others_description = data.uploadedFilesAttachType[i];
          }
      }

      

      deligateForm.forEach((delegate:any, index:any) => {
        console.log(delegate)
        form.append(`TasDelegates[${index}][pooaNumber]`, delegate.pooaNumber);
        form.append(`TasDelegates[${index}][poValue]`, delegate.poValue);
        form.append(`TasDelegates[${index}][contractor]`, delegate.contractor);
        form.append(`TasDelegates[${index}][currency]`, delegate.currency.value);
        form.append(`TasDelegates[${index}][departmentId]`, delegate.departmentId.value);

        form.append(`TasDelegates[${index}][ddqStatus]`, delegate.ddqStatus.value);
        form.append(`TasDelegates[${index}][positionTitle]`, delegate.positionTitle);
        form.append(`TasDelegates[${index}][mobilizationDate]`, formatYYYYMMDD(delegate.mobilizationDate));

        form.append(`TasDelegates[${index}][positionNumber]`, delegate.positionNumber);
        form.append(`TasDelegates[${index}][commitmentStartDate]`, formatYYYYMMDD(delegate.commitmentStartDate));
        form.append(`TasDelegates[${index}][prNumber]`, delegate.prNumber);
        form.append(`TasDelegates[${index}][commitmentEndDate]`, formatYYYYMMDD(delegate.commitmentEndDate));

        form.append(`TasDelegates[${index}][newPOValue]`, delegate.newPOValue);
        form.append(`TasDelegates[${index}][newRate]`, delegate.newRate);

        form.append(`TasDelegates[${index}][oldPOValue]`, delegate.oldPOValue);
        form.append(`TasDelegates[${index}][oldRate]`, delegate.oldRate);
        form.append(`TasDelegates[${index}][IsHumanRights]`, delegate.human_right.value);

        form.append(`TasDelegates[${index}][ReferenceCost]`, delegate.ReferenceCost);
        form.append(`TasDelegates[${index}][NewCost]`, delegate.NewCost);
        form.append(`TasDelegates[${index}][SavingsJustification]`, delegate.SavingsJustification );
      });


      form.append("OthersDescription", others_description);

      return await makePOST('RoutingSlipForTASContractsForm/Review', form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } 
    catch (error:any) {

      const allKeysRequired = !!error.response.data.errors ? Object.keys(error.response.data.errors).join(', ') : error.response.data.Message;
      tinyAlert("error", 'Complete all required field: '+ JSON.stringify(allKeysRequired) );
      
    }
  }

  async fetchFormById(id: string): Promise<any> {
    try{
      return await makeREQUEST("GET", "RoutingSlipForTASContractsForm/" + id)
      .then((res) => {
        return res.data.data;

      })

    } catch (error) {
      tinyAlert("error", 'there is an error');
      console.error('Error:', error);
    }
  }

  async fetchApprovalSequence(data: any): Promise<any> {
    try{
      //?ContractValue=${data.ContractValue}&Currency=${data.Currency}&DepartmentId=${data.DepartmentId}
      return await makeREQUEST("GET", `RoutingSlipForTASContractsForm/GetApprovalSequence`)
      .then((res) => {
        tinyAlert("success", "approval sequence updated!");
        return res.data.data;
      })
    } catch (error) {
      tinyAlert("error", printError(error));
      console.error('Error:', error);
    }
  }

  async deleteForm(id: string): Promise<any> {
    try{
      return await makeREQUEST("DELETE", "RoutingSlipForTASContractsForm/" + id)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;

      })

    } catch (error) {
      tinyAlert("error", 'there is an error');
      console.error('Error:', error);
    }
  }


};

const tas = new RoutingSlipForTASContractsFormService();
export default tas;