// seen - but not optimized, page should be part of login main


import { useState, ChangeEvent } from 'react';
import { FaCheck } from 'react-icons/fa6';
import auth from '../../services/authentication/Authentication.services';
import { closeAlert, preloader, tinyAlert } from '../../utils';

const Policy = () => {
    const [consentCheckbox, setConsentCheckbox] = useState<boolean>(false);

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setConsentCheckbox(e.target.checked);
    };

    const handleConsent = async () => {
        try {
            preloader();
            const result = await auth.ConsentToPersonalDataAndCookies();

            closeAlert();
            
            if(result)
                window.location.reload();

                
        } catch (error) {
            console.error('Error occurred during consent:', error);
            tinyAlert('An error occurred')
            closeAlert()
        }
    };

    return (
        <section className="min-h-screen bg-gray-100">
            <div className="max-w-screen-md mx-auto p-6 xs:p-4 mb-20">
                <div className="text-center mb-6">
                    <img className="w-32 mx-auto mb-6" src="/logo.png" alt="logo" />
                    <h2 className="uppercase font-semibold text-lg border-b-2 border-gray-700">CPNC e-FORMS | Personal Data and Cookies Consent</h2>
                </div>

                {/* Policy Content */}
                <div className="p-6 xs:p-4 mb-14">
                    <div className="max-w-screen-md mx-auto flex justify-between items-center">
                        <div className="text-justify text-sm">
                            <h6 className="mb-0 font-bold">Personal Data and Cookies Charter</h6>
                            <p className='mb-2'>
                                <span>Welcome to the website </span>
                                <a href="https://cpnceforms-dev.tepnglocal.iasp.tgscloud.net" target="_blank" rel="noreferrer noopener">
                                    https://cpnceforms-dev.tepnglocal.iasp.tgscloud.net
                                </a>
                            </p>
                            <p className='mb-2'>
                                The Personal Data and Cookies Charter (hereinafter Charter) aims to inform you
                                about your rights that you can exercise regarding the processing of your data and describes
                                the measures that we take to protect these data.
                            </p>
                            <p className='mb-5'>
                                TotalEnergies EP Nigeria is the data controller with responsibility for personal data processing on the website
                                <a href="https://cpnceforms-dev.tepnglocal.iasp.tgscloud.net" target="_blank" rel="noreferrer noopener">
                                    https://cpnceforms-dev.tepnglocal.iasp.tgscloud.net
                                </a>.
                                These processing operations are carried out in accordance with the applicable law.
                            </p>

                            {/* 1 Purpose of processing */}
                            <h6 className="mb-0 font-bold mt-12 underline">1. Purpose of processing, legal basis, the period of storage and types of data collected</h6>
                            <p className='mb-2'>
                                <span>When visiting the website </span>
                                <a href="https://cpnceforms-dev.tepnglocal.iasp.tgscloud.net" target="_blank" rel="noreferrer noopener">
                                    https://cpnceforms-dev.tepnglocal.iasp.tgscloud.net
                                </a>,
                                you may need to provide us with personal data.
                            </p>
                            <table className="table-auto w-full mb-5 border border-solid border-gray-300">
                                <thead className="bg-gray-100 text-black">
                                    <tr>
                                        <th className="px-4 py-2  border border-solid border-gray-300">Purposes</th>
                                        <th className="px-4 py-2  border border-solid border-gray-300">Categories of personal data</th>
                                        <th className="px-4 py-2  border border-solid border-gray-300">Legal basis</th>
                                        <th className="px-4 py-2  border border-solid border-gray-300">Retention period</th>
                                    </tr>
                                </thead>
                                <tbody className='bg-white divide-y divide-gray-200'>
                                    <tr>
                                        <td className="px-4 py-2  border border-solid border-gray-300">CPNC Forms</td>
                                        <td className="px-4 py-2  border border-solid border-gray-300">Identification data (last name, first name, email address)</td>
                                        <td className="px-4 py-2  border border-solid border-gray-300">
                                            The information collected is necessary for the implementation of the contract with TotalEnergies EP Nigeria, failing which the contract cannot be executed.
                                        </td>
                                        <td className="px-4 py-2  border border-solid border-gray-300">As long as the person is using the application and working for TotalEnergies EP Nigeria</td>
                                    </tr>
                                </tbody>
                            </table>


                            {/* 2. Recipients of the data */}
                            <h6 className="mb-0 font-bold mt-12 underline">2. Recipients of the data</h6>
                            <p className='mb-5'>In order to process your personal data according to the Charter, your personal data may be communicated to one or more of the data controller's departments or to TotalEnergies company subsidiaries and one or more partners, independent distributors or subcontractors: Certain departments at TotalEnergies based on the nature of transaction</p>


                            {/* 3. Data security and confidentiality */}
                            <h6 className="mb-0 font-bold mt-12 underline">3. Data security and confidentiality</h6>
                            <p className='mb-5'>The data controller takes appropriate steps to preserve the security and confidentiality of your personal data, including to prevent them from being distorted, damaged, or disclosed to unauthorized third parties.</p>



                            {/* 4. Cookies management */}
                            <h6 className="mb-0 font-bold mt-12 underline">4. Cookies management</h6>
                            <p className='mb-2'>
                                <span>A cookie is a file which enables a website to save information relating to your computer's browsing of the website</span>
                                <a href="https://cpnceforms-dev.tepnglocal.iasp.tgscloud.net" target="_blank" rel="noreferrer noopener">
                                    https://cpnceforms-dev.tepnglocal.iasp.tgscloud.net
                                </a>
                                to make your visits to the website smoother.
                            </p>
                            <p className='mb-2'>
                                <span>Cookies are text files that are stored on your computer's browser. They allow access to various information about you. Some of them belong to the publisher of the Website</span>
                                <a href="https://cpnceforms-dev.tepnglocal.iasp.tgscloud.net" target="_blank" rel="noreferrer noopener">
                                    https://cpnceforms-dev.tepnglocal.iasp.tgscloud.net
                                </a>
                                (First party) and some to third parties.
                            </p>
                            <p>The table below details each cookie.</p>
                            <p className='mb-2'>
                                <span>We enable cookies once you have given your consent, except for technical cookies that are necessary to provide the service you request on the Website </span>
                                <a href="https://cpnceforms-dev.tepnglocal.iasp.tgscloud.net" target="_blank" rel="noreferrer noopener">
                                    https://cpnceforms-dev.tepnglocal.iasp.tgscloud.net
                                </a>
                            </p>
                            <table className="table-auto w-full mb-5 border border-solid border-gray-300">
                                <thead className="bg-gray-100 text-black">
                                    <tr>
                                        <th className="px-4 py-2  border border-solid border-gray-300">Categories of cookies and trackers</th>
                                        <th className="px-4 py-2  border border-solid border-gray-300">Name of the cookies and trackers</th>
                                        <th className="px-4 py-2  border border-solid border-gray-300">Objectives of the cookies and trackers</th>
                                        <th className="px-4 py-2  border border-solid border-gray-300">Editor</th>
                                        <th className="px-4 py-2  border border-solid border-gray-300">Retention period of the cookies and trackers</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="px-4 py-2  border border-solid border-gray-300">Technical cookie</td>
                                        <td className="px-4 py-2  border border-solid border-gray-300">TepngUserRefreshToken</td>
                                        <td className="px-4 py-2  border border-solid border-gray-300">Authentication Cookie for JavaScript Web Token Refresh</td>
                                        <td className="px-4 py-2  border border-solid border-gray-300">HttpOnly, Secure</td>
                                        <td className="px-4 py-2  border border-solid border-gray-300">24 hours</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p className='font-medium'>How to withdraw your consent?</p>
                            <p className='mb-2'>
                                <span>On your first connection to the website </span>
                                <a href="https://cpnceforms-dev.tepnglocal.iasp.tgscloud.net" target="_blank" rel="noreferrer noopener">
                                    https://cpnceforms-dev.tepnglocal.iasp.tgscloud.net
                                </a>,
                                you will be asked to give your consent to the use of non-essentials cookies and/or to make setting. If you subsequently wish to change your choices, you can manage your cookies by going to the section at the bottom of the page of our site or application or via a reminder button called cookies.
                            </p>
                            <p className='mb-10'>To delete cookies already stored, please refer to the procedure provided by your operating system (Windows, OS X, etc.).</p>



                            {/* 5. Your rights / Contact */}
                            <h6 className="font-bold mb-0 underline mt-12">5. Your rights / Contact</h6>
                            <p className='mb-2'>
                                In accordance with the current regulations, you have the right to access and correct your data and to give post-mortem instructions. Regarding processing based on the performance of a contract or the implementation of pre-contractual measures, you have also the right to delete your data and to data portability and you can request a restriction of processing.
                            </p>
                            <p className='mb-12'>
                                To exercise your rights and question us about your personal data processing, you can contact Human Resource
                                <a href="mailto:ep-ng.dpl@totalenergies.com" target="_blank" rel="noreferrer noopener"> ep-ng.dpl@totalenergies.com </a>
                                If you feel, after contacting us, that your rights have not been respected, you can lodge a complaint with the competent supervisory authority.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Consent Checkbox */}
                <div className="fixed bottom-0 left-0 w-full bg-gray-800 text-white p-6 xs:p-4">
                    <div className="max-w-screen-md mx-auto flex justify-between items-center text-sm">
                        <div className="flex items-center gap-4">
                            <div className="round square absolute">
                                <input type="checkbox" id="consentCheckbox" checked={consentCheckbox} onChange={handleCheckboxChange} />
                                <label htmlFor="consentCheckbox"></label>
                            </div>
                            <label htmlFor="consentCheckbox" className='ml-8'>I have read, understood and consent to the use of my name and TotalEnergies email</label>
                        </div>
                        
                        <button onClick={handleConsent} disabled={!consentCheckbox} className="bg-white hover:bg-gray-100 border text-red-800 px-6 py-2 font-bold rounded-3xl flex items-center gap-2">
                            <FaCheck />
                            <span>Accept</span>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Policy;