import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { APP_ENV } from "./utils";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    {APP_ENV !== "prod" ? (
      <section className="test-env-message">
        <div className="text-center">
          This is a test environment
          <span className="sm:inline hidden">
            - forms submitted on this link will not be treated!
          </span>
        </div>
      </section>
    ) : (
      <></>
    )}

    <App />
  </Provider>
);

reportWebVitals();
console.log("Hey Devo, 😆 Positive energy!");
