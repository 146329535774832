import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { XMark } from '../../components/Icons';
import { _$http } from '../../services/_config.services';


export default function SettingsHeader() {
    const [currentURl, setCurrentURL] = useState("")
    const [toggleModal, setToggleModal] = useState(false)
    const [ConversionRates, setConversionRates] = useState<any[]>([])

    useEffect(() => {
        setCurrentURL(window.location.href)
        getConversionRates();
    }, []);

    

  const getConversionRates = () => {
        _$http('GET', 'ForeignExchange/RefreshConversionRates').then(res => {
            setConversionRates(res.data.data)
        })
    };

    

    return (
        <>
            <section className="dark:bg-gray-800 flex justify-between rounded-tr-lg rounded-tl-lg bg-white pt-4 text-sm font-medium text-center text-gray-500 dark:text-gray-400 border-b">
                <ul className="flex flex-wrap justify-start">
                    <Link to={"/admin/forms/raisedforms/"+ currentURl.split('/')[currentURl.split('/').length-1]} className="me hover:bg-gray-50">
                        <button className={window.location.href.includes("raisedforms") ? "inline-block py-4 px-12 font-bold text-red-500 border-red-500 border-b-2 dark:text-red-500 dark:border-blue-500": "inline-block py-4 px-12 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}>Raised Forms</button>
                    </Link>
                    <Link to={"/admin/forms/manageformcontent/"+ currentURl.split('/')[currentURl.split('/').length-1]} className="me hover:bg-gray-50">
                        <button className={window.location.href.includes("manageformcontent") ? "inline-block py-4 px-12 font-bold text-red-500 border-red-500 border-b-2  dark:text-red-500 dark:border-blue-500": "inline-block py-4 px-12 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"}>Manage Form Content</button>
                    </Link>
                </ul>                

                <div className='flex justify-end px-4 mb-4'>
                    <button onClick={() => setToggleModal(true)} className='rounded border border-red-500 text-red-500 px-4 py-2 text-sm bg-red-500 text-white'>View Exchange Rate</button>
                </div>
            </section>


            {
              toggleModal &&
              <div className="fixed inset-0 flex items-center justify-center z-50" style={{background:'rgb(0 0 0 / 52%)'}}>
                  <div className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto p-4 dark:bg-gray-600">
                        <div className='flex justify-between'>
                            <h2 className="text-lg">Exchange Rate</h2>
                            <button onClick={() => setToggleModal(false)} className="ml-6 p-1" title='Close Modal'><XMark /></button>
                        </div>

                        <div className="modal-content my-4  h-[300px] overflow-scroll">
                            <p className='text-xs'><span className='text-gray-500'>Rate as at:</span> {`${new Date().getDate()}/${new Date().getMonth()+1}/${new Date().getFullYear()}`}</p>

                            {
                                ConversionRates.map((item, index)=>
                                    <div className='flex justify-between gap-3 mt-1' key={index}>
                                        <div>
                                            {/* <label htmlFor="" className="block text-xs font-medium text-gray-900 dark:text-white">{item.targetCurrency}</label> */}
                                            <input type="text" name='name' disabled className="w-full bg-white-50 border border-gray-300 text-gray-900 text-xs rounded focus:ring-blue-800 focus:border-blue-800 p-0.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={`${item.sourceCurrency} 1`} />        
                                        </div>  

                                        <div className="block mb-2 text-sm font-bold text-gray-900 dark:text-white mt-1">=</div>

                                        <div>
                                            {/* <label htmlFor="" className="block text-xs font-medium text-gray-900 dark:text-white">{item.sourceCurrency}</label> */}
                                            <input type="text" name='name' disabled className="w-full bg-white-50 border border-gray-300 text-gray-900 text-xs rounded focus:ring-blue-800 focus:border-blue-800 p-0.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={`${item.targetCurrency} ${item.factor}`} />        
                                        </div> 
                                    </div> 
                                )
                            }                    
                        </div>
                  </div>
              </div>
            } 

        </>
    )
}