import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  APP_ENV,
  axiosError,
  closeAlert,
  preloader,
  tinyAlert,
  truncateText,
  _$http,
} from "../../utils";
import { TepngUser, User } from "../../interfaces/users.interface";
import { ReactProps } from "../../interfaces";
import { useEffect, useState } from "react";
import {
  LuBell,
  LuChevronDown,
  LuFileSignature,
  LuHome,
  LuLock,
  LuSettings2,
  LuShield,
  LuUser2,
  LuZap,
} from "react-icons/lu";
import api from "../../services/form.services";
import { FormDetails } from "../../interfaces/forms.interface";
import DOMPurify from "dompurify";

const Header = ({ title = "CPNC" }: ReactProps) => {
  const location = useLocation();
  const account_type = location.pathname.split("/")[1];
  const active_path = location.pathname.split("/")[2];
  const secondary_path = location.pathname.split("/")[3];
  const formType = location.pathname.split("/")[4];
  const [isApproval, setIsApproval] = useState(false);
  const [formDescription, setFormDescription] = useState<FormDetails[]>();
  const [pendingApprovalCount, setPendingApprovalCount] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCounter, setNotificationCounter] = useState<number>(0);

  const tepngUser: TepngUser = useSelector(
    (state: any) => state.tepngUser.value
  );

  const user: User = tepngUser.user;

  useEffect(() => {
    getNotificationCount();
    CheckIsApprover();
  }, []);

  useEffect(() => {
    api.fetchAvailableFormsCount().then((res) => setFormDescription(res));
    api.fetchNotification(1, 5, 2).then((res) => {
      const data = res.data;
      setNotifications(data);

      const unreadIds: any = data.map((item: any) => item.id);

      const Payload: any = {
        notificationMessageIds: unreadIds,
        status: true,
      };

      api.updateNotification("Notification", Payload);
    });
  }, []);

  useEffect(() => {
    const countval = Number(
      formDescription
        ?.map((form) => form.pendingCount)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    );

    setPendingApprovalCount(countval);

    if (countval > 0)
      tinyAlert(
        "info",
        `You have ${countval} pending form${
          countval > 1 ? "s" : ""
        } awaiting your approval`
      );
  }, [formDescription]);

  const getNotificationCount = () => {
    preloader();
    _$http("GET", "Notification/MessageCount")
      .then((res) => {
        closeAlert();
        const record: any = res.data.data.totalUnreadMessages;
        setNotificationCounter(record);
      })
      .catch((error) => {
        axiosError(error);
      });
  };

  const CheckIsApprover = () => {
    preloader();
    _$http("GET", "User/CheckIsApprover")
      .then((res) => {
        setIsApproval(res.data.data.isApprover);
      })
      .catch((error) => {
        axiosError(error);
      });
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const sanitizeHTML = (html: string | undefined) => {
    return { __html: DOMPurify.sanitize(html || "") };
  };

  return (
    <section>
      <header className={`shadow-lg header-fixed-area font-medium sm:py-3 py-2 sm:px-8 px-3 fixed ${APP_ENV !== "prod"  ? 'top-5':'top-0' } right-0 z-999 dark:bg-gray-700 dark:text-gray-300 dark:border-[#474747]`}>
        <div className="flex justify-between">
          <div className="">
            <h1 className="pt-4 sm:pt-1 text-md font-bold capitalize">
              {truncateText(title, 50)}
            </h1>
            <p className="text-gray-600 font-semibold text-sm dark:text-gray-400 hidden sm:block relative top-[-2px]">
              {account_type} / {active_path} {!!secondary_path && "/"}{" "}
              {secondary_path} {!!formType && "/"} {formType}
            </p>
          </div>

          <div className="flex mb-2 gap-2">
            <span className="border-gray-400 pr-6 pt-1 font-bold text-md hidden sm:block">
              Welcome, {user.firstName} {user.lastName}
              <b className="text-[#F93E4C] text-2xl">.</b>
            </span>

            <span className='pr-6 pt-1 font-["50"] text-2xl text-gray-400'>
              |
            </span>

            <span className="mr-5 pt-3 relative">
              <button onClick={toggleDropdown}>
                <LuBell className="text-2xl text-gray-600 hover:text-[#F93E4C] dark:text-gray-400" />
                {notificationCounter > 0 && (
                  <label className="beamer_icon active bg-red-600 text-white rounded text-[10px] p-1 border shadow-sm ml-1 h-[23px] flex">
                    {notificationCounter}
                  </label>
                )}
              </button>

              {isOpen && (
                <div
                  className="absolute right-[-20px] mt-2 w-[300px]"
                  onMouseLeave={toggleDropdown}
                >
                  <div className="relative">
                    <div className="absolute right-6 top-0 transform -translate-y-full">
                      <div className="w-0 h-0 border-l-8 border-r-8 border-b-8 border-transparent border-b-gray-200"></div>
                    </div>

                    <div
                      className="py-2 bg-gray-200 rounded-md shadow-lg overflow-hidden z-20"
                      style={{ height: "50vh", overflowY: "scroll" }}
                    >
                      {notifications.length === 0 ? (
                        <div className="text-gray-600 px-4 py-2">
                          No notifications
                        </div>
                      ) : (
                        notifications.map((notification: any) => (
                          <div
                            key={notification?.id}
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-200 text-xs notification-link border-b border-1 border-[#ccc]"
                          >
                            {/* <div
                              dangerouslySetInnerHTML={{
                                __html: !!notification
                                  ? notification?.message
                                  : "",
                              }}
                            /> */}
                            
                            <div
                              dangerouslySetInnerHTML={sanitizeHTML(
                                notification?.message
                              )}
                            />
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              )}
            </span>

            <div className="mt-3">
              <div className="relative group">
                <button className="flex">
                  <LuUser2 className="text-2xl text-gray-600 dark:text-gray-400" />
                  <LuChevronDown className="font-light text-md text-gray-700 mt-2 dark:text-gray-400" />
                  {pendingApprovalCount > 0 && (
                    <label className="border-[#000] absolute top-[-13px] w-[45px] right-[-27px] bg-red-600 text-white rounded text-[9px] p-1 border shadow-sm ml-1 h-[21px] flex hidden">
                      {pendingApprovalCount} NEW
                    </label>
                  )}
                </button>

                <ul
                  style={{ left: "-180px", top: "20px", fontSize: "14px" }}
                  className="absolute hidden mt-1 space-y-2 bg-white border border-gray-300 py-2 rounded-md shadow-lg w-[230px] left-0 z-10 group-hover:block dark:bg-gray-600 dark:text-gray-400"
                >
                  <li>
                    <Link
                      to={`/${account_type}/user-manual`}
                      className="block px-4 py-1 text-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 flex dark:text-gray-300"
                    >
                      <LuZap className="text-lg" />
                      <span className="ml-3">User Manual</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={`/${account_type}/help`}
                      className="block px-4 py-1 text-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 flex dark:text-gray-300"
                    >
                      <LuShield className="text-lg" />
                      <span className="ml-3">Help</span>
                    </Link>
                  </li>

                  {account_type !== "initiator" && (
                    <li className="border-t">
                      <Link
                        to="/initiator/dashboard"
                        className="block px-4 py-1 pt-3 text-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 flex cursor-pointer dark:text-gray-300 flex justify-between"
                      >
                        <span className="flex">
                          <LuHome className="text-lg" />
                          <span className="ml-3">Go Home</span>
                        </span>
                      </Link>
                    </li>
                  )}

                  {isApproval && account_type !== "approval" && (
                    <li className="border-t">
                      <Link
                        to="/approval/approval-dashboard"
                        className="block px-4 py-1 pt-3 text-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 flex cursor-pointer dark:text-gray-300 flex justify-between"
                      >
                        <span className="flex">
                          <LuFileSignature className="text-lg" />
                          <span className="ml-3">Approval Portal</span>
                        </span>
                        {pendingApprovalCount > 0 && (
                          <label className="bg-red-600 text-white rounded text-[10px] p-1 border shadow-sm ml-1 h-[23px] flex">
                            {pendingApprovalCount}
                          </label>
                        )}
                      </Link>
                    </li>
                  )}

                  {tepngUser.roles.includes("Administrator") &&
                    account_type !== "admin" && (
                      <li className="border-t">
                        <Link
                          to="/admin/ms-reporting-and-analytics"
                          className="block px-4 py-1 pt-3 text-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 flex cursor-pointer dark:text-gray-300"
                        >
                          <LuSettings2 className="text-lg" />
                          <span className="ml-3">Admin Portal</span>
                        </Link>
                      </li>
                    )}

                  <li className="border-t border-gray-100 bg-gray-200 dark:bg-gray-500">
                    <Link
                      to="/logout-tepnguser"
                      className="block px-4 py-1 text-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 flex cursor-pointer dark:text-gray-300"
                    >
                      <LuLock className="text-lg" />
                      <span className="ml-3">Logout</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </section>
  );
};

export default Header;
