import { Routes, Route } from "react-router-dom";
import { ReactProps } from "../../interfaces";
import useApprovalProtectedRoute from "../../hooks/UseApprovalProtectedRoute";
import ApprovalDashboard from "../../views/utilities/ApprovalDashboard";
import PendingForms from "../../views/forms/PendingForms";
import Notifications from "../../views/utilities/Notifications";
import Help from "../../views/utilities/Help";
import Manual from "../../views/utilities/Manual";
import NoRouteMatch from "../../views/authentication/NoRouteMatch";
import AAFDetails from "../../views/forms/admin-amendment-form/AAF.details";
import CAFADetails from "../../views/forms/contract-approval-form-admin-amendment-for-decentralized-purchasing/CAFA.details";
import CAFDetails from "../../views/forms/contract-approval-form/CAF.details";
import CPNCRSDetails from "../../views/forms/cpnc-routing-slip/CPNCRS.details";
import RSMPDetails from "../../views/forms/routing-slip-for-material-purchase/RSMP.details";
import TUCNDetails from "../../views/forms/tucn-savings-declaration-sheet/TUCN.details";
import DFSTDetails from "../../views/forms/routing-slip-for-deviation-from-standard-template/RST.details";
import TASDetails from "../../views/forms/routing-slip-for-tas-contracts/TAS.details";
import ApprovalHistory from "../../views/forms/ApprovalHistory";

function ProtectedApprovalRoute({ children, title = "CPNC" }: ReactProps) {
  const getProtectedRoute = useApprovalProtectedRoute();
  return getProtectedRoute(children, title);
}

function ApprovalRoutes() {
  return (
    <Routes>
      <Route path="*" element={<NoRouteMatch />} />
      <Route
        path="approval-dashboard"
        element={
          <ProtectedApprovalRoute title="Approval Statistic">
            <ApprovalDashboard />
          </ProtectedApprovalRoute>
        }
      />
      <Route
        path="forms/pending/:formType"
        element={
          <ProtectedApprovalRoute title="Pending Approval">
            <PendingForms />
          </ProtectedApprovalRoute>
        }
      />
      <Route
        path="forms/history/:formType"
        element={
          <ProtectedApprovalRoute title="Approval History">
            <ApprovalHistory />
          </ProtectedApprovalRoute>
        }
      />
      <Route
        path="notifications"
        element={
          <ProtectedApprovalRoute title="Notifications">
            <Notifications />
          </ProtectedApprovalRoute>
        }
      />
      <Route
        path="help"
        element={
          <ProtectedApprovalRoute title="FAQ & Help">
            <Help />
          </ProtectedApprovalRoute>
        }
      />
      <Route
        path="user-manual"
        element={
          <ProtectedApprovalRoute title="User's Manual">
            <Manual />
          </ProtectedApprovalRoute>
        }
      />

      {/* Approve form links */}
      <Route
        path="forms/details/admin-amendment-form/:id/:formApproverId"
        element={
          <ProtectedApprovalRoute title="Approve Form">
            <AAFDetails />
          </ProtectedApprovalRoute>
        }
      />
      <Route
        path="forms/details/contract-approval-form-admin-amendment-for-decentralized-purchasing/:id/:formApproverId"
        element={
          <ProtectedApprovalRoute title="Approve Form">
            <CAFADetails />
          </ProtectedApprovalRoute>
        }
      />
      <Route
        path="forms/details/contract-approval-form/:id/:formApproverId"
        element={
          <ProtectedApprovalRoute title="Approve Form">
            <CAFDetails />
          </ProtectedApprovalRoute>
        }
      />
      <Route
        path="forms/details/cpnc-routing-slip/:id/:formApproverId"
        element={
          <ProtectedApprovalRoute title="View Form">
            <CPNCRSDetails />
          </ProtectedApprovalRoute>
        }
      />
      <Route
        path="forms/details/routing-slip-for-material-purchase/:id/:formApproverId"
        element={
          <ProtectedApprovalRoute title="View Form">
            <RSMPDetails />
          </ProtectedApprovalRoute>
        }
      />
      <Route
        path="forms/details/tucn-savings-declaration-sheet/:id/:formApproverId"
        element={
          <ProtectedApprovalRoute title="View Form">
            <TUCNDetails />
          </ProtectedApprovalRoute>
        }
      />
      <Route
        path="forms/details/routing-slip-for-deviation-from-standard-template/:id/:formApproverId"
        element={
          <ProtectedApprovalRoute title="View Form">
            <DFSTDetails />
          </ProtectedApprovalRoute>
        }
      />
      <Route
        path="forms/details/routing-slip-for-tas-contracts/:id/:formApproverId"
        element={
          <ProtectedApprovalRoute title="View Form">
            <TASDetails />
          </ProtectedApprovalRoute>
        }
      />
    </Routes>
  );
}

export default ApprovalRoutes;
