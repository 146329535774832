import { configureStore } from '@reduxjs/toolkit';
import tepngUser from './tepngUser';
import formDescription from './formDescription';

export default configureStore({
  reducer: {
    tepngUser : tepngUser,
    formDescription : formDescription,
  },
})


// import { configureStore } from '@reduxjs/toolkit';
// import tepngUser from './tepngUser';
// import formDescription from './formDescription';

// configureStore({
//   reducer: {
//     tepngUser : tepngUser,
//     formDescription : formDescription,
//   },
// })

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch