import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import {TepngUser} from '../interfaces/users.interface';
import AdminLayout from '../layouts/admin/AdminLayout';


const useProtectedRoute = (defaultTitle: string = 'Digital CPNC') => {
  const tepngUser: TepngUser = useSelector((state: any) => state.tepngUser.value);
  const location = useLocation();

  useEffect(() => {
    if (tepngUser == null) {
      localStorage.setItem("loginRedirectedFromUrl", location.pathname + location.search);
    }
  }, [tepngUser, location]);

  const getProtectedRoute = (children: React.ReactNode, title: string = defaultTitle) => {
    if (tepngUser == null) {
      return <Navigate to="/login-tepnguser" replace />;

    } else {
      if (tepngUser.user.consent.consentToeForms) {
        return <AdminLayout title={title}>{children}</AdminLayout>;

      } else {
        return <Navigate to='/personal-data-and-cookies-consent-form' replace />;
        
      }
    }
  };

  return getProtectedRoute;
};

export default useProtectedRoute;
